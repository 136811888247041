import React, { useCallback, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import catalogStyles from "../../pages/active-offers/styles.module.sass";
import { after } from '../../utils';

const CatalogList = ({ catalogs, exclude = "", filter = "", formToLinkFn }) => {
  const catalogsList = useMemo(()=> catalogs?.filter(catalog => (catalog.type !== exclude || catalog.type === filter)), [catalogs, exclude, filter]);
  const [imagesLoaded, setImagesLoaded] = useState(!catalogsList?.length);

  const onLoad = useMemo(()=> after(catalogsList?.length, () => {
    setImagesLoaded(true);
  }), [catalogsList]);

  const Catalog = useCallback(({ obj }) => {
    const image = obj.images.find((i) => i.type === 'marketing');

    const html = <div role="button" tabIndex="0" aria-label="Go to catalog" className={catalogStyles["catalog-item"]}>
      <img alt="catalog thumbnail" src={image.url} onLoad={onLoad} style={{
        backgroundImage: `url(${image.url})`,
        backgroundSize: "100% 100%"
      }} />
    </div>;
    
    return formToLinkFn ? <Link to={formToLinkFn(obj)}>{html}</Link> : html;
  }, [formToLinkFn, onLoad]);

  if (!catalogs?.length) return '';

  return (
    <section className={`${catalogStyles["catalogs-section"]} ${!imagesLoaded ? 'hide' : ''}`}>
      <section className={catalogStyles["catalogs-content"]}>
        <div className={catalogStyles["horizontal-items"]}>
          {catalogsList?.map((catalog) => (
            <Catalog key={catalog?.id} obj={catalog} />
          ))}
        </div>
      </section>
    </section>
  );
};

export default withTranslation()(CatalogList);
