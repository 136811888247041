import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthActions from '../../redux/auth/actions';
import TutorialItem from './TutorialItem';

const TutorialList = ({ data, loading, modals, userId}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(AuthActions.fetchUserMe());
  }, [dispatch]);  
  
  const goToHome = () => {
    history.push(`/home`);
  };

  const goToActions = {
    catalogoPersonalizado: goToHome
  };

  const defaultGoTo = goToHome;

  const onClickHandler = (tutorial) => {

    const newModals = { ...modals };
    newModals[tutorial.slug] = false;

    if (userId) dispatch(AuthActions.updateUserMe({ userId, modals: newModals }));    
    
    if (goToActions[tutorial.slug]) goToActions[tutorial.slug]();
    else defaultGoTo();
  };

  return (
    <>
      {(data && data.length > 0) &&
        data.map((tutorialItem, index) => (
          <TutorialItem 
            key={`Tutorial${index}`}
            data={tutorialItem}  
            onClick={onClickHandler}
          />
        ))
      }
      {loading &&
        <>
          <TutorialItem loading={loading} />
          <TutorialItem loading={loading} />
          <TutorialItem loading={loading} />
        </>
      }
    </>
  );
};

export default TutorialList;
