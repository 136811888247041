import { createActions } from "redux-actions";
// import { ToastContainer, ToastMessage } from "react-toastr";


import { GenericError } from "../../utils/errors";
import { 
    getPaymentMethods,
    createPaymentTransaction
 } from "../../services/payments";


const PaymentActions = createActions({
  FETCH_PAYMENT_METHODS_REQUEST: () => {},
  FETCH_PAYMENT_METHODS_SUCCESS: (paymentMethods) => ({paymentMethods}),
  FETCH_PAYMENT_METHODS_FAILED: (error) => ({ error }),

  CREATE_PAYMENT_TRANSACTION_REQUEST: () => {},
  CREATE_PAYMENT_TRANSACTION_SUCCESS: (paymentTransaction) => ({paymentTransaction}),
  CREATE_PAYMENT_TRANSACTION_FAILED: (error) => ({ error }),
});

PaymentActions.fetchPaymentMethods = (data) => async (dispatch) => {
  try {
    dispatch(PaymentActions.fetchPaymentMethodsRequest());
    const result = await getPaymentMethods(data);
    if (!result.ok) throw (result.originalError);
    dispatch(PaymentActions.fetchPaymentMethodsSuccess(result.data));
  } catch (err) {
    dispatch(PaymentActions.fetchPaymentMethodsFailed({ error: new GenericError(err, err) }));
  }
};

PaymentActions.createPaymentTransaction = (data, token) => async (dispatch) => {
  try {
    dispatch(PaymentActions.createPaymentTransactionRequest());
    const result = await createPaymentTransaction(data, token);
    dispatch(PaymentActions.createPaymentTransactionSuccess(result));
  } catch (err) {
    dispatch(PaymentActions.createPaymentTransactionFailed({ error: new GenericError(err, err) }));
  }
};




export default PaymentActions;
