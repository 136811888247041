import Immutable from 'seamless-immutable';

const initialState = Immutable({
  pointsLoading: false,
  points: [],
  pointsLoaded: false,
  pointsError: null,
  pointsHistoryLoading: false,
  pointsHistory: [],
  pointsHistoryLoaded: false,
  pointsHistoryError: null,
  pointsHistoryPagination: null,
  challengesLoading: false,
  challenges: [],
  challengesLoaded: false,
  challengesError: null, 
  voucherLoading: false,
  voucherError: null,
  voucherLoaded: false,
  voucher: null, 
  vouchersLoading: false,
  vouchesrError: null,
  vouchersLoaded: false,
  vouchers: null,
  vouchersPagination:null,
  exchangePointsLoading: false,
  exchangePointsError: null,
  exchangePointsLoaded: false,
  exchangePoints: null,
});


export default initialState;
