/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import { findIndex, prop, propEq, clone } from 'ramda';
import mixpanel from '../../services/mixpanel';

const StorageCart = (carts) => {
  localStorage.setItem('carts', JSON.stringify(Object.keys(carts).length > 0 ? carts : {}));
};

const StorageCartBuyNow = (carts) => {
  localStorage.setItem('cartsBuyNow', JSON.stringify(Object.keys(carts).length > 0 ? carts : {}));
};

const save = (state) => {
  StorageCart(state.carts);
  StorageCartBuyNow(state.cartsBuyNow);
  return state;
};

export const sumItems = (cartItems) => {
  const itemCount = cartItems.reduce((total, product) => total + product.currentQuantity, 0);
  const total = cartItems.reduce((total, product) => total + product.price * product.currentQuantity, 0);
  const basePrice = cartItems.reduce((total, product) => total + product.discountPrice * product.currentQuantity, 0);

  return { itemCount, total: total.toFixed(2), commission: (total - basePrice).toFixed(2) };
};

export const sumItemsBuyNow = (cartItems) => {
  const itemCount = cartItems.reduce((total, product) => total + product.currentQuantity, 0);
  const total = cartItems.reduce((total, product) => total + product.price * product.currentQuantity, 0);
  const basePrice = cartItems.reduce((total, product) => total + product.discountPrice * product.currentQuantity, 0);

  return { itemCount, total: total.toFixed(2), commission: (total - basePrice).toFixed(2) };
};

export const CartReducer = (state, action) => {
  
  const copy = clone(state);
  const storeCart = copy.carts[action.payload.storeId] || { cartItems: [], itemCount: 0, total: 0, commission: 0 };
  const storeCartBuyNow = copy.cartsBuyNow?.[action.payload.storeId] || { cartItems: [], itemCount: 0, total: 0, commission: 0 };

  switch (action.type) {
    case "ADD_ITEM":
      mixpanel.track_optimized('Add Item ', {id: action.payload.item.dealId});
      if (!storeCart.cartItems.find(item => item.id === action.payload.item.id)) {
        storeCart.cartItems.push({
          ...action.payload.item
        });
        const {itemCount, total, commission} = {...sumItems(storeCart.cartItems)};
        storeCart.itemCount = itemCount;
        storeCart.total = total;
        storeCart.commission = commission;
      } else {
        const indx = findIndex(propEq('id', prop('id', action.payload.item)))(storeCart.cartItems);
        storeCart.cartItems[indx] = action.payload.item;
      }
      !copy.carts[action.payload.storeId] && (copy.carts[action.payload.storeId] = storeCart);
      return save(copy);

    case "REMOVE_ITEM":
      mixpanel.track_optimized('Remove Item ', {id: action.payload.item.dealId});
      copy.carts[action.payload.storeId] = {
        ...storeCart,
        ...sumItems(storeCart.cartItems.filter(item => item.id !== action.payload.item.id)),
        cartItems: [...storeCart.cartItems.filter(item => item.id !== action.payload.item.id)]
      };
      !copy.carts[action.payload.storeId] && (copy.carts[action.payload.storeId] = storeCart);
      return save(copy);

    case "INCREASE": {
      mixpanel.track_optimized('Increase Item ', { id: action.payload.item.dealId });
      const indxIncrease = storeCart.cartItems.findIndex(item => item.id === action.payload.item.id);
      const cloneIncrease = storeCart.cartItems;
      const productIncrease = cloneIncrease[indxIncrease];
      if (productIncrease.quantity == null || productIncrease.currentQuantity < productIncrease.quantity) {
        productIncrease.currentQuantity++;
      }
      const updateTotals = sumItems(cloneIncrease);
      storeCart.total = updateTotals.total;
      storeCart.itemCount = updateTotals.itemCount;
      storeCart.commission = updateTotals.commission;
      return save(copy);
    }
    case "DECREASE": {
      mixpanel.track_optimized('Decrease Item ', { id: action.payload.item.dealId });
      const indxDecrease = storeCart.cartItems.findIndex(item => item.id === action.payload.item.id);
      const cloneDecrease = storeCart.cartItems;
      const productDecrease = cloneDecrease[indxDecrease];
      if (productDecrease.currentQuantity > 1) {
        productDecrease.currentQuantity--;
      }
      const updateTotals2 = sumItems(cloneDecrease);
      storeCart.total = updateTotals2.total;
      storeCart.itemCount = updateTotals2.itemCount;
      storeCart.commission = updateTotals2.commission;
      return save(copy);
    }
    case "CHECKOUT": {
      copy.carts[action.payload.storeId] = {
        cartItems: [],
        checkout: true,
        itemCount: 0,
        total: 0,
        commission: 0
      };
      return save(copy);
    }
    case "CLEAR": {
      copy.carts[action.payload.storeId] = {
        cartItems: [],
        itemCount: 0,
        total: 0,
        commission: 0
      };
      return save(copy);

      // Buy Now
    }
    case "ADD_ITEM_BUYNOW": {
      mixpanel.track_optimized('Add Item ', { id: action.payload.item.dealId });
      if (!storeCartBuyNow.cartItems.find(item => item.id === action.payload.item.id)) {
        storeCartBuyNow.cartItems.push({
          ...action.payload.item
        });
        const { itemCount, total, commission } = { ...sumItemsBuyNow(storeCartBuyNow.cartItems) };
        storeCartBuyNow.itemCount = itemCount;
        storeCartBuyNow.total = total;
        storeCartBuyNow.commission = commission;
      } else {
        const indx = findIndex(propEq('id', prop('id', action.payload.item)))(storeCartBuyNow.cartItems);
        storeCartBuyNow.cartItems[indx] = action.payload.item;
      }
      !copy.cartsBuyNow[action.payload.storeId] && (copy.cartsBuyNow[action.payload.storeId] = storeCartBuyNow);
      return save(copy);
    }
    case "REMOVE_ITEM_BUYNOW":
      mixpanel.track_optimized('Remove Item ', {id: action.payload.item.dealId});
      copy.cartsBuyNow[action.payload.storeId] = {
        ...storeCartBuyNow,
        ...sumItemsBuyNow(storeCartBuyNow.cartItems.filter(item => item.id !== action.payload.item.id)),
        cartItems: [...storeCartBuyNow.cartItems.filter(item => item.id !== action.payload.item.id)]
      };
      !copy.cartsBuyNow[action.payload.storeId] && (copy.cartsBuyNow[action.payload.storeId] = storeCartBuyNow);
      return save(copy);

    case "INCREASE_BUYNOW": {
      mixpanel.track_optimized('Increase Item ', { id: action.payload.item.dealId });
      const indxIncreaseBuyNow = storeCartBuyNow.cartItems.findIndex(item => item.id === action.payload.item.id);
      const cloneIncreaseBuyNow = storeCartBuyNow.cartItems;
      const productIncreaseBuyNow = cloneIncreaseBuyNow[indxIncreaseBuyNow];
      if (productIncreaseBuyNow.quantity == null || productIncreaseBuyNow.currentQuantity < productIncreaseBuyNow.quantity) {
        productIncreaseBuyNow.currentQuantity++;
      }
      const updateTotalsBuyNow = sumItemsBuyNow(cloneIncreaseBuyNow);
      storeCartBuyNow.total = updateTotalsBuyNow.total;
      storeCartBuyNow.itemCount = updateTotalsBuyNow.itemCount;
      storeCartBuyNow.commission = updateTotalsBuyNow.commission;
      return save(copy);
    }
    case "DECREASE_BUYNOW": {
      mixpanel.track_optimized('Decrease Item ', { id: action.payload.item.dealId });
      const indxDecreaseBuyNow = storeCartBuyNow.cartItems.findIndex(item => item.id === action.payload.item.id);
      const cloneDecreaseBuyNow = storeCartBuyNow.cartItems;
      const productDecreaseBuyNow = cloneDecreaseBuyNow[indxDecreaseBuyNow];
      if (productDecreaseBuyNow.currentQuantity > 1) {
        productDecreaseBuyNow.currentQuantity--;
      }
      const updateTotals2BuyNow = sumItemsBuyNow(cloneDecreaseBuyNow);
      storeCartBuyNow.total = updateTotals2BuyNow.total;
      storeCartBuyNow.itemCount = updateTotals2BuyNow.itemCount;
      storeCartBuyNow.commission = updateTotals2BuyNow.commission;
      return save(copy);
    }
    case "CHECKOUT_BUYNOW":
      copy.cartsBuyNow[action.payload.storeId] = {
        cartItems: [],
        checkout: true,
        itemCount: 0,
        total: 0,
        commission: 0
      };
      return save(copy);
      
    case "CLEAR_BUYNOW":
      copy.cartsBuyNow[action.payload.storeId] = {
        cartItems: [],
        itemCount: 0,
        total: 0,
        commission: 0
      };
      return save(copy);

    default:
      return state;
  }
};