import PointsActions from "./actions";
import InitialState from ".";

// eslint-disable-next-line default-param-last
export const reducer = (state = InitialState, action) => {

  let comm;
  let commVoucher;

  switch (action.type) {

    case PointsActions.fetchPointsRequest().type:
      return state.merge({
        pointsLoading: true,
        pointsError: null,
        pointsLoaded: false,
        points: 0,
      });

    case PointsActions.fetchPointsSuccess().type:
      comm = action.payload.points.points;
      return state.merge({
        pointsLoading: false,
        pointsError: null,
        pointsLoaded: true,
        points: comm,
      });
    
    case PointsActions.fetchPointsFailed().type:
      return state.merge({
        pointsLoading: false,
        pointsError: action.payload.error || state.error,
        pointsLoaded: true,
        points: null,
      });
    
    case PointsActions.fetchPointsHistoryRequest().type:
      return state.merge({
        pointsHistoryLoading: true,
        pointsHistoryError: null,
        pointsHistoryLoaded: false,
        // pointsHistoryPagination: null,
        // pointsHistory: action.payload.currentPage === 1 ? null: state.pointsHistory
      });

    case PointsActions.fetchPointsHistorySuccess().type:
      if (action.payload.points.pagination.currentPage === 1) {
        comm = action.payload.points.points;
      } else if(action.payload.points.points && action.payload.points.points.length) {
        comm = state.pointsHistory.concat(action.payload.points.points);
      } else {
        commVoucher = action.payload.points.points;
      }
      return state.merge({
        pointsHistoryLoading: false,
        pointsHistoryError: null,
        pointsHistoryLoaded: true,
        pointsHistory: comm,
        pointsHistoryPagination: action.payload.points.pagination
      });
    
    case PointsActions.fetchPointsHistoryFailed().type:
      return state.merge({
        pointsHistoryLoading: false,
        pointsHistoryError: action.payload.error || state.error,
        pointsHistoryLoaded: true,
        pointsHistoryPagination: null,
        pointsHistory: null,
      });
    
    case PointsActions.fetchChallengesRequest().type:
      return state.merge({
        challengesLoading: true,
        challengesError: null,
        challengesLoaded: false        
      });

    case PointsActions.fetchChallengesSuccess().type:
      return state.merge({
        challengesLoading: false,
        challengesError: null,
        challengesLoaded: true,
        challenges: action.payload.challenges,
      });
    
    case PointsActions.fetchChallengesFailed().type:
      return state.merge({
        challengesLoading: false,
        challengesError: action.payload.error || state.error,
        challengesLoaded: true,
        challenges: null,
      });

      case PointsActions.createVoucherRequest().type:
        return state.merge({
          voucherLoading: true,
          voucherError: null,
          voucherLoaded: false,
        });
  
      case PointsActions.createVoucherSuccess().type:
        return state.merge({
          voucherLoading: false,
          voucherError: null,
          voucherLoaded: true,
          voucher: action.payload.voucher.data || state.voucher,
        });

          
      case PointsActions.createVoucherFailed().type:
        return state.merge({
          voucherLoading: false,
          voucherError: action.payload.error || state.error,
          voucherLoaded: true,
          voucher: null,
        });

      case PointsActions.fetchVouchersRequest().type:
        return state.merge({
          vouchersLoading: true,
          vouchesrError: null,
          vouchersLoaded: false,
        });
  
      case PointsActions.fetchVouchersSuccess().type:
        if (action.payload.paging.page === 1) {
          commVoucher = action.payload.data;
        } else if(action.payload.data && action.payload.data.length){
          commVoucher = state.vouchers.concat(action.payload.data);
        } else {
          commVoucher = action.payload.data;
        }
        return state.merge({
          vouchersLoading: false,
          vouchersError: null,
          vouchersLoaded: true,
          vouchers: commVoucher,
          vouchersPagination: action.payload.paging
        });
      
      case PointsActions.fetchVouchersFailed().type:
        return state.merge({
          vouchersLoading: false,
          vouchersError: action.payload.error || state.error,
          vouchersLoaded: true,
          vouchers: null,
        });  

        case PointsActions.fetchExchangePointsRequest().type:
          return state.merge({
            exchangePointsLoading: true,
            exchangePointsError: null,
            exchangePointsLoaded: false,
            exchangePoints: null,
          });
    
        case PointsActions.fetchExchangePointsSuccess().type:
        return state.merge({
            exchangePointsLoading: false,
            exchangePointsError: null,
            exchangePointsLoaded: true,
            exchangePoints: action.payload,
          });
        
        case PointsActions.fetchExchangePointsFailed().type:
          return state.merge({
            exchangePointsLoading: false,
            exchangePointsError: action.payload.error || state.error,
            exchangePointsLoaded: true,
            exchangePoints: null,
          }); 

        case PointsActions.resetExchangePointsRequest().type:
          return state.merge({
            exchangePointsLoading: true,
            exchangePointsError: null,
            exchangePointsLoaded: false,
            exchangePoints: null,
          });
    
        case PointsActions.resetExchangePointsSuccess().type:
        return state.merge({
            exchangePointsLoading: false,
            exchangePointsError: null,
            exchangePointsLoaded: true,
            exchangePoints: null,
          });
        
        case PointsActions.resetExchangePointsFailed().type:
          return state.merge({
            exchangePointsLoading: false,
            exchangePointsError: action.payload.error || state.error,
            exchangePointsLoaded: true,
            exchangePoints: null,
          }); 
  
    default:
      return state;
  }
};
