import React, { useMemo } from "react";
import socialFooterFB from "../../assets/images/social/facebook-color.webp";
import socialFooterIG from "../../assets/images/social/instagram-color.webp";
import socialFooterWA from "../../assets/images/social/whatsapp-color.webp";
import { withTranslation } from 'react-i18next';
import packageJson from '../../../package.json';
import useGlobalStyles from "../../hooks/useGlobalStyles";
import { useTheme } from '@mui/material';
import View from "../../transition/View";
import LinkSurface from "../atoms/LinkSurface";
import TextLink from "../atoms/TextLink";
import Text from "../../transition/Text";
import { getSocialLinks } from "../../utils/countries";

const currentYear = new Date().getFullYear();
const FooterSocial = ({ dark, t, country }) => {

  const socials = useMemo(() => getSocialLinks(country), [country]); 
  const socialButtons = useMemo(()=>[
    {
      src: socialFooterWA,
      url: socials?.whatsapp?.url,
      alt: 'WhatsApp',
    },
    {
      src: socialFooterIG,
      url: socials?.instagram?.url,
      alt: 'Instagram',
    },
    {
      src: socialFooterFB,
      url: socials?.facebook?.url,
      alt: 'Facebook',
    },
  ], [socials]);

  const globalStyles = useGlobalStyles();
  const theme = useTheme();

  return <View style={{
    ...globalStyles.column,
    fontSize: theme.typography.body2.fontSize,
    color: dark ? theme?.palette?.common.white : theme?.palette?.text.primary,
    fontWeight: 600,
    background: dark ? theme?.palette?.customDarkBlue.main : theme?.palette?.background.default || theme?.palette?.background,
    padding: "22px 10px",
    boxShadow: "0px -2px 4px 0px rgb(0 0 0 / 8%)"
  }}>
    {(country && socials) &&
      <View style={{ ...globalStyles.row, justifyContent: "space-between", alignItems: "center" }}>
        <View><span role="img" aria-label={`${socials?.name} flag`}>{socials?.flag}</span> {socials?.name}</View>
        <View style={{...globalStyles.row, gap: "10px"}}>
          {
            socialButtons.map(({ alt, url, src }) => <LinkSurface newTab key={url} url={url} >
              <img style={{ borderRadius: "4px", height: "26px", width: "26px" }} src={src} alt={alt} />
          </LinkSurface>)
          }
        </View>
      </View>
    }
    <View style={{ marginTop: "20px", gap: "10px" }}>
      <TextLink path="/terms-and-conditions">
        {t('Privacy')}
      </TextLink>
      <TextLink path="/terms-and-conditions">
        {t('Terms & Conditions')}
      </TextLink>
      <Text>v{packageJson.version}</Text>
    </View>
    <View style={{ ...globalStyles.center, marginTop: '15px' }}>
      © WAO! Inc. {currentYear}
    </View>
  </View>;
};

export default withTranslation()(FooterSocial);