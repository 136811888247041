import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { isVideo } from '../../utils/constants';
import styles from './styles.module.sass';

const SliderProducts = ({ media, link, disable }) => {
  
  const history = useHistory();
  const [mediaState, setMediaState] = useState((typeof media === 'string') ? JSON.parse(media) : media);
  const [isVideoPlaying, setIsVideoPlaying] = useState({});

  useEffect(() => {
    setMediaState((typeof media === 'string') ? JSON.parse(media) : media);
  }, [setMediaState, media]);

  const handleClick = () => {
    history.push(link);
  };

  const settings = {
    dots: true,
    lazyLoad: 'ondemand',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    afterChange: () => setIsVideoPlaying({}),
  };


  return (
    <div>
      <Slider className={styles['deal-images']} {...settings} >
      {disable &&
        <div className={styles['slider-content']} onClick={handleClick}>
          <img src={mediaState?.[0]} alt=""/>
        </div>
      }
      { (!disable && mediaState) ?
        mediaState?.map( (item, key) => (
          <div className={styles['slider-content']} key={item || key} onClick={handleClick}>
            {
              isVideo(item) ?
                <ReactPlayer 
                  playsinline
                  style={{ maxHeight: "250px" }}
                  url={item} controls volume={0.5} width="100%"
                  stopOnUnmount
                  playing={isVideoPlaying[key] || false}
                  onPlay={() => {
                    const newObject = {};
                    newObject[key] = true;
                    setIsVideoPlaying({ ...isVideoPlaying, ...newObject });
                  }}
                  onPause={() => setIsVideoPlaying({})}
                  />
                :
                 (<img src={item} alt=""/>)
                }
          </div>
        ))
        :
        ""
      }      
    </Slider>
    </div>
  );
};

export default withTranslation()(SliderProducts);
