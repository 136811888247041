import React from 'react';
import styles from './styles.module.sass';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import {useSelector } from 'react-redux';
import HeaderStore from '../../components/HeaderStore';
import TutorialsList from './TutorialsList';
import { paths } from 'ramda';

const TutorialsContainer = ({ history, t }) => {

  const showLogo = false;
  const [modals, userId, userType, userMeLoaded] = paths([['userMe', 'modals'], ['userMe', 'id'], ['userMe', 'type'], ['userMeLoaded']], useSelector(state => state.auth));
  const {steppers, steppersLoading} = useSelector(state => state.stepperInfo);
  

  return (
    <div id="tutorial-scroll" className={`${styles['page-wrapper'] } app-wrapper-mobile`}>
      <section className={styles.content}>
        <section className={styles.header}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>WAO! - {t('Sociotutorial')}</title>
            <link rel="canonical" href="https://app.wao.shop/socio-tutorial" />
          </Helmet>
          <HeaderStore history={history} showLogo={showLogo} icons={{ back: true }} altColor />
        </section>
        <div className={styles['page-wrapper-tutorial']}>
          <div className={styles['tutorial-list-tile']}>
            <h3 >{t('Tutorials')}</h3>
          </div>
          <div className={styles['tutorial-list']}>
            <TutorialsList loading={steppersLoading} t={t} data={steppers} modals={modals} userId={userId} userType={userType} userMeLoaded={userMeLoaded}/>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(TutorialsContainer);
