import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { CancelToken } from "apisauce";
import mixpanel from "../../../services/mixpanel";
import styles from "./styles.module.sass";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SpinnerCircularFixed } from "spinners-react";
import { useSelector, useDispatch } from "react-redux";
import { equals, keys, paths, propEq } from "ramda";
import DealsList from "./DealsList";
import WaoIcon from "../../../components/WaoIcon";
import DealActions from "../../../redux/deals/actions";
import KeepScroll from "../../../components/KeepScroll";
import StoreActions from "../../../redux/stores/actions";
import HeaderStore from "../../../components/HeaderStore";
import { useWindowDimensions, usePrevious } from "../../../utils/hooks";
import { Grid } from "@mui/material";
import FooterSocial from "../../../components/FooterSocial";
import SliderPromos from "../../../components/SliderPromos";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import PageTrackingActions from "../../../redux/rewards/actions";
import PromotionsActions from "../../../redux/promotions/actions";
import FilterSortByPanel from "../../../components/FilterPanel/FilterSortByPanel";
import FiterByCategoryPanel from "../../../components/FilterPanel/FiterByCategoryPanel";
import VerticalCatalog from "../../../components/CatalogList/VerticalCatalog";
import CatalogsLoader from "../../../components/LoadingSkeletons/dealsCatalogsLoader";
import LoadingLine from "../../../components/LoadingSkeletons/loadingLine";
import { userSettingsLocal } from "../../../services/local";
  
const StoreDealsListing = ({ t, history, location }) => {
  const { storeGridView: previouslyStoredStoreGridView = true } = userSettingsLocal.get();
  const secondsToRedirectToStore = 4;
  const dispatch = useDispatch();
  const { height: windowHeight } = useWindowDimensions();
  const [promotionParamSelected, setPromotionParamSelected] = useState();
  const { userMe } = useSelector(state => state.auth);
  const { deals, dealsPagination, dealsLoading, dealsLoaded, dealsError } = useSelector(state => state.deals);

  const { promos, promosLoading } = useSelector(state => state.promotions);

  const {
    store: storeData,
    storeLoading: storeDataLoading,
    storeError: storeDataError,
  } = useSelector(state => state.stores);
  const previousStoreData = usePrevious(storeData);
  const [scroll, setScroll] = useState(false);
  const [categories, categoriesLoading] = paths(
    [['categories'], ['categoriesLoading']],
    useSelector(state => state.deals)
  );
  const { storeId, shareId } = useParams();
  const storeIdPrev = usePrevious(storeId);
  const [sourceDeals, setSourceDeals] = useState();
  const [requestedStore, setRequestedStore] = useState(false);
  const [requestedPromos, setRequestedPromos] = useState(false);
  const isReferrer = propEq('type', 'referrer');
  const isOwnStore = isReferrer && storeId && userMe?.shopId === storeId;
  const userId = userMe?.id;
  const { searchTerm, filters } = useSelector(state => state.deals);
  const [searchValue, setSearchValue] = useState(searchTerm || '');
  const [currentFilters, setCurrentFilters] = useState(filters || null);
  const [sort, setSort] = useState(null);
  const prevPromotionParamSelected = usePrevious(promotionParamSelected);
  const prevFilters = usePrevious(currentFilters);
  const prevSort = usePrevious(sort);
  const prevDeals = usePrevious(deals);
  const [visibleByCategory, setVisibleByCategory] = useState(false);
  const [visibleSortByPanel, setVisibleSortByPanel] = useState(false);
  const [singleDealView, setSingleDealView] = useState(!previouslyStoredStoreGridView);
  const [calledTimeout, setCalledTimeout] = useState(false);
  const store = useSelector(state => state.stores.store);
  const referrerId = store?.id;
  const fiterStickyRef = useRef();
  const [isSticky, setIsSticky] = useState(false);
  const willRedirect = promotionParamSelected && dealsError?.type === "DealsError" && dealsError?.status === 400;

  const fetchData = useCallback(
    (search, increase) => {
      const source = CancelToken.source();
      setSourceDeals(source);
      // If search term isn't empty send mixpanel event
      if (search) mixpanel.track_optimized("Search deals", { referrerId, search });
      dispatch(
        DealActions.fetchDeals({
          name: search,
          currentPage: increase ? (dealsPagination?.currentPage || 1) + 1 : 1,
          cancelToken: source.token,
          shopId: storeId,
          category: keys(currentFilters),
          promotions: promotionParamSelected,
          shareId,
          sort,
          userId,
          searchPromosObject: true,
          grantPoints: userMe?.type !== 'referrer' ? true : null
        })
      );
    },
    [referrerId, dispatch, dealsPagination?.currentPage, storeId, currentFilters, promotionParamSelected, shareId, sort, userId, userMe?.type]
  );

  const lookupEvent = value => {
    fetchData(value, false);
  };
  const onSearchChange = value => {
    // if (!value) fetchData(null, false);
    setSearchValue(value);
  };

  const formToLinkFn = obj => {
    const params = new URLSearchParams({ promotions: [obj.id] });
    return { pathname: location.pathname, search: params.toString() };
  };

  

  

  const capitalize = str => {
    const arr = str.toLowerCase().split(' ');

    for (let i = 0; i < arr.length; i += 1) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };
  const title = `WAO! - ${t("Socios Store", {
    socio: capitalize(storeData?.firstName || " "),
  })}`;
  const url = `${document.location.origin}/store/${storeId}`;
  let sectionTitle = t("OffersForYou");
  let currentPromo;
  let bannerToShow;



  if (promotionParamSelected && promos) {
    let promosList = [];
    const getPromos = () => {
      let p = [];
      if (promos?.promotionBackgroundList?.length) p = [...promos.promotionBackgroundList];
      if (promos?.promotionCatalogList?.length) p = [...p, ...promos.promotionCatalogList];
      return [...new Map(p.map((m) => [m.id, m])).values()];
    };
    promosList = getPromos();
    if (promosList.length > 0) {
      currentPromo = { ...promosList.find(({ id }) => `${id}` === `${promotionParamSelected}`) };
      if (currentPromo) {
        currentPromo.images = currentPromo?.images?.filter(({ type }) => !type || type === "marketing").map(image => image.url || image);
        sectionTitle = currentPromo?.name || sectionTitle;
         // currentPromo.images.find((i) => i.type === 'marketing')
        bannerToShow = currentPromo?.images?.[0];
      }
    }
  }

  const hasBackButton = (userMe && isReferrer(userMe)) || promotionParamSelected;

  useEffect(() => {
    if (sourceDeals) sourceDeals.cancel();
    if (!categoriesLoading) dispatch(DealActions.fetchCategories({}));
    mixpanel.track_optimized("List Deals");
    return () => {
      if (calledTimeout) clearTimeout(calledTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  // On search results
  useEffect(() => {
    if (prevDeals !== deals && searchValue) {
      const tempList = deals.map((deal) => {
        const [firstDeal] = deal.deals || [];
        return {
          name: deal?.name || firstDeal.name,
          category: deal?.category || firstDeal.category,
          details: deal?.details || firstDeal.details
        }
      });
      mixpanel.track_optimized('Search results', { count: deals?.length || 0, deals: tempList });
    }
  }, [deals, prevDeals, searchValue])

  // Update promos param
  useEffect(() => {
    if (location?.search) {
      const qParams = new URLSearchParams(location?.search);
      const currentPromotions = qParams.get('promotions');
      setPromotionParamSelected(currentPromotions || undefined);
    } else {
      setPromotionParamSelected(undefined);
    }
  }, [location]);

  // Sticky filters
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].intersectionRatio === 0) {
        setIsSticky(true);
      } else if (entries[0].intersectionRatio === 1) {
        setIsSticky(false);
      }

    }, { threshold: [0, 1] });
    observer.observe(fiterStickyRef.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (fiterStickyRef.current) observer.unobserve(fiterStickyRef.current);
    };
  }, [fiterStickyRef]);


  // Tracking useEffect
  useEffect(() => {
    if (referrerId) {
      let type = 'shop';
      let metadata = {};

      if (shareId) {
        type = 'catalog';
        metadata = { catalogId: shareId };
      } else if (promotionParamSelected) {
        type = 'promotion';
        metadata = { promotionId: promotionParamSelected };
      }

      dispatch(PageTrackingActions.createPageTracking({ type, metadata, referrerId }));
    }
  }, [referrerId, promotionParamSelected, shareId, dispatch]);

  // Fetch store and data
  useEffect(() => {
    if (((storeId !== storeIdPrev) || !deals?.length) && !dealsLoaded) {
      if (storeId) {
        dispatch(StoreActions.fetchStore(storeId));
        setRequestedStore(true);
        fetchData(false);
      }
    }
  }, [dispatch, storeId, fetchData, storeIdPrev, dealsLoaded, promotionParamSelected, deals]);

  // Define if scroll
  useEffect(() => {
    if (dealsPagination && !scroll) setScroll(true);
  }, [dealsPagination, scroll]);

  // Trigger deal fetch
  useEffect(() => {
    if (
      (currentFilters && !equals(prevFilters, currentFilters)) ||
      (sort && sort !== prevSort) ||
      (!equals(prevPromotionParamSelected, promotionParamSelected))
    ) {
      fetchData(searchValue, false);
      setRequestedStore(true);
    } else if (!sort && prevSort) {
      fetchData(searchValue, false);
      setRequestedStore(true);
    }
  }, [currentFilters, sort, prevFilters, prevSort, searchValue, fetchData, promotionParamSelected, prevPromotionParamSelected]);

  // If store available, request promos
  useEffect(() => {
    if (requestedStore && !requestedPromos && !storeDataLoading && storeData?.id) {
      dispatch(PromotionsActions.fetchPromos({ referrerId: storeData.id, userId }));
      setRequestedPromos(true);
    }
  }, [storeData, userId, previousStoreData, requestedPromos, dispatch, requestedStore, storeDataLoading]);

  // Redirect on failed promotion
  useEffect(() => {
    if (dealsError && !calledTimeout) {
      if (willRedirect) {
        setCalledTimeout(
          setTimeout(() => {
            const qParams = new URLSearchParams(location.search);
            qParams.delete('promotions');
            history.replace({ search: qParams.toString() });
          }, secondsToRedirectToStore * 1000)
        );
      }
    }
  }, [calledTimeout, dealsError, history, willRedirect, location]);

  return (
    <div>
      <KeepScroll view="deals" scroll={scroll} delay={500} />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="twitter:card" content="summary" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content="https://www.wao.shop/wp-content/uploads/2021/08/header-logo.png" />
        <title>{title}</title>
        <link rel="canonical" href={`https://app.wao.shop/store/${storeId}`} />
      </Helmet>
      <section className={styles['header-centered']}>
        <header>
          <HeaderStore
            headerSlug="socio store"
            customBack={
              promotionParamSelected
                ? () => {
                    history.push(`/store/${storeId}`);
                    // history.go(0);
                  }
                : false
            }
            history={history}
            storeId={storeId}
            icons={{
              share: true,
              cart: true,
              profile: userMe && !hasBackButton,
              back: hasBackButton,
              customCatalog: userMe && isReferrer(userMe),
            }}
            altColor
            v={1}
            country={storeData?.country?.toLowerCase()}
            onChange={onSearchChange}
            lookupEvent={lookupEvent}
            storeTitle={t('Socios Store', {
              socio: capitalize(storeData?.firstName || ' '),
            })}
            searchValue={searchValue}
            scroll={scroll}
          />
        </header>
      </section>
      <section style={{ paddingTop: 0 }} className={`${styles['page-wrapper']} app-wrapper-mobile`}>
        {promosLoading ? (
          <div style={{ width: "100%" }}>
            <div style={{ padding: "20px 0 10px 20px" }}>
              <LoadingLine width="65%" viewBox="0 0 400 20" />
            </div>
            <CatalogsLoader />
          </div>
        ) : ("")}
        {(promosLoading) ? "" : (
          <div style={{ background: '#f6f6f6' }}>
            {
              !promotionParamSelected && promos?.promotionCodeList?.length ? (
                <div className={styles["promo-codes-slider"]}>
                  <SliderPromos id="promo-codes" promos={promos?.promotionCodeList} copy />
                </div>
              ) : ('')
            }
            {!promotionParamSelected && promos?.promotionBackgroundList?.length ? (
              <div className={styles['promo-banners-slider']}>
                <SliderPromos
                  id="promo-banners"
                  promos={promos?.promotionBackgroundList.filter(p => p.images.length && p.images.length > 0)}
                  imagesSlider
                  clickFunction={data => {
                    if (data?.id) {
                      const params = new URLSearchParams({
                        promotions: [data.id],
                      });
                      history.push({
                        pathname: location.pathname,
                        search: params.toString(),
                      });
                      // history.go(0);
                      // setpromotionParamSelected(data.id);
                    }
                  }}
                />
              </div>
            ) : (
              ''
              )}
            {!promotionParamSelected && promos?.promotionCatalogList?.length ? (
              <div>
                <p className={styles["catalog-title"]}>{t("Top catalogs of the week")}</p>
                <VerticalCatalog catalogs={promos?.promotionCatalogList} formToLinkFn={formToLinkFn} filter="marketing"/>
              </div>
            ) : (
              ''
            )}
            {
              bannerToShow ? (
                <img src={currentPromo?.images?.[0]} alt="" style={{ width: '100%' }} />
              ) : ('')
            }
          </div>
        )}
        <Grid
          container
          style={{
            justifyContent: 'space-between',
            padding: '32px 20px 12px 20px',
            background: '#f6f6f6',
            margin: '0px 0',
          }}
          alignItems="center"
        >
          <Grid item xs className={styles["active-offer-legend"]}>
            {sectionTitle}
          </Grid>
        </Grid>
        <div className={`${styles['active-offer-container-sticky']} ${styles['sticky--last']} ${isSticky ? styles['box-shadow'] : styles['light-border-top']}`}>
          <div style={{
            display: "flex", gap: "24px", justifyContent: "space-between", flexGrow: 1,
            color: "#0a84ff", cursor: "pointer", fontSize: "14px", fontWeight: "400", flexWrap: "wrap"
          }}>
            <span className={styles["active-offer-legend-count"]}>
              {
                dealsLoading ?
                  <SpinnerCircularFixed size={15} thickness={180} speed={100} color='85, 85, 85' secondaryColor='85, 85, 85' />
                  :
                  t('amountDeals', { count: dealsPagination?.total || 0 }).toLowerCase()
              }
            </span>
            {/* Actions */}
            <div style={{ display: "flex", gap: "16px", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "8px" }} onClick={() => {
                setVisibleByCategory(false);
                setVisibleSortByPanel(true);
              }}>
                <WaoIcon color="#0a84ff" size={17} icon="sort-by-arrows" />
                {t("Order")}
              </div>
              <div style={{ borderLeft: "1px solid #0001", height: "16px" }} />
              <div style={{ display: "flex", gap: "8px" }} onClick={() => {
                setVisibleSortByPanel(false);
                setVisibleByCategory(true);
              }}>
                <WaoIcon color="#0a84ff" size={17} icon="filter_alt--2" />
                {`${t("Filter")}`}
              </div>
              <div style={{ borderLeft: "1px solid #0001", height: "16px" }} />
              <div style={{ display: "flex", gap: "8px" }} onClick={() => {
                userSettingsLocal.update('storeGridView', singleDealView);
                setSingleDealView(!singleDealView);
              }}>
                <WaoIcon color="#0a84ff" size={17} icon={singleDealView ? "grid_view" : "th-list-outline"} />
                {`${t("SwitchView")}`}
              </div>
            </div>
          </div>
        </div>
        <div ref={fiterStickyRef} />
        <section className={`${styles['store-deals-listing']} ${!singleDealView ? styles.grid : ''}`}>
          <DealsList
            t={t}
            deals={deals}
            dealsPagination={dealsPagination}
            storeDataError={storeDataError}
            styles={styles}
            singleDeal={singleDealView}
            dealsError={dealsError}
            willRedirect={willRedirect}
            isOwnStore={isOwnStore}
            dealsLoading={dealsLoading}
            fetchData={() => fetchData(searchValue, dealsPagination?.lastPage && (dealsPagination?.currentPage < dealsPagination?.lastPage))}
            showPoints={userMe && isReferrer(userMe)}
          />
        </section>
      </section>
      <FilterSortByPanel
        t={t}
        sort={sort}
        setSort={setSort}
        visible={visibleSortByPanel}
        setVisible={setVisibleSortByPanel}
      />
      <FiterByCategoryPanel
        t={t}
        filters={currentFilters}
        setFilters={setCurrentFilters}
        visible={visibleByCategory}
        setVisible={setVisibleByCategory}
        categories={categories}
      />
      <ScrollToTopButton scrollLimit={windowHeight} />
      <FooterSocial dark country={storeData?.country?.toLowerCase()} />
    </div>
  );
};

export default withTranslation()(StoreDealsListing);
