import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import log from '../utils/log';
import firebase from "firebase/app";
import 'firebase/auth';
import { toast } from "react-toastify";


export default function useCaptcha({errorMessage, messageLog, functionsFinally}){
  const { userMe } = useSelector(state => state.auth);

  const [captchaObject, setCaptchaObject] = useState(null);
  const [validateRefreshForRecaptcha, setValidateRefreshForRecaptcha] = useState(false);
  const [renderedCaptcha, setRenderedCaptcha] = useState(false);
  const recaptchaContainerRef = useRef(null);

  const initRecaptchaVerifier = useCallback(() => {
    setRenderedCaptcha(false);
    if (userMe) return;
    if (window?.recaptchaVerifier) {
      if(window.recaptchaVerifier?.reset) window.recaptchaVerifier?.reset();
      if (window.recaptchaVerifier?.clear) window.recaptchaVerifier?.clear();
      delete window.recaptchaVerifier;
    } 
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      // TODO this can be reenabled in the future
      // 'size': 'invisible',
      'callback': (/* response */) => setCaptchaObject(window.recaptchaVerifier),
      'expired-callback': () => window.recaptchaVerifier?.reset && window.recaptchaVerifier?.reset()
    });

    if( window.recaptchaVerifier)setCaptchaObject(window.recaptchaVerifier)
    window.recaptchaVerifier?.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    }).catch((err) => {
      if(messageLog) log.error(messageLog, err);
      if(errorMessage) toast.error(errorMessage);
      setValidateRefreshForRecaptcha(true);
      if(window.recaptchaVerifier?.reset)window.recaptchaVerifier?.reset();
      if(window.recaptchaVerifier?.clear)window.recaptchaVerifier?.clear();
    }).finally(() => {
      if (functionsFinally) functionsFinally();
      setRenderedCaptcha(true);
    });
  }, [errorMessage, functionsFinally, messageLog, userMe]);


    return {
      captchaObject,
      initRecaptchaVerifier,
      validateRefreshForRecaptcha,
      renderedCaptcha,
      setValidateRefreshForRecaptcha,
      recaptchaContainerRef
    };
  };