import React from 'react';
import { SpinnerCircularFixed } from 'spinners-react';

/**
* @param {string} secondaryColor - color of the secondary circle in rgba format
* @param {string} color - color of the spinner in rgba format
* @param {number} size - size of the spinner
* @param {number} thickness - thickness of the spinner
* @param {number} speed - speed of the spinner
 */

const CircularFixed = ({...props}) => (
    <SpinnerCircularFixed 
      size={props.size} 
      thickness={props.thickness} 
      speed={props.speed} 
      color={`rgba(${props.color})`}
      secondaryColor={`rgba(${props.secondaryColor}, 0.20)`}
      />
  );

export default CircularFixed;
