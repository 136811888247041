import { Box, Chip, Divider, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import WaoIcon from "../WaoIcon";
import styles from './styles.module.sass';

const FilterSortByPanel = (
  {
    t,
    visible = false,
    setVisible,
    sort,
    setSort,

  }
) => {

  const theRef = useRef();
  const height = theRef?.current?.clientHeight ? theRef.current.clientHeight : 0;
  const [sortInternal, setSortInternal] = useState(sort);
  const chipStyle = (active) => ({
    height: 24,
    borderRadius: 12,
    border: '1px solid #dcdcdc',
    marginRight: 10,
    marginBottom: 10,
    background: active ? '#030029' : 'transparent',
    color: active ? '#ffffff' : '#555555',
    fontSize: 11,
    letterSpacing: '-0.37px',
    fontFamily: 'Open Sans'
  });
  const onClickSort = (sortParam) => () => {
    if (sortParam === sortInternal) {
      setSortInternal(null);
    } else {
      setSortInternal(sortParam);
    }
  };
  const close = () => setVisible && setVisible(false);
  const applyFilters = () => {
    if (setSort) setSort(sortInternal);
    if (setVisible) setVisible(false);
  };

  useEffect(() => {
    setSortInternal(sort);
  }, [sort]);

  return (
    <div
      ref={theRef}
      className={styles.filters}
      style={{bottom: visible ? `${height !== 0 ? '-45px': `${height*-1}`}` : `-800px`}}>
      <div className={styles['filters-header']}>
        <Box />
        <div className={styles['title-cnt']}>{t('Filters')}</div>
        <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={close}>
          <div className={styles['close-cnt']}>
            <WaoIcon color="#000000" size={14} icon="close-" />
          </div>
        </div>
      </div>
      <Divider />
      <div className={styles['filters-body']}>
        {/* <Box height={'1px'} bgcolor={'#979797'} mb='10px' /> */}
            <h2>
              {t('Order')}
            </h2>
            <Grid container style={{paddingBottom: '11px'}}>
              <Chip
                label={t('PriceHighToLow')}
                variant="outlined"
                style={chipStyle(sortInternal === 'price-desc')}
                onClick={onClickSort('price-desc')}
              />
              <Chip
                label={t('MostSold')}
                variant="outlined"
                style={chipStyle(sortInternal === 'sold-desc')}
                onClick={onClickSort('sold-desc')}
              />
              <Chip
                label={t('PriceLowToHigh')}
                variant="outlined"
                style={chipStyle(sortInternal === 'price-asc')}
                onClick={onClickSort('price-asc')}
              />
              <Chip
                label={t('New')}
                variant="outlined"
                style={chipStyle(sortInternal === 'created_at-desc')}
                onClick={onClickSort('created_at-desc')}
              />
            </Grid>
        <button className="btn btn-round" style={{marginBottom: '11px'}} onClick={applyFilters}>
          {t('ApplyFilters')}
        </button>
      </div>
    </div>
  );
};

export default FilterSortByPanel;
