import Immutable from 'seamless-immutable';

const initialState = Immutable({
  orderLoading: false,
  orderError: null,
  orderLoaded: false,
  order: null,

  orderPaymentLoading: false,
  orderPaymentError: null,
  orderPaymentLoaded: false,
  orderPayment: null,

  orderStatusLoading: false,
  orderStatusError: null,
  orderStatusLoaded: false,
  orderStatus: null,

  orderStatusesLoading: false,
  orderStatusesError: null,
  orderStatusesLoaded: false,
  orderStatuses: null,
});


export default initialState;
