import Immutable from 'seamless-immutable';

const initialState = Immutable({
  customCatalogsLoading: false,
  customCatalogsLoaded: false,
  customCatalogsError: null,
  customCatalogs: null,
});

export default initialState;
