import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { onEnter } from '../../utils';
import Icon from '../Icon';
import styles from './styles.module.sass';

const Alert = ({ open, type = "warning", disableClose, customClass, onClose, children }) => {
  
  const [visible, setVisible] = useState(!!open);

  const closeHandler = () => {
    setVisible(false);
    if (onClose) onClose();
  };

  useEffect(() => setVisible(open), [open]);

  return (
    <section className={`${styles.container} ${!customClass ? `bg-${type}` : ''} ${customClass ? styles[customClass] : ''} ${visible ? "" : "hidden"}`}>
      {!disableClose ? (
        <div
          role='button' tabIndex='0' className={styles.close}
          onClick={closeHandler} onKeyDown={onEnter(closeHandler)}
        >
          <Icon icon='close-' size="10"/>
        </div>
      ) : (
        ""
      )}
      <div className={styles.content}>{children}</div>
    </section>
  );
};

export default withTranslation()(Alert);