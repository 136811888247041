import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './styles.module.sass';

const TutorialItem = ({ data, loading, onClick = () => { } }) => {  

  if (loading || !data) {
    return (
      <ContentLoader
        speed={2}
        width="320px"
        height={120}
        viewBox="0 0 320 120"
        backgroundColor="#f2f2f2"
        foregroundColor="#ecebeb"
      >
        <rect x="0px" y="10px" rx="3" ry="3" width="75px" height="80px" />
        <rect x="110px" y="35px" rx="3" ry="3" width="200px" height="20px" />
        <rect x="110px" y="65px" rx="3" ry="3" width="75px" height="15px" />
      </ContentLoader>
    );
  }

  const handleButtonClick = () => {
    onClick(data);
  };

  return (
      <div className={styles['tutorial-item']}>
        <div className={styles['img-cnt']}>
          <img src={data?.steps[0].media} alt="tutorial-thumbnail" />
        </div>
        <div className={styles['tutorial-item-content']}>
          <h3 className={styles['tutorial-title']}>{data?.label}</h3>
        </div>
        <div className={styles['button-cnt']}>
          <button onClick={handleButtonClick}>
           Ver
          </button>
        </div>
      </div>
  );
};

export default TutorialItem;
