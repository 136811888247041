import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import styles from './styles.module.sass';
import stylesPoints from '../../points/styles.module.sass';
import HeaderStore from '../../../components/HeaderStore';
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import CommissionHeader from '../../commissions/header';
import CommissionActions from '../../../redux/commission/actions';
import PointsWidget from '../../points/widget';
import { createWeekDateRange, onEnter, pageUrls } from '../../../utils';
import { formatISO } from 'date-fns';
import { map } from 'ramda';
import WaoIcon from '../../../components/WaoIcon';
import Alert from '../../../components/Alert';
import { Link } from 'react-router-dom';
import FooterSocial from '../../../components/FooterSocial';
import mixpanel from '../../../services/mixpanel';
import OrderActions from '../../../redux/orders/actions';
import SocioOrderItem from '../../../components/SocioOrderItem';
import OrderLoader from '../../../components/OrderLoader/orderLoader';
import ShareButton from '../../../components/ShareButton';
import DrawerPanel from '../../../components/DrawerPanel';
import Emoji from '../../../components/Emoji';
import PointsActions from '../../../redux/points/actions';
import AuthActions from '../../../redux/auth/actions';
import { homePointsNotificationLocal } from '../../../services/local';
import ExgengeSection from '../../points/ExgengeSection';
import SharePointsSection from '../../points/SharePointsSection';
import PointVouchersSection from '../../points/PointVouchersSection';
import PointVoucherSuccess from '../../points/PointVoucherSuccess';
import Screen from '../../../transition/Screen';
import ScreenContent from '../../../transition/ScreenContent';
import { currencies, defaultCountry, getSocialLinks } from '../../../utils/countries';

const phonesByCountry = {
  ar: { formatted: "5491173629387" },
  co: { formatted: "573053746344" },
  mx: { formatted: "5491173629387" },
};

const socialPixels = {
  fb: {
    co: process.env.REACT_APP_FB_TRACKING_CO,
    ar: process.env.REACT_APP_FB_TRACKING_AR,
    mx: process.env.REACT_APP_FB_TRACKING_AR
  }
};

const ShopBox = ({ toObject, title, content, linkText, icon: { ariaLabel, emoji } }) => {
  const wrapInLink = !!toObject;

  const innerContent = <div className={styles.box}>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.content}>
      <p>{content}</p>
    </div>
    <div className={styles.bottom}>
      <div className={styles.link}>{linkText}</div>
      <Emoji label={ariaLabel} content={emoji} />
    </div>
  </div>;

  if (!wrapInLink) return <div className="pointer">{innerContent}</div>;
  return <Link className="no-deco" to={toObject}>{innerContent}</Link>;
};

const Home = ({ t: tc, history, location }) => {
  const qParams = new URLSearchParams(location.search);
  const [welcomeToggle, setWelcomeToggle] = useState(qParams.get('welcome'));
  const hideCommissions = !!(process.env.REACT_APP_HIDE_COMMISSIONS && process.env.REACT_APP_HIDE_COMMISSIONS !== 'false') || false;
  const testCountry = useState(qParams.get('country'))?.[0];
  const [didLoad, setDidLoad] = useState(false);
  const [showSharePanel, setShowSharePanel] = useState(false);
  const [showExgengePanel, setShowExgengePanel] = useState(false);
  const [showPointVouchersPanel, setShowPointVouchersPanel] = useState(false);
  const [showPointVouchersSuccessPanel, setShowPointVouchersSuccessPanel] = useState(false);

  const showWelcome = welcomeToggle;

  const dispatch = useDispatch();
  const { userMe, userMeLoaded } = useSelector(state => state.auth);
  const { commissionsTotal } = useSelector(state => state.commissions);
  const { orders, orderStatusLabels, orderStatusesLoading } = useSelector(state => state.orders);
  const { pointsHistory, pointsHistoryLoaded } = useSelector(state => state.points);
  const [showPointsAlert, setShowPointsAlert] = useState(false);
  const [pointsNotificationContent, setPointsNotificationContent] = useState();
  const [pointsAvailable, setPointsAvailable] = useState();
  const [pointsPending, setPointsPending] = useState();
  const [selectTab, setSelectTab] = useState(0);
  const [shareCode, setShareCode] = useState(null);
  const missingProfileData = !userMe?.document || !userMe?.email || !userMe?.phone;
  const relativeShopUrl = `/store/${userMe?.shopId}`;
  const myProfileUrl = './profile';
  const country = testCountry?.toLowerCase() || userMe?.country?.toLowerCase() || defaultCountry;
  const context = useMemo(() => ({ context: country }), [country]);
  const dateRange = createWeekDateRange();
  const t = useCallback((text) => tc(text, context), [tc, context]);

  for (const countryCode in phonesByCountry) {
    if (countryCode) phonesByCountry[countryCode].clean = phonesByCountry[countryCode]?.formatted.replace(/\s/g, '');
  }

  const countrySocialLinks = getSocialLinks(country);
  const countrySocialPixelFb = socialPixels.fb[country];

  const shareTitle = t('shopShareText', { context: country });
  const shareObject = {
    title: shareTitle,
    url: document.location.origin + relativeShopUrl,
    copyText: `${shareTitle}`
  };
  const shareCodeTitle = `${t('shopShareCodeText', { context: country })}: ${shareCode}`;
  const shareCodeObject = useMemo(() => ({
    title: shareCodeTitle,
    url: document.location.origin + relativeShopUrl,
    copyText: `${shareCodeTitle}`,
  }), [relativeShopUrl, shareCodeTitle]);

  const ShareHtml = useCallback(() => (
    <ShopBox
      title={t("Share")}
      content={t("HomeShareText")}
      linkText={t('Share link to my WAO! Shop')}
      icon={{
        emoji: "📲",
        ariaLabel: "arrow phone"
      }}
    />
  ), [t]);

  const welcomeCloseHandler = () => {
    setWelcomeToggle(false);
    const oldParam = new URLSearchParams(location.search);
    const newParams = {};
    for (const [p1, p2] of oldParam) {
      newParams[p1] = p2;
    }
    if (newParams.welcome) delete newParams.welcome;
    const paramsResult = new URLSearchParams(newParams);
    history.replace({ pathname: location.pathname, search: paramsResult.toString() });
  };

  const pointsAlertCloseHandler = () => {
    homePointsNotificationLocal.set(pointsHistory?.[0]?.id);
    setShowPointsAlert(false);
  };

  const shareClickHandler = e => {
    e.preventDefault();
    setSelectTab(0);
    setShowSharePanel(true);
  };

  const pointVouchersSuccessClickHandler = e => {
    if (e) e.preventDefault();
    setShowPointVouchersSuccessPanel(!showPointVouchersPanel);
    setShowSharePanel(!showSharePanel);
  };

  const pointVouchersClickHandler = e => {
    e.preventDefault();
    setSelectTab(1);
    setShowPointVouchersSuccessPanel(!showPointVouchersSuccessPanel);
    setShowSharePanel(!showSharePanel);
  };

  const fetchUser = () => {
    dispatch(AuthActions.fetchUserMe());
  };

  const onClose = fetchUser;

  const pointsRate = useMemo(() =>
    userMe?.ratePointsByCountry?.find(countryRate => countryRate?.country === country?.toUpperCase())?.rate
    , [country, userMe]);

  useEffect(() => {
    const [fromDate, toDate] = map(formatISO, dateRange);
    window.scrollTo(0, 0);
    mixpanel.track_optimized('Home');
    if (!orderStatusLabels && !orderStatusesLoading) dispatch(OrderActions.fetchOrderStatuses());
    dispatch(AuthActions.fetchUserMe());
    dispatch(CommissionActions.fetchCommissionTotals({ fromDate, toDate }));
    dispatch(PointsActions.fetchPointsHistory({ country: country.toUpperCase(), perPage: 1, limit: 1, sort: "id desc" }));
    dispatch(OrderActions.fetchOrders({ limit: 3 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showWelcome && countrySocialPixelFb && !didLoad) {
      setDidLoad(true);
      ReactPixel.init(countrySocialPixelFb);
      ReactPixel.pageView();
      ReactPixel.track('track', 'CompleteRegistration');
    }
  }, [showWelcome, country, didLoad, countrySocialPixelFb]);

  useEffect(() => {
    if (pointsHistoryLoaded && pointsHistory?.length) {
      const currentStoredNotification = homePointsNotificationLocal.get();
      if (pointsHistory?.[0]?.id.toString() !== currentStoredNotification) {
        setPointsNotificationContent(pointsHistory?.[0]?.notification);
        setShowPointsAlert(true);
      }
    }

    if (userMeLoaded && userMe) {
      setPointsAvailable(userMe?.points?.pointsAvailable);
      setPointsPending(userMe?.points?.pointsAccumulated);
    }
  }, [pointsHistoryLoaded, pointsHistory, userMeLoaded, userMe]);


  return (
    <Screen className={styles['socio-home']}>
      <Helmet>
        <meta charSet="utf-8" />
        {showWelcome ? <title>WAO! ({country.toUpperCase()}) - Gracias!</title> : <title>WAO! - Home</title>}
        <link rel="canonical" href="https://app.wao.shop/home" />
      </Helmet>
      <HeaderStore history={history} icons={{ profile: true }} showLogo altColor clickable={false} />
      <ScreenContent webMobile>
        {/* Welcome */}
        <section className={`${styles['page-wrapper']}`} style={{ padding: '0 20px' }}>
          <section className={styles['welcome-section'] + (missingProfileData ? '' : ' hidden')}>            
            <div className={styles['top-section']}>
              <span className={styles['successful-description']}>
                {t('pages.home.updateProfileText')}
              </span>
            </div>
            <div className={styles['bottom-section']}>
              <Link to={myProfileUrl}>
                <button type="button" className="btn btn-round">
                  {t('Update my info')}
                </button>
              </Link>
            </div>
          </section>
          <section className={styles['welcome-section'] + (showWelcome ? '' : ' hidden')}>
            <div
              role="button"
              tabIndex="0"
              className={styles['close-button']}
              onClick={welcomeCloseHandler}
              onKeyDown={onEnter(welcomeCloseHandler)}
            >
              X
            </div>
            <div className={styles['top-section']}>
              <h3 className={styles['successful-title']}>{t('Welcome to WAO!')}</h3>
              <span className={styles['successful-description']}>
                {t('thankYou.welcome')} <strong>{t('WAO Community!')}</strong>
              </span>
            </div>
            <div className={styles['middle-section']}>
              <h3>{t('How to start')}</h3>
              <p>{t('thankYou.welcomeMessage')}</p>
              <p>{t('thankYou.please')}</p>
              <span>
                <ul>
                  <li>
                    <i>1.</i> {t('thankYou.1st')}
                  </li>
                  <li>
                    <i>2.</i> {t('thankYou.2nd')}
                  </li>
                  <li>
                    <i>3.</i> {t('thankYou.3rd')}
                  </li>
                </ul>
              </span>
              <p>{t('thankYou.support', { context: country })} +{phonesByCountry[country]?.formatted}.</p>
            </div>
            <div className={styles['bottom-section']}>
              <Link to={`.${relativeShopUrl}`}>
                <button type="button" className="btn btn-round">
                  {t('View my shop')}
                </button>
              </Link>
            </div>
          </section>
        </section>
        {/* Commissions */}
        <section className={`${!hideCommissions ? '' : ' hidden'}`}>
          <Link to="/socio-help">
            <section className={`${styles['learn-ur-store']} ${styles['page-wrapper']}`}>
              <span>{t('becomeWaoExpert', context)}</span>
              <WaoIcon color="white" size={14} icon="arrow-up-right2" />
            </section>
          </Link>
          <CommissionHeader dateRange={dateRange} data={commissionsTotal} infoIcon />
        </section>

        {/* Other sections */}
        <section>
          {/* Points */}
          <hr className="divider" style={{ marginTop: 0 }} />
          <section className={styles['points-section']}>
            <h3 className={styles.title}>{t('waoPointsTitle')}</h3>
            <Alert open={showPointsAlert} onClose={pointsAlertCloseHandler} type="succinfo">
              <div className="fs14 fw600"><Emoji wrapper>{pointsNotificationContent}</Emoji></div>
            </Alert>
            <section className={styles.content}>
              <PointsWidget showAvailable data={{ available: pointsAvailable, pending: pointsPending }} showAvailiblePoints />
              <div className={styles["button-section-share-points"]}>
                <Link to={pageUrls.pointsDetails}>
                  <button className={styles["button-details-points"]}>
                    {t('See Detail')}
                  </button>
                </Link>
                <a href="/" onClick={shareClickHandler}>
                  <button className={styles["button-share-points"]}>
                    {t('Share with customers')}
                  </button>
                </a>
              </div>
            </section>
          </section>
          <hr className="divider" />

          {/* Tus Ventas */}
          {!orders || orders?.length > 0 ? (
            <>
              <section className={styles['orders-section']}>
                <h3 className={styles.title}>{t('My Sales')}</h3>
                <section className={styles.content}>
                  {!orders || !orderStatusLabels ? (
                    <OrderLoader amount={3} />
                  ) : (
                    <>
                      {orders.map(sale => (
                        <SocioOrderItem key={sale.id} content={sale} orderStatusLabels={orderStatusLabels} />
                      ))}
                      <Grid container spacing={0}>
                        <Grid item xs={12} className={styles.link}>
                          <Link to="/orders">{t('SeeOrdersDetails')}</Link>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </section>
              </section>
              <hr className="divider" />
            </>
          ) : null}

          <section className={styles['shop-section']}>
            {/* <div style={{ padding: "0px 0px 32px", display: "flex", justifyContent: "space-around" }}>
              <div style={{
                padding: "16px", border: "1px solid rgb(167, 167, 167)",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "150px",
                height: "180px"
              }}>
                <div style={{ fontWeight: "bold", textAlign: "center" }}>Mi WAO! Shop</div>
                <div style={{ width: "100%", height: "auto", padding: "20px", fontSize: "80px" }}><Emoji label="shop" />🛍</div>
              </div>
              <div style={{
                padding: "16px", border: "1px solid rgb(167, 167, 167)",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "150px",
                height: "180px"
              }}>
                <div style={{ fontWeight: "bold", textAlign: "center" }}>Compartir</div>
                <div style={{ width: "100%", height: "auto", padding: "20px", fontSize: "80px" }}><Emoji label="shop" />📲</div>
              </div>
            </div> */}
            <ShopBox
              toObject={`.${relativeShopUrl}`}
              title={t('My WAO Shop')}
              content={t('Click below to go to your Shop')}
              linkText={t('View shop')}
              icon={{
                emoji: '🛍',
                ariaLabel: 'shopping bags',
              }}
            />

            <ShareButton sourceSlug="socio home" component={ShareHtml} info={shareObject} drawer />

            <ShopBox
              toObject={pageUrls.activeOffers}
              title={t('Promotional content')}
              content={t('Access marketing deals')}
              linkText={t('View content')}
              icon={{
                emoji: '📣',
                ariaLabel: 'megaphone',
              }}
            />
          </section>

          <hr className="divider" />

          <section className={styles['contact-section']}>
            <h3 className={styles['contact-section-title']}>{t('Subscribe to our sales coaching program')}</h3>
            <section className={styles['contact-content']}>
              <a className="no-deco" href={countrySocialLinks.whatsapp.url} target="_blank" rel="noopener noreferrer">
                <button type="button" className="btn btn-round">
                  {t('Contact agent now!')}
                </button>
              </a>
            </section>
          </section>
        </section>
        <DrawerPanel styleDrawerBody={stylesPoints.styleDrawerBody} styleDrawerCloseSection={stylesPoints.styleDrawerCloseSection} visible={showSharePanel} setVisible={setShowSharePanel} onClose={onClose} height="700px">
          <SharePointsSection country={country} currencies={currencies} pointsRate={pointsRate} t={t} pointsAvailable={userMe?.points?.pointsAvailable} selectTab={selectTab} setVisibleDrawer={pointVouchersSuccessClickHandler} setShareCode={setShareCode} />
        </DrawerPanel>
        <DrawerPanel styleDrawerBody={stylesPoints.styleDrawerBody} styleDrawerCloseSection={stylesPoints.styleDrawerCloseSection} visible={showExgengePanel} setVisible={setShowExgengePanel} onClose={onClose} height="700px">
          <ExgengeSection points={2500} country={country} currency={currencies[country]} pointRate={pointsRate} t={t} />
        </DrawerPanel>
        <DrawerPanel visible={showPointVouchersSuccessPanel} setVisible={setShowPointVouchersSuccessPanel} onClose={onClose} height="700px">
          <PointVoucherSuccess country={country} currency={currencies[country]} pointRate={pointsRate} t={t} setVisibleDrawer={pointVouchersClickHandler} info={shareCodeObject} />
        </DrawerPanel>
        <DrawerPanel styleDrawerBody={stylesPoints.styleDrawerBody} styleDrawerCloseSection={stylesPoints.styleDrawerCloseSection} visible={showPointVouchersPanel} setVisible={setShowPointVouchersPanel} onClose={onClose} height="800px">
          <PointVouchersSection country={country} currency={currencies[country]} pointRate={pointsRate} t={t} relativeShopUrl={relativeShopUrl} />
        </DrawerPanel>
        <FooterSocial dark country={country} />
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(Home);
