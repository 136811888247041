import IcomoonReact from "icomoon-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import iconSet from "../../assets/fonts/icomoon/selection.json";
import { ShopingCartContext } from "../../contexts/shoppingCart/ShopingCartContext";
import { CustomCatalogContext } from "../../contexts/customCatalog/CustomCatalogContext";
import { usePrevious } from "../../utils/hooks";
import { withTranslation } from 'react-i18next';
import { AppContext } from "../../contexts/AppContext";

const WaoOverlay = ({ t }) => {

  const [show, setShow] = useState(false);
  const prevShow = usePrevious(show);
  const [onBack, setOnBack] = useState(true);
  const [hideEnv, setHideEnv] = useState(false);
  const [defaultConfig] = useState({
    message: t('AddedToCart'),
    background: "#05604d"
  });
  const [currentConfig, setCurrentConfig] = useState(defaultConfig);
  const { shopId } = useSelector(state => (state.stores.store || {}));
  const { carts } = useContext(ShopingCartContext);
  const appState = useContext(AppContext);
  const { cartItems: { length: productCount } } = (carts[shopId] || { cartItems: { length: 0 } });
  const prevProductCount = usePrevious(productCount);
  const { getCatalog } = useContext(CustomCatalogContext);
  const { items: {length: customCatalogItemsCount} } = getCatalog(shopId);
  const prevCustomCatalogItemsCount = usePrevious(customCatalogItemsCount);
  
  const revertToDefaults = useCallback(() => {
    setCurrentConfig(defaultConfig);
  }, [defaultConfig]);

  const setCustomCatalogConfig = useCallback(() => {
    setCurrentConfig({
      ...defaultConfig,
      message: t('AddedToCustomCatalog'),
    });
  }, [defaultConfig, t]);
  
  useEffect(() => {
    if (productCount > prevProductCount) {
      revertToDefaults();
      setShow(true);
      setOnBack(false);
      const timeout = setTimeout(() => {
        setShow(false);
        clearTimeout(timeout);
      }, 1500);
    } else if (customCatalogItemsCount > prevCustomCatalogItemsCount) {
      setCustomCatalogConfig();
      setShow(true);
      setOnBack(false);
      const timeout = setTimeout(() => {
        setShow(false);
        clearTimeout(timeout);
      }, 1500);
    }
  }, [productCount, prevProductCount, setShow, revertToDefaults, setCustomCatalogConfig, customCatalogItemsCount, prevCustomCatalogItemsCount]);

  useEffect(() => {
    if (prevShow !== show && !show) {
      const timeout = setTimeout(() => {
        setOnBack(true);
        clearTimeout(timeout);
      }, 500);
    }
  }, [prevShow, show, setOnBack]);

  useEffect(() => {    
    if (appState?.notification?.status) {
      setCurrentConfig({ ...defaultConfig, ...appState.notification });
      setShow(true);
      setOnBack(false);
      const timeout = setTimeout(() => {
        setShow(false);
        clearTimeout(timeout);
      }, appState.notification.duration || 1500);
    } else {
      revertToDefaults();
    }
  }, [appState.notification, defaultConfig, revertToDefaults]);

  const envIsStg = process.env.REACT_APP_API_BASE_URL.includes('stg') && process.env.NODE_ENV !== 'production';
  const envIsDev = (process.env.NODE_ENV === 'development.local' || process.env.NODE_ENV === 'development') && !envIsStg;

  return (
    <>
      {
        !hideEnv && envIsDev &&
        <div className="env-overlay" onClick={() => { setHideEnv(true); }}>
          DEVELOPMENT
        </div>
      }
      {
        !hideEnv && envIsStg &&
        <div className="env-overlay" onClick={() => { setHideEnv(true); }}>
          STAGING
        </div>
      }
      <div className="overlay" style={{ opacity: show ? 1 : 0, pointerEvents: 'none', zIndex: onBack ? -1 : 1400 }}>
        <div>
          <span style={{ background: currentConfig.background }}><IcomoonReact style={{ marginRight: '2px' }} iconSet={iconSet} color="#ffffff" size={14} icon="checkmark" />
            <span style={{ background: currentConfig.background }}>{currentConfig.message}</span>
          </span>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(WaoOverlay);
