import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DealActions from '../../../redux/deals/actions';
import styles from './styles.module.sass';
import FooterSocial from '../../../components/FooterSocial';
import mixpanel from '../../../services/mixpanel';
import PageTrackingActions from '../../../redux/rewards/actions';
import DealDetail from './DealWithVariants';

const StoreDealsDetail = ({ t, history }) => {

  const { selected } = useSelector(state => state.deals);
  const { userMe } = useSelector(state => state.auth);
  const { dealId } = useParams();
  const dispatch = useDispatch();
  const { store: storeData } = useSelector(state => state.stores);
  const isReferrer = userMe && userMe?.type === 'referrer';
  const { storeId } = useParams();
  const isOwnStore = isReferrer && storeId && userMe?.shopId === storeId;
  const store = useSelector(state => state.stores.store);
  const referrerId = store?.id;
  const userId = userMe?.id;

  useEffect(() => {
    if (referrerId) {
      dispatch(PageTrackingActions.createPageTracking({
        type: 'deal',
        metadata: { dealId },
        referrerId,
      }));
    }
  }, [referrerId, dealId, dispatch]);

  useEffect(() => {
    dispatch(DealActions.setSelectedDeal(null));
    dispatch(DealActions.fetchDeal({ dealId, userId, shopId: storeId, searchPromosObject: true, grantPoints: !isReferrer ? true : null }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    mixpanel.track_optimized('Deal Details', { dealId });
  }, [dispatch, dealId, userId, storeId, isReferrer]);

  return (
    <div>
      <section className={`${styles['page-wrapper']} app-wrapper-mobile`}>
        {
          selected && <DealDetail t={t} deals={selected} history={history} isOwnStore={isOwnStore} mainDealId={dealId} />
        }
      </section>
      {selected && <FooterSocial dark country={storeData?.country?.toLowerCase()} />}
    </div>
  );
};

export default withTranslation()(StoreDealsDetail);
