import React from 'react';
import Twemoji from 'react-twemoji';

// While content isn't really required, it gets rid of warnings and is simpler to use
const Emoji = ({ label, children, content, wrapper }) => {
  if (label) return <Twemoji noWrapper={!wrapper} options={{ className: "twemoji" }}>
    <span role='img' aria-label={label}>
      {children || content}
    </span>
  </Twemoji>;
  
  return <Twemoji noWrapper={!wrapper} options={{ className: "twemoji" }}>
    {children || content}
  </Twemoji>;
};

export default Emoji;