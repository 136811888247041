import React from 'react';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingContentDeals from '../../../../components/LoadingContentDeals';
import DealCard from '../../DealItemVariants';

const DealsList = ({
  t,
  deals = [],
  dealsPagination,
  storeDataError,
  styles,
  dealsError,
  willRedirect,
  isOwnStore,
  fetchData,
  dealsLoading,
  showPoints,
  singleDeal
}) => {

  if (deals?.length === 0 && !dealsLoading) {
    return <div className={styles['no-deals']}>{t('SorryNoDealsMessage')}</div>;
  }

  if (storeDataError && !dealsLoading) {
    return (
      <div className={styles['no-deals']}>
        {`${t('ErrorDealsListingStore')} (${storeDataError?.userMessage || t('NotAbleToLoadStore')})`}
      </div>
    );
  }

  if (willRedirect) {
    return (
      <div className={styles['no-deals']}>
        {dealsError.userMessage ? (
          <div>
            <span>{dealsError.userMessage}</span>
            {willRedirect ? <CircularProgress style={{ color: '#e54a38' }} /> : ''}
            {willRedirect ? <span>{t('Redirecting')}...</span> : ''}
          </div>
        ) : (
          t('ErrorDealsListingStore')
        )}
      </div>
    );
  }

  const dealsLength = dealsPagination?.to || 0;
  const hasMoreDeals = dealsPagination?.lastPage && (dealsPagination.currentPage < dealsPagination.lastPage);

  return (
    <InfiniteScroll
      className={`${styles['deals-container']} ${singleDeal ? null : styles.grid}`}
      next={fetchData}
      scrollThreshold={0.7}
      hasMore={hasMoreDeals}
      dataLength={dealsLength}
      loader={<LoadingContentDeals singleDeal={singleDeal} isOwnStore={isOwnStore} />}
    >
      {deals?.map(deal => (
        <article key={deal.id} className={singleDeal ? styles['deal-box'] : styles['deal-box-grid']}>
          <DealCard singleDeal={singleDeal} sourceSlug="deals list" disableCarousel={!singleDeal} deals={deal} share isOwnStore={isOwnStore} showPoints={showPoints} />
        </article>
      ))}
    </InfiniteScroll>
  );
};
export default DealsList;
