import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

const SocioGuard = (props) => {

  const{ userMe } = useSelector(state => state.auth);

  if (userMe && userMe.type === 'referrer' && userMe?.metadata?.status !== 'lead') {
    return <Route {...props} />;
  }

  return null;
};


export default SocioGuard;