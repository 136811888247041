/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormikProvider, useFormik, Form /* , Field */ } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import 'firebase/auth';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import HeaderStore from '../../../components/HeaderStore';
import AuthActions from '../../../redux/auth/actions';
import phoneIcon from "../../../assets/images/auth/auth-number.png";
import phoneIcon2x from "../../../assets/images/auth/auth-number@2x.png";
import phoneIcon3x from "../../../assets/images/auth/auth-number@3x.png";
import styles from './styles.module.sass';
import { usePrevious } from '../../../utils/hooks';
import { TextInputLiveFeedback } from '../../registration/utils';
// import { getDefaultRolePageRedirect } from '../../../utils/constants';
import { equals, path } from 'ramda';
import mixpanel from '../../../services/mixpanel';
import log from '../../../utils/log';
import Screen from '../../../transition/Screen';
import { Helmet } from 'react-helmet';
import ScreenContent from '../../../transition/ScreenContent';
import View from '../../../transition/View';
import Text from '../../../transition/Text';
import FooterSocial from '../../../components/FooterSocial';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import Icon from '../../../components/Icon';
import useCaptcha from '../../../hooks/useCaptcha';
import { useLocation } from 'react-router-dom';
import { Recaptcha } from '../../../components/Recaptcha.js';

const defaultCountry = 'AR';

const EmailSignIn = ({ history, t, ...props }) => {
  const globalStyles = useGlobalStyles();
  const [atStep2, setAtStep2] = useState(false);
  const dispatch = useDispatch();
  const { loginError, loginLoading } = useSelector(state => state.auth);
  const prevLoginError = usePrevious(loginError);
  const recaptchaContainer = useRef(null);
  const { store } = useSelector(state => state.stores);
  const location = useLocation();
  const [countryCode, setCountryCode] = useState(store ? store.country : defaultCountry);
  const emailCheckURL = `${window.location.origin}${window.location.pathname}/check${window.location.search}`;
  const {initRecaptchaVerifier, captchaObject, validateRefreshForRecaptcha, renderedCaptcha, setValidateRefreshForRecaptcha} = useCaptcha({errorMessage:`${t('LoginProcessFailed')} (sR)`});

  const formikStep1 = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({ email: Yup.string().email(t('EnterValidEmail')).required(t('RequiredField')), }),
  });

  const { email } = formikStep1.values;
  
  const cleanup = useCallback(() => {
    const currElement = recaptchaContainer?.current;
    while (currElement?.firstChild) currElement?.removeChild(currElement?.firstChild);
    setAtStep2(false);
    initRecaptchaVerifier();
  }, [recaptchaContainer, setAtStep2, initRecaptchaVerifier]);
  
  const goLoginWithEmail = useCallback(() => {
    const functionMain = async () => {
      mixpanel.track_optimized('Auth Email link sending email');
      await dispatch(AuthActions.sendLinkToEmail(emailCheckURL, email, countryCode, location));
      setAtStep2(true);
    };
    functionMain();
  }, [countryCode, dispatch, email, emailCheckURL, location]);

  const isContinueButtonDisabled = !!(loginLoading || !captchaObject);
  const showIfStep1 = { display: !atStep2 ? "flex" : "none", flexDirection: "column" };
  const showIfStep2 = { display: atStep2 ? "flex" : "none", flexDirection: "column" };

  useEffect(() => {
    if (store !== null) setCountryCode(store.country);
  }, [store, setCountryCode]);

  useEffect(() => {
    dispatch(AuthActions.resetLoginState());
  }, [dispatch]);
  

  useEffect(() => {
    if (loginError !== null && formikStep1.isValid) {
      dispatch(AuthActions.resetLoginState());
      // goLoginWithEmail();
    }
    if (loginError && (prevLoginError !== loginError)) {
      log.error('Login error', JSON.stringify(loginError));
      cleanup();
      if (equals("auth/too-many-requests", path(['userMessage', 'code'], loginError))) {
        toast.error(t('AuthTooManyRequests'));
      } else if (equals("SESSION_EXPIRED", path(['error', 'message'], loginError))) {
        toast.error(t('SessionExpired'));
      } else if (equals("TOKEN_EXPIRED", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC3)`);
      } else if (equals("USER_NOT_FOUND", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC4)`);
      } else if (equals("INVALID_GRANT_TYPE", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC5)`);
      } else if (equals("TOO_MANY_ATTEMPTS_TRY_LATER", path(['error', 'message'], loginError))) {
        toast.error(`${t('AuthTooManyRequests')} (sC6)`);
      } else if (equals("USER_DISABLED", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC7)`);
      } else if (equals("MISSING_REFRESH_TOKEN", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC8)`);
      } else if (equals("QUOTA_EXCEEDED", path(['error', 'message'], loginError))) {
        toast.error(`${t('AuthTooManyRequests')} (sC9)`);
      } else {
        toast.error(`${t('LoginProcessFailed')} (sD)`);
      }
    }
  }, [loginError, prevLoginError, formikStep1, dispatch, cleanup, t, goLoginWithEmail]);

  useEffect(() => {
    if (!renderedCaptcha) {
      initRecaptchaVerifier();
      mixpanel.time_event_optimized('Auth Email link send');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedCaptcha]);
  
  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("pages.title", { title: t("pages.emailSignIn.title") })}</title>
        <link rel="canonical" href="https://app.wao.shop/sign-in/phone" />
      </Helmet>
      <HeaderStore showLogo altColor icons={{ back: true }} />
      <ScreenContent webMobile>
        <View style={{ padding: "30px" }}>
          <View>
            <Text style={globalStyles.sectionTitle}>{t("pages.emailSignIn.innerTitle")}</Text>
            <Divider style={globalStyles.titleDivider} />
          </View>
          <View style={{ ...showIfStep1, alignItems: "center", marginBottom: "-16px" }}>
            <Text style={{ paddingTop: "8px" }}>{t("pages.emailSignIn.step1Instructions")}</Text>
            <img style={{ marginTop: "24px" }} src={phoneIcon} width="235" height="205" alt="" srcSet={`${phoneIcon} 1x, ${phoneIcon2x} 2x, ${phoneIcon3x} 3x`} />
          </View>
          <View style={{ ...showIfStep2, alignItems: "center" }}>
            <Text style={{ paddingTop: "8px" }}>{t('pages.emailSignIn.step2Instructions')} <b>{formikStep1?.values.email}</b>.</Text>
            <Icon icon="check_circle_outline" size="84" color="#ED703F" style={{ marginTop: "48px", marginBottom: "48px" }} />
            <Text style={{ paddingTop: "8px" }}>{t('pages.emailSignIn.step2Instructions2')}.</Text>
          </View>
          <View style={showIfStep1}>
            <FormikProvider value={formikStep1}>
              <Form>
                <div className={styles['__form-init']}>
                  <div className={`${styles['phone-section']} form-control-mat2`}>
                    <TextInputLiveFeedback label={t('Email Address')} trim id="email" name="email" type="text" value={props?.location?.user?.email || formikStep1.values.email} />
                  </div>
                </div>
              </Form>
            </FormikProvider>
            <Recaptcha loader renderedCaptcha={renderedCaptcha} refRecaptchaContainer={recaptchaContainer} validateRefreshForRecaptcha={validateRefreshForRecaptcha} setValidateRefreshForRecaptcha={setValidateRefreshForRecaptcha} t={t}/>
            <button type="button" disabled={isContinueButtonDisabled} id="sign-in-button" className={`btn btn-round ${styles.__btn}`} onClick={goLoginWithEmail}>
              <span>{t('Send')}</span>
            </button>
          </View>
        </View>
        <FooterSocial dark={!!props?.location?.user?.country?.toLocaleLowerCase()} country={props?.location?.user?.country?.toLocaleLowerCase()}/>
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(EmailSignIn);