import React, { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { onEnter } from '../../utils';
import Tooltip from '@mui/material/Tooltip';
import Icon from '../Icon';
import styles from './styles.module.sass';

const WaoTooltip = ({ msg }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);



  return (
    <Tooltip arrow placement="bottom"
      title={
        <div className="test">
          <div className={styles.header}>
            <span role="button" onClick={handleClose} onKeyDown={onEnter(handleClose)} tabIndex={0}>
              <Icon color="#fff" size={10} icon="close-" />
            </span>
          </div>
          <div className={styles.content}>
            <span className={styles.msg}>{msg}</span>
          </div>
        </div>
      }
      open={isOpen}
      onClose={handleClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      className={styles.tooltip}
    >
      <span
        className={styles.socioDiscountIcon}
        role="button"
        onClick={handleOpen}
        onKeyDown={onEnter(handleOpen)}
        tabIndex={0}
      >
        <Icon color="#090909" size={16} icon="info2" />
      </span>
    </Tooltip>
  );
};

export default withTranslation()(WaoTooltip);