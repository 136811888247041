import React from 'react';
import styles from './index.module.sass';

const DialogHCentered = ({ children, leftAdj }) => (
    <div
      style={{
        width: `${document.body.clientWidth}px`,
        left: `${(leftAdj).toFixed(0)}px`
      }}
      className={styles.dialog}
    >
      <div className={styles['dialog-inner']}>
        {children}
      </div>
    </div>
  );

export default DialogHCentered;
