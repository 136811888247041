import { createActions } from "redux-actions";
import { validate } from "../../services/shipments";
import { GenericError } from "../../utils/errors";

const ShipmentActions = createActions({
  VALIDATE_ZIPCODE_REQUEST: (data) => (data),
  VALIDATE_ZIPCODE_SUCCESS: (valid) => (valid),
  VALIDATE_ZIPCODE_FAILED: (error) => ({ error })
});

ShipmentActions.validateZipcode = (data) => async (dispatch) => {
    try {
      dispatch(ShipmentActions.validateZipcodeRequest(data));
      const result = await validate(data.country, data.zipCode);
      dispatch(ShipmentActions.validateZipcodeSuccess(result));
    } catch (err) {
      dispatch(ShipmentActions.validateZipcodeFailed({ error: new GenericError(err, err) }));
    }
  };

export default ShipmentActions;