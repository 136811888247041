import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import styles from './styles.module.sass';
import { filterMedia, getType } from '../../utils';
import Icon from '../Icon';

const renderAsset = (url, index, alt='') => {
  let result = "";
  const type = getType(url);
  if (type === 'application') result = <iframe title={index} src={url} />;
  else if (type === 'image') result = <img src={url} alt={alt} />;
  else if (type === 'video') {
    // eslint-disable-next-line jsx-a11y/media-has-caption
    result = <video src={url} controls>Your browser does not support the video tag.</video>;
  }  
  if (result) result =
    <div className={styles["asset-row"]} key={index}>
      {result}
    </div>;
  return result;
};

const ucFirst = str => (str || '').toString().charAt(0).toUpperCase() + (str || '').toString().slice(1);

const ProductVariantSelector = ({
  productGroup,
  onProductDecision = () => {},
  onFilterChange = () => {},
  preSelectedId,
  isDrawerLayout,
  title,
}) => {
  const [filters, setFilters] = useState({});
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);

  const defaultProductCandidate = productGroup.find(product => product.id === preSelectedId) || productGroup[0];
  const defaultProduct =
    defaultProductCandidate.quantity !== 0
      ? defaultProductCandidate
      : productGroup.find(product => product.quantity !== 0) || productGroup[0];
  const variantsRows = useMemo(() => {
    const spreadAttributes = {};
    productGroup.forEach(element => {
      element.attributes.forEach(attr => {
        if (!spreadAttributes[attr.name]) {
          spreadAttributes[attr.name] = [];
        }
        spreadAttributes[attr.name].push(attr?.value);
      });
    });

    const variants = [];
    Object.keys(spreadAttributes).forEach(key => {
      variants.push({
        ...productGroup[0].attributes.find(attr => attr.name === key),
        values: [...new Set(spreadAttributes[key])].sort().map(value => {
          const sample = productGroup.find(product =>
            product.attributes.find(attr => attr.name === key && attr?.value === value)
          );
          return {
            value,
            sample,
          };
        }),
      });
    });

    return variants;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGroup, filters]);

  const flattenAttributes = attr => {
    const flattened = {};
    attr.forEach(element => {
      flattened[element.name] = element?.value;
    });
    return flattened;
  };
  const activeProduct = useMemo(() => {
    const filterKeys = Object.keys(filters);

    if (filterKeys.length === 0) {
      return { decision: defaultProduct.attributes.length === 0, product: defaultProduct };
    }

    return {
      decision: filterKeys.length === variantsRows.length,
      product: productGroup.find(item => {
        const attr = flattenAttributes(item.attributes);

        for (const key of filterKeys) {
          if (attr[key] !== filters[key]) {
            return false;
          }
        }
        return true;
      }),
    };
  }, [productGroup, filters, variantsRows, defaultProduct]);

  useEffect(() => {
    if (activeProduct.decision) {
      onProductDecision(activeProduct.product);
    }
  }, [activeProduct, onProductDecision]);

  useEffect(() => {
    const initialFilters = defaultProduct.attributes
      .map(attr => ({ [attr.name]: attr?.value }))
      .reduce(
        (acc, curr) => ({
          ...acc,
          ...curr,
        }),
        {}
      );

    if (Object.keys(initialFilters).length > 0) {
      setFilters(initialFilters);
    } else {
      onFilterChange(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      onFilterChange(filters);
    }
  }, [filters, onFilterChange]);

  const isDisabled = useCallback(
    (variantName, value) =>
      !productGroup.some(product => {
        const attr = flattenAttributes(product.attributes);
        const otherVariantsExists = Boolean(attr[variantName] && attr[variantName] === value);

        if (!otherVariantsExists) {
          return false;
        }

        if (product.quantity === 0) {
          return false;
        }

        return true;
      }),
    [productGroup]
  );

  const isGrayedOut = useCallback(
    (variantName, value) => {
      if (Object.keys(filters).length === 0) {
        return false;
      }

      if (filters[variantName] === value) {
        return false;
      }

      const searchFilters = { ...filters, [variantName]: value };
      const searchFiltersKeys = Object.keys(searchFilters);
      return !productGroup.some(product => {
        if (product.quantity === 0) {
          return false;
        }

        const attr = flattenAttributes(product.attributes);
        for (const key of searchFiltersKeys) {
          if (attr[key] !== searchFilters[key]) {
            return false;
          }
        }

        return true;
      });
    },
    [filters, productGroup]
  );

  const isActive = (variantName, value) => filters[variantName] === value;

  const addToFilters = (variantName, value, sample) => {
    if (isDisabled(variantName, value)) {
      return;
    }

    setSelectedMediaIndex(0);

    if (isGrayedOut(variantName, value)) {
      const suggestedProductAttributes = flattenAttributes(sample.attributes);

      setFilters({
        ...suggestedProductAttributes,
      });
    } else {
      setFilters({
        ...filters,
        [variantName]: value,
      });
    }
  };

  const variantIsThumbnail = variant => Boolean(variant.type === 'thumbnail' || variant.useImage);
  const findThumbnail = media => filterMedia(media, 'product', 'zoho')?.[0] || media[0].url;

  const availableImagesOnActiveProduct = useMemo(
    () => filterMedia(activeProduct.product.media, 'product', 'zoho'),
    [activeProduct.product.media]
  );

  return (
    <div className={styles.productVariantSelector}>
      <div className={`${styles.variantsHeadingArea} ${isDrawerLayout ? styles.variantsDrawerLayout : ''}`}>
        {isDrawerLayout && <div className={styles.title}>{title || activeProduct.product.name}</div>}
        <div className={`${styles.mainImage} ${isDrawerLayout ? styles.drawer : ''}`}>
          {renderAsset(availableImagesOnActiveProduct[selectedMediaIndex], selectedMediaIndex, activeProduct.product.name)}
        </div>
      </div>
      {availableImagesOnActiveProduct.length > 1 && !isDrawerLayout && (
        <div className={styles.imageScroller}>
          <div className={styles.imageSelector}>
            {availableImagesOnActiveProduct.map((media, index) => (
              <button
                key={`${activeProduct.id}-${index}`}
                type="button"
                onClick={() => setSelectedMediaIndex(index)}
                className={styles.imageSelectorThumbnail + (index === selectedMediaIndex ? ` ${styles.active}` : '')}
              >
                {getType(media) !== 'image' && <div className={styles.icon}><Icon icon="film" size="22" /></div>}
                {getType(media) === 'image' && <img src={media} alt={activeProduct.product.name} />}
              </button>
            ))}
          </div>
        </div>
      )}
      {variantsRows.length > 0 && !isDrawerLayout && <hr className={styles.separator} />}
      {!isDrawerLayout
        ? variantsRows.map(variant => (
            <div className={styles.variantsSelectorRow} key={variant.id}>
              <span className={styles.name}>
                {ucFirst(variant.label)}: <strong>{ucFirst(filters[variant.name])}</strong>
              </span>
              <div className={styles.scrollableArea}>
                <div className={styles.scrollableContent}>
                  {variant?.values.map(({ value, sample }) => (
                    <button
                      type="button"
                      key={sample.id}
                      className={
                        styles.inputSelector +
                        (isGrayedOut(variant.name, value) ? ` ${styles.grayedOut}` : '') +
                        (isDisabled(variant.name, value) ? ` ${styles.disabled}` : '') +
                        (isActive(variant.name, value) ? ` ${styles.active}` : '') +
                        (!variantIsThumbnail(variant) ? ` ${styles.round}` : '')
                      }
                      onClick={() => addToFilters(variant.name, value, sample)}
                    >
                      {variantIsThumbnail(variant) ? (
                        <img src={findThumbnail(sample.media)} alt={sample.name} />
                      ) : (
                        ucFirst(value)
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ))
        : variantsRows.map(variant => (
            <div className={styles.inputSelectContainer} key={variant.id}>
              <span className={styles.name}>{ucFirst(variant.label)}: </span>
              <Select
                isSearchable={false} 
                menuPlacement="top"
                className={styles.variantsSelectInput}
                placeholder={ucFirst(filters[variant.name])}
                options={variant?.values
                  .filter(vRow => !isDisabled(variant.name, vRow?.value))
                  .map(({ value, sample }) => ({ sample, value, label: ucFirst(value) }))}
                onChange={row => addToFilters(variant.name, row?.value, row.sample)}
                isOptionSelected={row => isActive(variant.name, row?.value)}
                value={filters[variant.name]}
                styles={{
                  control: stl => ({
                    ...stl,
                    borderRadius: '8px',
                    borderColor: 'rgb(208, 208, 208)',
                  }),
                  placeholder: stl => ({
                    ...stl,
                    fontSize: '14px',
                  }),
                  dropdownIndicator: stl => ({
                    ...stl,
                    color: '#040415',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  option: (stl, { isSelected }) => ({
                    ...stl,
                    ...(isSelected ? { backgroundColor: '#ee7d40' } : { backgroundColor: undefined }),
                  }),
                }}
              />
            </div>
          ))}
      {/* {!isDrawerLayout && <hr className={`${styles.separator} ${styles.last}`} />} */}
    </div>
  );
};

export default ProductVariantSelector;
