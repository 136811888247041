import { createActions } from "redux-actions";
import { createCustomCatalog, deleteCustomCatalog, getCustomCatalogs, updateCustomCatalog } from "../../services/customCatalogs";
import { GenericError } from "../../utils/errors";
// import { toast } from 'react-toastify';


const CustomCatalogActions = createActions({
  CREATE_CUSTOM_CATALOG_REQUEST: () => {},
  CREATE_CUSTOM_CATALOG_SUCCESS: (customCatalog) => ({customCatalog}),
  CREATE_CUSTOM_CATALOG_FAILED: (error) => ({ error }),

  FETCH_CUSTOM_CATALOG_REQUEST: () => {},
  FETCH_CUSTOM_CATALOG_SUCCESS: (customCatalogs) => ({customCatalogs}),
  FETCH_CUSTOM_CATALOG_FAILED: (error) => ({ error }),

  UPDATE_CUSTOM_CATALOG_REQUEST: () => {},
  UPDATE_CUSTOM_CATALOG_SUCCESS: (customCatalog) => ({customCatalog}),
  UPDATE_CUSTOM_CATALOG_FAILED: (error) => ({ error }),

  DELETE_CUSTOM_CATALOG_REQUEST: () => {},
  DELETE_CUSTOM_CATALOG_SUCCESS: (id) => ({id}),
  DELETE_CUSTOM_CATALOG_FAILED: (error) => ({ error }),

});

CustomCatalogActions.createCustomCatalog = (data) => (
   async (dispatch) => {
    try {
      dispatch(CustomCatalogActions.createCustomCatalogRequest());
      const result = await createCustomCatalog(data);
      if (!result.ok) {
        const errMsg = result.data?.message || result.originalError?.message;
        const customerError = new GenericError("", errMsg, { status: result?.status });
        // toast.error(errMsg);
        throw customerError;
      }
      dispatch(CustomCatalogActions.createCustomCatalogSuccess(result?.data));
    } catch (err) {
      dispatch(CustomCatalogActions.createCustomCatalogFailed({ error: err }));
    }
  }
);

CustomCatalogActions.fetchCustomCatalogs = () => (
   async (dispatch) => {
    try {
      dispatch(CustomCatalogActions.fetchCustomCatalogRequest());
      const result = await getCustomCatalogs();
      if (!result.ok) {
        const errMsg = result.data?.message || result.originalError?.message;
        const customerError = new GenericError("", errMsg, { status: result?.status });
        // toast.error(errMsg);
        throw customerError;
      }
      dispatch(CustomCatalogActions.fetchCustomCatalogSuccess(result?.data));
    } catch (err) {
      dispatch(CustomCatalogActions.fetchCustomCatalogFailed({ error: err }));
    }
  }
);

CustomCatalogActions.updateCustomCatalog = (data) => (
  async (dispatch) => {
   try {
     dispatch(CustomCatalogActions.updateCustomCatalogRequest());
     const result = await updateCustomCatalog(data);
     if (!result.ok) {
       const errMsg = result.data?.message || result.originalError?.message;
       const customerError = new GenericError("", errMsg, { status: result?.status });
       // toast.error(errMsg);
       throw customerError;
     }
     dispatch(CustomCatalogActions.updateCustomCatalogSuccess(result?.data));
   } catch (err) {
     dispatch(CustomCatalogActions.updateCustomCatalogFailed({ error: err }));
   }
 }
);

CustomCatalogActions.deleteCustomCatalog = (data) => (
  async (dispatch) => {
   try {
     dispatch(CustomCatalogActions.deleteCustomCatalogRequest());
     const result = await deleteCustomCatalog(data);
     if (!result.ok) {
       const errMsg = result.data?.message || result.originalError?.message;
       const customerError = new GenericError("", errMsg, { status: result?.status });
       // toast.error(errMsg);
       throw customerError;
     }
     dispatch(CustomCatalogActions.deleteCustomCatalogSuccess(result?.data));
   } catch (err) {
     dispatch(CustomCatalogActions.deleteCustomCatalogFailed({ error: err }));
   }
 }
);

export default CustomCatalogActions;
