import Immutable from 'seamless-immutable';

const initialState = Immutable({
  pageTrackingLoading: false,
  pageTrackingError: null,
  pageTrackingLoaded: false,
  pageTrackingData: null,
});

export default initialState;
