import React from 'react';
import { withTheme } from '@mui/styles';

const Pill = ({ theme, style = {}, children, color = "waoYellow" }) => {
  
  const pillStyles = {
    width: "min-content",
    padding: "2px 8px",
    borderRadius: "12px",
    color: theme.palette[color].contrastText,
    background: style.backgroundColor || style.background || theme.palette[color].main,
    display: "flex",
    justifyContent: "center",
    ...style,
  };

  return (
    <div style={pillStyles}>
      {children}
    </div>
  );
};

export default withTheme(Pill);