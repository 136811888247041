/* eslint-disable no-shadow */
import currency from 'currency.js';
import { curry, equals, concat, head, last, pipe, reject, split, test, type, union, when, __ } from 'ramda';
import { matchPath } from 'react-router-dom';
import { format, isMonday, isSameYear, isSunday, nextSunday, previousMonday, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';
import { capitalize } from '@mui/material';
import log from './log';
import TagManager from 'react-gtm-module';
import BigNumber from 'bignumber.js';
import { t } from 'i18next';

const { detect } = require('detect-browser');

const formatCurried = curry(format);
const setHoursC = curry(setHours);
const setMinutesC = curry(setMinutes);
const setSecondsC = curry(setSeconds);
const setMillisecondsC = curry(setMilliseconds);

export const pageUrls = {
  activeOffers:'./active-offers',
  points: '/points',
  pointsDetails: '/points/details',
  tutorials:'/socio-help/tutorials',
};

export const getSiteUrl = () => process.env.REACT_APP_SITE_URL || 'https://app.wao.shop';

export const getOrderStatusPage = (shopId, id, isDetail) =>
  `/store/${shopId}/checkout/success?orderId=${id}&socioDetail=${(!!isDetail).toString()}`;

export const getOrderStatusPageLinkTo = (shopId, id, isDetail) => ({
  pathname: `/store/${shopId}/checkout/success`,
  search: `orderId=${id}&socioDetail=${(!!isDetail).toString()}`
});

export const phonesByCountry = {
  ar: {
    support: "5491169595869",
    sales: "5491169595869"
  },
  co: {
    support: "573108757559",
    sales: "573108757559",
  },
  mx: {
    support: "573108757559",
    sales: "573108757559",
  }
};

const whatsAppUrlPrefix = 'https://wa.me/';

export const getWAUrl = (countryCode, text, urlType = 'support') => {
  if (!countryCode || !countryCode.toLowerCase) return '';
  return (whatsAppUrlPrefix + (phonesByCountry[countryCode.toLowerCase()])[urlType] + (text ? (`?text=${encodeURIComponent(text)}`) : ''));
};

const phoneMasks = { co: '(...) ... ....', ar: '(.) ... ... ....', mx: '.(...) ... ....', };

export const formatDate = ((date, joinWith='-') => {
  if (!date) return '';
  const d = new Date(date);
  let month = `${(d.getMonth() + 1)}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join(joinWith);
});

export const getWeekStartEndFromDate = (date) => {
  const formDate = ((typeof date) === 'string') ? new Date(date) : date;
  const start = formDate.getDate() - formDate.getDay();
  const end = start + 6; // last day is the first day + 6

  const startDay = new Date(formDate.setDate(start));
  const endDay = new Date(formDate.setDate(end));
  return [startDay, endDay];
};

export const showFriendlyDateSameYearCase = (date, locale, ignoreSameYear, ignoreEs) => {  
  
  if (!date) return '';
  const formatIt = formatCurried(date, __, { locale });
  const isSameYearDate = isSameYear(new Date(), date);
  if (locale.code !== 'es' || ignoreEs) {
    return isSameYearDate && !isSameYearDate ? formatIt('EEEE, d MMMM') : formatIt('EEEE, d MMMM y');
  }

  // "EEEE, d 'de' MMMM"
  if (isSameYearDate && !ignoreSameYear) return `${capitalize(formatIt('EEEE, d'))} de ${ capitalize(formatIt('MMMM')) }`;
  // "EEEE, d 'de' MMMM 'de' y"
  return `${capitalize(formatIt('EEEE, d'))} de ${capitalize(formatIt('MMMM'))} de ${formatIt('y')}`;
};

export const showFriendlyDateSameYearCaseShort = (date, locale, ignoreSameYear = false, ignoreEs = false) => {
  const formatIt = formatCurried(date, __, { locale });
  const isSameYearDate = isSameYear(new Date(), date);
  if (locale.code !== 'es' || ignoreEs) {
    return isSameYearDate && !ignoreSameYear ? formatIt('d MMMM') : formatIt('d MMMM y');
  }
  if (isSameYearDate && !ignoreSameYear) return formatIt("d 'de '") + capitalize(formatIt('MMMM'));
  return `${formatIt("d 'de '")}${capitalize(formatIt('MMMM'))} de ${formatIt('y')}`;
};

export const showFriendlyDateSameYearCaseShortInverted = (date, locale, ignoreSameYear = false, ignoreEs = false, sliceLong = null) => {
  const formatIt = formatCurried(date, __, { locale });
  const isSameYearDate = isSameYear(new Date(), date);
  if (locale.code !== 'es' || ignoreEs) {
    return isSameYearDate && !ignoreSameYear ? formatIt('MMMM d') : formatIt('MMMM d y');
  }
  if (isSameYearDate && !ignoreSameYear && sliceLong) return `${capitalize(formatIt('MMMM')).slice(0, sliceLong)} ${capitalize(formatIt('d'))}`;
  if (isSameYearDate && !ignoreSameYear) return capitalize(formatIt('MMMM d'));
  return `${capitalize(formatIt('MMMM d'))} de ${formatIt('y')}`;
};

export const setHoursDownToZero = pipe(setHoursC(__,0), setMinutesC(__, 0), setSecondsC(__, 0), setMillisecondsC(__, 0));
export const setHoursUpTo23 = pipe(setHoursC(__,23), setMinutesC(__, 59), setSecondsC(__, 0), setMillisecondsC(__, 0));

export const createWeekDateRange = (date = new Date()) => {    
  const dateObject = ((typeof date) === 'string') ? new Date(date) : date;
  const monday = setHoursDownToZero(isMonday(dateObject) ? dateObject : previousMonday(dateObject));
  const sunday = setHoursUpTo23(isSunday(dateObject) ? dateObject : nextSunday(dateObject));
  return [monday, sunday];
};

const priceDiscount = (price = 0, discount = 0) => price - (price * discount);

export const whatUrlMatch = (urls, location) => {
  let match;
  let result = urls.find(url => {
    const tempMatch = matchPath(location.pathname, url.path);
    if (tempMatch?.isExact) match = tempMatch;
    return match?.isExact;
  });
  if (result && match?.isExact) result = { ...result, match };
  return result;
};

export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const takeNullPairsOut = (obj) => reject(x => !x, obj);

// ("https://youtu.be/3OFokYqSOc8", 'https://img.youtube.com/vi/w0DhPHIxIiQ/0.jpg', 'https://www.youtube.com/watch?v=3OFokYqSOc8')
/* export const generateYoutubeImgUrl = converge(
  pipe(concat, concat('https://img.youtube.com/vi/'), concat(__, '/0.jpg')), [
  pipe(split('youtu.be/'), last, split('?'), head, dbug),
  pipe(split('v='), last, split('&'), head, dbug)
]); */

export const generateYoutubeImgUrl = pipe(
  when(
    test(/(.+)?(youtu\.be)(.+)?/),
    pipe(split('youtu.be/'), last, split('?'), head)
  ),
  when(
    test(/(.+)?(youtube\.com)(.+)?/),
    pipe(split('v='), last, split('&'), head)
  ),
  concat('https://img.youtube.com/vi/'),
  concat(__, '/0.jpg')
);

export const getBrowserName = () => {
  const browser = detect();
  return browser?.name;
};

export const manualDetectBrowser = () => {
  let ua = navigator?.userAgent || navigator?.vendor || window?.opera || "";
  ua = ua.toLowerCase();
  if (ua?.indexOf("instagram") !== -1) return 'Instagram';
  if ((ua?.indexOf("opera") !== -1 || ua?.indexOf('opr')) !== -1) return 'Opera';
  if (ua?.indexOf("chrome") !== -1) return 'Chrome';
  if (ua?.indexOf("safari") !== -1) return 'Safari';
  if (ua?.indexOf("firefox") !== -1 || ua?.indexOf("fxios") !== -1) return 'Firefox';
  if ((ua?.indexOf("msie") !== -1) || (!!document.documentMode === true)) return 'IE';
  return 'Unknown';
};

const isChrome = (browserName) => {
  if (!browserName) return false;
  const lcBrowserName = browserName.toLowerCase();
  return (lcBrowserName === 'crios' || lcBrowserName === 'chrome');
};

const isSafari = (browserName) => {
  if (!browserName) return false;
  const lcBrowserName = browserName.toLowerCase();
  return (lcBrowserName === 'ios' || lcBrowserName === 'safari');
};

export const showBrowserWarning = () => {
  const browser = getBrowserName();
  return !isSafari(browser) && !isChrome(browser);
};



export const filterMedia = (media, mediaType = "product", sortSource = "no") =>
{
  // TODO Remove once the data in DB is cleaned and the issue is fixed
  let filteredMedia = [];
  if (media) {
    let cleanMedia = media;
    if (equals(type(media), 'String')) cleanMedia = JSON.parse(cleanMedia);
    let sortedMedia = [...cleanMedia].sort((a, b) => {
      if (a.priority < b.priority) return -1;
      if (a.priority > b.priority) return 1;
      return 0;
    });

    // Sort by source
    sortedMedia = sortedMedia.reduce((acc, element) => {
      if (element.source === sortSource) return [element, ...acc];
      return [...acc, element];
    }, []);

    filteredMedia = sortedMedia.reduce((prev, mediaObject) => {
      const prevResult = [...prev];
      if ((mediaType === 'all' || mediaObject.type === mediaType)) prevResult.push((mediaObject.url || mediaObject));
      return prevResult;
    }, []);
  }
  return filteredMedia;
};

export const getUrlExtension = (url) => {
  if (!url) return '';
  const urlSplit = url.substr(1 + url.lastIndexOf("/")).split('?')[0];
  const result = urlSplit.split('#')[0].substr(urlSplit.lastIndexOf(".") + 1).toLowerCase();
  return result;
};

export const getFileExtension = (filename) => filename.split('.').pop();

export const mimeTypes = {
  jpeg: 'image',
  jpg: 'image',
  gif: 'image',
  png: 'image',
  tif: 'image',
  tiff: 'image',
  webp: 'image',
  mov: 'video',
  mp4: 'video',
  webm: 'video',
  pdf: 'application'
};

export const getAssetType = (assetType) => {
  let result;
  if (assetType && assetType.length) result = assetType?.split('/').shift();
  return result;
};

export const getType = (url) => {
  let fileType = getUrlExtension(url);
  if (mimeTypes[fileType]) fileType = mimeTypes[fileType];
  return getAssetType(fileType);
};

export const fileDownload = (data, filename, mime, bom) => {
  const blobData = (typeof bom !== 'undefined') ? [bom, data] : [data];
  const blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
  }
  else {
      const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempLink);
      tempLink.click();

      // Fixes "webkit blob resource error 1"
      setTimeout(() => {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
      }, 200);
  }
};

export const browserSupportsSharing = () => !!(navigator.canShare || navigator.share);

export const selectTextById = containerid => {
  let range;
  if (document.selection) {
    range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select();
  } else if (window.getSelection) {
    range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
};

export const selectTextByElement = element => {
  let range;
  if (document.selection) {
    range = document.body.createTextRange();
    range.moveToElementText(element);
    range.select();
  } else if (window.getSelection) {
    range = document.createRange();
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
};

export const BigNumberRounDown = (valor) => {
  let valorNotdecimal = 0;
  BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN });
  const roundDown = new BigNumber(valor);
  if(valor >= 1) {
    valorNotdecimal = roundDown?.c[0];
  }    
  return valorNotdecimal;
};

export const capitalizeText = (word) => {
  if (!word || !word[0].toUpperCase) return word;
  return word[0].toUpperCase() + word.slice(1);
};

export const clearSelection = () => {
  if (window.getSelection) { window.getSelection().removeAllRanges(); }
  else if (document.selection) { document.selection.empty(); }
};

export const addDebugger = () => {
  // eslint-disable-next-line no-debugger
  window.addEventListener('keydown', (e) => { if (e.key === 'F8') { debugger; } }, false);
};

export const buildProductURL = (shopId, dealId) => `${document.location.origin}/store/${shopId}/deals/${dealId}`;
export const buildPromoURL = (shopId, promoId) => `${document.location.origin}/store/${shopId}?promotions=${promoId}`;
export const getPriceString = (value) => currency(value, { precision: 0 }).format();

export const onEnter = (handler) => (event) => {
  if (['keydown', 'keypress'].includes(event.type)
    && ['Enter', ' '].includes(event.key)) {
    handler();
  }
};

export const deleteDuplicates = (arr, sort) => {
  const uniqueIds = [];
  const unique = arr.filter(element => {
    const querrySort = sort ? element[`${sort}`] : (element.id || element.label );
    const isDuplicate = uniqueIds.includes(querrySort || element);
    if (!isDuplicate) {
      uniqueIds.push(querrySort || element);
      return true;
    }
    return false;
  });
  return unique;
};

/**
   * 
   * @param {Array with all values} array 
   * @returns {Array} with min value in the first position and max value in the second position
   */
export const getMinMax = (array) => {
  if (!array?.length) return [];
  return [Math.min(...array), Math.max(...array)];
};

export const gtmRegisterSuccess = (data) => {
  // tracking event for Google Tag Manager - Register Success 
  
  if (data?.message === 'Success') {

    TagManager.dataLayer({
      dataLayer: {
          event: 'gtm.registrationSuccess',
          eventCategory: 'Registration',
          eventAction: 'Success',
          eventLabel: data?.data?.country,
          eventValue: 1,
          eventCallback: () => log.log('GTM register success')
    }});

  }
};

export const after = (count, f, notYet = ()=>{}) => {
  let noOfCalls = 0;
  return (...rest) => {
    noOfCalls += 1;
    if (count === noOfCalls) {
      f(...rest);
    } else {
      notYet(noOfCalls);
    }
  };
};

// Fisher-Yates algorithm
export const shuffleArray = array => {
  const result = [...array];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
};

// Format a number as 1K if a thousand or more, otherwise 900
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((item) => num >= item?.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

export const translateOptions = options => options?.map((item) => {
  if (typeof item.label === "string") return { ...item, label: t([item.label]), value: item?.value };
  return item;
});

export const toFloat = (value) => {
  if (value === null || value === undefined) return undefined;
  const testValue = parseFloat(value);
  if (Number.isNaN(testValue)) return testValue;
  return BigNumber(value).decimalPlaces(2).toNumber();
};

export const getDifferentKeys = (obj1, obj2) => {
  const keys = union(Object.keys(obj1), Object.keys(obj2));
  return keys.filter(key => obj1[key] !== obj2[key] ? key : null);
};

export const compareObj = (a, b) => {

  if (typeof a !== 'object' || typeof b !== 'object') {
    console.log('Not Object');
  } else {
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    if (aKeys.join('') !== bKeys.join('')) {
      return false;
    }
    for (let i = 0; i < aKeys.length; i++) {
      if (a[aKeys[i]] !== b[bKeys[i]]) {
        return false;
      }
    }
    return true;
  }
  return null;
};

export const getDealPrices = (deal) => {
  const hasPromotion = !!deal.promotion;
  const currency = deal?.currency;
  const discountPrice = hasPromotion ? deal?.promotion?.priceWithDiscount : deal?.price;
  const discount = hasPromotion ? deal?.promotion?.discountRate : deal?.discount;
  const price = hasPromotion ? deal?.price : deal?.marketPrice || deal.price;
  const marketDiscount = deal?.marketPrice ? 1 - (deal.price / deal.marketPrice) : 0;
  const fullPrice = hasPromotion ? deal?.price : deal?.marketPrice || deal.price;
  const customerPrice = hasPromotion ? deal?.promotion?.priceWithDiscount : deal?.price;  
  const customerDiscount = hasPromotion ? deal?.promotion?.discountRate : marketDiscount;
  const referrerPrice = hasPromotion ? toFloat(deal.promotion.priceWithDiscount * toFloat(1 - deal.discount)) : deal?.discountPrice
  const commission = toFloat(customerPrice - referrerPrice);

  const result = {
    price,
    discount,
    discountPrice,
    commission,
    currency,
    customerPrice,
    referrerPrice,
    fullPrice,
    customerDiscount
  };
  
  return result;
}  

export default {
  phoneMasks,
  formatDate,
  priceDiscount,
  whatUrlMatch
};