// TODO Fix manually for each case
import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import TermsAndConditionsAsModal from '../terms-and-conditions/modal';
import { Field, useField } from 'formik';
import styles from './styles.module.sass';
import 'react-phone-input-2/lib/style.css';
import { pipe, find, propEq, prop, __, concat, curry, toLower, toUpper } from 'ramda';
import Emoji from '../../components/Emoji';
import { useDebounce, usePrevious, useRequest } from '../../utils/hooks';
import Progress from '../../components/Progress';
import Icon from '../../components/Icon';
import { availableCountries, getAvailableCountries } from '../../utils/countries';

const debounceTime = 500;
const sleep = (ms) => new Promise((r) => { setTimeout(r, ms); });
const emojify = option => <Emoji wrapper>{option.label}</Emoji>;

const currentCountries = getAvailableCountries();
const enforceList = [];
const phoneMasks = {};

Object.keys(currentCountries).forEach(countryCode => {
  const currentCountry = currentCountries[countryCode];
  const lowerCaseCode = currentCountry.code.toLowerCase();
  const enforceObject = {
    countryCode: lowerCaseCode,
    dialCode: currentCountry.dialCode,
  };
  if (currentCountry.enforcePrefix) enforceObject.enforce = currentCountry.enforcePrefix;
  enforceList.push(enforceObject);
  phoneMasks[lowerCaseCode] = currentCountry.mask;
});

const defaultCountries = [...availableCountries];

const replaceBegSubstring = curry((target, deliver, toReplace) => {
  const i = target && target.length ? target.length : 0;
  if (toReplace?.substr(0, i) === target) {
    return `${deliver}${toReplace?.substring(i)}`;
  }
  return toReplace;
});

const lookForCode = curry((x, name) => prop(name, find(propEq('countryCode', prop('countryCode', x)), enforceList)));
const lookForCodeEnforce = lookForCode(__, 'enforce');
const lookForDialCode = lookForCode(__, 'dialCode');
const enforce = curry((val, d, e) => pipe(replaceBegSubstring(d, ''), replaceBegSubstring(e, ''), concat(`${d}${e}`))(val));


export const TextInputLiveFeedback = React.memo(({ label, helpText, value: propsVal, trim, required, ...props }) => {

  const [field, meta, helpers] = useField(props);
  const [didFocus, setDidFocus] = React.useState(false);
  const handleFocus = () => setDidFocus(true);
  const showFeedback = (!!didFocus && field?.value?.trim().length > 2) || meta.touched;
  const [value, setValue] = useState(propsVal);
  const isTextArea = props.type === 'textarea';
  const [count, setCount] = useState(0);
  const maxCharacters = props.maxLength;

  const onChangeHandler = (e) => {
    let val = e?.target?.value;
    if (trim) val = val.replace(/\s/g, '');
    setValue(val);
    helpers.setValue(val);
  };

  const handleChangeTxtArea = (e) => {
    setCount(e?.target?.value?.length);
    onChangeHandler(e);
  };

  let validityStyle = '';
  if (showFeedback) validityStyle = meta.error ? styles.invalid : styles.valid;

  return (
    <div className={`${styles['form-control-mat']} ${validityStyle}`}>
      <div className="flex items-center space-between">
        <label htmlFor={props.id}>{label}</label>{required ? <span className={styles.required}>*</span> : ''}
      </div>
      {isTextArea ?
        (<>
          <textarea
            {...props} {...field} value={value}
            aria-describedby={`${props.id}-feedback ${props.id}-help`}
            onFocus={handleFocus} onChange={handleChangeTxtArea}
          />
          {maxCharacters ? <div type="text/html" style={{ color: "gray", float: "right", fontSize: "11px" }} >{`${count}/${maxCharacters}`}</div> : ''}
        </>) : ''
      }
      {!isTextArea ?
        <input
          {...props} {...field} value={value}
          aria-describedby={`${props.id}-feedback ${props.id}-help`}
          onFocus={handleFocus} onChange={onChangeHandler}
        /> : ''
      }
      {showFeedback ? (
        <div
          id={`${props.id}-feedback`}
          aria-live="polite"
          className={styles["control-feedback"]}
        >
          {meta.error ? meta.error : null}
        </div>
      ) : null}
    </div>
  );
});

const PhoneInputEnforcer = (props) => {
  const { form, field, value: v ='', country: c, setCountryCode } = props;
  const [country, setCountry] = useState(c);
  const [value, setValue] = useState(enforce(v, lookForDialCode({ countryCode: country }), lookForCodeEnforce({ countryCode: country })));

  const onChangeHandler = useCallback((option, countryObj) => {
    if (countryObj.countryCode !== country) {
      const codeEnforce = lookForCodeEnforce({ countryCode: toLower(country) });
      const dialCode = lookForDialCode({ countryCode: toLower(countryObj.countryCode) });
      const codeEnforce2 = lookForCodeEnforce(countryObj);
      const dialCode2 = lookForDialCode(countryObj);
      const cleanedOption = pipe(replaceBegSubstring(dialCode, ''), replaceBegSubstring(codeEnforce, ''))(option);
      const enforced = enforce(cleanedOption, dialCode2, codeEnforce2);
      setTimeout(() => {
        if (form && field) form.setFieldValue(field?.name, enforced);
        setValue(enforced);
      }, 20);
    } else {
      const codeEnforce = lookForCodeEnforce(countryObj);
      const dialCode = lookForDialCode(countryObj);
      const enforced = ((dialCode && codeEnforce) && enforce(option, dialCode, codeEnforce)) || option;
      if (form && field) form.setFieldValue(field?.name, enforced);
      setValue(enforced);
    }
    if(setCountryCode) setCountryCode(toUpper(countryObj.countryCode));
    setCountry(countryObj.countryCode);
    if (props.onChange) props.onChange(v);
  }, [country, setCountryCode, props, v, form, field]);

  useEffect(() => {
    setTimeout(() => {
      setValue(field?.value || `${lookForDialCode({ countryCode: country })}${lookForCodeEnforce({ countryCode: country })}`);
    }, 20);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const validateDialCode = `${lookForDialCode({ countryCode: country })||''}${lookForCodeEnforce({ countryCode: country })||''}`;
  const previousValidateDialCode = usePrevious(v?.includes(validateDialCode));
  const defaultValue = validateDialCode && v?.includes(validateDialCode) ? v : value;
  if (form && field && v?.includes(validateDialCode) && (v?.includes(validateDialCode) !== previousValidateDialCode)) form.setFieldValue(field?.name, v);

  return (
    <PhoneInput
      {...props}
      country={country}
      value={defaultValue}
      onChange={onChangeHandler}
    />
  );
};

export const SelectInputLiveFeedback = React.memo(({ t, field, form, label, onChange, isDisabled, cb, options = [], placeholder, styleSelectInput, styleContentSelect, styleLabel, ...props }) => {
  const handleBlur = useCallback(() => { form.setFieldTouched(field?.name, true); }, [field?.name, form]);
  const showFeedback = (form?.touched[field?.name] && form.errors[field?.name]);
  // const validClass = showFeedback ? styles.invalid : styles.valid;
  const translatedOptions = options?.map((item) => {
    if (typeof item.label === "string") return { ...item, label: t([item.label]), value: item?.value };
    return item;
  });

  const validations = useCallback(async (option) => {
    form.setFieldValue(field.name, option);
    await sleep(1); // Not sure why but this was the only fix that properly triggered the validation
    form.validateField(field.name);
    if (onChange) onChange(option);
    // Moved here so it doesn't flash a validation error message for a split second
    handleBlur();
    if (cb) {
      const splitted = field.name.split('.');
      const name = splitted[splitted.length - 1];
      cb({ [name]: option });
    }
  }, [cb, field.name, form, handleBlur, onChange]);

  const errorsContent = () => {
    let result = null;

    if (form.errors[field?.name]) {
      if (form.errors[field?.name].value) result = form.errors[field?.name].value;
      else result = form.errors[field?.name];
    }

    return result;
  };

  return (
    <div className={`${styleContentSelect || styles['form-control-mat']} validClass`}>
      <label htmlFor={field?.name} className={`${styleLabel || styles['label-normal']}`}>{label}</label>
      <Select
        className={`${styleSelectInput || styles['normal-input']}`}
        classNamePrefix="react-select"
        placeholder={placeholder}
        isDisabled={isDisabled}
        options={translatedOptions}
        {...field}
        formatOptionLabel={emojify}
        onChange={option => {
          if(props?.isPoints) {
            option.label = `${option.label} ⭐️`;
          }
          validations(option);
        }}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 }),
        }}
      />
      {showFeedback ? (
        <div id={`${props.id}-feedback`} aria-live="polite" className={styles["control-feedback"]}>
          {errorsContent()}
        </div>
        ) : null}
      
    </div>
  );
});

export const SelecInputPhone = React.memo(({ alt, country = '', countries, form, field, label, feedbackClass, description, name, noBorder, required, t, validateFunction, formValidationParams, validateWait, validationCustomError, disableWhileValidating, setValidationError, ...props }) => {
  const lowerCaseCountry = country?.toLocaleLowerCase();
  const [countryCodes, setCountryCodes] = useState([]);
  const [inputChanged, setInputChanged] = useState(false);
  const handleFocus = () => form.setFieldTouched(field?.name, true);
  const handleChange = () => { setInputChanged(true); if (setValidationError) setValidationError(true); }
  const debouncedSearchValue = useDebounce(field?.value, validateWait || debounceTime);
  const previouseDebouncedValue = usePrevious(debouncedSearchValue);
  const { loading: validationLoading, error: validationError, data: validationResult, run: runValidation } = useRequest(validateFunction);
  let currentTouched = form?.touched[field?.name];
  if (field?.name && field.name.includes('.')) {
    const fieldNameSplit = field.name.split('.');
    currentTouched = form?.touched[fieldNameSplit[0]]?.[fieldNameSplit[1]];
  }
  let currentError = form?.errors[field?.name];
  if (field?.name && field.name.includes('.')) {
    const fieldNameSplit = field.name.split('.');
    currentError = form?.errors[fieldNameSplit[0]]?.[fieldNameSplit[1]];
  }
  const showFeedback = currentTouched && currentError || validationError ;
  const validClass = showFeedback ? (`${styles.invalid} invalid`): (`${styles.valid} valid`);

  useEffect(() => {
    const currentUpdate = async () => {
      setCountryCodes(countries.data.map(innerCountry => (innerCountry.code).toLowerCase()));
    };
    if (countries && countries.data) currentUpdate();
  }, [countries]);

  useEffect(() => {
    const validateParamAsync = async () => {
      if (validateFunction && !currentError) {
        if (debouncedSearchValue && debouncedSearchValue !== previouseDebouncedValue) {
          if (setValidationError) setValidationError(null);
          const params = formValidationParams ? formValidationParams(debouncedSearchValue) : debouncedSearchValue;
          setInputChanged(false);
          const response = await runValidation(params);
          if (setValidationError && response && !response.ok && response?.data?.message) setValidationError(validationCustomError || response?.data?.message);
        }
      }
    };
    validateParamAsync();
  }, [debouncedSearchValue, formValidationParams, currentError, previouseDebouncedValue, runValidation, validateFunction, form, field.name, validationCustomError, setValidationError]);
  const shouldValidate = validateFunction && !currentError;
  const showValidationLoading = shouldValidate && (inputChanged || validationLoading);
  const showValidationCheck = shouldValidate && !validationLoading && !inputChanged && !validationError && validationResult;
  const showValidationFailed = shouldValidate && !validationLoading && !inputChanged && validationError;

  return (

    <div className={`${(alt ? 'form-control-mat-alt ' : styles['form-control-mat'])} ${validClass}`}>
      <label htmlFor={name} className={styles['label-normal']}>{label}</label>{required ? <span className={styles.required}>*</span> : ''}
      {description && <span className={styles.desc}>{description}</span>}
      <div>
      <div style={{ position: "absolute", right: "0px", zIndex: 1 }}>
        {showValidationLoading ? <Progress size={14} color="waoOrange" /> : ''}
        {showValidationCheck ? <Icon icon="check_circle_outline" size={16} color="#0aae8c" /> : ''}
        {showValidationFailed ? <Icon icon="cancel" size={16} color="#de0e0e" /> : ''}
      </div>
      <PhoneInputEnforcer
        {...props}
        prefix="+"
        name={name}
        country={lowerCaseCountry}
        disableCountryGuess
        masks={phoneMasks}
        disableDropdown={props?.disableDropdown}
        disabled={disableWhileValidating && validationLoading}
        form={form}
        field={field}
        countryCodeEditable={false}
        onlyCountries={countryCodes && countryCodes.length > 0 ? countryCodes : defaultCountries}
        onBlur={handleFocus}
        onChange={handleChange}
        buttonClass={validClass}
        dropdownClass={validClass}
        inputClass={`${noBorder ? 'no-border' : ''} ${validClass}`}
        autoComplete="new-password"
        isValid={() => (!showFeedback)}
        /></div>
      {showFeedback ? (
        <div
          id={`${props.id}-feedback`}
          aria-live="polite"
          className={`${styles["control-feedback"]} ${(feedbackClass || '')}`}
        >
          {currentError || validationCustomError || validationError?.message || ''}
        </div>
      ) : null}
    </div>
  );

});

export const CheckLiveFeedback = React.memo(({ alt, field, labelLinkHelperText, labelLink, label, typeComp, ...props }) => {

  const getCheckType = () => {

    let comp;
    switch (typeComp) {
      case 'link':
        comp = <span>
          {labelLinkHelperText}
          <a href={labelLink} target="_blank" rel="noopener noreferrer">{label}</a>
        </span>;
        break;
      case 'modal':
        comp = <span>
          {labelLinkHelperText}
          <TermsAndConditionsAsModal label={label} />
        </span>;
        break;
      default:
        comp = <span>{label}</span>;

    }
    return comp;
  };

  return (
    <div className={`${(alt ? 'form-control-mat-alt ' : styles['form-control-mat'])}`}>
      <label htmlFor={field?.name} className={alt ? 'label-normal-checkbox' : styles['label-normal-checkbox']}>
        <Field {...field} type="checkbox" {...props} />
        {getCheckType()}
      </label>
    </div>
  );

});