import React, { useCallback, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import PointActions from '../../redux/points/actions';
import styles from './styles.module.sass';
import PointsHistory from './PointsHistory';
import mixpanel from '../../services/mixpanel';
import Emoji from '../../components/Emoji';
import ScreenContent from '../../transition/ScreenContent';


const Points = ({ t, earnClickHandler }) => {

  const dispatch = useDispatch();
  const { userMe } = useSelector(state => state.auth);
  const country = userMe?.country?.toLowerCase();
  const { pointsHistory, pointsHistoryPagination, pointsHistoryLoading, pointsHistoryError } = useSelector(state => state.points);
  
  const fetchData = useCallback((currentPage) => {
    if (!pointsHistoryLoading) {
      dispatch(PointActions.fetchPointsHistory({ sort: "id desc", country: country.toUpperCase(), currentPage: currentPage || ((pointsHistoryPagination?.currentPage || 0) + 1) }));
    }
  }, [pointsHistoryLoading, dispatch, country, pointsHistoryPagination]);

  useEffect(() => {
    fetchData(1);
    mixpanel.track_optimized('Points');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <ScreenContent>
      <div id="scrollable-section" className={`${styles['points-section']} px20`}>
      <div className={`${styles["your-available-points"]} ${styles.top}`}>
        <div className={styles["your-available-points__title"]}>
          <span>{t("Your available points")}: </span>
        </div>
        <div className={styles.total}>
          <span>{userMe?.points?.pointsAvailable}</span>
          <div><Emoji label="star" content="⭐️" /></div>
        </div>
        </div>
        <h1 className={`${styles.title} m0 text-center`}>
          {t('pageTitle.pointsHistory')}
        </h1>
        <section className={styles.content}>
          <div className={styles.historyContainer}>
            <PointsHistory loading={pointsHistoryLoading} pointsHistory={pointsHistory} pointsHistoryPagination={pointsHistoryPagination} fetchData={fetchData} pointsHistoryError={pointsHistoryError} earnClickHandler={earnClickHandler}/>
          </div>
        </section>
      </div>
    </ScreenContent>
  );
};

export default withTranslation()(Points);