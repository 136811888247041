import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';

const ImageConga = ({ media, offset = 0.3, limit = 3, size = 36, congaClass }) => {
  const [imageSize] = useState(size);
  const previewStyles = {
    width: `${imageSize.toString()}px`,
    height: `${imageSize.toString()}px`,
    marginLeft: `${-(size * offset).toFixed(0)}px`,
  };
  const containerStyle = {
    marginLeft: `${(size * offset).toFixed(0)}px`,
  };
  return (
    <div className={`${styles['conga-container']} ${congaClass}`} style={containerStyle}>
      {media.map((imgSrc, index) => {
        if (index >= limit) return null;
        return (
          <div key={`${imgSrc}-${index}`} className={styles['preview-container']} style={previewStyles}>
            <img src={imgSrc} alt="" />
          </div>
        );
      })}
    </div>
  );
};

export default withTranslation()(ImageConga);
