import React, { useCallback, useEffect, useState } from 'react';
import { onEnter } from '../../utils';
import WaoIcon from '../WaoIcon';
import styles from './index.module.sass';

const ScrollButton = ({scrollLimit = 400}) => {

  const [visible, setVisible] = useState(false);

  const toggleVisible = useCallback(() => {
    const scrolled = document?.documentElement?.scrollTop || 0;
    if (scrolled > scrollLimit) {
      setVisible(true);
    } else if (scrolled <= scrollLimit) {
      setVisible(false);
    }
  }, [scrollLimit]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };  

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => window.removeEventListener('scroll', toggleVisible);
  }, [toggleVisible]);

  return (
    <div role="button" tabIndex="0" onClick={scrollToTop} className={!visible ? 'hide' : styles.button} onKeyDown={onEnter(scrollToTop)}>
      <WaoIcon icon="arrow_up" size="14" color="#040415" />
    </div>
  );
};

export default ScrollButton;