/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './styles.module.sass';
import { Link } from 'react-router-dom';
import ShoppingButtonCounterCart from '../ShoppingCart/ButtonCounterCart';
import WaoIcon from '../WaoIcon';
import ShareButton from '../ShareButton';
import SearchBar from '../SearchBar';
import ButtonCounterCustomCatalog from '../CustomCatalog/ButtonCounterCustomCatalog';

const DealListHeader = (
  {
    t,
    drawerSharePayload,
    history,
    icons,
    altColor,
    goBack,
    onChange,
    country = 'co',
    lookupEvent,
    searchValue,
    storeTitle,
  }) => {
      const [/* show */, setShow] = useState(true);
      const [lastScrollY, setLastScrollY] = useState(0);
    
  const controlSearchBar = () => {
    const isWindowLoaded = typeof window !== 'undefined';
    if (isWindowLoaded) {
      const isSearchBarVisible = window.pageYOffset <= lastScrollY;
      setShow(isSearchBarVisible);

      const isPageYOffsetSecured = window.pageYOffset >= 0;
      if (isPageYOffsetSecured) {
        setLastScrollY(window.pageYOffset);
      }

    }
  };


  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', controlSearchBar);
      window.addEventListener('scroll', controlSearchBar);

      return () => {
        window.removeEventListener('scroll', controlSearchBar);
      };
    }
    return () => { };
  }, [lastScrollY]);



  const defaultFontColor = "#040415";
  const defaultBgClass = "header-button";
  const altFontColor = "#040415";
  const altBgClass = "header-button-alt";
  const fontColor = !altColor ? defaultFontColor : altFontColor;
  const buttonClass = !altColor ? defaultBgClass : altBgClass;

  const shareInfo = {
    title: t('shopShareText', { context: country }),
    url: document.location.href,
    copyText: `${t('shopShareText', { context: country })}`,
    ...drawerSharePayload
  };
  
  return (
    <div className={styles['list-header-cnt']}>
      <div className={styles['header-content']}>
        <div style={{ zIndex: 2 }}>
          <div className={styles['header-first-row']}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {
                icons.back && <button style={{ marginLeft: 0}} type="button" className={`${styles[buttonClass]} ${styles['header-button-back']}`} onClick={goBack}>
                  <WaoIcon color={fontColor} size={20} icon="arrow_left-7" />
                </button>
              }
              {
                !icons.profile ? '' :
                  <Link to='/account'>
                    <WaoIcon color={fontColor} size="22" icon="Group-5" />
                  </Link>
              }
              <span className={styles['header-title']}>{storeTitle}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {
                icons?.customCatalog && <ButtonCounterCustomCatalog color={fontColor} buttonClass={buttonClass} history={history}/>
              }
              {
                icons?.share && <ShareButton drawer info={shareInfo} buttonClass={styles[buttonClass]} fontColor={fontColor} />
              }
              {
                icons?.cart &&
                <div>
                  <ShoppingButtonCounterCart color={fontColor} buttonClass={buttonClass} history={history} />
                </div>
              }
            </div>
          </div>
        </div>
        {
          // show &&
            <div style={{ zIndex: 2, boxSizing: "content-box" }}>
              <SearchBar
                clearable
                onChange={onChange}
                lookupEvent={lookupEvent}
                value={searchValue}
                placeholder={t('Buscar Oferta')}
                waitTime={250}
                letterLength={2}
                inputstyle={{
                  borderRadius: '4px',
                  border: '1px solid rgb(236, 236, 236)',
                  padding: '0.5em 24px 0.5em 1em'
                }}
                containerstyle={{
                  width: 'calc(100% - 24px - 1em)'
                }}
              />
            </div>
        }
      </div>
    </div>
  );
};

export default DealListHeader;
