import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PointsActions from '../../redux/points/actions';
import PointVouchersList from './PointVouchersList';
import { CancelToken } from 'apisauce';
import SearchBarAndDownload from '../active-offers/SearchBarAndDownload';
import Emoji from '../../components/Emoji';
import mixpanel from 'mixpanel-browser';
import { useRequest } from '../../utils/hooks';
import { updateVoucher } from '../../services/points';
import Menu from '../../components/Menu';
import { capitalizeText, nFormatter } from '../../utils';
import ShareButton from '../../components/ShareButton';
import { toast } from 'react-toastify';
import Progress from '../../components/Progress';

const PointVouchersSection = (({ t }) => {
  
  const dispatch = useDispatch();
  const { userMe } = useSelector(state => state.auth);
  const country = userMe?.country?.toLowerCase();
  const { vouchers, vouchersLoading, vouchersPagination, /*  vouchersLoaded */ } = useSelector(state => state.points);
  const { run: updateVoucherRun } = useRequest(updateVoucher);

  const [tableRows, setTableRows] = useState(null);
  const [sourceVouchers, serSourceVouchers] = useState();
  const [searchValue, setSearchValue] = useState('');
  
  const relativeShopUrl = `/store/${userMe?.shopId}`;

  const fetchData = useCallback((search, increase) => {
    if (vouchersLoading) {
      sourceVouchers?.cancel();
    }
    const source = CancelToken?.source();
    serSourceVouchers(source);
    dispatch(PointsActions.fetchVouchers(
      {
        name: search,
        page: increase && vouchersPagination?.page ? Number(vouchersPagination?.page) + 1 : 1,
        cancelToken: source.token
      })
    );
  }, [dispatch, sourceVouchers, vouchersLoading, vouchersPagination]);

  const lookupEvent = (value) => {
    fetchData(value, false);
  };
  const onSearchChange = (value) => {
    if (!value) {
      fetchData(null, false);
    }
    setSearchValue(value);
  };

  const getMoreVouchers = useCallback(() => {
    if (vouchers) {
      fetchData(searchValue || null, vouchersPagination?.totalPages && (vouchersPagination?.page < vouchersPagination?.totalPages));
    }
  },[fetchData, searchValue, vouchers, vouchersPagination]);

  const ShareHtml = useCallback(() => (
    <span>{t("Share")}</span>
  ), [t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createData = useCallback(({nameVoucher, codeVoucher, points, statusVoucher, id}) =>{
    let title = ''; 
    let code = ''; 
    let value = '';  
    let status = ''; 
    let actions = ''; 

    const shareCodeTitle = `${t('shopShareCodeText', { context: country })}: ${codeVoucher}`;
    const shareCodeObject = {
      title: shareCodeTitle,
      url: document.location.origin + relativeShopUrl,
      copyText: `${shareCodeTitle}`,
    };

    const menuItems = [];

    const reactiveVoucherButton = {
      // icon: {icon:  'arrow_in_right-'},
      text: t("Reactive"),
      onClick: async () => {
        const response = await updateVoucherRun({id, statusVoucher: 'reactivation'});

        if (response?.ok) {
          toast.success(response?.data?.data);
          fetchData(searchValue || null, false);
        }else{
          toast.error(t('errors.SorryProblem'));
        }
      }
    };

    const shareVoucherButton = {
      html: <ShareButton sourceSlug="socio home" component={ShareHtml} info={shareCodeObject} drawer />,
    };

    const deleteVoucherButton = {
      // icon: { icon: 'delete-' },
      text: t("Delete"),
      onClick: async () => {
        const response = await updateVoucherRun({id, statusVoucher: 'delete'});

        if (response?.ok) {
          toast.success(response?.data?.data);
          fetchData(searchValue || null, false);
        } else {
          toast.error(t('errors.SorryProblem'));
        }
      }
    };
    if (statusVoucher === "active") menuItems.push(shareVoucherButton);
    if (statusVoucher === "active") menuItems.push(deleteVoucherButton);
    if (statusVoucher === "expired") menuItems.push(reactiveVoucherButton);
    if (statusVoucher === "expired") menuItems.push(deleteVoucherButton);

    const styleIconMenu ={
      cursor: 'pointer'
    };
    const menu = !menuItems?.length ? null : <Menu styles={{ zIndex: 1401 }} items={menuItems} iconName="navigation-more" activeColor='#0A84FF' styleIconMenu={styleIconMenu}/>;

    title = <div title={nameVoucher} style={{ width: "50px", whiteSpace: "pre-wrap", wordBreak: "break-word" /* , overflow: "hidden", textOverflow: "ellipsis" */}}><span>{nameVoucher}</span></div>;
    code = <div><span>{codeVoucher}</span></div>;
    value = <Emoji><div style={{ display: "flex", gap: "2px" }}><span>{nFormatter(points, 1)}</span><Emoji label="star" content="⭐️" /></div></Emoji>;
    status = <div><span>{t(capitalizeText(statusVoucher))}</span></div>;
    actions = <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{menu}</div>;
    return [ {value: title}, {value: code}, {value}, {value: status}, {value: actions}];
  },[ShareHtml, country, fetchData, relativeShopUrl, searchValue, t, updateVoucherRun]);

  const tableColumns = [
    { label : t('Name') },
    { label : t('Code') },
    { label : t('Points') },
    { label : t('Status') },
    { label : t('Actions') },
  ];
    
  useEffect(() => 
  // cancels after the component's life cycle is over
   () => {
    if(sourceVouchers)sourceVouchers?.cancel();
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
, []);
  
  useEffect(() => {
    if (vouchers) {
      setTableRows(vouchers?.map(createData));
    }
  }, [createData, vouchers, setTableRows]);

  useEffect(() => {
    fetchData(null, false);
    mixpanel.track_optimized('PointsVouchers');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const inputstyle = {
      borderRadius: '2px',
      border: '1px solid #979797',
      padding: '0.5em',
      margin: '30px 0'
    };
    
    const inputsIconStyle = {
      right: '12px',
    };

    const containerstyle = {
      width: 'calc(100% - 2px)',
      padding:'0px',
    };

    const hiddenSeeMoreButton = vouchersPagination?.totalPages && (vouchersPagination?.page < vouchersPagination?.totalPages);


  return <div className={`${styles.vouchersContainer} ${styles['points-section']} px20`}>
    <section className={styles.vouchersSection}>
      <section>
        <SearchBarAndDownload 
          clearable
          value={searchValue} 
          action={() => { }} 
          showButton={false} 
          text={t('Search')}
          lookupEvent={lookupEvent}
          onChange={onSearchChange}
          inputstyle={inputstyle}
          inputsIconStyle={inputsIconStyle}
          containerstyle={containerstyle}
        />
      </section>
      <div className="flex column g24 ">
        { vouchers && vouchers?.length ? <>
        <div className={styles['voucher-container']}>
          <div className={styles['voucher-container__header']}>
            <div className={styles['voucher-container__header__row']}>
              {tableColumns?.map(column => <div className={styles['voucher-container__header__row__cell']} key={column.label}>{column.label}</div>)}
            </div>
          </div>
          <div className={styles['voucher-list-container']}>
            <PointVouchersList
              data={tableRows}
              loading={vouchersLoading}
              pagination={vouchersPagination}
              fetchData={() => fetchData(searchValue, vouchersPagination?.totalPages && (vouchersPagination?.page < vouchersPagination?.totalPages))}
            />
          </div>
        </div>
        {hiddenSeeMoreButton && !vouchersLoading ? <div className={styles.seeMorebutton}>
          <span onClick={getMoreVouchers}>{t("See more")}</span>
        </div> : ""}
        </>
        : ""}
        {vouchersLoading ? <Progress />
          :
          ""}
        {(!vouchersLoading && !vouchers?.length) ? <div className={styles["no-points"]}>
          <h3>{t("Sorry no point vouchers")}</h3>
          <div className="my20 fs80"><Emoji label="shrug" content="🤷‍♀️" /></div>
          </div>
        :""}
      </div>
    </section>
  </div>;
  }
);

export default withTranslation()(PointVouchersSection);