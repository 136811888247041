/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useCallback } from 'react';
import * as Yup from 'yup';
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector, /* useSelector */ } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { BigNumberRounDown, makeId } from '../../utils';
import { SelectInputLiveFeedback } from '../registration/utils';
import PriceDiscountFormat from '../../components/PriceDiscountFormat';
import BigNumber from 'bignumber.js';
import { createVoucher, getExchangePoints } from '../../services/points';
import { useRequest } from '../../utils/hooks';
import { toast } from 'react-toastify';
import mixpanel from '../../services/mixpanel';
import AuthActions from '../../redux/auth/actions';
import Emoji from '../../components/Emoji';


const ExchangePoints = ({t, currencyEquivalent, exchangePointsLoading, currency}) => (
    <div className={styles['value-vaucher-input']}>
    {!currencyEquivalent && !exchangePointsLoading && <span> - </span>}
    {exchangePointsLoading && <span>{t("Exchanging")}</span>}
    {currencyEquivalent && !exchangePointsLoading && <PriceDiscountFormat suffix value={currencyEquivalent} currency={currency} parenthesis={false} />}
    </div>
  );

const CreatePointsVoucherSection = (({ t, currency, setVisibleDrawer, setShareCode }) => {
  
  const dispatch = useDispatch();
  const { userMe, userMeLoaded } = useSelector(state => state.auth);
  const country = userMe?.country;
  const { run: createVoucherRun } = useRequest(createVoucher);
  const { loading: exchangePointsLoading, run: getExchangePointsRun } = useRequest(getExchangePoints);
  const [values, setValues] = useState(null);
  const [disableButton, /* setDisableButton */] = useState(false);
  const [isFormValid, setValidFormik] = useState(false);
  const [pointsAvailable, setPointsAvailable] = useState(0);
  const [remainingPoints, setRemainingPoints] = useState(0);
  const [currencyEquivalent, setCurrencyEquivalent] = useState(null);

  const tr = {
    required: t('RequiredField'),
    pleaseWait: t('Please Wait')
  };

  // Form placeholders
  const placeholder = {
    title: t('ExampleVoucherTitle'),
    code: t('Code'),
    value: t("Select"),
    pleaseWait: t('Please Wait'),
  };

  const PointsVoucherSchema = Yup.object().shape({
    title: Yup.string().max(15, t('Title need to have N characters maximum', {number: 15})).required(tr.required),
    code: Yup.string().min(6, t('Code need to have 6 characters minimum')).required(t('Required')),
    valueVoucher:  Yup.object().shape({
      value: Yup.number().min(25, t('25 minimum')).required(t('Required')),
      label: Yup.string().required(t('RequiredField')),
    }),
    currencyEquivalent: Yup.number().required(t('Required'))
  });

  // Formik initial/default values
  const formikInitialValues = {
    title: "",
    code: makeId(6).toUpperCase(),
  };

  useEffect(() => {
   mixpanel.track_optimized('CreatePoints');
   dispatch(AuthActions.fetchUserMe());
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 useEffect(() => {
  if(userMeLoaded && userMe) {
    setPointsAvailable(userMe?.points?.pointsAvailable);
    setRemainingPoints(userMe?.points?.pointsAvailable);
  }
}, [userMeLoaded, userMe]);


  useEffect(() => {
    if (values) {
      const validations = [PointsVoucherSchema.isValid(values)];
      Promise.all(validations).then(validArray => {
        setValidFormik(validArray[0]);
      });
    }
  }, [values, PointsVoucherSchema]);


  const valueOptions = (availablePoints, step) => {
    const valuesOptions = [];
    let count = 0;
    const stepRange = step || 25;
    const currentDataTotal = BigNumberRounDown(availablePoints / stepRange);

    for (let index = 0; index < currentDataTotal; index++) {
      count += stepRange;
      valuesOptions.push({
        value: count,
        label: `${count}`
      });
      
    }
    return valuesOptions;
  };

  const selectChangeHandler = useCallback(async (option, setFieldValue) => {
    const remainingPointsChange = pointsAvailable - option.value;
    setRemainingPoints(remainingPointsChange);
    
    const res = await getExchangePointsRun({country, points: option?.value});

    if(res.ok) {
      const exchangePoints = BigNumber(res.data.data).decimalPlaces(2).toNumber();
      setFieldValue('currencyEquivalent', exchangePoints);
      setCurrencyEquivalent(exchangePoints);
    }else{
      toast.error(t('errors.exchangePoints'));
    }

  },[country, getExchangePointsRun, pointsAvailable, t]);

  const formOnSubmit = useCallback(async (values) => {
    const payload = {
      name:values?.title,
      code: values?.code,
      discount: {
          value: BigNumber(currencyEquivalent).decimalPlaces(2).toNumber(),
          points: values?.valueVoucher?.value
      }
  };
  const res = await createVoucherRun(payload);
  if(res.ok) {
    setVisibleDrawer();
  }else{
    toast.error(t('errors.SorryProblem'));
  }

  },[createVoucherRun, currencyEquivalent, setVisibleDrawer, t]);

  useEffect(() => () => {
      setCurrencyEquivalent(null);
  }, []);

    
  return <div className={`${styles.shareCreateContainer} ${styles['points-section']} px20`}>
    <section className={styles.shareCreateSection}>
      <div className="pt30 pb32">{t("pages.CreatePointsVoucher.description")}</div>
      <div className="flex column g24 ">
      <Formik
        initialValues={formikInitialValues}
        validationSchema={PointsVoucherSchema}
        onSubmit={formOnSubmit}
      >
      {({ isSubmitting, values, handleBlur, setFieldValue, errors, touched }) => {
        setValues(values);
        if(values?.code) setShareCode(values?.code);
        return(
        <section className={styles['__col-top']}>
            <div className={`${styles['wrap-form']} form-control-mat3`}>
          <Form>
              <div className={styles["generate-voucher-code-row"]}>
              <label htmlFor="label" className={styles['label-voucher']}>{t('Coupon Name')}:</label>
              <Field className={`${styles['field-voucher']} ${styles['field-voucher__placeholder']}`} placeholder={placeholder.title} id="title" name="title" type="text" value={values?.title}/>
              {errors?.title && touched?.title ? (<div className="danger-text-color">{errors?.title}</div>) : ' '}
              </div>
              <div className={styles["generate-voucher-code-row"]}>
                <label htmlFor="label" className={styles['label-voucher']}>{t('Code')}:</label>
                  <Field className={styles["field-voucher"]} name="code" placeholder={placeholder.code} value={values.code} disabled
                    onBlur={handleBlur} type="text"
                  />
                  {errors?.code && touched?.code ? (<div className="danger-text-color">{errors?.code}</div>) : ' '}
              </div>
              <div className={styles['value-voucher-section']}>
                <div>
                <Field onChange={e => selectChangeHandler(e, setFieldValue)} styleLabel={styles['label-voucher']} styleContentSelect={styles["form-control-mat-voucher"]} styleSelectInput="value-vaucher-input-select" 
                    t={t} label={`${t("Points Assigned")}:`}
                    placeholder={<div className={styles.placeholderSelect}>{placeholder?.value}</div>} name='valueVoucher' isPoints
                    options={valueOptions(pointsAvailable, 25)} component={SelectInputLiveFeedback}
                    />
                <div className={`${styles["your-available-points"]} ${styles.top}`}>
                <div className={styles["your-available-points__title"]}>
                  <span>{t("Available points")}: </span>
                </div>
                <div className={styles.total}>
                  <span>{pointsAvailable}</span>
                  <div><Emoji label="star" content="⭐️" /></div>
                </div>
              </div>
                </div>
                <div className={styles["generate-voucher-code-row"]}>
                <label htmlFor="label" className={styles['label-voucher']}>{t('Monetary value')}:</label>
                <ExchangePoints t={t} currencyEquivalent={currencyEquivalent} exchangePointsLoading={exchangePointsLoading} currency={currency}/>
                <div className={`${styles["your-available-points"]} ${styles.top}`}>
                <div className={styles["your-available-points__title"]}>
                  <span>{t("Remaining points")}: </span>
                </div>
                <div className={styles.total}>
                  <span>{remainingPoints}</span>
                  <div><Emoji label="star" content="⭐️" /></div>
                </div>
              </div>
                </div>
              </div>
            <div className={`${styles["action-section"]}`}>
            <div>
              <button 
              type="submit" 
              disabled={!isFormValid || disableButton} 
              className={`btn btn-round ${(!isFormValid || disableButton) ? "btn-disabled" : ""} ${styles["btn-submit-voucher"]}`}
              // onClick={setVisibleDrawer}
              >
                {isSubmitting ? `${tr.pleaseWait}...` : t("Create voucher")}
              </button>
            </div>
            </div>
            </Form>
            </div>
          </section>
        );}}
      </Formik>
      </div>
    </section>
  </div>;
  }
);

export default withTranslation()(CreatePointsVoucherSection);