import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid, Drawer } from '@mui/material';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';
import WaoIcon from '../WaoIcon';
import { toast } from 'react-toastify';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { AppContext } from '../../contexts/AppContext';
import { browserSupportsSharing, onEnter } from '../../utils';
import Progress from '../Progress';
import log from '../../utils/log';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const enableFacebookMessenger = false;

const ShareDrawer = ({ t, visible, setVisible, info, isLoading = false, onShareClick = () => { }, onShareClose = () => { } }) => {

  const usableAppContext = useContext(AppContext);
  const { notify } = usableAppContext;
  const canCopy = true;
  const [canShare,] = useState(browserSupportsSharing());
  const drawerInfo = useMemo(()=> ({ ...info }), [info]);
  const closeHandler = () => { setVisible(false); };
  const openHandler = useCallback(() => {
    if (!info.url) drawerInfo.url = document.location.href;
    if (!info.title) drawerInfo.title = document.title;
  }, [drawerInfo, info.title, info.url]);

  const nativeShareClickHandler = async () => {
    onShareClick('native', drawerInfo);
    navigator.share(drawerInfo)
      .then(() => {
        log.log('Successfully shared');
        onShareClose('native', drawerInfo);
        closeHandler();
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          toast.error('No fue posible compartir la información');
          log.error('Something went wrong sharing', error);
          closeHandler();
        }
      });
  };

  const facebookShareClickHandler = () => { onShareClick('facebook', drawerInfo); };
  const facebookShareCloseHandler = () => { onShareClose('facebook', drawerInfo); };
  const facebooMsnShareClickHandler = () => { onShareClick('facebook-messenger', drawerInfo); };
  const facebooMsnShareCloseHandler = () => { onShareClose('facebook-messenger', drawerInfo); };
  const whatsappShareClickHandler = () => { onShareClick('whatsapp', drawerInfo); };
  const whatsappShareCloseHandler = () => { onShareClose('whatsapp', drawerInfo); };
  const twitterShareClickHandler = () => { onShareClick('twitter', drawerInfo); };
  const twitterShareCloseHandler = () => { onShareClose('twitter', drawerInfo); };
  const telegramShareClickHandler = () => { onShareClick('telegram', drawerInfo); };
  const telegramShareCloseHandler = () => { onShareClose('telegram', drawerInfo); };

  const copyText = `${drawerInfo.copyText} ${drawerInfo.url}`;

  const copySuccessHandler = () => {
    // onShareClick('copy', copyText);
    onShareClose('copy', copyText);
    closeHandler();
    notify({ message: t('URL copied to clipboard!'), background: '#000', color: '#fff', duration: 1500 });
  };

  useEffect(() => {
    if (visible) openHandler();
  }, [visible, openHandler]);

  return (
    <Drawer
      anchor='bottom'
      open={visible}
      onClose={closeHandler}
      transitionDuration={500}
      PaperProps={{ style: { borderRadius: '20px 20px 0 0', boxShadow: '0px 0px 12px 0px rgba(3, 0, 41, 0.4)' } }}
    >
      <div className={styles['drawer-body']}>
        <Grid container className={styles['close-section']}>
          <Grid item xs>
            <WaoIcon icon="close-" size="16" color="#030029" onClick={closeHandler} />
          </Grid>
        </Grid>

        {/* <h4 className={styles.title}>{drawerInfo?.title || ('ShareDrawer.headline')}</h4> */}
        <h4 className={styles.title}>{drawerInfo?.userAction || t('Share')}</h4>
        <p className={styles.content}>{drawerInfo?.text || t('Selecciona a través de que plataforma deseas compartir o copia el URL')}</p>
        {isLoading ? <Progress /> : (
          <div className={`${styles['share-buttons']} ${(((canCopy || canShare) && enableFacebookMessenger) ? (styles.flex) : '')}`}>
            <div>
              <FacebookShareButton beforeOnClick={facebookShareClickHandler} onShareWindowClose={facebookShareCloseHandler} url={drawerInfo.url} title={drawerInfo.title}>
                <FacebookIcon size={42} />
              </FacebookShareButton>
            </div>
            {enableFacebookMessenger && (
              <div>
                <FacebookMessengerShareButton beforeOnClick={facebooMsnShareClickHandler} onShareWindowClose={facebooMsnShareCloseHandler} appId="3902581913189085" redirectUri={drawerInfo.url} url={drawerInfo.url} title={drawerInfo.title}>
                  <FacebookMessengerIcon size={42} />
                </FacebookMessengerShareButton>
              </div>
            )}
            <div>
              <WhatsappShareButton beforeOnClick={whatsappShareClickHandler} onShareWindowClose={whatsappShareCloseHandler} url={drawerInfo.url} title={drawerInfo.title}>
                <WhatsappIcon size={42} />
              </WhatsappShareButton>
            </div>
            <div>
              <TwitterShareButton beforeOnClick={twitterShareClickHandler} onShareWindowClose={twitterShareCloseHandler} url={drawerInfo.url} title={drawerInfo.title}>
                <TwitterIcon size={42} />
              </TwitterShareButton>
            </div>
            <div>
              <TelegramShareButton beforeOnClick={telegramShareClickHandler} onShareWindowClose={telegramShareCloseHandler} url={drawerInfo.url} title={drawerInfo.title}>
                <TelegramIcon size={42} />
              </TelegramShareButton>
            </div>
            {
              canCopy && <CopyToClipboard text={copyText} onCopy={copySuccessHandler}>
                <div className={`${styles['social-button']} ${styles.copy}`}>
                  <WaoIcon icon="link" size="34" color="#fff" />
                </div>
              </CopyToClipboard>
            }
            {
              canShare &&
              <div>
                <div role="button" tabIndex="0" className={`${styles['social-button']} ${styles.share}`} onClick={nativeShareClickHandler} onKeyDown={onEnter(nativeShareClickHandler)}>
                  <WaoIcon icon="navigation-more" size="34" color="#fff" />
                </div>
              </div>
            }
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default withTranslation()(ShareDrawer);