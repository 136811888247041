import Drawer from '@mui/material/Drawer';
import React from 'react';
import Icon from '../Icon';
import styles from './styles.module.sass';

const DrawerPanel = ({ visible = false, setVisible, children, onClose, height, styleDrawerBody, styleDrawerCloseSection, disableCloseReason=[] }) => {
  const close = () => {
    if (setVisible) setVisible(false);
    if (onClose) onClose();
  };

  const onCloseHandler = (event, reason) => {
    if (!disableCloseReason.includes(reason)) {
      if (setVisible) setVisible(false);
      if (onClose) onClose();
    }
  };

  return (
    <Drawer
      anchor='bottom'
      open={visible}
      onClose={onCloseHandler}
      transitionDuration={500}
      PaperProps={{ style: { margin: "auto", maxWidth: "500px", height, borderRadius: '20px 20px 0 0', boxShadow: '0px 0px 12px 0px rgba(3, 0, 41, 0.4)' }}}
    >
      <div className={`${styleDrawerBody || styles['drawer-body']}`}>
        <div className={`${styleDrawerCloseSection || styles['close-section']}`}>
          <Icon icon="close-" size="16" color="#030029" onClick={close} />
        </div>
        <div>
          {children}
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerPanel;
