import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from '@mui/material';

function CustomizedSnackbars({ message, autoHideDuration, open, handleClose, anchorOrigin }) {

  return (
    <Snackbar
      anchorOrigin={ anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

CustomizedSnackbars.defaultProps = {
  message: 'This is a success message!',
  autoHideDuration: 3000,
  open: false,
  handleClose: () => {}
};

CustomizedSnackbars.propTypes = {
  autoHideDuration: PropTypes.number,
  message: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

export default CustomizedSnackbars;
