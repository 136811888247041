import React, { createContext, useMemo, useReducer } from 'react';
import { AppReducer } from './AppReducer';

export const AppContext = createContext();

const initialState = {
  notification: {
    status: false,
    message: '',
    // background: 'red',
    // color: 'blue',
    duration: 1500
  }
};

const AppContextProvider = ({ children }) => {

  const [state, dispatch] = useReducer(AppReducer, initialState);
  const notify = payload => {
    dispatch({ type: 'NOTIFY', payload });
  };
  const notificationHide = payload => {
    dispatch({ type: 'NOTIFICATION_HIDE', payload });
  };
  
  const contextValues = useMemo(() => ({
    notify,
    notificationHide,
    ...state
  }), [state]);

  return (
    <AppContext.Provider value={contextValues}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;