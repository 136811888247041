import React from 'react';
import { withTranslation } from 'react-i18next';
import IcomoonReact from "icomoon-react";
import iconSet from "../../assets/fonts/icomoon/selection.json";

import styles from './styles.module.sass';

const EmptyCart = () => (
  <section className={styles['empty-content']}>
    <figure>
      <IcomoonReact iconSet={iconSet} color="#555" size={50} icon="inbox_empty_round-" />
    </figure>
    <h3>El carrito esta vacio.</h3>
  </section>
);

export default withTranslation()(EmptyCart);
