import React, {useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';
import mixpanel from '../../services/mixpanel';
import Icon from '../../components/Icon';

const TermsAndConditions = ({ history }) => {

  useEffect( () => {
    mixpanel.track_optimized('Terms And Conditions');
  }, []);

  const goBack = () => history.goBack();

  return (  
    <div className={styles['page-wrapper']}>

      {/* <h1>{t.AcceptTermsConditions}</h1> */}
      <button
        type="button" style={{ width: "50px" }} onClick={goBack}
        className={`${styles['header-button']} ${styles['header-button-back']}`}
      >
        <Icon size={20} icon="arrow_left-7" />
      </button>
       <h1>Condiciones Generales</h1>

      <br />

      <p>Las presentes Condiciones regulan el uso de esta Aplicación, y, cualquier otro Contrato o relación jurídica conexos celebrados con el WAO! Holdings Inc.de forma jurídicamente vinculante. Las palabras en mayúsculas se definen en la sección correspondiente específica del presente documento.</p>

      <p>Los Usuarios deben leer atentamente el presente documento.</p>

      <p>Esta Aplicación es ofrecida por: <br/> Wao! Holdings Inc. - 2121 NW 2nd Avenue Unit 203, Miami FL 33127 (USA)
      Correo electrónico de contacto del WAO! Holdings Inc.: hello@wao.shop</p>

      <br />

      <h3>Lo que el Usuario debe conocer al inicio</h3>

      <ul>
        <li>El derecho de desistimiento sólo es aplicable a los Consumidores europeos. En este documento se hará referencia al derecho de desistimiento, también denominado derecho de cancelación en el Reino Unido, como «derecho de desistimiento».</li>
        <li>Por favor tenga en cuenta que algunas disposiciones de las presentes Condiciones solo son aplicables a determinadas categorías de Usuarios. En particular, ciertas disposiciones sólo serán de aplicación a los Consumidores o a aquellos Usuarios que no entren en la categoría de Consumidores. Tales limitaciones se mencionan siempre de forma expresa en cada una de las cláusulas a las que se refieren. En ausencia de una mención de este tipo, las cláusulas serán aplicables a todos los Usuarios.</li>
        <li><b>La utilización de esta Aplicación y del Servicio está restringida en función de la edad</b>: para acceder a esta Aplicación y a sus Servicios y utilizarlos es preciso ser mayor de edad según la ley aplicable.</li>
      </ul>

      <h3>CONDICIONES DE USO</h3>

      <p>Salvo que se establezca lo contrario, las condiciones de uso detalladas en esta sección se aplicarán de forma general al uso de esta Aplicación.</p>

      <p>En situaciones concretas pueden aplicarse condiciones de uso o de acceso individuales o adicionales y en tales supuestos se indicarán de forma adicional en el presente documento.</p>

      <p>Al utilizar esta Aplicación, los Usuarios confirman que cumplen los siguientes requisitos.</p>

      <ul>
        <li>Que No existen restricciones referidas a los Usuarios en el sentido de que estos sean Consumidores o Usuarios Profesionales;</li>
        <li>Que los Usuarios deberán ser mayores de edad de acuerdo a la ley aplicable;</li>
      </ul>

      <h4>Registro de la cuenta</h4>

      <p>Para utilizar el Servicio los Usuarios deberán registrar o crear una cuenta de Usuario, facilitando todos los datos o la información exigidos de forma completa y fidedigna. En caso de no hacerlo el Servicio no estará disponible o podría ser restringido o cancelado.</p>

      <p>Los Usuarios son responsables de la custodia de sus credenciales de acceso con garantías de confidencialidad y seguridad. Por este motivo, los Usuarios también deberán escoger contraseñas que cumplan los más altos estándares de seguridad permitidos por esta Aplicación. Al registrarse, los Usuarios acuerdan asumir plena responsabilidad por todas las actividades que tengan lugar bajo su nombre de usuario y su contraseña.</p>

      <p>Los Usuarios deberán informar a Wao! Holdings Inc. de forma inmediata y sin ambigüedades, usando para ello los datos de contacto indicados en el presente documento, en caso de que crean que su información personal, incluyendo, con carácter enunciativo y no limitativo, las cuentas de Usuario, las credenciales de acceso o los datos personales, han sido vulnerados, revelados de forma indebida o robados.</p>

      <b>Condiciones para el registro de cuentas</b>

      <p>El registro de cuentas de Usuario en esta Aplicación está sometido a las condiciones establecidas a continuación. Al registrarse, los Usuarios aceptan en cumplir tales condiciones</p>

      <ul>
        <li>No se permiten las cuentas registradas por bots o por cualquier otro método automatizado.</li>
        <li>Salvo que se especifique lo contrario, cada Usuario deberá registrar solo una cuenta.</li>
        <li>Salvo que se autorice de forma expresa, las cuentas de Usuario no podrán compartirse con otras personas.</li>
      </ul>

      <b>Cancelación de la cuenta</b>

      <p>Los Usuarios podrán cancelar sus cuentas y dejar de utilizar el Servicio en cualquier momento de la siguiente forma:</p>

      <ul>
        <li>Contactando directamente con el WAO! Holdings Inc. a través de los datos de contacto facilitados en el presente documento.</li>
      </ul>

      <b>Suspensión y eliminación de la cuenta</b>

      <p>El WAO! Holdings Inc. se reserva el derecho, a su entera discreción, de suspender o eliminar las cuentas de Usuarios en cualquier momento y sin preaviso, si las considera inapropiadas, ofensivas o entiende que incumplen las presentes Condiciones.
La suspensión o eliminación de cuentas de Usuario no dará derecho a los Usuarios a exigir ninguna compensación, indemnización por daños y perjuicios o reembolso.
La suspensión o eliminación de cuentas debido a causas imputables al Usuario no eximirá a dicho Usuario de abonar las tarifas o los precios que sean de aplicación.
</p>

      <h4>Contenido en esta Aplicación</h4>

      <p>Salvo que se especifique lo contrario o se pueda reconocer de forma clara, todos los contenidos disponibles en esta Aplicación son propiedad del WAO! Holdings Inc. o son proporcionados por éste o sus licenciantes.</p>

      <p>El WAO! Holdings Inc. se compromete a actuar con la máxima diligencia para velar por que los contenidos proporcionados en esta Aplicación no infrinjan ninguna disposición legal ni vulneren los derechos de terceros. Sin embargo, no siempre será posible conseguir dicho objetivo. En tales supuestos, sin perjuicio de las prerrogativas legales de que dispongan los Usuarios para hacer cumplir sus derechos, se ruega a los Usuarios que comuniquen las quejas en este sentido utilizando los datos de contacto facilitados en el presente documento.</p>

      <b>Derechos relativos a los contenidos en esta Aplicación</b>

      <p>El WAO! Holdings Inc. se reserva todos los derechos de propiedad intelectual sobre la totalidad de dichos contenidos.
Por consiguiente, los Usuarios no podrán utilizar esos contenidos de formas que no sean necesarias o estén implícitas en el uso adecuado del Servicio.
En particular, pero sin limitaciones, los Usuarios no podrán copiar, descargar, compartir (más allá de los límites establecidos más adelante), modificar, traducir, transformar, publicar, transmitir, vender, sublicenciar, editar, transferir/ceder a terceros o crear obras derivadas de los contenidos disponibles en esta Aplicación, ni permitir a ningún tercero hacerlo a través del Usuario o de su dispositivo, incluso sin el conocimiento del Usuario.
En los casos en que se establezca de forma expresa en esta Aplicación, el Usuario podrá descargar, copiar y/o compartir cualquier contenido disponible a través de esta Aplicación únicamente para su uso personal y no comercial y siempre y cuando se efectúen correctamente los reconocimientos de derechos de autor y todos los demás reconocimientos solicitados por el WAO! Holdings Inc..
Las limitaciones o excepciones de los derechos de autor establecidas por ley no se verán afectadas.
</p>

    <h4>Acceso a recursos externos</h4>

    <p>A través de esta Aplicación los Usuarios podrán acceder a recursos externos proporcionados por terceros. Los Usuarios reconocen y aceptan que el WAO! Holdings Inc. no tiene ningún control sobre dichos recursos y que, por tanto, no es responsable de sus contenidos y disponibilidad.
Las condiciones aplicables a los recursos proporcionados por terceros, incluidas las aplicables a cualquier posible concesión de derechos sobre el contenido, se derivan de los términos y condiciones de dichos terceros o, a falta de estos, de las leyes aplicables.
</p>

    <h4>Usos aceptables</h4>

    <p>Esta Aplicación y el Servicio sólo podrán utilizarse dentro del ámbito para el cual se proporcionan, con arreglo a las presentes Condiciones y a la legislación aplicable.</p>

    <p>Los Usuarios serán los únicos responsables de asegurarse de que su utilización de esta Aplicación y/o del Servicio no infringe ninguna ley o reglamento ni vulnera derechos de terceros. Por consiguiente, el WAO! Holdings Inc. se reserva el derecho a adoptar las medidas oportunas para protéger sus intereses legítimos, incluyendo denegar a los Usuarios el acceso a esta Aplicación o al Servicio, resolver contratos, denunciar conductas inadecuadas llevadas a cabo a través de esta Aplicación o del Servicio a las autoridades competentes - tales como las autoridades judiciales o administrativas - siempre que los Usuarios realicen o se sospechen que han realizado cualquiera de las siguientes actividades:</p>

    <ul>
      <li>Infracciones de las leyes, los reglamentos y/o de las presentes Condiciones;</li>
      <li>Vulneración de los derechos de terceros;</li>
      <li>Causar un perjuicio considerable a los intereses legítimos de WAO! Holdings Inc.;</li>
      <li>Ofender a WAO! Holdings Inc. o a algún tercero.</li>
    </ul>

    <h3>TÉRMINOS Y CONDICIONES DE VENTA</h3>

    <h4>Productos pagados</h4>

    <p>Algunos de los Productos proporcionados en esta Aplicación, como parte del Servicio, se facilitan a cambio de una contraprestación.</p>

    <p>Las tarifas, la duración y las condiciones aplicables a la compra de dichos Productos se describen más adelante y en las secciones específicas de esta Aplicación</p>

    <h4>Descripción del producto</h4>

    <p>Los precios, las descripciones o la disponibilidad de los Productos se detallan en las secciones correspondientes de esta Aplicación y podrán sufrir cambios sin preaviso.</p>

    <p>Aunque los Productos en esta Aplicación se presentan con la máxima fidelidad posible desde un punto de vista técnico, la representación en esta Aplicación a través de cualquier medio (incluyendo, según corresponda, materiales gráficos, imágenes, colores o sonidos) únicamente pretenden servir como referencia y no implican ninguna garantía de las características del Producto adquirido.Las características del Producto escogido se describirán durante el proceso de compra</p>

    <h4>Proceso de compra</h4>

    <p>Todos los pasos dados desde la elección de un Producto hasta el envío del pedido forman parte del proceso de compra.</p>

    <p>El proceso de compra incluye los siguientes pasos:</p>

    <ul>
      <li>Los Usuarios deben escoger el Producto que quieren y comprobar su selección de compra.</li>
      <li>Después de haber revisado la información mostrada en la selección de compra, los Usuarios podrán realizar el pedido enviándolo.</li>
    </ul>

    <h4>Envío del pedido</h4>

    <p>Cuando los Usuarios realizan un pedido, son de aplicación las siguientes cláusulas:</p>

    <ul>
      <li>La realización de un pedido determina la celebración del contrato y, por tanto, obliga al Usuario a abonar el precio, los impuestos y todas las posibles comisiones y gastos adicionales, según lo indicado en la página de pedido.</li>
      <li>En caso de que el Producto adquirido exija una intervención activa por parte del Usuario, como aportar información o datos personales, especificaciones o deseos especiales, la realización del pedido obligará al Usuario a cooperar en este sentido.</li>
      <li>Una vez que se haya realizado el pedido, los Usuarios recibirán un comprobante confirmando la recepción del pedido.</li>
    </ul>

    <p>Todas las notificaciones relativas al proceso de compra descrito se enviarán a la dirección de correo electrónico facilitada por el Usuario a tal fin.</p>

    <h4>Precios</h4>

    <p>Se informará a los Usuarios, durante el proceso de compra y antes del envío del pedido, de todas las comisiones, tarifas y costes (incluidos, en su caso, los costes de envío) que deban abonarse.</p>

    <p>Los precios en esta Aplicación se muestran:</p>

    <ul>
      <li>ya sea incluyendo o excluyendo todas las comisiones, tarifas y costes aplicables, dependiendo de la sección que esté consultando el Usuario.</li>
    </ul>

    <h4>Métodos de pago</h4>

    <p>Se facilitará la información relativa a los métodos de pago aceptados durante el proceso de compra.
Algunos métodos de pago solo estarán disponibles sujetos a condiciones o comisiones adicionales. En esos casos, la información relativa a estos podrá encontrarse en la sección específica de esta Aplicación.
Todos los pagos se tramitan de forma independiente a través de servicios de terceros. Por consiguiente, esta Aplicación no recoge ninguna información de pago - como datos de tarjetas de crédito - sino que únicamente recibe una notificación una vez que se ha completado satisfactoriamente el pago.
Si falla el pago a través de los métodos disponibles o bien es denegado por el proveedor de servicios de pago, el WAO! Holdings Inc. no tendrá ninguna obligación de ejecutar el pedido de compra. Todos los posibles costes o comisiones resultantes del pago fallido o denegado serán por cuenta del Usuario.
</p>

    <h4>Moneda virtual para uso exclusivo dentro de esta Aplicación</h4>

    <p>En esta Aplicación determinados pagos pueden realizarse mediante una Moneda virtual. Salvo que se establezca lo contrario, dicha Moneda virtual no puede comercializarse, cambiarse o canjearse por cualquier moneda tradicional, cualquier otra moneda digital abierta, bienes u otros valores.
Al comprar la Moneda virtual, los Usuarios reconocen y entienden que solo podrá utilizarse dentro de esta Aplicación para los fines expresamente autorizados por WAO! Holdings Inc. en el marco de sus Servicios. Los Usuarios también reconocen y aceptan que no podrán transferir, comprar, vender o intercambiar dicha Moneda virtual fuera del Servicio.
En consecuencia, los Usuarios no podrán sublicenciar, comercializar, vender o tratar de vender la Moneda virtual a cambio de dinero, o cambiar la Moneda virtual por valores de cualquier tipo fuera de la oferta específica que facilita el WAO! Holdings Inc. a través de esta Aplicación. Cualquier utilización o transacción prohibidas de este tipo se considerará nula de pleno derecho y podrá dar lugar a la adopción de acciones legales contra el Usuario.
En caso de resolución del contrato o cancelación de la cuenta por cualquier causa imputable al Usuario, se perderán todas las Monedas virtuales sin utilizar y no se concederá ningún reembolso.
</p>

    <h4>Reserva de dominio sobre el Producto</h4>

    <p>Hasta que el WAO! Holdings Inc. haya recibido el pago íntegro del precio de compra, el Usuario no adquirirá la propiedad de los Productos objeto del pedido.</p>

    <h4>Retención de los derechos de uso</h4>

    <p>Los Usuarios no adquirirán ningún derecho a utilizar el Producto adquirido hasta que WAO! Holdings Inc. haya recibido íntegramente el precio de compra.</p>

    <h4>Entrega</h4>

    <p>Las entregas se realizarán en la dirección indicada por el Usuario y de la forma especificada en el resumen del pedido.
Una vez realizada la entrega, los Usuarios deberán comprobar el contenido de dicha entrega y notificar cualquier anomalía sin demoras indebidas, utilizando los datos de contacto que se señalan en el presente documento o según lo descrito en el albarán de entrega. Los Usuarios podrán negarse a aceptar el paquete si está visiblemente dañado.
Se efectúan entregas de bienes en los países o territorios especificados en la sección correspondiente de esta Aplicación.
Los plazos de entrega se especifican en esta Aplicación o durante el proceso de compra.</p>

  
  <b>Entrega fallida</b>

  <p>No se podrá exigir responsabilidad a WAO! Holdings Inc. por los errores en la entrega debidos a inexactitudes o a que el Usuario no haya completado la ejecución del pedido de compra, ni tampoco por cualesquiera daños o retrasos que se produzcan tras la entrega al transportista si este último ha sido organizado por el Usuario.
Si los bienes no se reciben o recogen en el momento o dentro del plazo especificado, los bienes se devolverán al WAO! Holdings Inc., que contactará con el Usuario para organizar un segundo intento de entrega o acordar cómo proceder en el futuro.
Salvo acuerdo en contrario, cualquier intento de entrega a partir del segundo será por cuenta y a costo del Usuario.
</p>

    <b>Entrega de contenidos digitales</b>

    <p>Salvo que se establezca lo contrario, los contenidos digitales comprados en esta Aplicación se entregarán mediante descarga en el dispositivo o dispositivos elegidos por el Usuario.
Los Usuarios reconocen y aceptan que para poder descargar y/o utilizar el Producto, el dispositivo o dispositivos en que se pretende hacerlo y sus softwares respectivos (incluidos los sistemas operativos) deberán ser legales, de uso habitual, estar actualizados y ser compatibles con los estándares de mercado vigentes.</p>
      
    <p>Los Usuarios reconocen y aceptan que la capacidad para descargar el Producto adquirido se puede ver limitada en el tiempo y el espacio.</p>

    <b>Prestación de los servicios</b>

    <p>El servicio contratado se prestará o se facilitará en el plazo especificado en esta Aplicación o según lo comunicado antes del envío del pedido.</p>

    <h4>Duración del contrato</h4>

    <b>Suscripciones</b>

    <p>Las suscripciones permiten a los Usuarios recibir un Producto de forma continua o periódica a lo largo de un período de tiempo determinado.
Las suscripciones de pago comenzarán el día en que el WAO! Holdings Inc. reciba el pago.
Para mantener las suscripciones, los Usuarios deberán pagar la tarifa periódica exigida dentro del plazo establecido. En caso de no hacerlo se podrán producir interrupciones en el servicio.</p>

    <h3>Derechos de los Usuarios</h3>

    <h4>Derecho de desistimiento</h4>

    <p>Salvo en caso de que sea aplicable alguna excepción, los Usuarios podrán desistir del contrato en el plazo señalado a continuación (por lo general, 14 días), por cualquier motivo y sin necesidad de justificación. Los Usuarios pueden obtener más información sobre las condiciones de desistimiento en esta sección.</p>

    <b>A quiénes les resulta aplicable el derecho de desistimiento</b>

    <p>Salvo en caso de que se mencione una excepción aplicable, los Usuarios que sean Consumidores europeos disfrutan de un derecho de cancelación establecido por ley con arreglo al Derecho de la UE, que les permite desistir de contratos celebrados en línea (contratos a distancia) dentro del plazo indicado que sea aplicable a su caso, por cualquier motivo y sin necesidad de justificación.
Los Usuarios que no entren en esta categoría no podrán disfrutar de los derechos descritos en esta sección.</p>

    <b>Ejercicio del derecho de desistimiento</b>

    <p>Para ejercitar el derecho de desistimiento, los Usuarios deberán enviar a WAO! Holdings Inc. una declaración inequívoca de su intención de desistir del contrato.
A tal fin, los Usuarios podrán utilizar el modelo de formulario de desistimiento que aparece en la sección de «definiciones» del presente documento. No obstante, los Usuarios son libres de expresar su intención de desistir del contrato efectuando una declaración inequívoca de cualquier otro modo que sea oportuno. Con el fin de cumplir el plazo en el que se debe ejercitar este derecho, los Usuarios deberán enviar la notificación de desistimiento antes de que expire el plazo de desistimiento.
¿Cuándo finaliza el plazo de desistimiento?</p>

<ul>
  <li><b>En cuanto a la adquisición de bienes</b>, el plazo de desistimiento expirará 14 días a partir del día en que el Usuario o un tercero – distinto del transportista e indicado por el Usuario – tomen posesión material de los bienes.</li>
  <li><b>En cuanto a la adquisición de varios bienes encargados en el mismo pedido</b> pero entregados por separado o en caso de adquisición de un único bien consistente en múltiples componentes o piezas entregados por separado, el plazo de desistimiento expirará 14 días a partir del día en que el Usuario o un tercero – distinto del transportista e indicado por el Usuario – adquieran la posesión material del último bien, componente o pieza.</li>
  <li><b>En cuanto a la contratación de un servicio</b>, el plazo de desistimiento expirará 14 días a partir del día en que se celebre el contrato, salvo que el Usuario haya renunciado a su derecho de desistimiento.</li>
  <li><b>En caso de la adquisición de contenidos digitales no suministrados en un soporte material</b>, el plazo de desistimiento expirará 14 días a partir del día en que se celebre el contrato, salvo que el Usuario haya renunciado a su derecho de desistimiento.</li>
</ul>

<h4>Efectos del desistimiento</h4>

<p>El WAO! Holdings Inc. reembolsará a los Usuarios que desistan correctamente de un contrato todos los pagos realizados al WAO! Holdings Inc., incluyendo, en su caso, los que sirvieran para cubrir los gastos de envío.
Sin embargo, no se reembolsarán los costes adicionales derivados de la elección de un método de envío concreto distinto de la forma de envío estándar menos costosa ofrecida por el WAO! Holdings Inc..
Dicho reembolso se realizará sin demoras indebidas y, en cualquier caso, como máximo 14 días habiles a partir del día en que se informe a WAO! Holdings Inc. de la decisión del Usuario de desistir del contrato. Salvo que se haya acordado lo contrario con el Usuario, los reembolsos se efectuarán utilizando los mismos medios de pago utilizados para tramitar la transacción inicial. En cualquier caso, el Usuario no incurrirá en ningún coste o tarifa a consecuencia de dicho reembolso.</p>

<p><b>…sobre la adquisición de bienes tangibles</b>
Salvo que WAO! Holdings Inc. haya ofrecido realizar la recogida de los bienes, los Usuarios deberán enviar de vuelta los bienes o entregarlos en mano a WAO! Holdings Inc., o bien a una persona autorizada por este último para recibir los bienes, sin demoras indebidas y en todo caso dentro de un plazo de 14 días hábiles desde que comunicarán su decisión de desistir del contrato.
Se considerará cumplido el plazo si los bienes son entregados al transportista o devueltos de cualquier otro modo según lo indicado más arriba, antes de la expiración del período de 14 días para la devolución de los bienes. Podrá retenerse el reembolso hasta la recepción de los bienes o hasta que los Usuarios hayan aportado la prueba de que han devuelto los bienes, lo que suceda primero.
Los Usuarios solo serán responsables de la disminución de valor de los bienes derivada de la manipulación de los bienes que exceda de lo necesario para establecer su naturaleza, características y funcionamiento.
</p>

<b>Los costes de la devolución de los bienes serán por cuenta del Usuario</b>

<b>…sobre la contratación de servicios</b>

<p>Cuando un Usuario ejercite el derecho de desistimiento tras haber solicitado que el servicio sea prestado antes de la expiración del plazo de desistimiento, el Usuario deberá abonar a WAO! Holdings Inc. un importe que sea proporcional a la parte del servicio que se haya prestado.
Dicho pago se calculará sobre la base de la tarifa acordada en el contrato y será proporcional a la parte del servicio que se haya prestado hasta el momento en que el Usuario desista, en comparación con la cobertura completa del contrato.</p>

<h3>Garantía jurídica de conformidad para los bienes</h3>

<p>Con arreglo al Derecho de la Unión Europea, durante un plazo de 2 años a partir de la entrega, los comerciantes garantizarán la conformidad de los bienes que vendan. Esto significa que los comerciantes deben velar por que los bienes adquiridos tengan la calidad prometida, o la calidad que sea razonablemente esperable, las funcionalidades o características, durante un mínimo de dos años desde que fueran entregados al comprador.</p>

<p>Cuando los Usuarios entren en la categoría de Consumidores europeos, la garantía jurídica de conformidad para los bienes se aplicará a los artículos disponibles en esta Aplicación con arreglo al Derecho del país de su residencia habitual.
Es posible que las leyes nacionales de dicho país concedan derechos más amplios a los Usuarios.
Los Consumidores que no entren en la categoría de Consumidores europeos podrán disfrutar de los derechos derivados de la garantía jurídica de conformidad con arreglo a la normativa del país de su residencia habitual.
</p>

<h4>Garantía jurídica sobre bienes de segunda mano o usados</h4>

<p>Los Usuarios reconocen y aceptan que la garantía jurídica sobre los bienes de segunda mano o los bienes usados estará limitada a 3 meses desde la recepción de dichos bienes.</p>

<h3>Responsabilidad y exención de responsabilidad</h3>

<p>Limitaciones de responsabilidad
En la máxima medida de lo permitido por la ley aplicable, en ningún caso serán responsables WAO! Holdings Inc. o sus filiales, sociedades afiliadas, directivos, administradores, agentes, como ninguna de sus marcas, socios, proveedores y empleados respecto de:</p>

<ul>
  <li>cualesquiera daños y perjuicios de carácter indirecto, punitivo, incidental, especial, emergente o ejemplar, incluyendo, con carácter enunciativo y no limitativo, daños y perjuicios por pérdida de beneficios, fondo de comercio, uso, datos u otras pérdidas intangibles, derivados del uso o incapacidad para usar el Servicio, o relacionados con dicho uso o incapacidad de usarlo; y</li>
  <li>cualesquiera daños y perjuicios, pérdidas o lesiones resultantes de la piratería informática, la manipulación o cualquier otro acceso o utilización no autorizados del Servicio o de la cuenta de Usuario o de la información contenida en estos;</li>
  <li>cualesquiera errores, equivocaciones o inexactitudes del contenido;</li>
  <li>daños personales o materiales de cualquier naturaleza resultantes del acceso al Servicio por parte del Usuario o su utilización;</li>
  <li>cualquier acceso o utilización no autorizada de los servidores seguros del WAO! Holdings Inc. y/o de cualquier información personal almacenada en ellos;</li>
  <li>cualquier interrupción o cese en la transmisión al Servicio o desde este;</li>
  <li>cualesquiera errores de programación, virus, troyanos o elementos similares que puedan transmitirse al Servicio o a través de este;</li>
  <li>cualesquiera errores u omisiones en cualquier contenido o por cualquier pérdida o daños y perjuicios que se produzcan como resultado de la utilización de cualquier contenido publicado, enviado por correo electrónico, transmitido o puesto a disposición de cualquier otra manera a través del Servicio; y/o</li>
  <li>la conducta difamatoria, ofensiva o ilegal de cualquier Usuario o tercero. En ningún caso serán responsables WAO! Holdings Inc. o sus filiales, sociedades afiliadas, directivos, administradores, agentes, marcas, socios, proveedores o empleados por cualesquiera demandas, procedimientos, responsabilidades, obligaciones, daños y perjuicios, pérdidas o costes por un importe que exceda de la cantidad abonada por el Usuario a WAO! Holdings Inc. con arreglo a las presentes Condiciones en los 12 meses anteriores, o en el plazo de duración del presente Contrato entre WAO! Holdings Inc. y el Usuario, siendo relevante el plazo más corto de los dos anteriormente mencionados.</li>
</ul>

<p>Esta sección de limitación de responsabilidad será aplicable en la máxima medida de lo permitido por la ley en la jurisdicción correspondiente, con independencia de si la responsabilidad alegada es de carácter contractual, extracontractual, basada en la negligencia, en la responsabilidad objetiva o en cualquier otro supuesto, incluso si se ha advertido el WAO! Holdings Inc. de la posibilidad de tales daños y perjuicios.
Algunas jurisdicciones no permiten la exclusión o la limitación de los daños incidentales o emergentes y, por tanto, las limitaciones o exclusiones antes mencionadas podrían no ser aplicables al Usuario. Las presentes Condiciones otorgan al Usuario derechos legales específicos y el Usuario puede disponer también de otros derechos, que variarán dependiendo de la jurisdicción. Las cláusulas de exención de responsabilidad, exclusiones y limitaciones de responsabilidad en virtud de las presentes Condiciones no serán aplicables en la medida en que estén prohibidas por la ley aplicable.
</p>

<b>Exención de responsabilidad</b>

<p>El Usuario acuerda defender, eximir y liberar de toda responsabilidad al WAO! Holdings Inc. y a sus filiales, sociedades afiliadas, directivos, administradores, agentes, cotitular es de marcas, socios, proveedores y empleados en relación con cualquier demanda o reclamación, daños y perjuicios, obligaciones, pérdidas, responsabilidades, costes o deudas y gastos, incluyendo, de forma enunciativa y no limitativa, gastos y costas legales, derivados de:</p>

<ul>
  <li>la utilización del Servicio por parte del Usuario o su acceso a este, incluyendo cualesquiera datos o contenidos transmitidos o recibidos por el Usuario;</li>
  <li>el incumplimiento por parte del Usuario de las presentes Condiciones, incluyendo, de forma enunciativa y no limitativa, la vulneración por parte del Usuario de cualquiera de las declaraciones y garantías establecidas en las presentes Condiciones,</li>
  <li>la vulneración por parte del Usuario de cualesquiera derechos de terceros, incluyendo, de forma enunciativa y no limitativa, cualquier derecho a la privacidad o derechos de propiedad intelectual;</li>
  <li>la infracción por parte del Usuario de cualquier ley, norma o reglamento;</li>
  <li>cualesquiera contenidos que sean publicados desde la cuenta del Usuario, incluyendo el acceso de terceros con el nombre único del Usuario, la contraseña u otras medidas de seguridad únicas del Usuario, en su caso, incluyendo, de forma enunciativa y no limitativa, la información engañosa, falsa o inexacta;</li>
  <li>la conducta dolosa del Usuario; o</li>
  <li>a infracción de cualquier disposición legal por parte del Usuario o de sus sociedades afiliadas, directivos, administradores, agentes, cotitular es de marcas, socios, proveedores y empleados en la máxima medida de lo permitido por la ley aplicable.</li>
</ul>

<h3>Disposiciones comunes</h3>

<h4>No renuncia</h4>

<p>La falta de ejercicio de cualquier derecho o el hecho de no invocar una disposición en virtud de las presentes Condiciones no constituirán una renuncia a dicho derecho o dicha disposición. No se considerará que ninguna renuncia constituya a su vez una renuncia adicional o continuada a dicho término o a cualquier otro término.</p>

<h4>Interrupción del servicio</h4>

<p>Para garantizar el mejor nivel de servicio posible, WAO! Holdings Inc. se reserva el derecho a interrumpir el Servicio para labores de mantenimiento, actualizaciones del sistema o cualesquiera otros cambios, informando adecuadamente a los Usuarios.
Dentro de los límites de la ley, WAO! Holdings Inc. también podrá decidir suspender o cancelar por completo el Servicio. Si el Servicio se cancela, WAO! Holdings Inc. cooperará con los Usuarios para permitirles retirar Datos personales o información de conformidad con la ley aplicable.
Asimismo, el Servicio podrá no estar disponible debido a motivos fuera del control razonable de WAO! Holdings Inc., como la «fuerza mayor» (p.ej. protestas laborales, averías en las infraestructuras o apagones, etc.).</p>

<h4>Reventa del Servicio</h4>

<p>Los Usuarios no reproducirán, duplicarán, copiarán, venderán, revenderán o explotarán ninguna parte de esta Aplicación y de su Servicio sin la autorización previa, expresa y por escrito de WAO! Holdings Inc., concedida ya sea directamente o a través de un programa de reventa legítimo.</p>

<h4>Política de privacidad</h4>

<p>Para obtener más información sobre la utilización de sus Datos personales, los Usuarios podrán referirse a la política de privacidad de esta Aplicación.</p>

<h4>Derechos de propiedad intelectual</h4>

<p>Sin perjuicio de cualesquiera disposiciones más específicas de las presentes Condiciones, los derechos de propiedad intelectual, tales como los derechos de autor, derechos derivados de marcas registradas, derechos de patentes y derechos de diseños relativos a esta Aplicación son propiedad exclusiva de WAO! Holdings Inc. o de sus licenciantes y están protegidos por las leyes en vigor en materia de marcas y los tratados internacionales relacionados.</p>

<p>Todas las marcas registradas - sean denominativas o gráficas - y cualesquiera otras marcas, nombres comerciales, marcas de servicio, signos denominativos, ilustraciones, imágenes o logotipos que aparezcan en relación con esta Aplicación son y seguirán siendo propiedad exclusiva de WAO! Holdings Inc. o de sus licenciantes y están protegidos por las leyes en vigor en materia de marcas y los tratados internacionales relacionados.</p>

<h4>Cambios de las presentes Condiciones</h4>

<p>WAO! Holdings Inc. se reserva el derecho a cambiar o modificar de cualquier otro modo las presentes Condiciones en cualquier momento. En tales casos, WAO! Holdings Inc. informará adecuadamente a los Usuarios de esos cambios.</p>

<p>Dichos cambios sólo afectarán a la relación con los Usuarios de cara al futuro.
La continuidad en el uso del Servicio indicará la aceptación por parte de los Usuarios de las Condiciones modificadas. Si los Usuarios no desean quedar vinculados por estos cambios, deberán dejar de usar el Servicio. El hecho de no aceptar las condiciones modificadas dará derecho a cualquiera de las dos partes a resolver el Contrato.
La versión aplicable previa regulará la relación antes de la aceptación del Usuario. Los Usuarios podrán obtener cualquier versión previa de WAO! Holdings Inc..
Si la ley aplicable lo exige, WAO! Holdings Inc. especificará la fecha a partir de la cual entrarán en vigor las condiciones modificadas.</p>

<h4>Cesión del contrato</h4>

<p>WAO! Holdings Inc. se reserva el derecho a transferir, ceder, disponer mediante novación o subcontratar cualquiera de los derechos o las obligaciones establecidos con arreglo a las presentes Condiciones, teniendo en cuenta los intereses legítimos de los Usuarios.
Las disposiciones relativas a los cambios de las presentes Condiciones se aplicarán mutatis mutandis.
Los Usuarios no podrán ceder ni transferir sus derechos u obligaciones con arreglo a las presentes Condiciones en modo alguno, salvo con el permiso por escrito del WAO! Holdings Inc..</p>

<h4>Contacto</h4>

<p>Todas las comunicaciones relativas a la utilización de esta Aplicación deberán remitirse utilizando los datos de contacto señalados en el presente documento.</p>

<h4>Posibilidad de separar una disposición</h4>

<p>En el caso de que cualquier disposición de las presentes Condiciones fuera declarada o se convirtiera en inválida o inejecutable con arreglo a la ley aplicable, la invalidez o inejecutabilidad de dicha disposición no afectarán a la validez de las disposiciones restantes, que continuarán gozando de plena vigencia y efectividad.</p>

<h4>Ley aplicable</h4>

<p>Las presentes Condiciones se rigen por las leyes del lugar en el que tenga su sede WAO! Holdings Inc., según lo declarado en la sección correspondiente del presente documento, sin tener en cuenta los principios sobre conflictos de leyes.</p>

<h4>Fuero jurisdiccional</h4>

<p>La competencia exclusiva para resolver cualquier controversia resultante de las presentes Condiciones o relacionada con estas corresponde a los tribunales del lugar en el que se encuentre el domicilio social de WAO! Holdings Inc., como se indica en la sección correspondiente del presente documento.</p>

<h3>Resolución de conflictos</h3>

<h4>Resolución amistosa de conflictos</h4>

<p>Los Usuarios podrán plantear cualquier conflicto WAO! Holdings Inc., que tratará de resolverlo de forma amistosa.
Aunque el derecho de los Usuarios a emprender acciones legales no se verá afectado en ningún momento, en caso de producirse una controversia relativa a la utilización de esta Aplicación o del Servicio, se ruega a los Usuarios que contacten con  WAO! Holdings Inc. mediante los datos de contacto indicados en el presente documento.
El Usuario podrá formular su queja incluyendo una breve descripción y, si es relevante, los datos del pedido, la compra o la cuenta a los que se refiere, enviándolos a la dirección de correo electrónico de WAO! Holdings Inc. especificada en el presente documento.
WAO! Holdings Inc. tramitará la queja sin demoras indebidas y en el plazo de 21 días habiles desde su recepción.</p>

<h4>Resolución de conflictos en línea para Consumidores</h4>

<p>La Comisión Europea ha establecido una plataforma en línea para la resolución alternativa de conflictos que facilita un método extrajudicial para resolver cualquier conflicto relacionado con las ventas en línea y los contratos de servicios, o resultante de estos.
En consecuencia, cualquier Consumidor europeo podrá utilizar dicha plataforma para resolver los conflictos resultantes de contratos celebrados en línea. La plataforma es accesible a través del siguiente enlace.</p>

<h4>Definiciones y referencias legales</h4>

<p>Esta Aplicación (o esta Aplicación) La propiedad que permite la prestación del Servicio.</p>

<p>Contrato Cualquier relación jurídica o contractualmente vinculante establecida entre el WAO! Holdings Inc. y el Usuario,regulada por las presentes Condiciones.</p>

<p>Usuario profesional Cualquier Usuario que no entre en la categoría de Consumidor.</p>

<p>Europeo (o Europa) Es aplicable cuando un Usuario se encuentra físicamente presente o tiene su domicilio social dentro del territorio de la Unión Europea, con independencia de su nacionalidad.</p>

<p>Formulario modelo de desistimiento
*A la atención de:
Wao! Holdings Inc. - 2121 NW 2nd Avenue Unit 203, Miami FL 33127 (USA)
hello@wao.shop
Yo/Nosotros comunico/comunicamos mi/nuestra intención de desistir de mi/nuestro contrato de compraventa de los siguientes bienes/para la prestación del siguiente servicio:.
_____________________________________________ (insertar una descripción de los bienes/servicios a los que se refiere el desistimiento correspondiente)
Pedido el: _____________________________________________ (inserte la fecha)
Recibido el: _____________________________________________ (inserte la fecha)
Nombre del/de los consumidor(es):_____________________________________________
Dirección del/de los consumidor(es):_____________________________________________
Fecha: _____________________________________________
(firmar si se comunica este formulario en formato impreso)
</p>


<p><b>WAO! Holdings Inc. (o Nosotros)</b><br/> Designa a la persona física o jurídica que proporciona esta Aplicación y/o el Servicio a los Usuarios.</p>

<p><b>Producto</b><br/>
Un bien o servicio disponibles para su compra o contratación a través de esta Aplicación, como por ejemplo bienes tangibles, archivos digitales, software, servicios de reservas, etc.
La venta de Productos puede formar parte del Servicio, según se define más arriba.
</p>

<p><b>Servicio</b><br/>
El servicio prestado por esta Aplicación según lo descrito en las presentes Condiciones y en esta Aplicación.
</p>

<p><b>Condiciones</b><br/>Todas las disposiciones aplicables a la utilización de esta Aplicación y/o del Servicio según lo descrito en el presente documento, incluyendo todos los demás documentos o acuerdos conexos y en sus versiones actualizadas vigentes en cada momento.</p>

<p><b>Usuario (o Usted)</b><br/>
Designa a cualquier persona física o jurídica que utilice esta Aplicación.</p>

<p><b>Moneda virtual</b><br/>
Un activo no monetario mediante el cual los Usuarios puedan comprar Productos específicos ofrecidos en esta Aplicación con arreglo a las condiciones establecidas por WAO! Holdings Inc.. Dichos activos pueden manifestarse en forma de códigos, fichas, imágenes digitales, etc.
</p>

<p><b>Consumidor</b><br/>
Cualquier Usuario que, siendo persona física, acceda a bienes o servicios para su uso personal, o de forma más general, que actúe con fines ajenos a su actividad económica, negocio, oficio o profesión.
</p>

    </div>
  );
};

export default withTranslation()(TermsAndConditions);
