import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import { paths } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useAddToHomescreenPrompt } from '../../hooks/useAddToHomescreenPrompt';
import AuthActions from '../../redux/auth/actions';
import StepperActions from '../../redux/stepperInfo/actions';
import PWAInstallerModal from '../PWAInstallerModal';
import StepperInfoPanel from '../StepperInfoPanel';
import DrawerPanel from '../DrawerPanel';
import { Link } from "react-router-dom";

const PanelManager = ({ t }) => {

  const [modals, userId, userType,userMeCountry, userMeLoading] = paths([
    ['userMe', 'modals'],
    ['userMe', 'id'],
    ['userMe', 'type'],
    ['userMe', 'country'],
    ['userMeLoading']], useSelector(state => state.auth));

  const { steppers } = useSelector(state => state.stepperInfo);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [stepperActive, setStepperActive] = useState({});
  const dispatch = useDispatch();


  // Handle order
  const videoPanelSlug = 'isFirstLogin';
  const mustShowVideoPanel = !modals?.[videoPanelSlug];
  const pwaPanelSlug = 'pwaInstaller';
  const mustShowPWAPanel = prompt ? !modals?.[pwaPanelSlug] : false;
  
  const availableSteppers = steppers?.reduce((result, stepper) => {
    if (stepper.status === "active") result.push(stepper.slug);
    return result;
  }, []);

  const nextPanelSlugToShow = modals && availableSteppers?.find(key => (!modals[key]));

  const addToClosedModals = (data) => {
    const newModals = { ...modals };
    newModals[data] = true;
    if (userId) dispatch(AuthActions.updateUserMe({ userId, modals: newModals }));
  };

  const [isWelcomePanelOpen, setIsWelcomePanelOpen] = useState(true);
  const toggleWelcomePanel = () => setIsWelcomePanelOpen(!isWelcomePanelOpen);
  const onWelcomePanelClose = () => addToClosedModals("isFirstLogin");

  const welcomePanel = <DrawerPanel disableCloseReason={['backdropClick']} visible={isWelcomePanelOpen} onClose={onWelcomePanelClose} maxHeight="600px">
    <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
      <p style={{ fontSize: "16px", letterSpacing: "-.53px", textAlign: "center" }}>
        {t('LearnAboutNewPortalText')}
      </p>
      <div>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/oMU93iW_tsg"
          title="Bienvenido a WAO!"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </div>
      <Link to='/socio-help' onClick={() => { toggleWelcomePanel(); onWelcomePanelClose(); }}>
        <button className="btn btn-round">
          {t('View tutorials')}
        </button>
      </Link>
    </div>
  </DrawerPanel>


  const showPanels = () => {
    if (userMeLoading || userType !== 'referrer') return null;
    if (mustShowVideoPanel) return welcomePanel;

    if (mustShowPWAPanel) return <PWAInstallerModal
      modals={modals}
      userId={userId}
      userType={userType}
      promptToInstall={promptToInstall}
      onClick={addToClosedModals}
    />;

    if (nextPanelSlugToShow) return <StepperInfoPanel
      modals={modals}
      userType={userType}
      data={stepperActive}
      onClick={addToClosedModals}
      userMeLoading={userMeLoading}
    />;

    return null;
  };


  useEffect(() => {
    setStepperActive(steppers?.find(stepper => (stepper.slug === nextPanelSlugToShow)));
  }, [steppers, nextPanelSlugToShow]);

  useEffect(() => {
    if (userMeCountry) dispatch(StepperActions.fetchStepper({ country: userMeCountry }));
  }, [dispatch, userMeCountry]);


  return (
    <>
      {showPanels()}
    </>
  );
};

export default withTranslation()(PanelManager);