import React from 'react';
import { createRoot } from 'react-dom/client';
import 'normalize.css';
import './index.sass';
import 'animate.css/animate.min.css';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import log, { init as initLog } from './utils/log';
import ReactGA from 'react-ga';

// import TagManager from 'react-gtm-module'
// import { setConfig } from 'wao-react-shared-components/src/config';

import firebase from 'firebase/app';
import 'firebase/auth';
import store from './redux/store';
import ServiceWorkerActions from './redux/serviceWorker/actions';
import GA4React from 'ga-4-react';
// TODO Fixing this by adding history to the package json causes blank screens
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import gtm from './gtm';
import mixpanel from './services/mixpanel';

// Initializes logs with default env logLevel
initLog();

// Initialize Mix Panel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_MIXPANEL_DEBUG});
// mixpanel.track_optimized('Init App');

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().useDeviceLanguage();
log.log('Firebase initialized');

gtm(process.env);

const gaTrackingId = process.env.REACT_APP_GOOGLE_TAG_MANAGER;
const gaAvailable = gaTrackingId && gaTrackingId !== 'disable';

log.log('==> gaAvailable', gaAvailable);

if (gaAvailable) {
  const trackingId = process.env.REACT_APP_GOOGLE_TAG_MANAGER;
  const history = createBrowserHistory();
  const ga4react = new GA4React(trackingId);
  ga4react.initialize().then(
    (ga4) => {
      log.log('GTAG initialized');
      history.listen((/* location */) => {
        ga4.pageview(window.location.pathname);
        ga4.gtag('event', 'pageview', window.location.pathname);
      });
    },
    (err) => {
      log.error('GA error', err);
    }
  );

}

if (
  process.env.REACT_APP_GA_TRACKING_CODE &&
  process.env.REACT_APP_GA_TRACKING_CODE !== 'disable') {
  log.log('GA initialized', process.env.REACT_APP_GA_TRACKING_CODE);
  ReactGA.initialize(
    process.env.REACT_APP_GOOGLE_TAG_MANAGER,
    {
      debug: true
    }
  );
}

const script = document.createElement("script");
script.src = process.env.REACT_APP_DLOCAL_URL;
script.async = true;
document.body.appendChild(script);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

serviceWorker.register({
  onSuccess: () => store.dispatch( ServiceWorkerActions.init() ),
  onUpdate: registration =>
    store.dispatch(ServiceWorkerActions.update(registration)),
});
