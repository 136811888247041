import { withTheme } from '@mui/styles';
import React from 'react';

const defaultStyles = {
  displayLarge: {fontSize:"57px", lineHeight: "64px" },
  displayMedium: {fontSize:"45px", lineHeight: "52px" },
  displaySmall: {fontSize:"36px", lineHeight: "44px" },

  headlineLarge: {fontSize:"32px", lineHeight: "40px" },
  headlineMedium: {fontSize:"28px", lineHeight: "36px" },
  headlineSmall: {fontSize:"24px", lineHeight: "32px" },

  titleLarge: {fontSize:"22px", lineHeight: "28px" },
  titleMedium: {fontSize:"16px", lineHeight: "24px" },
  titleSmall: {fontSize:"14px", lineHeight: "20px" },

  bodyLarge: {fontSize:"16px", lineHeight: "24px" },
  bodyMedium: {fontSize:"14px", lineHeight: "20px" },
  bodySmall: {fontSize:"12px", lineHeight: "16px" },

  labelLarge: {fontSize:"14px", lineHeight: "20px" },
  labelMedium: {fontSize:"12px", lineHeight: "16px" },
  labelSmall: {fontSize:"11px", lineHeight: "16px" },
};

// Apparently React Native Paper uses a div, React Native uses a span
const Text = ({ variant, children, style, theme, onPress, ...props }) => (
  <div onClick={onPress} style={{ ...defaultStyles[variant], ...style }} {...props}>{children}</div>
);

export default withTheme(Text);