/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { after, shuffleArray } from '../../utils';

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    width: "100%",
    padding: "0px 15px",
    margin: "-5px 0px",
  },
  item: {
    boxSizing: "border-box",
    display: "flex",
    padding: "0.5em",
    width: "50%",
  },
};
const setWidth = 1841;
const setHeight = 1464;

// 1841 x 1464 = 1841 : 1464 = 1464/1841 = 0.79521
const enforcedHeightPercentage = setHeight / setWidth * 100;

const Catalog = ({ obj, onLoad, formToLinkFn }) => {
  const image = obj.images.find((i) => i.type === 'marketing');
  const html = <div
    style={{
      width: "100%",
      position: "relative",
      // CSS Hack, Padding helps set height from width
      paddingBottom: `${enforcedHeightPercentage}%`,
      // float: left
      // height: 0
    }}
  >
    <img alt="catalog thumbnail" src={image.url} onLoad={onLoad}
      style={{
        backgroundImage: `url(${image.url})`,
        borderRadius: "8px",
        backgroundSize: "100% 100%",
        boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.17)",
        width: "100%",
        height: "100%",
        position: "absolute",
        // left: 0
      }}
    />
  </div>;

  return formToLinkFn ? <Link style={styles.item} to={formToLinkFn(obj)}>{html}</Link> :
    <div style={styles.item} role="button" tabIndex="0" aria-label="Go to catalog">{html}</div>;
};

const CatalogList = ({ catalogs, exclude = "", filter = "", formToLinkFn }) => {
  const [catalogsList, setCatalogsList] = useState([]);
  const [/* imagesLoaded */, setImagesLoaded] = useState(false);

  useEffect(() => {
    let filteredCatalogs = catalogs?.filter(catalog => (catalog.type !== exclude || catalog.type === filter));
    if (filteredCatalogs.length > 6) filteredCatalogs = shuffleArray(filteredCatalogs);
    setCatalogsList(filteredCatalogs.slice(0, 6));
  }, [catalogs, exclude, filter]);

  const onLoad = useMemo(() => after(catalogsList?.length, () => {
    setImagesLoaded(true);
  }), [catalogsList]);

  if (!catalogs?.length) return '';



  return (
    <section style={styles.container}>
      {catalogsList?.map((catalog) => <Catalog style={styles.item} key={catalog?.id} obj={catalog} onLoad={onLoad} formToLinkFn={formToLinkFn} />)}
    </section>
  );
};

export default withTranslation()(CatalogList);
