import InitialState from ".";
import PagesActions from "./actions";

const procedures = {
  [PagesActions.updateScrollRequest().type]: (state, payload) => state.merge({
      [payload.view]: { scroll: payload.scroll }
    }),
  [PagesActions.updateBulkRequest().type]: (state, payload) => {
    const {view, ...toUpdate} = payload;
    return state.merge({
      [view]: {...state[view], ...toUpdate}
    });
  }
};

export const reducer = (state = InitialState, action) => procedures[action.type] ? procedures[action.type](state, action.payload) : state;
