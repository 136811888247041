import { CancelToken } from 'apisauce';
import React, { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeaderStore from '../../../components/HeaderStore';
import HelpActions from '../../../redux/help/actions';
import TutorialList from './TutorialList';
import styles from '../styles.module.sass';
import mixpanel from '../../../services/mixpanel';
import WaoIcon from '../../../components/WaoIcon';

const Tutorials = ({ history, t }) => {


  const showLogo = false;
  const [sourceHelp, setSourceHelp] = useState();
  const [searchValue, setSearchValue] = useState('');
  const { helpListLoading, helpList, helpListPagination } = useSelector(state => state.help);
  const dispatch = useDispatch();
  const fetchData = useCallback((name, increase) => {
    if (helpListLoading) {
      sourceHelp.cancel();
    }
    const source = CancelToken.source();
    setSourceHelp(source);
    dispatch(HelpActions.fetchHelpList(
      {
        name,
        currentPage: increase ? helpListPagination.page + 1 : 1,
        cancelToken: source.token
      })
    );
  }, [dispatch,
    helpListPagination,
    helpListLoading,
    sourceHelp
  ]);
  const lookupEvent = (value) => {
    fetchData(value, false);
  };
  const onSearchChange = (value) => {
    if (!value) {
      fetchData(null, false);
    }
    setSearchValue(value);
  };

  const styleItem = {
    margin: "5px 0"
  };

  useEffect(() => {
    fetchData(null, false);
    mixpanel.track_optimized('Help');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const meetingWAOCategory = helpList?.filter(tutorial => tutorial?.category === "Conoce tu WAO! Shop");
  const salesCategory = helpList?.filter(tutorial => tutorial?.category === "Ventas");
  const productsCategory = helpList?.filter(tutorial => tutorial?.category === "Productos");

  const SearchBarProps = {
    clearable:true,
    value:searchValue,
    action:() => { },
    showButton:false,
    text: t('SearchVideos'),
    lookupEvent,
    onChange: onSearchChange,
    inputstyle:{
      borderRadius: '4px',
      border: '1px solid rgb(236, 236, 236)',
      padding: '0.5em 0px 0.5em 1em',
      background: 'rgba(216, 216, 216, 0.2)'
    },
    containerstyle: {
      width: 'calc(100% - 26px - 1em)',
      padding:'0 10px',
    }
  };

  return (
    <div id="help-scroll" className={`${styles['page-wrapper']} app-wrapper-mobile`}>
      <section className={styles.content}>
        <section className={styles.header}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>WAO! - {t('SocioHelp')}</title>
            <link rel="canonical" href="https://app.wao.shop/socio-help" />
          </Helmet>
          <HeaderStore isSearchBar="true" SearchBarProps={SearchBarProps} title={t("Tutorials")} history={history} showLogo={showLogo} icons={{ back: true }} altColor />
        </section>
        <div className={styles['page-wrapper-tutorial']}>
        <section className={styles['tutorial-main']}>
          <h3>{t('Tutorials')}</h3>
          <span>{t('learnTutorials')}</span>

        {(helpList?.length === 0 && !helpListLoading) && <div className={styles.notFoundVideo}>
          <WaoIcon className={styles['search-bar-icon']} color="#555555" size={40} icon="search_left-" />
          <span>{t("NotFoundVideo")}</span>
        </div>}

        </section> 
          {meetingWAOCategory?.length > 0 ? <section className={`${styles['tutorial-category']} ${styles['section-category']}`}>
            <h3>{t('Meet WAO! Shop')}</h3>
            <TutorialList styleItem={styleItem} loading={helpListLoading} fetchData={() => fetchData(searchValue, true)} t={t} data={meetingWAOCategory} pagination={helpListPagination} infiniteScroll/>
          </section> : ""}
          {salesCategory?.length > 0 ? <section className={`${styles['tutorial-category']} ${styles['section-category']}`}>
            <h3>{t('Sales')}</h3>
            <TutorialList styleItem={styleItem} loading={helpListLoading} fetchData={() => fetchData(searchValue, true)} t={t} data={salesCategory} pagination={helpListPagination} infiniteScroll/>
          </section> : ""}
          {productsCategory?.length > 0 ? <section className={`${styles['tutorial-category']} ${styles['section-category']}`}>
            <h3>{t('Products')}</h3>
            <TutorialList styleItem={styleItem} loading={helpListLoading} fetchData={() => fetchData(searchValue, true)} t={t} data={productsCategory} pagination={helpListPagination} infiniteScroll/>
          </section> : ""}
        </div>
      </section>
    </div>
  );
};

export default withTranslation()(Tutorials);
