import React from 'react';
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import Emoji from '../../components/Emoji';
import PriceDiscountFormat from '../../components/PriceDiscountFormat';


const RedeemSection = (({ t, contentArray, country, currency, pointRate, handlerObject }) => {

  // const context = useMemo(() => ({ context: country }), [country]);
  const lowerCaseCountry = country?.toLowerCase();

  const defaultRedeemSections = [
    {
      id: 1,
      title: t(`pages.redeem_${lowerCaseCountry}.sections.1.title`),
      description: t(`pages.redeem_${lowerCaseCountry}.sections.1.description`),
      points: 100,
    },
    // {
    //   id: 2,
    //   title: t("pages.redeem.sections.2.title"),
    //   description: t("pages.redeem.sections.2.description"),
    //   linkOpenDrawer: {
    //     title: t("pages.redeem.sections.2.linkOpenDrawer.title"),
    //     DrawerPanel: {
    //       setVisible: handlerObject?.redeem
    //     }
    //   }
    // },
    {
      id: 3,
      title: t(`pages.redeem_${lowerCaseCountry}.sections.3.title`),
      description: t(`pages.redeem_${lowerCaseCountry}.sections.3.description`)
    },
    {
      id: 4,
      title: t(`pages.redeem_${lowerCaseCountry}.sections.4.title`),
      description: t(`pages.redeem_${lowerCaseCountry}.sections.4.description`),
      linkOpenDrawer: {
        title: t(`pages.redeem_${lowerCaseCountry}.sections.4.linkOpenDrawer.title`),
        drawerPanel: {
          setVisible: handlerObject?.sharePoints
        }
      }
    },
  ];
  let redeemSections = defaultRedeemSections;
  if (contentArray) redeemSections = contentArray;

  return <div className={`${styles.redeemContainer} ${styles['points-section']} px20`}>
    <section className={styles.redeemSection}>
      <h3>{t(`pages.redeem_${lowerCaseCountry}.title`)}</h3>
      <div className="flex column g30 mt12">
        {redeemSections.map(({ id, title, description, points, linkOpenDrawer }) => (
          <div key={id}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description} style={{marginBottom: linkOpenDrawer ? "20px": "" }}>{description}</div>
            {points ? <div className="box-shadow-item minH-M center mt20">
            <div className="flex nowrap">
              <div className="mr6 fw600">{points}</div><Emoji label="star" content="⭐️" /><span style={{marginLeft: "5px"}}>  =  </span>
              <div className="ml6"><PriceDiscountFormat suffix style={{fontWeight:"600"}} value={points * pointRate} currency={currency} parenthesis={false} /></div>
            </div>
            </div> : ""}
            {linkOpenDrawer ? <div>
              <span className={styles.linkOpenDrawer} onClick={linkOpenDrawer.drawerPanel.setVisible}>
                  {t(linkOpenDrawer.title)}
              </span>
            </div> : ""}
          </div>
        ))}
      </div>
    </section>
  </div>;
  }
);

export default withTranslation()(RedeemSection);