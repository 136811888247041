import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styles from './index.module.sass';
import { format, getMonth, getYear } from 'date-fns';
import PriceDiscountFormat from '../../components/PriceDiscountFormat';
import { es } from 'date-fns/locale';
import WaoIcon from '../../components/WaoIcon';
import DialogScreenHCentered from '../../components/DialogScreenHCentered';
import { Link } from 'react-router-dom';
import { onEnter } from '../../utils';

export const capitalize = (word) => {
  if (!word) return word;
  return word[0].toUpperCase() + word.slice(1);
};

const months = [];
for (let i = 0; i < 12; i += 1) {
  months.push(es.localize.month(i,/* { width: 'abbreviated' } */));
}

const getDateMonth = (date) => capitalize(months[getMonth(date, { locale: es })]);

const printDate = (startDate, endDate) => {
  if (!startDate || !endDate) return '';
  const startMonth = getDateMonth(startDate);
  const endMonth = getDateMonth(endDate);
  const endDay = format(endDate, 'd', { locale: es });
  const startDay = format(startDate, 'd', { locale: es });
  const startDateHtml = (startMonth === endMonth) ? (startDay) : (`${startDay} de ${startMonth}`);
  const endDateHtml = `${endDay} de ${endMonth}`;
  const endYear = getYear(endDate);
  return <span>{`${startDateHtml} al ${endDateHtml}`}, {endYear}</span>;
};

const CommissionHeader = ({ t, data, dateRange, infoIcon = false }) => {

  const commissionDetails = window.location.pathname.includes('commissions');
  const dateText = !data ? (`${t('loading')}...`) : (printDate(dateRange[0], dateRange[1]));
  const [viDialog, setViDialog] = useState(false);
  const infoIconRef = useRef();
  const leftVar = infoIconRef?.current?.getBoundingClientRect ? infoIconRef.current.getBoundingClientRect().left : 0;
  const { userMe } = useSelector(state => state.auth);
  const country = userMe?.country?.toLowerCase() || 'co';
  const context = { context: country };

  const infoClickHandler = () => !viDialog ? setViDialog(true) : false;
  const closeInfoDialog = () => setViDialog(false);
  return (
    <div className={styles['gray-box']}>
      <div>
        <h4>{t('yourAccumulatedCommission')}</h4>
        <div className={styles.value}>
          <h2>
            {data && <PriceDiscountFormat value={data.total} discount={0} />}
            {!data && t('loading')}
          </h2>
          {infoIcon &&
            <div role="button" tabIndex="0" ref={infoIconRef} className={styles['waoicon-cnt']} onClick={infoClickHandler} onKeyDown={onEnter(infoClickHandler)}>
              <WaoIcon color="#666" size={18} icon="info2" />
              {viDialog &&
                <DialogScreenHCentered leftAdj={-leftVar}>
                  <ul className={styles['dialog-list']}>
                    <li>
                      {t('YourEarnings', context)}
                    </li>
                    <li>
                      {t('RememberEarnings', context)}
                    </li>
                    <li>
                      {t('HistoryEarnings', context)}
                    </li>
                  </ul>
                  <button type="button" onClick={closeInfoDialog} className={styles['dialog-btn']}>{t('Accept')}</button>
                </DialogScreenHCentered>
              }
            </div>
          }
        </div>

      </div>

      <span className={styles['time-period']}>
        <Link to="/commissions">
          {`${t("Current week")}:`} {dateText}
        </Link>
      </span>
      {!commissionDetails && (<div className={styles['see-more']}>
        <Link to="/commissions">
          {t('SeeCommissionDetails')}
        </Link>
      </div>)}
    </div>
  );
};

export default withTranslation()(CommissionHeader);
