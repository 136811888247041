/* eslint-disable no-unused-expressions */
import { clone } from 'ramda';

const Storage = (customCatalog) => {
  localStorage.setItem('customCatalog', JSON.stringify(customCatalog || {}));
};

const save = (state) => {
  Storage(state.customCatalog);
  return state;
};

export const CustomCatalogReducer = (state, action) => {
  
  const copy = clone(state);
  const customCatalog = copy.customCatalog[action.payload.nameCustomCatalog] || { name:null,items: [] };

  switch (action.type) {
    case "ADD_ITEM":
      if (!customCatalog.items.find(item => item.id === action.payload.item.id)) {
        customCatalog.name = action.payload.nameCustomCatalog;
        customCatalog.items.push({
          ...action.payload.item
        });
      }

      !copy.customCatalog[action.payload.nameCustomCatalog] && (copy.customCatalog[action.payload.nameCustomCatalog] = customCatalog);
      return save(copy);

    case "REMOVE_ITEM":
      copy.customCatalog[action.payload.nameCustomCatalog] = {
        ...customCatalog,
        items: [...customCatalog.items.filter(item => item.id !== action.payload.item.id)]
      };
      !copy.customCatalog[action.payload.nameCustomCatalog] && (copy.customCatalog[action.payload.nameCustomCatalog] = customCatalog);
      return save(copy);

    case "CLEAR":
      copy.customCatalog[action.payload.nameCustomCatalog] = {
        items: [],
        name: action.payload.nameCustomCatalog
      };
      return save(copy);

    default:
      return state;
  }
};