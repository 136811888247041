import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import WaoIcon from "../WaoIcon";
import { Tooltip } from '@mui/material';

import { getUrlExtension, onEnter } from '../../utils';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import ShareDrawer from '../ShareDrawer';
import log from '../../utils/log';
import { makeStyles } from '@mui/styles';

const ShareButton = ({ t, switchViewList, sourceName, drawer, fontColor = "#000", size = "24", buttonClass = "header-button", info = { title: document.title, text: '', url: document.location.href, copyText: '' }, component: CustomComponent, ...props }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isShareVisible, setIsShareVisible] = useState(false);
  const [shareInfo, setShareInfo] = useState({});
  const [shareIsLoading, setShareIsLoading] = useState(false);
  let closeTooltipTimer; 

  useEffect(() => {
    if (tooltipOpen && !closeTooltipTimer) {
      setTimeout(() => {
        setTooltipOpen(false);
      }, 2000);
    }
  }, [closeTooltipTimer, tooltipOpen]);

  const urltoFile = (url, filename, ext) => (
    fetch(url)
      .then(res => res.blob())
      .then(blob => new File([blob], `${filename}.${ext}`, { type: blob.type }))
      .catch(err => {
        log.error('Unable to fetch file', err);
      })
  );
    
  const shareClick = (platform = 'unknown', shareContents) => {
    log.log('click ', platform, sourceName);
    // mixpanel.track_optimized(`${platform} share (${sourceName ? sourceName : '?'})`, shareInfo);
    mixpanel.track_optimized('Shared', { platform, sourceName, ...shareContents });
  };

  const shareClose = (platform = 'unknown') => {
    log.log('close ', platform, sourceName);
    // mixpanel.track_optimized(`${platform} share success`, shareInfo);
  };

  const shareApi = async (shareObject, oneLiner) => {

    if (drawer) {
      let infoToShare = { ...shareObject };
      setIsShareVisible(true);
      setShareIsLoading(true);

      if (shareObject?.urlGenerator) {
        const url = await shareObject?.urlGenerator?.();
        if (url) infoToShare = { ...shareObject, url };
      }
      setShareInfo(infoToShare);
      // mixpanel.track_optimized(`Share drawer open (${sourceName ? sourceName : '?'})`, infoToShare);

      setShareIsLoading(false);
    } else {
      setShareInfo(shareObject);
      /* const canShareText = navigator.canShare({ text: shareObject.text });
      const canShareFiles = navigator.canShare({ files: shareObject.files });
      const canShareTextAndFiles = navigator.canShare({ text: shareObject.text, files: shareObject.files }); */
  
      if (navigator.canShare || navigator.share) {
        // mixpanel.track_optimized(`Share native (${sourceName ? sourceName : '?'})`, shareObject);
        shareClick('old native');
        navigator.share(shareObject)
          .then(() => {
            // mixpanel.track_optimized('Share native success', shareObject);
            log.log('Successfully shared');
          })
          .catch(error => {
            if (error.name !== 'AbortError') {
              toast.error('No fue posible compartir la información');
              log.error('Something went wrong sharing', error);
            }
          });
      } else {
        const canCopy = true;
        if (canCopy) {
          mixpanel.track_optimized(`Share Copy (${sourceName || '?'})`, oneLiner);
          navigator.clipboard.writeText(oneLiner).then(() => {
            setTooltipOpen(true);
            log.log('Copy success');
            // mixpanel.track_optimized(`Share Copy success (${sourceName ? sourceName : '?'})`, oneLiner);
          });
        } else {
          toast.error('Este navegador no soporta compartir ni copiar texto al portapapeles');
        }
      }
    }
  };

  const shareStore = async (innerInfo) => {
    const shareObject = {...innerInfo};
    const shareOneLine = innerInfo.copyText || (innerInfo.url ? `${innerInfo.text} ${innerInfo.url}` : innerInfo.text);
    if (!!innerInfo.files && Array.isArray(innerInfo.files) && innerInfo.files.length > 0) {
      try {
        shareObject.files = await Promise.all(innerInfo.files.map((url, i) => urltoFile(url, `wao_image_${i}`, getUrlExtension(url))));
      } catch (error) {
        toast.warning('No fue posible adjuntar las imágenes');
        log.error('No fue posible adjuntar las imágenes', error);
      }
    }
    await shareApi(shareObject, shareOneLine);
  };

  const useStyles = makeStyles(() => ({ 
    custom: {
      // background: '#006d57',
      background: '#000',
      borderRadius: '15.5px',
      // border: '1px solid ##007b61',
      boxShadow: '0px 0px 7px 0px rgba(70, 70, 70, 0.29)',
      fontWeight: 'bold'
    }
  }));
  const classes = useStyles();
  
  const tooltipHtml = <div>
    {t('URL copied to clipboard!')}
  </div>;  
  
  const clickHandler = async () => { await shareStore(info); };
  const onCloseHandler = () => { setTooltipOpen(false); };
  
  return (
    <div>
      {drawer && <ShareDrawer onShareClick={shareClick} onShareClose={shareClose} visible={isShareVisible} setVisible={setIsShareVisible} info={shareInfo} isLoading={shareIsLoading} />}
      <Tooltip classes={{ tooltip: classes.custom }} open={tooltipOpen} onClose={onCloseHandler}
        title={tooltipHtml} disableFocusListener disableHoverListener disableTouchListener>
        <div role="button" tabIndex="0" onClick={clickHandler} onKeyDown={onEnter(clickHandler)}>
          {
            CustomComponent ? (<CustomComponent {...props} switchViewList={switchViewList} />) :
              (
                <button type="button" className={buttonClass}>
                  <WaoIcon color={fontColor} size={size} icon="share_alt" />
                </button>
              )
          }
        </div>
      </Tooltip>
    </div>
  );
};

  export default withTranslation()(ShareButton);