import React from 'react';
import { withTranslation } from 'react-i18next';
import WaoIcon from '../WaoIcon';
import styles from './styles.module.sass';

const Icon = ({ className, description, icon = "", size = "30", color = "#1b2835", onClick, style }) => (
  <div className={`${styles.container} ${styles[className]}`} onClick={onClick} style={style}>
    <WaoIcon icon={icon} size={size} color={color} />
    {description && <div>{description}</div>}
  </div>
);

export default withTranslation()(Icon);