import React from 'react';
import { useHistory } from 'react-router-dom';
import TouchableOpacity from '../../transition/TouchableOpacity';

const LinkSurface = ({ children, style, path, url, src, alt, newTab, ...props }) => {
  const history = useHistory();
  const goToNewTab = () => window.open(url);
  const goToSameTab = () => window.location.assign(url);
  const externalFunction = newTab ? goToNewTab : goToSameTab;
  const redirectFunction = path ? () => history.go(path) : externalFunction;
  return <TouchableOpacity onPress={redirectFunction} style={style} {...props}>
    {children}
  </TouchableOpacity>;
};

export default LinkSurface;