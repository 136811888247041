/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormikProvider, useFormik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { withTranslation } from 'react-i18next';
import { CircularProgress, Divider } from '@mui/material';
import ReactCodeInput from 'react-code-input';
import 'firebase/auth';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import HeaderStore from '../../../components/HeaderStore';
import AuthActions from '../../../redux/auth/actions';
import phoneIcon from "../../../assets/images/auth/auth-number.png";
import phoneIcon2x from "../../../assets/images/auth/auth-number@2x.png";
import phoneIcon3x from "../../../assets/images/auth/auth-number@3x.png";
import codeIcon from "../../../assets/images/auth/auth-code.png";
import codeIcon2x from "../../../assets/images/auth/auth-code@2x.png";
import codeIcon3x from "../../../assets/images/auth/auth-code@3x.png";
import 'react-phone-input-2/lib/style.css';
import styles from './styles.module.sass';
import { usePrevious } from '../../../utils/hooks';
import { useLocation } from 'react-router-dom';
import { SelecInputPhone } from '../../registration/utils';
import { enablePhoneDropdownUrls, getDefaultRolePageRedirect } from '../../../utils/constants';
import Countdown from "react-countdown";
import { equals, length, path } from 'ramda';
import mixpanel from '../../../services/mixpanel';
import log from '../../../utils/log';
import { onEnter } from '../../../utils';
import Screen from '../../../transition/Screen';
import ScreenContent from '../../../transition/ScreenContent';
import View from '../../../transition/View';
import FooterSocial from '../../../components/FooterSocial';
import Text from '../../../transition/Text';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import { Helmet } from 'react-helmet';
import useCaptcha from '../../../hooks/useCaptcha';
import { Recaptcha } from '../../../components/Recaptcha.js';

// const useLastXDigits = 4;
const waitTimeForCode = 1000 * 60 * 2;
const defaultCountry = 'AR';

/* const maskPhone = (phone) => {
  if (!phone || phone.length < useLastXDigits) return phone;
  return '*'.repeat(phone.length - useLastXDigits) + phone.slice(useLastXDigits * -1);
}; */

const CodeInput = React.memo((FieldProps) =>
  <ReactCodeInput
    name='otc' type="number" pattern="\d{6}" inputMode="numeric" autoFocus
    fields={6} autoComplete="one-time-code"
    className={`${styles.reactCodeInput} reactCodeInput`}
    onChange={option => FieldProps.form.setFieldValue(FieldProps.field.name, option)}
    {...FieldProps}
  />
);

const PhoneSignIn = ({ history, t , ...props }) => {
  const globalStyles = useGlobalStyles();
  const [endTime, setEndTime] = useState(Date.now());
  const [atStep2, setAtStep2] = useState(false);
  const dispatch = useDispatch();
  const { userMe, loginError, userMeLoading, loginLoading } = useSelector(state => state.auth);
  const prevLoginError = usePrevious(loginError);
  const recaptchaContainer = useRef(null);
  const [validatingCode, setValidatingCode] = useState(false);
  const { store } = useSelector(state => state.stores);
  const location = useLocation();
  const [countryCode, setCountryCode] = useState(store ? store.country : defaultCountry);
  const [enableCountrySelection, setEnableCountrySelection] = useState(false);
  const nextRoute = (new URLSearchParams(useLocation().search)).get('nextRoute');
  const { initRecaptchaVerifier, captchaObject, validateRefreshForRecaptcha, renderedCaptcha, setValidateRefreshForRecaptcha} = useCaptcha({errorMessage:`${t('LoginProcessFailed')} (sR)`});
  const goToNext = useCallback((userParam) => {
    if (nextRoute) {
      history.replace(nextRoute);
    } else {
      history.replace(getDefaultRolePageRedirect({role: userParam?.role || userParam?.type, user: userMe}));
    }
  }, [nextRoute, history, userMe]);
  
  Yup.addMethod(Yup.string, 'validPhoneNumber', function validation(message) {
    return this.test('phone', message, value => {
      if (!value) return false;
      return isValidPhoneNumber(value, countryCode);
    });
  });
  
  const formikStep1 = useFormik({
    initialValues: { phoneNumber: "", otc: "" },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .validPhoneNumber(t('EnterValidPhone', { context: countryCode?.toLowerCase() }))
        .required(t('RequiredField')),
      otc: Yup.string()
        .required(t('RequiredField')),
    }),
  });
  const { phoneNumber, otc } = formikStep1.values;
  const isValidPhone = isValidPhoneNumber(phoneNumber, countryCode);
  const validateCode = () => {
    setValidatingCode(true);
    if (window.confirmationResult) window.confirmationResult.confirm(otc).then((result) => {
      dispatch(AuthActions.otcConfirmed(result));
    }).catch((/* error */) => {
      setValidatingCode(false);
      toast.error(t('InvalidCode'));
    }).finally(() => {
    });
  };
  const cleanup = useCallback(() => {
    const currElement = recaptchaContainer?.current;
    while (currElement?.firstChild) {
      currElement?.removeChild(currElement?.firstChild);
    }
    setAtStep2(false);
    initRecaptchaVerifier();
  }, [recaptchaContainer, setAtStep2, initRecaptchaVerifier]);
  
  const goLoginWithPhone = useCallback(() => {
    const functionMain = async () => {
      if (!isValidPhone) {
        toast.error('Teléfono y país no son validos.');
        cleanup();
        return;
      }
      mixpanel.track_optimized('Auth Phone send code');
      await dispatch(AuthActions.signInWithPhoneNumber(`${phoneNumber}`, countryCode, captchaObject, location));
      setAtStep2(true);
    };
    functionMain();

  }, [dispatch, setAtStep2, countryCode, location, captchaObject, isValidPhone, phoneNumber, cleanup]);

  useEffect(() => {
    dispatch(AuthActions.resetLoginState());
  }, [dispatch]);
  
  useEffect(() => {
    if (userMe?.country) goToNext(userMe);
  }, [userMe, dispatch, goToNext]);

  useEffect(() => {

    // if (loginError !== null && formikStep1.isValid) {
    //   dispatch(AuthActions.resetLoginState());
    //    goLoginWithPhone();
    // }
    if (loginError && (prevLoginError !== loginError)) {
      log.error('Login error', JSON.stringify(loginError));
      cleanup();
      if (equals("auth/too-many-requests", path(['userMessage', 'code'], loginError))) {
        toast.error(t('AuthTooManyRequests'));
      } else if (equals("SESSION_EXPIRED", path(['error', 'message'], loginError))) {
        toast.error(t('SessionExpired'));
      } else if (equals("TOKEN_EXPIRED", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC3)`);
      } else if (equals("USER_NOT_FOUND", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC4)`);
      } else if (equals("INVALID_GRANT_TYPE", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC5)`);
      } else if (equals("TOO_MANY_ATTEMPTS_TRY_LATER", path(['error', 'message'], loginError))) {
        toast.error(`${t('AuthTooManyRequests')} (sC6)`);
      } else if (equals("USER_DISABLED", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC7)`);
      } else if (equals("MISSING_REFRESH_TOKEN", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC8)`);
      } else if (equals("QUOTA_EXCEEDED", path(['error', 'message'], loginError))) {
        toast.error(`${t('AuthTooManyRequests')} (sC9)`);
      } else {
        toast.error(`${t('LoginProcessFailed')} (sD)`);
      }
    }
  }, [loginError, prevLoginError, formikStep1, goLoginWithPhone, dispatch, cleanup, t]);

  useEffect(() => {
    if (store !== null) {
      setCountryCode(store.country);
    }
  }, [store, setCountryCode]);

  useEffect(() => {
    setEnableCountrySelection(enablePhoneDropdownUrls.reduce((prev, f) => prev || f(location, store?.country || userMe?.country), false));
    initRecaptchaVerifier();
    mixpanel.time_event_optimized('Auth Phone');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (atStep2) { setEndTime((Date.now() + (waitTimeForCode))); }
  }, [atStep2]);

  const countdownComp = <Countdown date={endTime}
    onComplete={() => setEndTime(0)}
    renderer={({ hours, minutes, seconds, completed }) => {
      if (completed) return '';
      return (
        <>
          {t('WaitForCodeWarning')}
          <span className={styles['send-code']}>
            {hours ? `${hours}:` : ""}{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
          </span>
        </>
      );
    }}
  />;

  const isContinueButtonDisabled = !!(loginLoading || !captchaObject || !isValidPhone);
  const isListoButtonDisabled = !!(validatingCode || userMeLoading || !otc || length(otc) !== 6);


  const reloadOnClick = () => window.location.reload();
  const showIfStep1 = { display: !atStep2 ? "flex" : "none", flexDirection: "column" };
  const showIfStep2 = { display: atStep2 ? "flex" : "none", flexDirection: "column" };

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("pages.title", { title: t("pages.phoneSignIn.title") })}</title>
        <link rel="canonical" href="https://app.wao.shop/sign-in/phone" />
      </Helmet>
      <HeaderStore showLogo altColor icons={{ back: true }} />
      <ScreenContent webMobile>
        <View style={{ padding: "30px" }}>
          <View>
            <Text style={globalStyles.sectionTitle}>{t("pages.phoneSignIn.innerTitle")}</Text>
            <Divider style={globalStyles.titleDivider} />
          </View>
          <View style={{ ...showIfStep1, alignItems: "center", marginBottom: "-16px" }}> 
            <Text style={{ paddingTop: "8px" }}>{t("pages.phoneSignIn.step1Instructions")}</Text>
            <img style={{ marginTop: "24px" }} src={phoneIcon} width="235" height="205" alt="" srcSet={`${phoneIcon} 1x, ${phoneIcon2x} 2x, ${phoneIcon3x} 3x`} />
          </View>
          <View style={{ ...showIfStep2, alignItems: "center" }}> 
            <Text style={{ paddingTop: "8px" }}>{t('EnterCodeSendToPhone', { phone: formikStep1?.values.phoneNumber })}.</Text>
            <img style={{ marginTop: "24px" }} src={phoneIcon} width="212" height="210" alt="" srcSet={`${codeIcon} 1x, ${codeIcon2x} 2x, ${codeIcon3x} 3x`} />
          </View>
          <FormikProvider value={formikStep1}>
            <Form style={{ padding: "8px 0px" }}>

              <View style={showIfStep1}>
                <div className={`${styles['phone-section']} form-control-mat2`}>
                  <Field
                    label={t('Phone Number')}
                    feedbackClass={styles['error-feedback']}
                    name='phoneNumber'
                    autoFocus
                    country={countryCode || defaultCountry}
                    value={props?.location?.user?.phone}
                    disableDropdown={!enableCountrySelection}
                    setCountryCode={setCountryCode}
                    component={SelecInputPhone}
                    className="react-tel-input2"
                    inputProps={{ autoFocus: true }}
                  />
                  {/* <span>{ t("signIn.areaCodeMessage")}</span> */}
                </div>
              </View>
              <View style={showIfStep2} className={styles['wrap-code-input']}>
                <Field
                  name='otc' autoComplete="one-time-code"
                  type="number" pattern="\d{6}" inputMode="numeric"
                  className={`${styles.reactCodeInput} reactCodeInput`}
                  component={CodeInput} fields={6}
                />
              </View>
            </Form>
          </FormikProvider>
          <View style={showIfStep1}>
            <Recaptcha loader renderedCaptcha={renderedCaptcha} refRecaptchaContainer={recaptchaContainer} validateRefreshForRecaptcha={validateRefreshForRecaptcha} setValidateRefreshForRecaptcha={setValidateRefreshForRecaptcha} t={t}/>
            <button type="button" disabled={isContinueButtonDisabled} id="sign-in-button" className={`btn btn-round ${styles.__btn}`} onClick={goLoginWithPhone}>
              <span>{t('Send')}</span>
            </button>
          </View>
          <View style={showIfStep2}>
            <button type="button" disabled={isListoButtonDisabled} className={`btn btn-round ${styles.__btn}`} onClick={validateCode}>
              {validatingCode ? <CircularProgress size={20} color="inherit" style={{ color: "#fff" }} /> : <span>{t("Confirm")}</span>}
            </button>
            <hr className={styles.divider} />
            <p className={styles.resend}>
              {
                endTime > (Date.now()) ? countdownComp
                  :
                  <>
                    {t("I haven't received the code")}
                    {/* TODO This garbage shall not be here! P2 */}
                    <span role="button" tabIndex="0" onClick={reloadOnClick} onKeyDown={onEnter(reloadOnClick)} className={styles['send-code']}>{t("Send a new code")}</span>
                  </>
              }
            </p>
          </View>
        </View>
        <FooterSocial country={props?.user?.country}/>
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(PhoneSignIn);