/* eslint-disable default-param-last */
import CustomCatalogActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case CustomCatalogActions.createCustomCatalogRequest().type:
      return state.merge({
        customCatalogsLoading: true,
        customCatalogsError: null,
        customCatalogsLoaded: false,
      });

    case CustomCatalogActions.createCustomCatalogSuccess().type:
      return state.merge({
        customCatalogsLoading: false,
        customCatalogsError: null,
        customCatalogsLoaded: true,
        customCatalogs: action.payload.customCatalog.data || state.customCatalogs,
      });
          
    case CustomCatalogActions.createCustomCatalogFailed().type:
      return state.merge({
        customCatalogsLoading: false,
        customCatalogsError: action.payload.error,
        customCatalogsLoaded: false,
      });

    case CustomCatalogActions.fetchCustomCatalogRequest().type:
      return state.merge({
        customCatalogsLoading: true,
        customCatalogsError: null,
        customCatalogsLoaded: false,
      });

    case CustomCatalogActions.fetchCustomCatalogSuccess().type:
      return state.merge({
        customCatalogsLoading: false,
        customCatalogsError: null,
        customCatalogsLoaded: true,
        customCatalogs: action.payload.customCatalogs.data,
      });
          
    case CustomCatalogActions.fetchCustomCatalogFailed().type:
      return state.merge({
        customCatalogsLoading: false,
        customCatalogsError: action.payload.error,
        customCatalogsLoaded: false,
      });

    case CustomCatalogActions.updateCustomCatalogRequest().type:
      return state.merge({
        customCatalogsLoading: true,
        customCatalogsError: null,
        customCatalogsLoaded: false,
      });

    case CustomCatalogActions.updateCustomCatalogSuccess().type:
      return state.merge({
        customCatalogsLoading: false,
        customCatalogsError: null,
        customCatalogsLoaded: true,
      });
          
    case CustomCatalogActions.updateCustomCatalogFailed().type:
      return state.merge({
        customCatalogsLoading: false,
        customCatalogsError: action.payload.error,
        customCatalogsLoaded: false,
      });

    case CustomCatalogActions.deleteCustomCatalogRequest().type:
      return state.merge({
        customCatalogsLoading: true,
        customCatalogsError: null,
        customCatalogsLoaded: false,
      });

    case CustomCatalogActions.deleteCustomCatalogSuccess().type:
      return state.merge({
        customCatalogsLoading: false,
        customCatalogsError: null,
        customCatalogsLoaded: true,
        customCatalogs: action.payload.customCatalog.data || state.customCatalogs,
      });
          
    case CustomCatalogActions.deleteCustomCatalogFailed().type:
      return state.merge({
        customCatalogsLoading: false,
        customCatalogsError: action.payload.error,
        customCatalogsLoaded: false,
      });

    default:
      return state;
  }
};
