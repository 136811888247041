import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {withStyles} from '@mui/styles';
// import stylesFile from './styles.module.sass';

const Tabber = ({ tabs, styles, selectTab, disableTab }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  
  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const selectorStyles = {
    root: {
      borderBottom: '1px solid #c8c8c8',
      backgroundColor: '#ffffff',
      top: '-1px',
      position: 'sticky',
      zIndex: '50',
    },
    indicator: {
      height: '3px',
      backgroundColor: '#ee7d40'
    }
  };

  const tabStyles = {
    root: {
      textTransform: 'none',
      fontSize: '16px !important'
    },
    selected: {
      fontWeight: 'bold',
      color: '#ee7d40'
    }
  };

  const Selector = withStyles(styles?.selector || selectorStyles)(Tabs);
  const SingleTab = withStyles(styles?.tab || tabStyles)(Tab);

  useEffect(() => {
    if(selectTab) setActiveTab(selectTab);
  }, [selectTab]);


  return (
    <>
      <div style={styles?.styleCotainerTabs}>
        <Selector value={activeTab} onChange={handleTabChange} variant="fullWidth">
          {tabs?.map((tab, index) => (
            <SingleTab key={tab?.label} label={tab.label} disabled={(disableTab && index === disableTab) || false} />
          ))}
        </Selector>
      </div>
      {tabs?.[activeTab]?.content}
    </>
  );
};

export default withTranslation()(Tabber);