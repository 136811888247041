import ServiceWorkerActions from './actions';
import InitialState from ".";

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case ServiceWorkerActions.init().type:
      return ({
        ...state,
        initialized: !state.initialized,
      });
    case ServiceWorkerActions.update().type:
      return ({
        ...state,
        updated: !state.updated,
        registration: action.payload.registration,
      });
    default: return state;
  }
};
