import api from './api';

export const getPromosByReferrer = ({referrerId, userId}) => {
  if (!referrerId) return console.error('Missing referrerId param');
  let objectRequest ={referrerId};
  if(userId) {
      objectRequest = {...objectRequest, userId};
  };
  return api.get("promotions/mini-store", objectRequest);
};

export const getCatalogsByReferrer = (referrerId) => {
  if (!referrerId) return console.error('Missing referrerId param');
  return api.get("promotions/catalogs", { referrerId });
};