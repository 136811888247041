/* eslint-disable no-shadow */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ShopingCartContext } from '../../contexts/shoppingCart/ShopingCartContext';
import { CustomCatalogContext } from '../../contexts/customCatalog/CustomCatalogContext';
import styles from './styles.module.sass';
import ShareButton from '../../components/ShareButton';
import { filterMedia, getDealPrices, showFriendlyDateSameYearCaseShort, toFloat } from '../../utils';
import { es } from 'date-fns/locale';
import WaoIcon from '../../components/WaoIcon';
// import SimpleQuantity from '../../components/ShoppingCart/SimpleQuantity';
import { useSelector } from 'react-redux';
import SliderProducts from '../../components/SliderProducts';
import BuyNowButton from '../../components/BuyNowButton';
import ProductVariantSelector from '../../components/ProductVariantSelector';
import ProductVariantsDrawer from '../../components/ProductVariantsDrawer';
import CurrencyFormat from 'react-currency-format';
import CreateCustomCatalog from '../../components/CreateCustomCatalog'
import DealPrice from '../../components/DealPrice';
import { mpLogoURL } from '../../utils/constants';
import Pill from '../../components/Pill';

const ShareWideButton = withTranslation()(({ t, ...props }) => (
  <div className={styles['cta-upper']}>
    <button className={`btn btn-round ${styles['btn-buyNow']}`}>
      <WaoIcon color="#fff" size={props?.isGridView ? "13" : "20"} icon="share" />
      <span style={{ fontSize: props?.isGridView ? "13px" : "16px" }}>{t('Share')}</span>
    </button>
  </div>
));

const customBuyNowButton = withTranslation()(({ t, isOwnStore, singleDeal }) => (
  <button className={`${isOwnStore ? `btn  btn-outline ${styles['add-btn']}` : styles['btn-buyNow']}`}>
    <span> <WaoIcon color={isOwnStore ? '#030029' : '#fff'} size={!singleDeal ? "12" : "16"} icon="cart" /> {t('Buy now')}</span>
  </button>
));

const InstallmentCopy = withTranslation()(({ t, installments, amountByInstallments, country }) => (
  // TODO Adjust this for future countries
  country === 'mx' ? '' : <div style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: "11px" }}>
    {installments ? <div className={styles["installments-copy__deal"]}>
      <span style={{ marginRight: "3px" }}>{t(`installments-deal-copy1`, { installments })}</span>
      <CurrencyFormat isNumericString value={amountByInstallments} displayType="text" thousandSeparator decimalSeparator="." prefix="$" />
      <span style={{ marginLeft: "3px" }}>{t(`installments-deal-copy2`)}</span>
    </div>
      : <span>{t(`installments-copy`, {context: country})}</span>}
    <img height="20px" src={mpLogoURL} alt='installments' />
  </div>
));

const PointsCopy = withTranslation()(({ pointsLimit, price, pointsRate, t }) => {
  // If points is null (no limit), calculate the amount of points required to pay the full price
  const points = pointsLimit ?? Math.ceil(price / pointsRate);

  return !points ? null : (
    <div className={styles["points-copy"]}>
      <div style={{ fontSize: "14px", color: "#0aae8c" }}>
        <span style={{ marginRight: "3px" }}>{t(`redeemUpToPoints`, { points })}</span>
      </div>
    </div>
  );
});

const DiscountInfo = withTranslation()(({ t, name, code, value, promoDiscountDetail, isMoneyVoucher }) => {
  const valueDiscountCurrency = <CurrencyFormat isNumericString value={value} displayType="text" thousandSeparator decimalSeparator="." prefix="$" />;
  return <div className={styles['discount-info']}>
    {isMoneyVoucher ? <span>{valueDiscountCurrency}</span> : ''}
    <span>{`${isMoneyVoucher ? '' : `${parseFloat(promoDiscountDetail)}%`} OFF ${name} ${code ? `${t("with code")}: ${code}` : ""}`}</span>
  </div>
});

const Tags = withTranslation()(({ t, noLimit, date, quantity }) => (
  <div style={{ display: "flex", flexDirection:"column", gap: "8px"}}>
    <Pill style={{whiteSpace: "pre", fontSize: "14px", padding: "4px 8px"}}>
      {!noLimit && quantity ? (
        <div>
          <span>
            <span>{t(quantity > 5 ? 'OnlyCountAvailable' : 'UntilStock', { count: quantity })}</span>
          </span>
        </div>
      ) : (
        ''
      )}
      {!noLimit && quantity === 0 && (
        <div>
          <span>
            <span>{t('NoUnitsAvailableLeft')}</span>
          </span>
        </div>
      )}
    </Pill>
    <div className={styles.expiration}>
      <span>{t('AvailableUntilDate', { date })}</span>
    </div>
  </div>
));

const DealCard = ({
  t,
  deals = {},
  // sourceSlug,
  share,
  disableCarousel = false,
  isOwnStore = false,
  enableSelector,
  mainDeal,
  showPoints,
  singleDeal
}) => {
  const firstAvailableDeal = deals?.deals.find(item => (item.quantity === null || item.quantity > 0)) || deals?.deals?.[0];
  const [selectedDeal, setSelectedDeal] = useState(mainDeal || firstAvailableDeal);
  const { media } = selectedDeal || [];
  const customCatalogContext = useContext(CustomCatalogContext);
  const { addProduct: addCustomCatalogProduct } = customCatalogContext;
  const { storeId } = useParams();
  const { store } = useSelector(state => state.stores);
  const { userMe } = useSelector(state => state.auth);
  const expirationDate = selectedDeal?.expiresAt ? new Date(selectedDeal?.expiresAt) : new Date();
  const formattedDate = showFriendlyDateSameYearCaseShort(expirationDate, es);
  const dealUrl = deals.deals.find(item => item.dealId === selectedDeal.dealId)?.dealId;
  const baseProductUrl = `/store/${storeId}/deals`;
  const relativeDealUrl = `${baseProductUrl}/${dealUrl}`;
  const sortImagesBy = mainDeal ? 'zoho' : undefined;
  const parsedMedia = filterMedia(media, undefined, sortImagesBy);
  const country = store?.country || 'co';
  const countryLowerCase = country?.toLowerCase();
  const [currentQuantity, /* setCurrentQuantity */] = useState(1); 
  const cartContext = useContext(ShopingCartContext);
  const { addProduct } = cartContext;
  const [showAddToCartVariantsDrawer, setShowAddToCartVariantsDrawer] = useState(false);
  const [showAddCustomCatalog, setShowAddCustomCatalog] = useState(false)
  const [promotionPrice, setPromotionPrice] = useState(null);
  const isDetailView = !!mainDeal;
  const isGridView = !singleDeal && !isDetailView; 

  const pointsRate = useMemo(() =>
    userMe?.ratePointsByCountry?.find(countryRate => countryRate?.country === country?.toUpperCase())?.rate
  , [country, userMe]);

  const shareTitle = t('productShareText', { context: countryLowerCase, productName: selectedDeal.name });
  const shareObject = {
    title: shareTitle,
    url: document.location.origin + relativeDealUrl,
    copyText: `${shareTitle}`,
  };

  let quantityToUse = selectedDeal?.quantity;
  let noQuantityLimit = quantityToUse === null;

  if (!isDetailView) {
    quantityToUse = deals.deals.reduce((acc, cur) => {
      if (cur.quantity === null) noQuantityLimit = true;
      return acc + (cur.quantity || 0);
    }, 0);
  }

  const onAddToCartClick = (selectedVariant, variantQuantity) => {
    if (!selectedVariant && deals.deals?.length !== 1 && !enableSelector) {
      setShowAddToCartVariantsDrawer(true);
      return;
    }
    addProduct({
      item: { currentQuantity: variantQuantity || currentQuantity, ...(selectedVariant || selectedDeal) },
      storeId,
    });
  };
  const { customerDiscount: discount, customerPrice, fullPrice } = getDealPrices(selectedDeal);
  const isMoneyVoucher = selectedDeal?.promotion ? selectedDeal?.promotion?.key === 'MONEY_VOUCHER' : false;
  const amountByInstallments = selectedDeal?.installmentPayment ? toFloat(customerPrice / selectedDeal.installmentPayment) : customerPrice;
  const onAddCustomCatalogClick = () => setShowAddCustomCatalog(true);

  useEffect(() => {
    if (selectedDeal?.promotion && typeof selectedDeal?.promotion === 'object') {
      if (Object.keys(selectedDeal?.promotion)?.length > 0) {
        setPromotionPrice(selectedDeal?.promotion);
      }
    }
  }, [selectedDeal]);

  return (
    <div className={!isGridView ? styles['deal-box'] : styles['deal-box-grid']}>
      <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
        {/* Image section */}
        {enableSelector ? (
          <ProductVariantSelector
            productGroup={deals.deals}
            title={deals.name}
            preSelectedId={mainDeal.id}
            onProductDecision={product => {
              if (product.dealId !== selectedDeal.dealId) {
                window.history.pushState('', '', `${baseProductUrl}/${product.dealId}`);
              }
              setSelectedDeal(product);
            }}
          />
        ) : (
          <div className={styles['deal-box-inner']}>
              <div className={styles['deal-slider-images']}>
                <figure className={`${styles['deal-slider']} deal-slider`}>
                  {!disableCarousel && <SliderProducts media={parsedMedia} link={dealUrl ? relativeDealUrl : undefined} />}
                  {disableCarousel && (<Link to={relativeDealUrl}> <SliderProducts disable media={parsedMedia} /></Link>)}
              </figure>
            </div>
          </div>
        )}
        {/* Name and details section */}
        <div style={{display: "flex", flexDirection: "column", gap:"12px"}}>
          {!isGridView ? (<div className={styles['messages-container']}><Tags noLimit={noQuantityLimit} date={formattedDate} quantity={quantityToUse} /></div>) : ('')}
          {!isGridView && promotionPrice ? (<DiscountInfo name={promotionPrice?.name} code={promotionPrice?.code} value={promotionPrice?.discountDetail} promoDiscountDetail={promotionPrice?.discountDetail} />) : ('')}

          <div className={`${styles['deal-desc-area']} ${isGridView ? styles['no-top'] : ''}`}>
            <div className={styles.name} style={{ fontSize: isGridView ? "14px" : null }}>
              {!dealUrl ? (
                <div>
                  <p style={{ fontSize: isGridView ? "14px" : null }}>{deals.name}</p>
                </div>
              ) : (
                <Link to={relativeDealUrl}>
                  <div>
                    <p style={{ fontSize: isGridView ? "14px" : null }}>{deals.name}</p>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {/* Price and others section */}
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div className={styles.price} style={{ fontSize: isGridView ? "14px" : null }}>
            <DealPrice
              style={{ whiteSpace: "pre" }}
              value={fullPrice}
              lowerPrice={customerPrice}
              discountRate={!isMoneyVoucher ? discount : undefined}
              flatDiscount={isMoneyVoucher ? selectedDeal.promotion.discountRateCalculated : undefined}
              oldStyle={!isGridView}
            />
          </div>
          {!isGridView && showPoints ? <PointsCopy pointsLimit={selectedDeal?.redeemPoints ? selectedDeal?.pointsLimit : 0} price={customerPrice} pointsRate={pointsRate} /> : ''}
          {!isGridView && <InstallmentCopy installments={selectedDeal?.installmentPayment} amountByInstallments={amountByInstallments} country={country?.toLowerCase()} />}
          {!isGridView && <div className="divider" />}
        </div>
        {/* Buttons and actions section */}
        <div className={isGridView ? styles['deal-actions-grid'] : styles['deal-actions']}>
          <div className={styles['cta-area']}>
            {/*
              (!isGridView && isDetailView) && <div className={styles['cta-area__qty']}>
              <SimpleQuantity
                max={selectedDeal.quantity}
                setValue={setCurrentQuantity}
                initialValue={selectedDeal.currentQuantity ? selectedDeal.currentQuantity : 1}
              />
              </div>
            */}
            {
              !share || !isOwnStore ? ('') : (
              <>
                <ShareButton
                  buttonClass={styles['base-header-button']}
                  fontColor="#040415"
                  info={shareObject}
                  component={ShareWideButton}
                  isGridView={isGridView}
                  drawer
                />
                  {
                    !isGridView &&
                    <button
                      className={`btn btn-outline btn-outline-primary ${styles['add-custom-catalog']}`}
                      onClick={onAddCustomCatalogClick}
                    >
                      <WaoIcon color="#ee7d40" size="16" icon="curated" /> {t('Add to custom catalog')}
                    </button>
                  }
                <CreateCustomCatalog
                  show={showAddCustomCatalog}
                  onClose={() => setShowAddCustomCatalog(false)}
                  selectedDeal={selectedDeal}
                  addCustomCatalogProduct={addCustomCatalogProduct}
                  t={t}
                />
              </>
              )
            }
            {((!isGridView || !isOwnStore) && (selectedDeal?.interests)) && (
              <BuyNowButton
                component={customBuyNowButton}
                currentQuantity={currentQuantity}
                addProduct={addProduct}
                deal={selectedDeal}
                storeId={storeId}
                deals={deals}
                enableDrawer={!enableSelector}
                isOwnStore={isOwnStore}
              />
            )}
            {(!isGridView || !isOwnStore) && !selectedDeal?.interests && <div className={styles['cta-lower']}>
              <button
                className={`btn  ${isOwnStore && share ? 'btn-outline' : `${styles['btn-buyNow']}`}  ${styles['add-btn']}`} 
                onClick={() => onAddToCartClick(null)}
                // style={{color: isOwnStore && share ? '#030029' : '#fff', backgroundColor: isOwnStore ? '#fff' : '#ee7d40'}}
              >
                <WaoIcon color={isOwnStore && share ? '#030029' : '#fff'} size={isGridView ? "12" : "16"} icon="cart" /> {t('Add to cart')}
              </button>
              <ProductVariantsDrawer
                deals={deals}
                show={showAddToCartVariantsDrawer}
                onClose={() => setShowAddToCartVariantsDrawer(false)}
                cta={onAddToCartClick}
                ctaText={t('Add to cart')}
                desiredQuantity={currentQuantity}
              />
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(DealCard);
