import React from 'react';
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import Tabber from '../../components/Tabber';
import CreatePointsVoucherSection from './CreatePointsVoucherSection';
import PointVouchersSection from './PointVouchersSection';

const SharePointsSection = (({ t, country, currencies, pointsRate, pointsAvailable, setVisibleDrawer, selectTab, setShareCode}) => {

  const tabs = [
    {
      label: t('Create voucher'),
      content: <CreatePointsVoucherSection country={country} currency={currencies[country]} pointsAvailable={pointsAvailable} setVisibleDrawer={setVisibleDrawer} setShareCode={setShareCode}/>
    },
    {
      label: t('History'),
      content: <PointVouchersSection country={country} currency={currencies[country]} pointRate={pointsRate}/>
    }
  ];

  const stylesOverride = {
    styleCotainerTabs:{
      borderBottom: '1px solid #c8c8c8',
      top: '-1px',
      position: 'sticky',
      zIndex: '50',
    },
    selector: {
      root: {
        borderBottom: 'transparent',
        backgroundColor: '#ffffff',
        top: '-1px',
        position: 'sticky',
        zIndex: '50',
      },
      indicator: {
        height: '4px !important',
        backgroundColor: '#FF6856 !important'
      }
    },
    tab: {
      root: {
        textTransform: 'none',
        fontSize: '18px !important',
        padding:'30px 5px 12px 5px !important',
        '&:nth-child(1)':{
        padding: '30px 5px 12px 0px !important',
        marginLeft: '20px'
        },
        '&:last-child':{
          padding: '30px 0px 12px 5px !important',
          marginRight: '20px'
        }
      },
      selected: {
        fontWeight: '600',
        color: '#FF6856 !important'
      }
    }
  };
  

  const disableTab = 2;
  
  return <div className={styles.shareContainer}>
    <section className={styles.shareSection}>
      <h3 className={styles.shareSection__title}>{t("Share WAO! points")}</h3>
      <section className={styles.shareSection__content}>
        <Tabber tabs={tabs} styles={stylesOverride} selectTab={selectTab} disableTab={disableTab}/>
      </section>
    </section>
  </div>;
  }
);

export default withTranslation()(SharePointsSection);