import IcomoonReact from 'icomoon-react';
import React, { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SubtitleAndLegend from '../SubtitleAndLegend';
import iconSet from "../../assets/fonts/icomoon/selection.json";
import styles from './index.module.sass';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from "react-infinite-scroll-component";
import PriceDiscountFormat from '../PriceDiscountFormat';
import DialogScreenHCentered from '../DialogScreenHCentered';
import { es } from 'date-fns/locale';
import WaoIcon from '../WaoIcon';
import { getOrderStatusPageLinkTo, onEnter, showFriendlyDateSameYearCaseShort } from '../../utils';

const AccumulatedCommissionList = ({ showLink, showHeader, data, totals, t, fetchData, enablePagination, pagination: p, infoIcon = false }) => {

  const [viDialog, setViDialog] = useState(false);
  const infoIconRef = useRef();
  const leftVar = infoIconRef?.current?.getBoundingClientRect ? infoIconRef.current.getBoundingClientRect().left : 0;
  const { userMe } = useSelector(state => state.auth);
  const country = userMe?.country?.toLowerCase() || 'co';
  const context = { context: country };
  const getOrderLegend = ({ byOrder, created_at, updated_at }) => (
    <div>
      <Link style={{ textDecoration: 'none', color: "#105FB3" }} to={getOrderStatusPageLinkTo(userMe?.shopId, byOrder.id, true) } >{byOrder.orderId || byOrder.id}</Link>
      <span>{` • ${showFriendlyDateSameYearCaseShort(new Date(created_at || updated_at), es, true)}`}</span>
    </div>
  );

  const clickHandler = () => !viDialog ? setViDialog(true) : false;

  return (
    <>
      {showHeader &&
        <div className={styles.header}>
          <h4>{t('howDoWeCommission')}</h4>
          <div className={styles.calculation}>
            <h6>{t('commissionCalculation')}</h6>
            {infoIcon &&
              <div role="button" tabIndex="0" ref={infoIconRef} className={styles['waoicon-cnt']} onClick={clickHandler} onKeyDown={onEnter(clickHandler)}>
                <WaoIcon color="#090909" size={16} icon="info2" />
                {viDialog &&
                  <DialogScreenHCentered leftAdj={-leftVar}>
                    <ul className={styles['dialog-list']}>
                      <li>
                        {t('YourEarnings', context)}
                      </li>
                      <li>
                        {t('RememberEarnings', context)}
                      </li>
                      <li>
                        {t('HistoryEarnings', context)}
                      </li>
                    </ul>
                    <button type="button" onClick={() => setViDialog(false)} className={styles['dialog-btn']}>{t('Accept')}</button>
                  </DialogScreenHCentered>
                }
              </div>
            }
          </div>          
          <div className={styles.subheader}>
            <div className={styles['subheader-title']}>{t('fixedCommission')}:</div>
            <div className={styles['subheader-total']}>
              {totals && <PriceDiscountFormat value={totals.totalByOrders} discount={0} />}
              {!totals && <PriceDiscountFormat value={0} discount={0} />}
            </div>
          </div>
        </div>
      }
      {!showHeader &&
        <div className={styles.header}>
          <div className={styles.subheader}>
            <div className={styles['subheader-title-no-header']}>{t('fixedCommission')}</div>
            <div className={styles['subheader-total-no-header']}>
              {totals && <PriceDiscountFormat value={totals.totalByOrders} discount={0} />}
              {!totals && <PriceDiscountFormat value={0} discount={0} />}
            </div>
          </div>
        </div>
      }
      <div className={styles.wrapper}>
        <div className={styles['commission-grid']}>
          {data && data.map((orderData) => (
            <Fragment key={orderData.id}>
              <SubtitleAndLegend
                subtitle={(orderData.byOrder.orderUserFirstName) ? `${orderData.byOrder.orderUserFirstName} ${orderData.byOrder.orderUserLastName}` : orderData.byOrder.customer.fullName}
                legend={getOrderLegend(orderData)} 
              />
              <SubtitleAndLegend
                style={{ textAlign: 'right' }}                
                subtitle={<PriceDiscountFormat align="right" value={orderData.amount} />}
                legend={`${t('fixedCommission')} (${(orderData.byOrder.commissionRateAverage * 100).toFixed(0)}%)`} 
              />
            </Fragment>
          ))}
        </div>
      </div>
      {p &&
        <InfiniteScroll
          dataLength={p.to}
          next={fetchData}
          hasMore={enablePagination ? (p.total - p.to) !== 0 : false}
        />
      }
      {showLink &&
        <Link to='/commissions/detail'>
          <div className={styles['see-more']}>
            {t('View details')} <IcomoonReact color="rgb(137, 59, 144)" iconSet={iconSet} icon="arrow_right-6" size={12} />
          </div>
        </Link>
      }
    </>
  );
};

export default withTranslation()(AccumulatedCommissionList);
