import { takeNullPairsOut } from '../utils';
import api from './api';

export const getHelpList = ({ currentPage = 1, name, cancelToken, limit }) => api.get(`helps`,
        takeNullPairsOut({
            page: currentPage,
            limit: limit || 10,
            q: name
        })
        ,{
            cancelToken
        }
    );
