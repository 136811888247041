/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useContext, useState, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';
import CheckoutSteps from './CheckoutSteps';
import HeaderStore from '../../components/HeaderStore';

import { ShopingCartContext } from '../../contexts/shoppingCart/ShopingCartContext';
import PaymentActions from '../../redux/payments/actions';
import { useParams } from 'react-router-dom';
import OrderActions from '../../redux/orders/actions';
import StoreActions from '../../redux/stores/actions';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { /* useCheckGlobalV, */ usePrevious, useUnload } from '../../utils/hooks';
import { countriesZipIsOptionalIn, getPaymentMethodsInfoByCountry } from '../../utils/constants';
import { Helmet } from "react-helmet";
import PagesActions from '../../redux/pages/actions';
import FooterSocial from '../../components/FooterSocial';
import mixpanel from '../../services/mixpanel';
import OrderSummary from '../../components/OrderSummary';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import useHiddenScreen from '../../hooks/useHiddenScreen';
import AuthActions from '../../redux/auth/actions';
import Icon from '../../components/Icon';
import { translateOptions } from '../../utils';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';

const CheckoutPage = (props) => {

  const { history, location, t } = props;
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const pages = useSelector(state => state.pages);
  const { getCart, getCartBuyNow, clearCartBuyNow } = useContext(ShopingCartContext);
  const { cartItems, total } = getCart(storeId);
  const { cartItems: cartItemsBuyNow, total: totalBuyNow, checkout: checkoutBuyNow } = getCartBuyNow(storeId);
  const { orderPayment, orderPaymentLoading } = useSelector(state => state.orders);
  const orderPaymentLoadingPrev = usePrevious(orderPaymentLoading);
  const { store } = useSelector(state => state.stores);
  const qParams = new URLSearchParams(location.search);
  const countryParam = store?.country || qParams.get('country');
  const lowerCaseCountryParam = countryParam && countryParam?.toLowerCase ? countryParam.toLowerCase() : '';
  
  const { userMe } = useSelector(state => state.auth);
  
  const isSocio = userMe ? userMe.type === 'referrer' : false;
  const isOwnStore = isSocio && userMe.shopId === storeId;
  const isCartBuyNow = !!cartItemsBuyNow?.length;
  const currentDeals = isCartBuyNow ? cartItemsBuyNow : cartItems;
  const shouldRenderForm = !!(currentDeals?.length);

  const firstStepRef = useRef(null);
  const secondStepRef = useRef(null);
  const thirdStepRef = useRef(null);
  const waText =
    `Hola! \nSoy cliente del WAO! Shop de ${store?.firstName} ${store?.lastName} - ${store?.id}. \n\nMe interesa comprar los siguientes productos:\n
      ${isCartBuyNow ? cartItemsBuyNow?.map(item => `${item.name} - ${item.currentQuantity} unidades\n`).join('') : cartItems?.map(deal => `(${deal?.currentQuantity}) ${deal?.name} - ${deal?.dealId}`).join('\n')}
    \n\nLos detalles de envío son los siguientes:\n${userMe?.firstName} ${userMe?.lastName} \n`;

  const initSteps = [
    { title: t.Step1, status: 'current' },
    { title: t.Step2, status: 'empty' },
    { title: t.Step3, status: 'empty' }
  ];
  const [steps, setSteps] = useState(initSteps);
  const [isPSESelected, setIsPSESelected] = useState(false);
  const [validFormik1, setValidFormik1] = useState(false);
  const [validFormik2, setValidFormik2] = useState(false);
  const [validFormik3, setValidFormik3] = useState(false);
  const [innerValues, setInnerValues] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [disableButton, setDisableButton] = useState(false);
  const [discountsLoaded, setDiscountsLoaded] = useState(false);
  const [promoCode, setPromoCode] = useState([]);
  const [pointstoUse, setPointsToUse] = useState(0);
  const [showCreditNotification, setShowCreditNotification] = useState(false);
  const [totalOrder, setTotalOrder] = useState(null);
  const [paymentMethodLimit, setPaymentMethodLimit] = useState(false);
  const [externalMinimumPayment, setExternalMinimumPayment] = useState(null);
  const paymentMethodsInfo = useMemo(() => getPaymentMethodsInfoByCountry(t, lowerCaseCountryParam) || [], [lowerCaseCountryParam, t]);
  const { paymentMethodsLoaded, paymentMethodsLoading, paymentMethodsError, paymentMethods = [] } = useSelector(state => state.payments);
  const ButtonStickyRef = useRef();
  const { isHiddenScreen } = useHiddenScreen({ externalRef: ButtonStickyRef, once: false });
  const [isFree, setIsFree] = useState(false);
  const [freeMethod, setFreeMethod] = useState(null);
  const [reportBeforeLeaving, setReportBeforeLeaving] = useState(true);
  const defaultPaymentMethod = useMemo(() => ({ value: '', label: "Selecciona método de pago" }), []);
  
  const pointsRate = useMemo(() =>
    userMe?.ratePointsByCountry?.find(countryRate => countryRate?.country === countryParam?.toUpperCase())?.rate
    , [countryParam, userMe]);

  const pointsData = useMemo(() => ({
    total: userMe?.points?.pointsAccumulated || 0,
    available: userMe?.points?.pointsAvailable || 0,
    step: 500,
    rate: pointsRate,
    currency: currentDeals?.[0]?.currency,
    // TODO We want to enable this once the backend allows losing 1 point to pay the full order
    // pointsLimit: Math.ceil(currentDeals?.reduce((acc, deal) => {
    pointsLimit: currentDeals?.reduce((acc, deal) => {
      const limit = (deal.pointsLimit === null && deal.redeemPoints) ? ((deal.price * deal.currentQuantity) / pointsRate) : (deal.pointsLimit * deal.currentQuantity)
      return acc + limit;
    // }, null)),
    }, null),
    redeemPoints: currentDeals?.find((deal) => deal?.redeemPoints === true) || false,
  }), [userMe, pointsRate, currentDeals]);

  let paymentMethodOptions = useMemo(() => paymentMethods?.map(pm => {
    
    let info = {};
    const isPSE = pm.method && pm.method === "DA";
    if (paymentMethodsInfo) {
      if (isPSE) info = paymentMethodsInfo?.find(pmi => (pmi.value === "PSE"));
      else info = paymentMethodsInfo?.find(pmi => pmi.value === pm.type);
    }
    
    const result = {
      ...pm,
      ...info,
      value: isPSE ? "PSE" : pm.type,
      label: isPSE ? "Pago por PSE" : pm.name,
      provider: pm.provider
    };
    if (result.label === "Todo Pago") result.label = "Cuotas sin interés*";
    return result;
  }), [paymentMethods, paymentMethodsInfo]);

  const cashLabelByCountry = {
    'ar': 'Rapipago o Pago Fácil',
    'co': 'Efecty'
  };

  const pickSampleOption = (innerPaymentMethods, label) => {
    const methodSample = paymentMethodOptions?.find(pm => pm.value === innerPaymentMethods[0]);
    if (methodSample) {
      paymentMethodOptions = paymentMethodOptions?.filter(pm => !innerPaymentMethods?.includes(pm.value));
      paymentMethodOptions = [
        ...paymentMethodOptions,
        {
          ...methodSample,
          value: innerPaymentMethods[0],
          label
        }
      ];
    }
  };

  // Filter type 'CASH' and replace with a single custom one that spreads the params from a sample original 'CASH' type
  pickSampleOption(['CASH'], cashLabelByCountry[lowerCaseCountryParam]);

  // Filter type 'CARD' and replace with a single custom one that spreads the params from a sample original 'CARD' type
  pickSampleOption(['CARD'], 'Tarjeta de Crédito / Débito');

  const formatCartItems = useCallback(() => {
    const deals = [];
    if (isCartBuyNow) {
      cartItemsBuyNow.forEach(item => {
        const newDeal = {
          id: item.id,
          quantity: item.currentQuantity
        };
        deals.push(newDeal);
      });
    } else {
      cartItems.forEach(item => {
        const newDeal = {
          id: item.id,
          quantity: item.currentQuantity
        };
        deals.push(newDeal);
      });
    }
    return deals;

  }, [isCartBuyNow, cartItems, cartItemsBuyNow]);

  const createOrderPayload = useCallback((formData) => {
    const phone = formData.formikStep1?.phone;

    const user = {
      "id": userMe.id,
      "document": formData.formikStep1.document || formData.formikStep3.document,
      "documentType": (userMe.documentType === 'national') ? "national" : "passport",
      "type": userMe.type || "referrer",
      "phone": (phone[0] === '+') ? phone : `+${phone}`,
      "email": formData.formikStep1?.email,
      "firstName": formData.formikStep1?.firstName,
      "lastName": formData.formikStep1?.lastName,
      "updateUser": !formData.formikStep1?.updateUser,
    };

    const billingAddress = {
      "address1": formData.formikStep2.address,
      "country": formData.formikStep2.country,
      "city": formData.formikStep2.city,
      "id": formData.formikStep2.id,
      "lat": formData.formikStep2.lat,
      "lng": formData.formikStep2.lng,
      "address2": formData.formikStep2.address2,
      "region": formData.formikStep2.region,
      "description": formData.formikStep2.description
    };

    const shippingAddress = {
      "address1": formData.formikStep2.address,
      "country": formData.formikStep2.country,
      "city": formData.formikStep2.city,
      "id": formData.formikStep2.id,
      "lat": formData.formikStep2.lat,
      "lng": formData.formikStep2.lng,
      "address2": formData.formikStep2.address2,
      "region": formData.formikStep2.region,
      "description": formData.formikStep2.description
    };

    if (formData.formikStep2.zipCode) {
      billingAddress.zipCode = formData.formikStep2.zipCode;
      shippingAddress.zipCode = formData.formikStep2.zipCode;
    }

    const deals = formatCartItems();


    const payload = {
      "user": user,
      "billingAddress": billingAddress,
      "shippingAddress": shippingAddress,
      "referrer": store.id,
      "sameAddressCheck": true,
      "taxId": "1",
      "notes": "Created from mini-store",
      // "expectedDeliveryDate": "2021/08/31",
      "deals": deals
    };
    
    if (promoCode && promoCode?.length) payload.promotionCodes = promoCode;
    return payload;
  }, [userMe, formatCartItems, store, promoCode]);

  const createOrderWithPayment = useCallback(async (formData) => {
    const getPayloadForPayment = (order, payloadFormData) => {
      const orderClone = { ...order };
      let currentPaymentMethodType = payloadFormData.formikStep3.paymentMethod.value;
      if (payloadFormData.formikStep3.paymentMethod?.value === "PSE") currentPaymentMethodType = "CASH";
      const currentPaymentMethod = paymentMethods?.find(pm => pm.type === currentPaymentMethodType);
      if (!currentPaymentMethod) throw (new Error('Failed to identify payment method'));
      const paymentObjectFromDB = { ...currentPaymentMethod };
      orderClone.paymentMethodId = currentPaymentMethod.id;
      const payload = { order: orderClone, payment: {} };
      
      if (payloadFormData.formikStep3.paymentMethod?.value === 'CASH') {
        paymentObjectFromDB.method = payloadFormData.formikStep3.paymentCashProvider;
      } else if (payloadFormData.formikStep3.paymentMethod?.value === 'PSE') {
        paymentObjectFromDB.method = 'DA';
        paymentObjectFromDB.value = 'CASH';
      } else {
        // console.error('Invalid payment method', payloadFormData.formikStep3.paymentMethod?.value, 'in', payloadFormData.formikStep3.paymentMethod);
      }

      return { ...payload, payment: { ...paymentObjectFromDB } };
    };
    const order = createOrderPayload(formData);
    const payload = getPayloadForPayment(order, formData);
    if (pointstoUse) payload.pointsToUse = pointstoUse;
    dispatch(OrderActions.createOrderWithPayment(payload));
    
  }, [createOrderPayload, pointstoUse, dispatch, paymentMethods]);

  const goToCheckoutSuccess = useCallback((paymentTransaction) => {
    if (paymentTransaction) {
      const { paymentReference, type } = paymentTransaction.payment;
      const { id } = paymentTransaction.order;
      const mewRoute = {
        pathname: `/store/${storeId}/checkout/success`,
        search: `?orderId=${id}`,
      };
      if (isPSESelected) mewRoute.search += '&paymentMethod=PSE';
      mixpanel.track_optimized('Checkout create order Success', { order: paymentTransaction.order, payment: paymentTransaction.payment , deals: currentDeals });
      setReportBeforeLeaving(false);
      if (type === 'checkout_pro' || type === 'wibond+') {
        window.history.pushState(null, null, `/store/${storeId}/checkout/success?orderId=${id}`);
        window.location.href = paymentReference;
      } else {
        history.push(mewRoute);
      }
    }
  }, [history, storeId, isPSESelected, currentDeals ]);

  const toggleStep = useCallback((stepToToggle /* , scroll */) => {
    const cloneObj = steps.map(step => ({ ...step, status: 'closed' }));
    if (cloneObj[stepToToggle - 1].status !== 'current') cloneObj[stepToToggle - 1].status = 'current';
    mixpanel.track_optimized(`Checkout step ${stepToToggle}`)
    setCurrentStep(stepToToggle);
    setSteps(cloneObj);
  }, [steps]);

  Yup.addMethod(Yup.string, 'validateDocumentArgentina', function validateFunc(message) {
    return this.test('document', message, (value) => {
      if (!value) return false;
      const size = value.length;
      if (size === 11) return true;
      if ((size > 9 || size < 7)) {
        return false;
      }
      return true;
    });
  });

  Yup.addMethod(Yup.string, 'validPhoneNumber', function validateFunc(message) {
    return this.test('phone', message, (value) => {
      if (!value) return false;
      return isValidPhoneNumber(value, countryParam);
    });
  });

  const formik1ValidationSchema = useMemo(() => Yup.object({
    country: Yup.string().required(),
    firstName: Yup.string().required(t('RequiredField')),
    lastName: Yup.string().required(t('RequiredField')),
    phone: Yup.string().validPhoneNumber(t('EnterValidPhone', { context: lowerCaseCountryParam })).required(t('RequiredField')),
    email: Yup.string().email(t('EnterValidEmail')).required(t('RequiredField')),
    document: Yup.string()
      .when('country', {
        is: (country) => country?.toLowerCase() === 'ec',
        then: Yup.string().min(5, t('must be at least x characters', { field: 'Cédula/NIT/DNI', size: 5 })).max(20, t('must be at most x characters', { field: 'Cédula/NIT/DNI', size: 20 })).required(t('RequiredField'))
      })
      .when('country', {
        is: (country) => country?.toLowerCase() === 'co',
        then: Yup.string().min(6, t('must be at least x characters', { field: t('documentCheckout', { context: lowerCaseCountryParam }), size: 6 })).max(10, t('must be at most x characters', { field: t('documentCheckout', { context: lowerCaseCountryParam }), size: 10 })).required(t('RequiredField'))
      })
      .when('country', {
        is: (country) => country?.toLowerCase() === 'ar',
        then: Yup.string().required(t('RequiredField')).validateDocumentArgentina(t('between x characters', { field: t('documentCheckout', { context: lowerCaseCountryParam }), min: 7, max: 9, other: 11 }))
      }).required(t('RequiredField'))
      .when('country', {
        is: (country) => country?.toLowerCase() === 'mx',
        then: Yup.string().required(t('RequiredField')).validateDocumentArgentina(t('between x characters', { field: t('documentCheckout', { context: lowerCaseCountryParam }), min: 7, max: 9, other: 11 }))
      }).required(t('RequiredField')),
    businessType: Yup.string()
  }), [lowerCaseCountryParam, t]);


  const formik2ValidationSchema = useMemo(() => Yup.object({
    address: Yup.string().required(t('RequiredField')),
    address2: Yup.string(),
    city: Yup.string().required(t('RequiredField')),
    // lat: Yup.string().required(t('RequiredField')),
    // lng: Yup.string().required(t('RequiredField')),
    region: Yup.string().required(t('RequiredField')),
    zipCode: countriesZipIsOptionalIn.includes(lowerCaseCountryParam) ? Yup.string() : Yup.string().required(t('RequiredField')),
    // zipCodeIsValid: Yup.bool().oneOf([true], t('The address provided is not valid')).required(t('RequiredField'))
  }), [lowerCaseCountryParam, t]);
  

  const formik3ValidationSchema = useMemo(() => Yup.object().shape({
    paymentMethod: Yup.object().shape({
      label: Yup.string().required(t('RequiredField')),
      value: Yup.string().required(t('RequiredField')),
      provider: Yup.string().required(t('RequiredField'))
    }),
    paymentCashProvider: Yup.string()
      .when("paymentMethod", (paymentMethod, schema) => paymentMethod?.value === 'CASH' ? schema.required() : schema),
    holder_name: Yup.string()
      .when("paymentMethod", (paymentMethod, schema) => paymentMethod?.value === 'CARD' ? schema.required() : schema),
    card: Yup.boolean()
      .when("paymentMethod", (paymentMethod, schema) => paymentMethod?.value === 'CARD' ? schema.oneOf([true]).required() : schema),
    cvv: Yup.boolean()
      .when("paymentMethod", (paymentMethod, schema) => paymentMethod?.value === 'CARD' ? schema.oneOf([true]).required() : schema),
    exp: Yup.boolean()
      .when("paymentMethod", (paymentMethod, schema) => paymentMethod?.value === 'CARD' ? schema.oneOf([true]).required() : schema),
    token: Yup.string(),
    document: Yup.string()
      .when("paymentMethod.provider", {
        is: (provider) => provider === 'DLOCAL',
        then: Yup.string()
          .when('country', {
            is: (country) => country === 'EC',
            then: Yup.string().min(5, t('must be at least x characters', { field: 'Cédula/NIT/DNI', size: 5 })).max(20, t('must be at most x characters', { field: 'Cédula/NIT/DNI', size: 20 })).required(t('RequiredField'))
          })
          .when('country', {
            is: (country) => country === 'CO',
            then: Yup.string().min(6, t('must be at least x characters', { field: t('documentCheckout', { context: lowerCaseCountryParam }), size: 6 })).max(10, t('must be at most x characters', { field: t('documentCheckout', { context: lowerCaseCountryParam }), size: 10 })).required(t('RequiredField'))
          })
          .when('country', {
            is: (country) => country === 'AR',
            then: Yup.string().required(t('RequiredField')).validateDocumentArgentina(t('between x characters', { field: t('documentCheckout', { context: lowerCaseCountryParam }), min: 7, max: 9, other: 11 }))
          }).required(t('RequiredField'))
      }),
  }), [lowerCaseCountryParam, t]);

  const dataFetchPaymentMethod = useMemo(()=>({ 
    country: store.country || 'CO',
    city: `${innerValues?.formikStep2?.city}` || null,
    totalOrder,
    deals: formatCartItems().map(deal => deal.id) || null,
  }),[formatCartItems, innerValues?.formikStep2?.city, store.country, totalOrder]);

  const stepProps = {
    t, currentStep, toggleStep, countryParam, lowerCaseCountryParam, formik1ValidationSchema, formik2ValidationSchema, userMe,
    formik3ValidationSchema, firstStepRef, secondStepRef, thirdStepRef, steps, paymentMethodsLoaded, paymentMethodsLoading, paymentMethodsError, paymentMethodOptions, setIsPSESelected,
    cashPaymentMethods: paymentMethods?.filter(paymentType => paymentType.type === 'CASH' && paymentType?.method !== 'DA'), waText,
    innerValues, setInnerValues, countriesZipIsOptionalIn
  };

  const updatePages = useCallback((data) => {
    dispatch(PagesActions.updateBulkRequest({ view: 'checkout', ...data }));
    dispatch(AuthActions.fetchUserMe());
  }, [dispatch]);
  
  const isFormValid = ((currentStep === 1 && validFormik1) ||
    (currentStep === 2 && validFormik2) ||
    (currentStep === 3 && validFormik3));

  const isLastStep = currentStep === steps.length;
  
  const getActionButton = useCallback(() => {
    let buttonText;
    let goNext = () => { };
    const formikStepPaymentMethod = innerValues.formikStep3?.paymentMethod?.value?.toLowerCase();
    if (isLastStep) {
      if (formikStepPaymentMethod === 'card' || formikStepPaymentMethod === 'checkout_pro') buttonText = t('Pay');
      else buttonText = t('Confirm order');
      goNext = () => createOrderWithPayment(innerValues);
    } else {
      buttonText = t('Next');
      goNext = toggleStep.bind(null, currentStep + 1);
    }
    return <div>
      <button type="button" disabled={!isFormValid || orderPaymentLoading || disableButton || paymentMethodLimit} className="btn btn-round" onClick={goNext}>{buttonText}</button>
    </div>;
  }, [isFormValid, currentStep, isLastStep, t, toggleStep, innerValues, createOrderWithPayment, paymentMethodLimit, orderPaymentLoading, disableButton]);

  const defineCustomBack = () => {
    let result;
    if (currentStep !== 1) result = toggleStep.bind(undefined, currentStep - 1);
    return result;
  };
  
  useUnload(() => {
    if (reportBeforeLeaving) mixpanel.track_optimized('Checkout incomplete');
  });

  useEffect(() => {
    if (paymentMethodOptions?.length === 1 && paymentMethodOptions[0].type === "FREE") {
      setIsFree(true);
      setFreeMethod(translateOptions(paymentMethodOptions)?.[0]);
    }else{
      setIsFree(false);
    }
  }, [paymentMethodOptions]);

  const formikInitialValues = useMemo(()=> ({
    formikStep1: { firstName: "", lastName: "", phone: "", email: "", document: "", businessType: "", country: countryParam, updateUser: false },
    formikStep2: {
      address: (pages.checkout.address || ""),
      address2: (pages.checkout.address2 || ""),
      city: (pages.checkout.city || ""),
      region: (pages.checkout.region || ""),
      zipCode: (pages.checkout.zipCode || ""),
      lat: (pages.checkout.lat || ""),
      lng: (pages.checkout.lng || ""),
      country: (pages.checkout.country || "")
    },
    formikStep3: {
      country: (pages.checkout.country || ""),
      paymentMethod:defaultPaymentMethod,
      paymentCashProvider: pages.checkout.paymentCashProvider || "",
      paymentCreditCard: "",
      holder_name: "",
      card: false, cvv: false, exp: false,
      document: ""
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }),[countryParam, defaultPaymentMethod, freeMethod, isFree, pages,paymentMethodOptions]);

  const orderSummaryPointsProps = {};

  if (isSocio) {
    orderSummaryPointsProps.dataPoints = pointsData;
    orderSummaryPointsProps.updatePointsToUse = setPointsToUse;
    orderSummaryPointsProps.showCreditNotification = showCreditNotification;
    orderSummaryPointsProps.setShowCreditNotification = setShowCreditNotification;
  }

  useEffect(() => {
    if(shouldRenderForm) dispatch(StoreActions.fetchStore(storeId));
  }, [shouldRenderForm, dispatch, storeId]);

  useEffect(() => {
    if (shouldRenderForm && orderPayment && (!orderPaymentLoading && orderPaymentLoadingPrev)) {
      setDisableButton(true);
      goToCheckoutSuccess(orderPayment.data);
    }
  }, [shouldRenderForm, orderPayment, goToCheckoutSuccess, orderPaymentLoading, orderPaymentLoadingPrev]);

  //
  useEffect(() => {
    if (innerValues) {
      const validations = [formik1ValidationSchema.isValid(innerValues.formikStep1),
      formik2ValidationSchema.isValid(innerValues.formikStep2),
      formik3ValidationSchema.isValid(innerValues.formikStep3)];
      Promise.all(validations).then(validArray => {
        setValidFormik1(validArray[0]);
        setValidFormik2(validArray[1]);
        setValidFormik3(validArray[2]);
      });
    }
  }, [innerValues, formik1ValidationSchema, formik2ValidationSchema, formik3ValidationSchema]);

  useEffect(() => () => {
      if (!checkoutBuyNow) clearCartBuyNow({ storeId });
    }
  // This was added because hot reloading was updating context so we are going to ignore context vars for now
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [ /* clearCartBuyNow  , */ storeId, checkoutBuyNow]);

  useEffect(() => {
    mixpanel.track_optimized('Checkout step 1');
  }, []);

  useEffect(() => {
    setDiscountsLoaded(true);
  }, [userMe]);


  useEffect(() => {
    if (shouldRenderForm && store && isLastStep) {
      dispatch(PaymentActions.fetchPaymentMethods(dataFetchPaymentMethod));
    }
  // This was added because hot reloading was updating context so we are going to ignore context vars for now
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRenderForm, store, isLastStep, /* formatCartItems, */ dispatch]);

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - Proceso de compra</title>
      </Helmet>
      <HeaderStore history={history} title='Comprar' icons={{ back: true }} customBack={defineCustomBack()} altColor safeBar />
      <ScreenContent webMobile>
        <section>
          <div className={`${styles['credit-notification']} ${showCreditNotification ? '' : styles.hide}`}>
            <Icon icon="checkmark" color="white" size={20} />{t('Credit applied')}
          </div>
          <div className={styles['content-container']}>
            {
              discountsLoaded &&
              <OrderSummary
                total={isCartBuyNow ? totalBuyNow : total}
                setTotalOrder={setTotalOrder}
                deals={currentDeals}
                updatePromoCode={setPromoCode}
                byCustomer={innerValues?.formikStep1?.updateUser}
                isOwnStore={isOwnStore}
                country={countryParam?.toLowerCase()}
                dataFetchPaymentMethod={dataFetchPaymentMethod}
                setExternalMinimumPayment={setExternalMinimumPayment}
                {...orderSummaryPointsProps}
              />
            }
            {!shouldRenderForm ? '' : <Formik
              initialValues={formikInitialValues}
              validationSchema={Yup.object().shape({
                formikStep1: formik1ValidationSchema,
                formikStep2: formik2ValidationSchema,
                formikStep3: formik3ValidationSchema
              })}>
              <section>
                {store && <CheckoutSteps currency={currentDeals?.[0]?.currency} externalMinimumPayment={externalMinimumPayment} freeMethod={freeMethod} isFree={isFree} {...stepProps} history={history} updatePages={updatePages} totalOrder={totalOrder} setPaymentMethodLimit={setPaymentMethodLimit}/>}
              </section>
            </Formik>
            }
          </div>
          <div ref={ButtonStickyRef} />
          <div className={`${styles["action-section"]} ${isHiddenScreen && styles["box-shadow"]}`}>
            {getActionButton()}
          </div>
        </section>
        <FooterSocial dark country={countryParam?.toLowerCase()} />
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(CheckoutPage);