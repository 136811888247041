import React from "react";
import Skeleton from '@mui/material/Skeleton';

const LoadingContentDeals = ({ isOwnStore = false, singleDeal }) => (
    <div
    style={{
      width: singleDeal ? "100%" : "calc(50% - 6px)",
      margin: "3px",
      borderRadius: "8px",
      background: "rgb(255, 255, 255)",
    }}
  >
    {
      singleDeal ? (
        <div style={{ padding: "0px 24px" }}>
          <div style={{ paddingTop: "26px" }}>
            <div
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Skeleton variant="text" height={25} width="60%" />
              {!isOwnStore ? (
                <Skeleton
                  variant="text"
                  height={40}
                  width={100}
                  style={{ borderRadius: "8px" }}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Skeleton
              variant="rect"
              height={250}
              style={{ margin: "0 auto" }}
            />
          </div>

          <div style={{ marginTop: "25px" }} className="divider" />

          <div style={{ paddingTop: "20px" }}>
            <Skeleton
              variant="rect"
              height={36}
              width={135}
              style={{ borderRadius: "8px" }}
            />
          </div>


          {isOwnStore ? (
            <>
              <div style={{ paddingTop: "25px" }}>
                <Skeleton
                  variant="rect"
                  height={36}
                  style={{ borderRadius: "8px" }}
                />
              </div>

              <div style={{ paddingTop: "10px" }}>
                <Skeleton
                  variant="rect"
                  height={36}
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </>
          ) : (
            ''
          )}

          {!isOwnStore ? (<div style={{ paddingTop: "10px" }}>
            <Skeleton
              variant="rect"
              height={36}
              style={{ borderRadius: "8px" }}
            />
          </div>
          ) : (
            ''
          )}
          <div style={{ paddingTop: "10px" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <Skeleton
                variant="rect"
                height={36}
                width="100%"
                style={{ borderRadius: "8px" }}
              />
            </div>
          </div>
        </div>
      ) : (
          <div style={{ padding: "16px" }}>
            <div style={{ paddingTop: "20px" }}>
              <Skeleton
                variant="rect"
                height={120}
                style={{ margin: "0 auto" }}
              />
            </div>

            <div style={{ paddingTop: "20px" }}>
              <Skeleton
                variant="rect"
                height={18}
                width={135}
                style={{ borderRadius: "8px" }}
              />
            </div>

            <div style={{ paddingTop: "8px" }}>
              <Skeleton
                variant="rect"
                width="70%"
                height={24}
                style={{ borderRadius: "8px" }}
              />
            </div>

            <div style={{ paddingTop: "10px" }}>
              <Skeleton
                variant="rect"
                height={32}
                style={{ borderRadius: "8px" }}
              />
            </div>

          </div>
      )
    }
    </div>
  );

export default LoadingContentDeals;
