import React, { useEffect, useState } from "react";
import { usePrevious } from "../../utils/hooks";
import StepperInfo from "../StepperInfo";
import DrawerPanel from "../DrawerPanel";

const StepperInfoPanel = (
  {
    data,
    loading,
    error,
    modals, 
    userType,
    onClick
  }
) => {
  const isSlug = modals?.[data?.slug];
  const prevSlug = usePrevious(isSlug);
  const [visible, setVisible] = useState(false);
  const close = () => {     
    onClick(data?.slug);
    setVisible(false);
  };

  useEffect(() => {
    if (!isSlug && userType === 'referrer') setVisible(true);
  }, [isSlug, prevSlug, userType]);

  return (
    <DrawerPanel disableCloseReason={['backdropClick']} visible={visible} setVisible={setVisible} onClose={close} maxHeight="600px">
      <StepperInfo steps={data?.steps} loading={loading} error={error} />
    </DrawerPanel>
  );
};

export default StepperInfoPanel;
