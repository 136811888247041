import React, { useEffect, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { useParams/* , Link */ } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { ShopingCartContext } from '../../../contexts/shoppingCart/ShopingCartContext';
import { usePrevious, useRequest } from '../../../utils/hooks';
import PriceDiscountFormat from '../../../components/PriceDiscountFormat';
import HeaderStore from '../../../components/HeaderStore';
import OrderActions from '../../../redux/orders/actions';
import { filterMedia, getPriceString, getWAUrl, showFriendlyDateSameYearCaseShort } from '../../../utils';
import { getPaymentMethodsInfoByCountry , daysToPay } from '../../../utils/constants';
import styles from './styles.module.sass';
import LoadingLine from '../../../components/LoadingSkeletons/loadingLine';
import es from 'date-fns/locale/es';
import mixpanel from '../../../services/mixpanel';
import WaoIcon from '../../../components/WaoIcon';
import OrderStatusStepper from '../../../components/OrderStatusStepper';
import ImageConga from '../../../components/ImageConga';
import { updateWibondPayment } from '../../../services/transactions';
import { toast } from 'react-toastify';
import Screen from '../../../transition/Screen';
import ScreenContent from '../../../transition/ScreenContent';
import { getSocialLinks } from '../../../utils/countries';


const cashOnDeliveryType = 'CASH_ON_DELIVERY';
const cardType = 'CARD';

const getPaymentMethodLabel = (pmObject, methodUsed, options) => {
    if (!pmObject) return "";
    const paymentTypeData = (methodUsed === "PSE") ?
      options.find((paymentObject) => paymentObject?.value === "PSE")
    : options.find((paymentObject) => paymentObject?.value === pmObject.type);
    return paymentTypeData?.label;
};

const getItemCount = (order) => {
  let result = 0;
  if (order) result = order?.deals?.reduce((prev, curr) => prev + curr.quantity, 0);
  return result;
};

// Replace with new version for methods that require an additional paygetSiteTitle
const getSiteTitle = (isSocioDetail, isPaymentPending, t) => {
  if (isSocioDetail) return t('Order Details');
  if (isPaymentPending) return t("You have a pending payment!");
  return t("Thank you for your purchase!");
};

const redStatuses = ['canceled', 'return__rejected', 'change__rejected', 'delivery failed', 'out for delivery', 'return__rejected', 'pending', 'abandoned', 'cancelled'];
// const yellowStatuses = ['in_review', 'not_confirmed', 'in progress', 'shipped', 'change__requested', 'return__requested', 'delivery partial', 'partial payment'];
const greenStatuses = ['placed', 'delivered', 'return__completed', 'change__completed', 'return_processing', 'change_processed', 'return__approved', 'change__approved'];

const getStatusClass = (orderStatus, pendingPaymentNotOnDelivery) => {
  if (pendingPaymentNotOnDelivery || redStatuses.includes(orderStatus)) return 'pill-danger';
  if (greenStatuses.includes(orderStatus)) return 'pill-success';
  // if (yellowStatuses.includes(orderStatus)) return 'pill-warning';
  return 'pill-warning';
}; 

const getStatusText = (orderStatus, pendingPaymentNotOnDelivery, orderStatusLabels) => {
  const pendingText = orderStatusLabels?.order?.pending || 'Pending';
  if (pendingPaymentNotOnDelivery) return pendingText;
  return orderStatusLabels?.order?.[orderStatus] || pendingText;
};

const getCurrentStep = (paymentType, status) => {
  const getStep = () => {
    switch (status) {
      case 'canceled':
      case 'abandoned':
      case 'placed': 
      case 'not_confirmed': 
      case 'pending': return 0;
      case 'in progress': return 1;
      case 'shipped':
      case 'delivery failed':
      case 'out for delivery': return 2;
      default: return 3;
    };
  };
  let result = getStep();
  if (result > 0 && (paymentType === cardType || paymentType === cashOnDeliveryType)) result -= 1;
  return result;
};

const CheckoutSuccessPage = ({ t, history, location }) => {

  const qParams = new URLSearchParams(location.search);
  const { storeId } = useParams();
  const paymentMethodUsed = qParams.get('paymentMethod');
  const isSocioDetail = !!qParams.get('socioDetail');
  const orderId = qParams.get('orderId');
  const prevOrderId = usePrevious(orderId); 
  const id = qParams.get('id');
  const { handleCheckout, handleCheckoutBuyNow, getCartBuyNow } = useContext(ShopingCartContext);
  const { cartItems: cartItemsBuyNow } = getCartBuyNow(storeId);
  const isCartBuyNow = cartItemsBuyNow?.length;
  const dispatch = useDispatch();
  const { run: updateWibondPaymentRun } = useRequest(updateWibondPayment);
  const { orders: order, orderLoaded } = useSelector(state => state.orders);
  const { userMe } = useSelector(state => state.auth);
  const { orderStatusLabels, orderStatusesLoading } = useSelector(state => state.orders);
  const { transaction } = (order || {});
  const isPaymentPending = transaction?.status === 'pending';
  const siteTitle = getSiteTitle(isSocioDetail, isPaymentPending, t);
  const orderDate = ((order?.created_at) ? showFriendlyDateSameYearCaseShort(new Date(order.created_at), es) : '');
  const orderExpectedDeliveryDate = ((order?.expectedDeliveryDate) ? showFriendlyDateSameYearCaseShort(new Date(order.expectedDeliveryDate), es) : '');
  const socioBuys = order?.user?.id === order?.referrer?.id;
  const listStatus = [
    { key: 'placed', title: `Pedido ${ orderDate}` },
    { key: 'ready', title: 'Listo para entregar' , subtitle: `Fecha estimada: ${ orderExpectedDeliveryDate}`},
    // { key: 'shipped', title: 'Llegando, aproximadamente 3-5 días hábiles' },
    { key: 'received', title: 'Recibido' }
  ];
  const itemCount = getItemCount(order);
  const countryCode = userMe.country.toLowerCase();
  const pendingPaymentNotOnDelivery = isPaymentPending && order?.paymentMethod?.type !== cashOnDeliveryType;
  const pendingPaymentOnDelivery = isPaymentPending && order?.paymentMethod?.type === cashOnDeliveryType;
  // Replace with new version for methods that require an additional payment step
  const paymentMethodOptions = getPaymentMethodsInfoByCountry(t, countryCode);
  const paymentMethodType = order?.paymentMethod?.type;
  const externalPaymentUrl = order?.externalPaymentUrl;
  const suMsgWpp = socioBuys ? t("socioSuMsgWpp", {socioName:`${order?.referrer?.firstName} ${order?.referrer?.lastName}`, orderNumber: order?.orderId}) : t("customerSuMsgWpp", {socioName:`${order?.referrer?.firstName} ${order?.referrer?.lastName}`, orderNumber: order?.orderId});
  const countrySocialLinksAppSU = getSocialLinks(countryCode, suMsgWpp, "appSU");

  const getSuccessMessage = useCallback((paymentTypeData) => {
    if (!pendingPaymentNotOnDelivery) return paymentTypeData.successInstructions;

    const days = daysToPay;
    const messagePayment = () => {
      if (paymentMethodType === "BANK_TRANSFER") {
        return <>
          <Trans i18nKey="WhenYouHaveMadeThePayment" context={countryCode} />
          <br />
          <br />
        </>;
      }
      if (paymentMethodType === "checkout_pro") {
        return "";
      }

      if (paymentMethodType === "PAGO_POR_SU+") {
        return <div>
          <section className='contact-section'>
            <h3 className='contact-section-title'>{t('payment.PayBySU+SuccessInstructions')}</h3>
            <section className='contact-content'>
              <a className="no-deco" href={countrySocialLinksAppSU.whatsapp.url} target="_blank" rel="noopener noreferrer">
                <button type="button" className="btn btn-round">
                  {`${t("App", countryCode)} SU+ Pay`}
                </button>
              </a>
            </section>
          </section>
        </div>;
      }

      return <>
        <Trans i18nKey="youHaveDaysToPay" context={countryCode}>
          You have <strong>{{ days }} days</strong> to pay.
        </Trans>
        <br />
        <br />
      </>;
    };

    return (
      <>
      {
        messagePayment()
      }
        {paymentMethodType === "BANK_TRANSFER" || paymentMethodType === "checkout_pro"? "" : paymentTypeData.successInstructions}
      </>
    );
  }, [pendingPaymentNotOnDelivery, paymentMethodType, countryCode, t, countrySocialLinksAppSU.whatsapp.url]);

  const getPayInfo = (paymentTypeData) => (    
    <div className={styles['info-pay']}>
      {paymentTypeData.PayInfo?.map(({ key, value }) => (
        <div key={key}><strong>{key}:</strong> <span>{value}</span></div>
      ))}
    </div>
  );
    
  const getPaymentMethodCTA = useCallback((paymentMethodObject) => {
    if (!paymentMethodObject) return "";
    const typeToMatch = paymentMethodUsed === "PSE" ? "PSE" : paymentMethodObject.type;
    const paymentTypeData = paymentMethodOptions.find((paymentObject) => paymentObject?.value === typeToMatch);
    if (!paymentTypeData) return "";

    const methodSelectedMsg = `Hola, seleccioné como método de pago _"${paymentTypeData.label}"_ para la orden *${order.orderId}*`;
    if (paymentMethodObject.provider?.toLowerCase() === "manual") {
      paymentTypeData.buttonUrl = getWAUrl(countryCode, methodSelectedMsg, "sales");
    } else if (paymentMethodObject.type?.toLowerCase() === "cash" || paymentMethodObject.type?.toLowerCase() === "checkout_pro") {
      paymentTypeData.buttonUrl = externalPaymentUrl;
    }

    let result = (
        isPaymentPending && paymentTypeData.successInstructions && (
          <div className={styles["success-instructions"]}>
            <div
              className={pendingPaymentNotOnDelivery ? styles["description-danger"] : styles["description-warning"]}
            >
              {paymentMethodType !== "PAGO_POR_SU+" ? <div>
                {pendingPaymentNotOnDelivery ? (
                  <WaoIcon color="#de0e0e" size="20" icon="warning" />
                ) : (
                  <WaoIcon color="#ffcc00" size="24" icon="info1" />
                )}
              </div> : ""}
              <div className={styles.content}>
                <div>{getSuccessMessage(paymentTypeData)}</div>
                
                {paymentMethodType !== "BANK_TRANSFER" && pendingPaymentNotOnDelivery && <div className={styles['pending-payment']}>
                  <div>{t('Pending payment')}: </div>
                  <div><PriceDiscountFormat value={order?.total} discount={0} /></div>
                </div>}

                {paymentMethodType === "BANK_TRANSFER" && getPayInfo(paymentTypeData)}

                {pendingPaymentNotOnDelivery && paymentTypeData.buttonText && (
                  <div className={styles.ticket}>
                      <button type="button" onClick={()=>{window.location.href = paymentTypeData.buttonUrl;}}>
                        {paymentTypeData.buttonText}
                      </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
    );
    result = <div>{result}</div>;
    return result;
  }, [countryCode, isPaymentPending, externalPaymentUrl, paymentMethodUsed, paymentMethodType, paymentMethodOptions, order, t, pendingPaymentNotOnDelivery, getSuccessMessage]);

  const sendConfirmOrdersuccess = useCallback(async() => {

      const response = await updateWibondPaymentRun({orderId});

      if (response?.ok) {
        setTimeout(() => {
        dispatch(OrderActions.fetchOrders({orderId}));
        }, 3000);
        toast.success(response?.data);
      }else{
        toast.error(t('errors.SorryProblem'));
      } 
  },[dispatch, orderId, t, updateWibondPaymentRun]);

  if (order?.paymentMethod?.type !== cardType) {
    listStatus.splice(0, 1, {
      key: "placed",
      title: "Confirmado con pago pendiente",
      subtitle: order?.created_at
        ? showFriendlyDateSameYearCaseShort(new Date(order.created_at), es)
        : "",
      content: pendingPaymentNotOnDelivery ? getPaymentMethodCTA(order?.paymentMethod) : '',
    });
  }
  // Insert step for payment methods that require a payment step
  if (order?.paymentMethod?.type !== cashOnDeliveryType && order?.paymentMethod?.type !== cardType) {
    listStatus.splice(1, 0, { key: 'paid', title: 'Pago recibido' });
  }

  useEffect(() => {
    if (orderId && (orderId !== prevOrderId)) {
      dispatch(OrderActions.fetchOrders({orderId}));
      if(isCartBuyNow) {
      handleCheckoutBuyNow({ storeId });
      } else {
      handleCheckout({ storeId });
      }
    }
  }, [dispatch, orderId, prevOrderId, storeId, isCartBuyNow, handleCheckoutBuyNow, handleCheckout]);

  useEffect(() => {
    if (order) {
      const aux = {
        commission: order.commission,
        commissionReward: order.commissionReward,
        id: order.id,
        status: order.status,
        total: order.total,
        country: order.country,
        currency: order.currency,
        deals: order.deals,
        expectedDeliveryDate: order.expectedDeliveryDate,
        orderId: order.orderId,
        paymentType: order?.paymentMethod?.type,
        referrer: order.referrer,
        transaction: order.transaction,
        user: order.user
      };
      delete aux.token;
      if (!isSocioDetail) mixpanel.track_optimized('Checkout Success', aux);
      else mixpanel.track_optimized('Order Status', aux);
    }
  }, [isSocioDetail, order]);

  useEffect(()=>{
    if(id && order?.transaction && order.transaction?.provider === "wibond" && order.transaction?.status !== "paid"){
    sendConfirmOrdersuccess();
  }
}, [id, order, dispatch, sendConfirmOrdersuccess]);

  // Load status labels if missing
  useEffect(() => {
    if (!orderStatusLabels && !orderStatusesLoading) dispatch(OrderActions.fetchOrderStatuses());
  }, [orderStatusLabels, orderStatusesLoading, dispatch]);

  const pillStatus = getStatusClass(order?.status, pendingPaymentNotOnDelivery);
  const statusText = getStatusText(order?.status, pendingPaymentNotOnDelivery, orderStatusLabels);
  const totalCalculated = Number.parseFloat((order?.total||0) - (order?.costShipping||0));

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`WAO! - ${siteTitle}`}</title>
      </Helmet>
      <HeaderStore history={history} title={isSocioDetail ? null : siteTitle} icons={{ back: true }}
        altColor customBack={isSocioDetail ? () => history.go(-1) : null} noShadow
      />
      <ScreenContent webMobile>
        <section style={{ paddingTop: "20px" }} className={styles["padded-section"]}>
          {orderLoaded && (
            <div className={`${styles["order-status"]} pill ${pillStatus}`}>
              {statusText}
            </div>
          )}
          <h4 className={styles["user-name"]}>
            {!orderLoaded && (
              <LoadingLine width={320} height={30} viewBox="0 0 320 30" />
            )}
            {orderLoaded && order?.deals && <div>
              <div>{`${order?.user?.firstName} ${order?.user?.lastName}`}</div>
              <div><ImageConga media={order?.deals?.map(deal => filterMedia(deal.media, 'product', 'zoho')[0])} /></div>
            </div>}
          </h4>
          <div className={styles["list-items"]}>
            <div className={styles.subtitle}>
              {orderLoaded ? (
                <>
                  {`${itemCount} ${t("item", { count: itemCount })} • ${ 
                    getPriceString(order?.total)}`}{" "}
                  {`| ${t("orderNumber")}: ${order?.orderId}`}
                </>
              ) : (
                <LoadingLine width={280} height={20} viewBox="0 0 280 20" />
              )}
            </div>
          </div>
          {orderLoaded && !isSocioDetail && pendingPaymentOnDelivery && <div className={styles['top-warning']}>{getPaymentMethodCTA({ type: cashOnDeliveryType })}</div>}
          {orderLoaded &&
            <div className={styles.steps}>
              <OrderStatusStepper steps={listStatus} currentStepIndex={getCurrentStep(order?.paymentMethod?.type, order?.status)}
                skipUpcoming={pendingPaymentNotOnDelivery} />
            </div>}
          {!orderLoaded && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <br />
              <LoadingLine
                width={250}
                height={15}
                margin="0 0 0 0"
                viewBox="0 0 250 15"
              />
              <LoadingLine
                width={250}
                height={15}
                margin="10px 0 10px 0"
                viewBox="0 0 250 15"
              />
              <LoadingLine
                width={250}
                height={15}
                margin="0 0 0 0"
                viewBox="0 0 250 15"
              />
            </div>
          )}
        </section>
        <section className={styles.divider} />
        <section className={styles["padded-section"]}>
          <section className={styles["__detail-sections"]}>
            <h4>Resumen del pedido</h4>
            {orderLoaded && (
              <section className={styles["__detail-sections__inner"]}>
                <div className={styles.title}>Detalle del pago</div>
                <p className={styles.__description}>
                  Método de pago: {getPaymentMethodLabel(order?.paymentMethod, paymentMethodUsed, paymentMethodOptions)}
                </p>
                {orderLoaded && (
                  <ul className={styles["__detail-prices"]}>
                    <li>
                      <div className={styles["__col-left"]}><span>Items subtotal ({itemCount}):</span></div>
                      <div className={styles["__col-right"]}><span>
                        <PriceDiscountFormat value={totalCalculated} discount={0} />
                      </span></div>
                    </li>
                    <li>
                      <div className={styles["__col-left"]}><span>Costo de envío:</span></div>
                      <div className={styles["__col-right"]}><span>
                        <PriceDiscountFormat value={order?.costShipping || 0} discount={0} />
                      </span></div>
                    </li>
                    <li>
                      {
                        isPaymentPending ? (
                        <div className={`${styles["__col-left"] } ${ styles.pending}`}> <span>{t('Pending payment')}:</span></div>) : (
                        <div className={styles["__col-left"]}> <b>Total: </b></div>)
                      }
                      <div className={styles["__col-right"] + (pendingPaymentNotOnDelivery ? ` ${ styles.pending}` : "")}>
                        <b><PriceDiscountFormat value={order?.total} discount={0} /></b>
                      </div>
                    </li>
                  </ul>
                )}
                {!orderLoaded && (
                  <>
                    <LoadingLine width={280} height={15} viewBox="0 0 280 15" margin="0 0 10px 0" />
                    <LoadingLine width={280} height={15} viewBox="0 0 280 15" />
                  </>
                )}
              </section>
            )}
            <section className={`${styles["__detail-sections__inner"]}`}>
              <div className={styles.title}>Detalle de envío</div>
              {orderLoaded && pendingPaymentNotOnDelivery && (
                <div className={styles["pending-warning"]}>
                  Su orden sera procesada/enviada luego de recibir la
                  confirmación de pago.
                </div>
              )}
              {orderLoaded && (
                <div className={styles.user}>
                  <div>
                    {order?.user?.firstName} {order?.user?.lastName}
                  </div>
                  <div>{order?.shippingAddress?.address1}</div>
                  <div>{order?.user?.phone}</div>
                </div>
              )}
              {/* {!!order?.expectedDeliveryDate &&
                <p className={styles['__description']}>
                  Fecha del pedido: {showFriendlyDateSameYearCase(new Date(order.expectedDeliveryDate), es)}
                </p>} */}
              {!orderLoaded && (
                <>
                  <br />
                  <LoadingLine width={280} height={15} viewBox="0 0 280 15" />
                  <LoadingLine width={280} height={15} viewBox="0 0 280 15" />
                </>
              )}
            </section>
          </section>
        </section>

        {/* Removed FOOTER! */}
        {/* <section className={styles["divider"]} />

        <section className={styles["cta-footer"]}>
          {!orderLoaded && (
            <>
              <LoadingLine width={280} height={40} />
              <br />
              <LoadingLine width={280} height={40} />
            </>
          )}
          {orderLoaded && (
            <>
              {bottomText}
              <div>{bottomButton}</div>
            </>
          )}
        </section> */}
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(CheckoutSuccessPage);