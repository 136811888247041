/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { createActions } from "redux-actions";
import { toast } from 'react-toastify';

import {
    createOrder,
    createOrderWithPayment,
    getOrders,
    getOrderStatus
} from "../../services/orders";


import { GenericError } from "../../utils/errors";
import AuthActions from "../auth/actions";


const OrderActions = createActions({
  CREATE_ORDER_REQUEST: () => {},
  CREATE_ORDER_SUCCESS: (order) => ({order}),
  CREATE_ORDER_FAILED: (error) => ({ error }),

  CREATE_ORDER_WITH_PAYMENT_REQUEST: () => {},
  CREATE_ORDER_WITH_PAYMENT_SUCCESS: (orderPayment) => ({orderPayment}),
  CREATE_ORDER_WITH_PAYMENT_FAILED: (error) => ({ error }),

  UPDATE_ORDER_PAYMENT_STATUS: payload => payload,

  FETCH_ORDERS_REQUEST: (filters) => ({ filters }),
  FETCH_ORDER_SUCCESS: (order) => ({order}),
  FETCH_ORDER_FAILED: (error) => ({ error }),

  FETCH_ORDER_STATUSES_REQUEST: () => {},
  FETCH_ORDER_STATUSES_SUCCESS: (statuses, labels) => ({ statuses, labels }),
  FETCH_ORDER_STATUSES_FAILED: (error) => ({ error }),

  FETCH_ORDERS_SUCCESS: (orders) => ({ orders }),
  FETCH_ORDERS_FAILED: (error) => ({ error }),
});

OrderActions.createOrder = (data) => async (dispatch) => {
    try {
      dispatch(OrderActions.createOrderRequest());
      const result = await createOrder(data);
      dispatch(OrderActions.createOrderSuccess(result));
    } catch (err) {
      dispatch(OrderActions.createOrderFailed({ error: new GenericError(err, err) }));
    }
  };

OrderActions.createOrderWithPayment = (data) => async (dispatch) => {
    try {
      if (data.dlocalCard) {
        const { card, name, dlocalFields }= data.dlocalCard;
        dispatch(OrderActions.updateOrderPaymentStatus({loading: true}));
        const result = await dlocalFields.createToken(card, { name });
        if (result.token) {
          data.payment.fields = { token: result.token };
        } if (result.error) {
          toast.error(result.error);
        }
      }
      dispatch(OrderActions.createOrderWithPaymentRequest());
      const result = await createOrderWithPayment(data);
      if(result.status === 400) {
        throw result.data.message;
      }
      dispatch(AuthActions.fetchUserMe());
      dispatch(OrderActions.createOrderWithPaymentSuccess(result));
    } catch (err) {
      toast.error(err);
      dispatch(OrderActions.createOrderWithPaymentFailed({ error: new GenericError(err, err) }));
    }
  };

OrderActions.fetchOrders = (data) => async (dispatch) => {
    try {
      dispatch(OrderActions.fetchOrdersRequest());
      const result = await getOrders(data);
      dispatch(OrderActions.fetchOrdersSuccess(result.data));
    } catch (err) {
      dispatch(OrderActions.fetchOrdersFailed({ error: new GenericError(err, err) }));
    }
  };

OrderActions.fetchOrderStatuses = () => async (dispatch) => {
    try {
      dispatch(OrderActions.fetchOrderStatusesRequest());
      const result = await getOrderStatus();
      if (!result.ok) {
        const dealsError = new GenericError("", undefined, { status: result?.status });
        throw dealsError;
      }

      const orderStatuses = result.data.data;
      const labels = Object.keys(orderStatuses).reduce((labelsResult, currentStatusType) => {
        labelsResult[currentStatusType] = orderStatuses[currentStatusType].reduce((result, current) => {
          // eslint-disable-next-line no-return-assign
          if (current.children) current.children.forEach(({ key, label }) => result[key] = label);
          else result[current.key] = current.label;
          return result;
        }, {});
        return labelsResult;
      }, {});

      dispatch(OrderActions.fetchOrderStatusesSuccess(orderStatuses, labels));
    } catch (err) {
      console.log(err);
      dispatch(OrderActions.fetchOrderStatusesFailed({ error: err }));
    }
  };



export default OrderActions;
