import { Switch } from '@mui/material';
import React from 'react';
import ContentLoader from 'react-content-loader';

const SettingsToggle = ({ label, description, value, updateFunction, dummy, loading }) => {  
  
  if (dummy || !updateFunction) {
    return (
      <ContentLoader speed={2} width="320px" height={120} viewBox="0 0 320 120" backgroundColor="#f2f2f2" foregroundColor="#ecebeb">
        <rect x="0px" y="10px" rx="3" ry="3" width="75px" height="80px" />
        <rect x="110px" y="35px" rx="3" ry="3" width="200px" height="20px" />
        <rect x="110px" y="65px" rx="3" ry="3" width="75px" height="15px" />
      </ContentLoader>
    );
  }

  const handleChange = (event) => updateFunction(event.target.checked);
  const muiLabel = { inputProps: { 'aria-label': label } };

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div style={{ fontWeight: "600", fontSize: "16px" }}>{label}</div>
        <div style={{ fontWeight: "400", fontSize: "14px", color: "#48484A" }}>{description}</div>
      </div>
      <div><Switch checked={!!value} onChange={handleChange} {...muiLabel} disabled={!!loading} /></div>
    </div>
  );
};

export default SettingsToggle;
