import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import log from './log';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const useCheckGlobalV = (global) => {
  const [loaded, setLoaded] = useState(false);
  const timer = setInterval(()=>{
    if (window[global]) {
      setLoaded(true);
      clearInterval(timer);
    }
  }, 500);
  return loaded;
};

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => { if(htmlElRef.current) htmlElRef.current.focus(); };
  return [htmlElRef, setFocus];
};

export const useRequest = (functionToRun, autorun = false) => {
  const [data, setData] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [requestFunction, setRequestFunction] = useState();

  const run = useCallback(async (...args) => {
    if (!functionToRun && !requestFunction) {
      log.error('Missing function to run');
      return {};
    }
    let tempResponse;
    let tempError;
    setLoading(true);
    try {
      setError(null);
      setResponse(null);
      setData(null);
      tempResponse = requestFunction ? await requestFunction(...args) : await functionToRun(...args);
      if(!tempResponse?.ok && !!tempResponse?.originalError) tempError = tempResponse?.originalError?.message;
    } catch (err) {
      tempError = err;
      log.log('run -err', err);
    }
    const responseError = tempResponse?.ok === false ? tempResponse?.data : null;

    setLoading(false);
    setError(tempError || responseError);
    setData(tempResponse?.data);
    setResponse(tempResponse);
    return tempResponse;
  }, [requestFunction, functionToRun]);

  useEffect(() => {
    if (autorun) run();
  }, [autorun, run]);
  const returnObject = useMemo(() => ({ data, loading, error, response, run, setRequestFunction }),[data, error, loading, response, run])
  return returnObject;
};

const compareInputs = (inputKeys, oldInputs, newInputs) => {
  inputKeys.forEach(key => {
    const oldInput = oldInputs[key];
    const newInput = newInputs[key];
    if (oldInput !== newInput) {
      log.log("change detected", key, "old:", oldInput, "new:", newInput);
    }
  });
};

export const useDependenciesDebugger = inputs => {
  const oldInputsRef = useRef(inputs);
  const inputValuesArray = Object.values(inputs);
  const inputKeysArray = Object.keys(inputs);
  useMemo(() => {
    const oldInputs = oldInputsRef.current;

    compareInputs(inputKeysArray, oldInputs, inputs);

    oldInputsRef.current = inputs;
  }, inputValuesArray); // eslint-disable-line react-hooks/exhaustive-deps
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const useRenderCounter = label => {
  const counter = useRef(0);
  counter.current++;
  console.log(`${label} rendered ${counter.current} times`);
};

export const useUnload = fn => {
  const cb = useRef(fn);
  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [cb]);
};