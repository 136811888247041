import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Helmet } from "react-helmet";
import HeaderStore from '../../components/HeaderStore';
import AuthActions from '../../redux/auth/actions';
import WaoIcon from '../../components/WaoIcon';
import styles from './styles.module.sass';
import mixpanel from '../../services/mixpanel';
import { useAddToHomescreenPrompt } from "../../hooks/useAddToHomescreenPrompt";
import { Link } from 'react-router-dom';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';
import Icon from '../../components/Icon';
// import ScreenTitle from '../../transition/ScreenTitle';

const rowStyles = { display: "flex", gap: "6px", alignItems: "center" };

const Account = ({ t, history }) => {

  const dispatch = useDispatch();
  const { userMe } = useSelector(state => state.auth);
  const userData = { ...userMe };
  if (userData?.fullName === 'Guest Guest') userData.fullName = '';
  const missingProfileData = !userMe?.document || !userMe?.email || !userMe?.phone;
  // const isReferrer = userMe?.type === 'referrer';
  // const relativeShopUrl = `/store/${userMe?.shopId}`;
  // const country = userMe?.country?.toLowerCase() || 'main';
  // const context = { context: country };

  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const warningStyles = useMemo(()=>({
    padding: "4px 10px",
    margin: "0px -10px",
    // background: "#e54a3821",
    background: "#ffcc0021",
    borderRadius: "8px",
    // border: "1px solid #e54a3833"
    border: "1px solid #ffcc0033"
  }),[]);

  const buttonsList = useMemo(()=>([
    {
      icon: 'profile-',
      title: t('Profile'),
      link: '/profile',
      roles: ['all'],
      description: 'Datos personales y direcciones',
      styles: missingProfileData ? warningStyles : undefined
    },
    {
      icon: 'dashboard',
      title: t('Dashboard'),
      link: '/home',
      roles: ['referrer'],
      description: 'Revisa tu actividad y la de tus clientes'
    },
    // {
    //   icon: 'shop_center-',
    //   title: t('Your Shop'),
    //   link: relativeShopUrl,
    //   roles: ['referrer'],
    //   description: 'Visita tu tienda'
    // },
    {
      icon: 'list-2',
      title: t('My Sales'),
      link: '/orders',
      roles: ['referrer', 'admin', 'superadmin'],
      description: 'Verifica el estado de tus ventas'
    },
    {
      icon: 'list-2',
      title: t('My Orders'),
      link: '/orders',
      roles: ['customer'],
      description: 'Verifica el estado de tus compras'
    },
    {
      icon: 'info1',
      title: t('Help center'),
      link: '/socio-help',
      roles: ['referrer'],
      description: 'Conoce mas sobre nuestra plataforma'
    },
    {
      icon: 'settings',
      title: t('Settings'),
      link: '/settings',
      roles: ['referrer'],
      description: 'Configura preferencias de tu cuenta'
    },
  ]),[missingProfileData, t, warningStyles]);

  let buttonsListResult = [...buttonsList];

  const buttonTutorials = useMemo(()=>({
    icon: 'blur_linear',
    title: t('Tutorials'),
    link: '/socio-tutorials',
    roles: ['referrer']
  }),[t]);

  const buttonOnboarding = useMemo(()=>({
    icon: 'blur_linear',
    title: t('Onboarding'),
    link: '/quiz',
    roles: ['referrer']
  }),[t]);


    if (process.env.REACT_APP_TUTORIALS_STEPPERINFO === "true") buttonsListResult = [...buttonsListResult, buttonTutorials];
    if (userMe?.metadata?.status === "lead") buttonsListResult = buttonsListResult?.filter((button)=>(button?.link !== '/orders' && button?.link !== '/home' && button?.link !== '/socio-help'));
    if (userMe?.metadata?.status === "lead") buttonsListResult = [...buttonsListResult, buttonOnboarding];
  
  useEffect(() => {
    window.scrollTo(0, 0);  
    mixpanel.track_optimized('Account');
  }, []);

  const renderButtonItem = (item) => (item.roles.includes(userMe.type) || item.roles.includes('all')) ? <li style={item.styles|| {}} key={item.icon}>
    <Link to={item.link} style={{ textDecoration: "none", color: "inherit" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <WaoIcon icon={item.icon} size={item.size || 20} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontWeight: "600" }}>{item.title}</span>
            {
              !item.description ? '' :
                <div style={{ marginTop: "5px", fontSize: "14px" }}>
                  <span>{item.description}</span>
                </div>
            }
          </div>
        </div>
        <div style={{marginRight: "8px"}}>
          <WaoIcon icon="arrow_right-6" color="#aaa" size={14} />
        </div>
      </div>

    </Link>
  </li> : null;

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - {t('Account')}</title>
        <link rel="canonical" href="https://app.wao.shop/account" />
      </Helmet>
      <HeaderStore history={history} icons={{ back: true }} altColor showLogo />
      <ScreenContent webMobile>
        <section style={{ paddingTop: "20px" }}>
          {/* <ScreenTitle title={t('Account')} /> */}
          <Link to="/profile" style={{ textDecoration: "none", color: "inherit" }}>
            <Box className={styles['user-info']}>
              <h2>{userData?.fullName}</h2>
              {userData?.id ? <div style={rowStyles}><h4>ID:</h4><h4>{userData?.id}</h4></div> : ""}
              {userData?.phone ? <div style={rowStyles}><Icon icon="call" size={14} /><h4>{userData?.phone}</h4></div> : ""}
              {userData?.email ? <div style={rowStyles}><Icon icon="mail_outline" size={14} /><h4>{userData?.email}</h4></div> : ""}
            </Box>
          </Link>
          {!missingProfileData ? '' :
            <div style={{ display: "flex", gap: "10px", padding: "0px 20px", color: "#e54a38", alignItems: "center", fontSize: "14px", paddingTop: "8px" }}>
              <Icon icon="warning" size={16} color="#ffcc00" />
              {t('pages.account.updateProfileText')}
            </div>
          }
          {/* <hr className={styles.divider} /> */}
          <Box className={styles['ul-container']}>
            <ul>
              {buttonsListResult?.map(renderButtonItem)}
            </ul>
          </Box>
        </section>
        <footer className={styles.footer}>
          {
            prompt &&
            <button className='btn' style={{ padding: "8px 48px" }} onClick={promptToInstall}>
              <div style={{ margin: "8px 0px", padding: "0px" }}>Agregar a mi pantalla de inicio</div>
            </button>
          }
          <nav>
            <button onClick={async () => {
              dispatch(AuthActions.logout(true));
            }}>
              <div style={{ margin: "8px 0px", padding: "0px" }}>{t('Sign Out')}</div>
            </button>
          </nav>
        </footer>
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(Account);