import Immutable from 'seamless-immutable';

const initialState = Immutable({  
  steppersLoading: false,
  steppersLoaded: false,
  steppersError: null,
  steppers: null,
  stepperPagination: null,
});


export default initialState;
