import firebase from "firebase/app";
import "firebase/auth";

export const getCurrentUser = async () => (
  new Promise(resolve => {
    let user;
    try {
      user = firebase.auth().currentUser;
    } catch (error) {
      console.log('Firebase getCurrentUser error', error);
    }
    console.log('Firebase user', user);
    resolve(user);
  })
);

export const getUserToken = async () => (
  new Promise((resolve, reject) => {
    try {
      const currenUser = firebase.auth().currentUser;
      if (currenUser) {
        currenUser.getIdToken(true)
          .then(idToken => { resolve(idToken); })
          .catch(error => { reject(error.message); });
      } else {
        // Looks like this could have been causing a blank screen by returning null as the current token
        resolve(null);
      }
    } catch (err) {
      reject(err);
    }
  })
);

export const getIdTokenRefreshed = async () => {
  console.log('Getting id token refreshed');
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      unsubscribe();
      let refreshedToken = '';
      if (user) {
        refreshedToken = await user.getIdToken(true).catch(err => console.error(err));
      }
      resolve(refreshedToken);
    }, reject);
  });
};

export const signOut = async () => firebase.auth().signOut();