
import Stylesheet from "reactjs-stylesheet";
// import { StyleSheet } from "react-native";
import { useTheme } from '@mui/material';
import { useMemo } from "react";

const getGlobalStyles = theme => Stylesheet.create({
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold"
  },
  titleDivider: {
    backgroundColor: theme?.palette?.primary.main,
    height: "3px",
    margin: "8px 0px",
    border: "none"
  },
  primary: {
    background: theme?.palette?.primary.main,
  },
  secondary: {
    background: theme?.palette?.secondary.main,
  },
  customYellow: {
    background: theme?.palette?.customYellow.main,
  },
  customGreen: {
    background: theme?.palette?.customGreen.main,
  },
  colorSample: {
    width: "40px",
    height: "40px",
    border: "1px solid grey",
    borderRadius: "4px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",    
    textAlign: "center",
  },
  textButton: {
    fontWeight: 600,
    fontSize: "16px",
    textDecoration: "underline",
    color: theme?.palette?.primary.main
  }
});

function useGlobalStyles() {
  const theme = useTheme();
  // We only want to recompute the stylesheet on changes in the theme.
  const result = useMemo(() => getGlobalStyles(theme), [theme]);

  return result;
}

export default useGlobalStyles;
