import ModalActions from './actions';
import InitialState from ".";

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case ModalActions.setShowModal().type:
      return state.merge({
        showModal: action.payload.show,
      });
    default:
      return state;
  }
};
