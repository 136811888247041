import React from 'react';
import OrderLineLoader from './orderLineLoader';

const OrderLoader = ({amount}) => (
  <>
    {
      Array.from({length: amount}, (v, k) => k).map((v, k) => (
        <OrderLineLoader key={k} />
      ))
    }
  </>
);

export default OrderLoader;