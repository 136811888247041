import React from 'react';
import { withTranslation } from 'react-i18next';
import Price from '../Price';
import { toFloat } from '../../utils';
import Pill from '../Pill';

const DealPrice = ({ style = {}, value, lowerPrice, discountRate, flatDiscount, oldStyle }) => {
  const mainPrice = lowerPrice || value;
  const crossedPrice = lowerPrice && value !== mainPrice ? value : undefined;
  const greenDiscount = {
    fontWeight: "600",
    color: "#0aae8c",
    fontSize: "14px",
    letterSpacing: "-.26px"
  };

  if (oldStyle) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "row",
        ...style,
      }}>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Price value={mainPrice} />
          {crossedPrice ? <Price style={{ fontSize: "14px", color: "#000" }} value={crossedPrice} crossed /> : ''}
          {discountRate ? <div style={greenDiscount}>{`${toFloat(discountRate * 100)}% OFF`}</div> : null}
          {flatDiscount ? <div style={greenDiscount}><Price value={flatDiscount} /> OFF</div> : null}
        </div>
      </div>
    );
  }

  // New version to use in grid

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      ...style,
    }}>
      {crossedPrice ? <Price style={{ fontSize: "12px" }} value={crossedPrice} crossed /> : ''}
      <div style={{display: "flex", gap: "8px", alignItems: "center" }}>
        <Price value={mainPrice} style={{fontSize: "12px"}}/>
        <div style={{ fontWeight: "bold", fontSize: "10px" }}>
          {discountRate ? <Pill>{`${toFloat(discountRate * 100)}% OFF`}</Pill> : null}
          {flatDiscount ? <Pill><Price style={{ fontWeight: "bold" }} value={flatDiscount} /> OFF</Pill> : null}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(DealPrice); 