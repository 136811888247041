import { combineReducers } from 'redux';
import { reducer as auth } from './auth/reducer';
import { reducer as commissions } from './commission/reducer';
import { reducer as customer } from './customer/reducer';
import { reducer as deals } from './deals/reducer';
import { reducer as help } from './help/reducer';
import { reducer as modals } from './modals/reducer';
import { reducer as notifications } from './notifications/reducer';
import { reducer as orders } from './orders/reducer';
import { reducer as pages } from './pages/reducer';
import { reducer as payments } from './payments/reducer';
import { reducer as points } from './points/reducer';
import { reducer as serviceWorker } from './serviceWorker/reducer';
import { reducer as shipments } from './shipment/reducer';
import { reducer as stores } from './stores/reducer';
import { reducer as workflow } from './workflow/reducer';
import { reducer as promotions } from './promotions/reducer';
import { reducer as rewards } from './rewards/reducer';
import { reducer as stepperInfo } from './stepperInfo/reducer';
import { reducer as crypto } from './crypto/reducer';
import { reducer as customCatalogs } from './customCatalogs/reducer';

export default combineReducers({
  auth,
  commissions,
  customer,
  deals,
  help,
  modals,
  notifications,
  orders,
  pages,
  payments,
  points,
  serviceWorker,
  shipments,
  stores,
  workflow,
  promotions,
  rewards,
  stepperInfo,
  crypto,
  customCatalogs
});
