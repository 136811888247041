import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ShopingCartContext } from '../../contexts/shoppingCart/ShopingCartContext';
import TotalItemsFooter from '../../components/ShoppingCart/TotalItemsFooter';
import EmptyCart from '../../components/ShoppingCart/EmptyCart';
import HeaderStore from '../../components/HeaderStore';
import CartItem from './CartItem';
import styles from './styles.module.sass';
import mixpanel from '../../services/mixpanel';
import { goToSuccessFromCheckoutLocal } from '../../services/local';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';

const ShoppingCart = ({ t, history }) => {

  const { storeId } = useParams();
  const dispatch = useDispatch();
  const [checkoutClicked, setCheckoutClicked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [nextRoute, setNextRoute] = useState(`/sign-in?nextRoute=/store/${storeId}/checkout`);
  const { getCart, clearCartBuyNow } = useContext(ShopingCartContext);
  const { cartItems } = getCart(storeId);
  const goToCheckout = () => {
    mixpanel.track_optimized('Shopping cart go to Checkout');
    goToSuccessFromCheckoutLocal.remove();
    setCheckoutClicked(true);
    clearCartBuyNow({ storeId });
  };

  useEffect(() => {
    if ((!cartItems || cartItems.length === 0) && !disabled) setDisabled(true);
    else if (disabled) setDisabled(true);
  }, [disabled, cartItems]);

  useEffect(() => {
    if (user) setNextRoute(`/store/${storeId}/checkout`);
  }, [user, storeId, setNextRoute]);

  useEffect(() => {
    if (checkoutClicked) history.push(nextRoute);
  }, [dispatch, checkoutClicked, nextRoute, history]);

  useEffect( () => {
    mixpanel.track_optimized('Shopping Cart', {storeId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - Carrito de Compras</title>
      </Helmet>
      <HeaderStore history={history} title={t("Shopping cart")} icons={{ back: true }} altColor />
      <ScreenContent webMobile>
        <section className={styles['items-added']}>
          {
            (cartItems && cartItems.length > 0) ?
              cartItems.map(product => <CartItem key={product.id} product={product} />)
              : <EmptyCart />
          }
        </section>
        <TotalItemsFooter cta={goToCheckout} titleButton="Comprar" disabled={disabled} />
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(ShoppingCart);
