import React, { useCallback, useEffect } from 'react'
import WaoIcon from '../WaoIcon'
import { CircularProgress, Drawer } from '@mui/material'
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass'
import CustomCatalogActions from '../../redux/customCatalogs/actions';
import { useDispatch, useSelector } from 'react-redux';
import mixpanel from '../../services/mixpanel';

 const CreateCustomCatalog = ({ t, show, onClose, selectedDeal}) => {
  const [currentCustomCatalog, setCurrentCustomCatalog] = React.useState(null)
  const [nameCustomCatalog, setNameCustomCatalog] = React.useState('')
  const [showOption, setShowOption] = React.useState(true)
  const [currentValue, setCurrentValue] = React.useState(nameCustomCatalog);
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  const { customCatalogs, /* customCatalogsError, */ customCatalogsLoading} = useSelector(state => state.customCatalogs);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { value } = event.target
    setNameCustomCatalog(value)
  }

  const close = useCallback(() => {
    setCurrentCustomCatalog(null)
    setIsDropdownVisible(false)
    setNameCustomCatalog('')
    setCurrentValue('');
    setShowOption(true)
    onClose()
  }, [onClose])

  const handleAddCustomCatalog = useCallback((catalog) => {
    const customCatalogvalidate = customCatalogs?.some(customCatalog => customCatalog?.id === catalog?.id)
    const name = catalog?.description ? catalog?.description?.replace(/' '/g, '_') : catalog?.replace(/' '/g, '_')
    
    if(name !== ''){
      if (!customCatalogvalidate){
        dispatch(CustomCatalogActions.createCustomCatalog({description: name, dealGroups:[selectedDeal?.dealGroup]}))
        close()
      }else{
        const dealGroupsIds = catalog.dealGroups?.filter((deal) => !Number.isNaN(deal?.id)).map(deal => deal?.id)
        const dealGroupsVallidate = dealGroupsIds?.some(id => id === selectedDeal?.dealGroup)
        if(!dealGroupsVallidate)dispatch(CustomCatalogActions.updateCustomCatalog({description: name, id:catalog?.id, dealGroups:[...dealGroupsIds, selectedDeal?.dealGroup]}))
        close()
      }
    }
  }, [close, customCatalogs, dispatch, selectedDeal])

  const updateVal = (value) => {
    if(typeof value === 'object') {
      setNameCustomCatalog(value?.description);
      setCurrentCustomCatalog(value);
      setCurrentValue(value?.description);
    } else if(typeof value === 'string') {
      setNameCustomCatalog(value);
      setCurrentValue(value);
    }
  }

  useEffect(() => {
    if(show) dispatch(CustomCatalogActions.fetchCustomCatalogs())
  }, [dispatch, show])
  

  const clearIcon = <WaoIcon className="input-clear" color="#000" icon="close-" size={16} onClick={() => {
    setNameCustomCatalog('')
  }}
  />;

  const dropDownSelect = () => (
      <div className={styles['qty-dropdown']}>
          <div className={`${styles['qty-dropdown-selection']}`} 
            style={{color: currentValue ? '#000' : '#979797'}}
            onClick={(e) =>{
            e.stopPropagation();
            setIsDropdownVisible(!isDropdownVisible);
          }} >
            <span>{!currentValue ? t("SelectCatalog") : currentValue?.replace(/'_'/g, ' ')}</span>
            <WaoIcon icon="arrow_down" size="12" color="#979797" className={`${isDropdownVisible ? styles['qty-dropdown-visible'] : ''}`}/>
          </div>
          { isDropdownVisible && 
          <div className={styles['qty-dropdown-item-holder']}>
            { !customCatalogsLoading &&
              customCatalogs?.map(catalog => (
                  <div key={catalog.id} className={`${styles['qty-dropdown-item']} ${currentCustomCatalog?.id === catalog?.id ? styles['qty-dropdown-item-active'] : ''}`} onClick={(e) => {
                    e.stopPropagation();
                    updateVal(catalog);
                    setIsDropdownVisible(false);
                    }}>
                    {catalog?.description?.replace(/'_'/g, ' ')}
                  </div>
                ))
            }
            {customCatalogsLoading && !customCatalogs?.lenght > 0 &&
                <CircularProgress/>
            }
          </div>
          }
        </div>
    )

  const renderOption1 = () => (
      <div className={styles.Option1}>
        <div className={styles.Option1Title}>
          <span>{t('AddCustomCatalogTitle')}</span>
        </div>
        <div className={styles.Option1Description}>
          <span>{t('AddCustomCatalogDescription')}</span>
        </div>
        <div className={styles.Option1Select}>
          {dropDownSelect()}
        </div>
        <div className={styles.Option1Button__newCatalog}>
          <button 
            type='button'  
            onClick={() => {
              setCurrentValue('')
              setCurrentCustomCatalog(null)
              setShowOption(false)             
            }}>
            <WaoIcon color="#7d37d7" size="16" icon="add-solid" /> {t('NewCatalog')}
          </button>
        </div>
        <div className={styles.Option1Button__add}>
          <button 
            type='button' 
            disabled={!currentCustomCatalog}
            className={`btn btn-outline btn-outline-primary ${styles['add-custom-catalog']}`}
            onClick={()=> handleAddCustomCatalog(currentCustomCatalog)}>
            <WaoIcon color="#ee7d40" size="16" icon="curated" /> {t('Add to custom catalog')}
          </button>
        </div>
      </div>
    )

   const renderOption2 = () => (
     <div className={styles.Option2}>
       <div className={styles.Option2Title}>
         <span>{t('NewCatalog')}</span>
       </div>
       <div className={styles.Option2Description}>
         <span>{t('NewCatalogDescription')}</span>
       </div>
       <div className={styles.Option2Input}>
         <input
           style={{ fontWeight: nameCustomCatalog ? '600' : 'normal' }}
           type="text"
           placeholder={t('CatalogName')}
           value={nameCustomCatalog}
           onChange={handleChange}
           // eslint-disable-next-line jsx-a11y/no-autofocus
           autoFocus
         />
         {clearIcon}
       </div>
       <div className={styles.Option2Button__createCatalog}>
         <button
           disabled={!nameCustomCatalog}
           className={styles['add-custom-catalog']}
           onClick={() => {
             mixpanel.track_optimized('Custom Catalog Create', { name: nameCustomCatalog });
             handleAddCustomCatalog(nameCustomCatalog)
           }}
         >
           <WaoIcon color="#ee7d40" size="16" icon="curated" /> {t('CreateCatalogAndAddProduct')}
         </button>
       </div>
     </div>
   )




    return (
        <Drawer
          anchor="bottom"
          open={show}
          onClose={onClose}
          transitionDuration={500}
          PaperProps={{ style: { borderRadius: '20px 20px 0 0', boxShadow: '0px 0px 12px 0px rgba(3, 0, 41, 0.4)' } }}
        >
          <div className={styles.CustomCatalogDrawerBody}>
            <div className={styles.headerSection}>
              <div className={styles.goBack}>
                {!showOption && <WaoIcon icon="arrow-up-right2" size="26" color="#030029" onClick={() => {
                //  setNameCustomCatalog('')
                 setShowOption(true)
                }} />}
              </div>
            <div className={styles.close}>
              <WaoIcon icon="close-" size="16" color="#030029" onClick={()=>{close()}} />
            </div>
          </div>
          {showOption ? renderOption1() : renderOption2()}
          </div>          
        </Drawer>
      )
}

export default withTranslation()(CreateCustomCatalog)