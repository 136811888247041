import React, { createContext, useMemo, useReducer } from 'react';
import { CustomCatalogReducer } from './CustomCatalogReducer';

export const CustomCatalogContext = createContext();

const customCatalog = localStorage.getItem('customCatalog') ? JSON.parse(localStorage.getItem('customCatalog')) : {};

const initialState = {
  customCatalog
};

const CustomCatalogContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomCatalogReducer, initialState);
  const addProduct = payload => { dispatch({ type: 'ADD_ITEM', payload }); };
  const removeProduct = payload => { dispatch({ type: 'REMOVE_ITEM', payload }); };
  const clearCatalog = (payload) => { dispatch({ type: 'CLEAR', payload }); };
  const getCatalog = (nameCustomCatalog) => (state.customCatalog[nameCustomCatalog] || {items: []});
  const getAllCatalogs = () => (state.customCatalog);

  const contextValues = useMemo(() => ({
      removeProduct,
      addProduct,
      clearCatalog,
      getCatalog,
      getAllCatalogs,
      ...state     
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]);

  return (
    <CustomCatalogContext.Provider value={contextValues}>
      {children}
    </CustomCatalogContext.Provider>
  );
};

export default CustomCatalogContextProvider;