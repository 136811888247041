import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import WaoIcon from "../WaoIcon";
import styles from './styles.module.sass';
import { usePrevious } from '../../utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import CustomCatalogActions from '../../redux/customCatalogs/actions';

const ButtonCounterCustomCatalog = ({ history, color = "#000", buttonClass = "header-button" }) => {

  const { storeId } = useParams();
  const { customCatalogs, /* customCatalogsError,  customCatalogsLoading */} = useSelector(state => state.customCatalogs);
  const dispatch = useDispatch();

  const goTo = () => {
    const path = `/store/${storeId}/custom-catalog`;
    const newRoute = { pathname: path };
    history.push(newRoute);
  };

  const catalogsCount = customCatalogs?.length;
  const prevItemsCount = usePrevious(catalogsCount);

  useEffect(() => {
    if(prevItemsCount !== catalogsCount)dispatch(CustomCatalogActions.fetchCustomCatalogs())
  }, [catalogsCount, dispatch, prevItemsCount])

  return (
    <button className={styles[buttonClass]} onClick={() => goTo()}>
      <figure className={prevItemsCount < catalogsCount ? styles.ring : null}>
        <WaoIcon color={color} size={24} icon="curated" />
      </figure>
      {!!catalogsCount && (catalogsCount > 0) && <b><span>{catalogsCount}</span></b>}
    </button>
  );
};

export default withTranslation()(ButtonCounterCustomCatalog);