import { takeNullPairsOut } from '../utils';
import api from './api';

export const getPointsHistory = ({ currentPage = 1, fromDate, toDate, perPage = 10, country, sort }) => api.get(`points`,
  takeNullPairsOut({
    fromDate,
    toDate,
    currentPage,
    perPage,
    country,
    sort,
  })
);

export const getPoints = params => api.get(`points`, { ...params });

export const getChallengesByCountry = country => api.get(`points/challenges/${country}`);

export const createVoucher = (data) => api.post("points/vouchers", data);

export const updateVoucher = ({statusVoucher, id}) => api.put(`points/vouchers/${id}`,   
takeNullPairsOut({
  statusVoucher
}),);

export const getVouchers = ({page = 1, cancelToken, name, limit, }) => api.get(`points/vouchers`, 
  takeNullPairsOut({
    page,
    limit: limit || 10,
    q: name
  }),
  {
    cancelToken
  }
);

export const getExchangePoints = data => api.get(`points/exchangePoints`, data);

