import React, { useCallback, useEffect } from 'react';
import { Divider } from '@mui/material';
import { withTranslation } from 'react-i18next';
import HeaderStore from '../../../components/HeaderStore';
import { Helmet } from "react-helmet";
import FooterSocial from '../../../components/FooterSocial';
import mixpanel from 'mixpanel-browser';
import View from '../../../transition/View';
import ScreenContent from '../../../transition/ScreenContent';
import Text from '../../../transition/Text';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import Button from '../../../transition/Button';
import AuthActions from '../../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getDefaultRolePageRedirect } from '../../../utils/constants';
import Icon from '../../../components/Icon';
import googleIcon from "../../../assets/images/social/google-color.webp";
import Screen from '../../../transition/Screen';
import TextLink from '../../../components/atoms/TextLink';

const SignIn = ({ t, history, location: propsLocation }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const { userMe /* , loginError, userMeLoading, loginLoading */ } = useSelector(state => state.auth);
  const nextRoute = (new URLSearchParams(location.search)).get('nextRoute');
  const comesFromRegistration = !!(new URLSearchParams(location.search)).get('new');
  const comesFromCheckout = nextRoute?.includes && nextRoute.includes('checkout');
  const globalStyles = useGlobalStyles();
  // if (comesFromRegistration) nextRoute = 
  const emailSignInPath = { pathname: '/sign-in/email', user: location?.user };
  const phoneSignInPath = { pathname: '/sign-in/phone', user: propsLocation?.user };

  if (nextRoute) {
    phoneSignInPath.search = `?nextRoute=${nextRoute}`;
    emailSignInPath.search = `?nextRoute=${nextRoute}`;
  }

  const navigateAfterLogin = useCallback((userParam) => {
    if (nextRoute) {
      history.replace(nextRoute);
    } else {
      history.push(getDefaultRolePageRedirect({role: userParam?.role || userParam?.type, user: userMe}));
    }
  }, [nextRoute, history, userMe]);

  const googleSignIn = () => {
    mixpanel.track_optimized('Auth Google open');
    dispatch(AuthActions.signInWithGoogle())
  };

  const providers = [
    { name: 'phone', action: () => history.push(phoneSignInPath), icon: "phone_android", label: t("Login with SMS") },
    { name: 'email', action: () => history.push(emailSignInPath), icon: "mail_outline", label: t("Login with Email") },
  ];

  if (!comesFromCheckout) providers.push({ name: 'google', action: googleSignIn, src: googleIcon, label: t("Login with Google") });

  useEffect(() => {
    if (userMe?.country) navigateAfterLogin(userMe);
  }, [userMe, dispatch, navigateAfterLogin]);

  const getMessage = () => {
    let msg = `${t("pages.signup.simpleTopMessage")}:`;
    if (comesFromRegistration) msg = `${t("pages.signup.registrationTopMessage")}:`;
    else if (comesFromCheckout) msg = `${t("pages.signup.checkoutTopMessage")}:`;
    return msg;
  };
  
  const goToRegister = () => {
    mixpanel.track_optimized('Go to Registration');
  };

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("pages.title", { title: t("pages.signIn.title") })}</title>
        <link rel="canonical" href="https://app.wao.shop/sign-in" />
      </Helmet>
      <HeaderStore icons={{ back: true }} showLogo altColor />
      <ScreenContent webMobile>
        <View style={{ padding: "0 30px" }}>
          <View style={{ marginTop: "30px" }}>
            <Text style={globalStyles.sectionTitle}>{t("Autenticación")}</Text>
            <Divider style={globalStyles.titleDivider} />
          </View>
          <Text style={{ paddingTop: "8px" }}>{getMessage()}</Text>
          <View style={{ gap: "16px", width: "100%", minHeight: "280px", ...globalStyles.flex, ...globalStyles.column, ...globalStyles.center }}>
            {
              providers.map((provider) => (
                <Button color="white" mode="contained" key={provider.name}
                  // icon={<Text style={{ fontSize: "40px" }}>{provider.icon}</Text>}
                  style={{ border: "1px solid", width: "100%", maxHeight: "40px", fontSize: "14px", fontWeight: 600, borderRadius: "7px", display: "flex", gap: "8px" }}
                  onPress={provider.action /* providerSelectionHandler(provider) */}
                >
                  {provider.icon ? <Icon icon={provider.icon} size="18" /> : ''}
                  {provider.src ? <img src={provider.src} alt="" style={{ objectFit: "contain", width: "18px" }} /> : ''}
                  <Text>{provider.label}</Text>
                </Button>
              ))
            }
          </View>
          {
            !comesFromCheckout && !comesFromRegistration ? <View style={{ ...globalStyles.row, ...globalStyles.center, margin: "14px 0 30px 0" }}>
              <Text>{t("Don't have an account yet")}</Text>
              <TextLink onPress={goToRegister} style={{ ...globalStyles.textButton, marginLeft: "10px", color: "#000" }} path="/registration"><Text>{t("Sign Up")}</Text></TextLink>
            </View> : ''
          }
        </View>
        <FooterSocial dark={!!propsLocation?.user?.country?.toLocaleLowerCase()} country={propsLocation?.user?.country?.toLocaleLowerCase()}/>
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(SignIn);
