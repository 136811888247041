import React from 'react';
import { Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';

import { filterMedia, getPriceString } from '../../utils';

import styles from './styles.module.sass';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImageConga from '../ImageConga';


const getStatusPillClass = (status) => {
  switch (status) {
    case "placed":
    case "delivered": 
    case "return__completed": 
    case "return_processing": 
    case "change_processed": 
    case "return__approved": 
    case "change__approved": 
    case "change__completed": return "green";

    case "in progress": 
    case "shipped": 
    case "in_review": 
    case "not_confirmed": 
    case "change__requested": 
    case "delivery partial": 
    case "partial payment": 
    case "return__requested": return "yellow";
    
    case "out for delivery": 
    case "delivery failed": 
    case "return__rejected": 
    case "change__rejected": 
    case "pending": 
    case "abandoned":
    case "canceled": 
    case "cancelled": return "red";
    
    default: return "pending";
  } 
};

const SocioOrderItem = ({ content: { status, user, deals, id, orderId, total }, t, orderStatusLabels }) => {
  const { userMe } = useSelector(state => state.auth);  
  const quantity = deals.reduce((acc, deal) => acc + deal.quantity, 0);
  const media = deals.map(deal => [filterMedia(deal.media, 'product', 'zoho')[0]].filter(Boolean))
    .reduce((acc, innerMedia) => acc.concat(innerMedia), [])
    .slice(0, 3);


  return <Link to={{ pathname: `/store/${userMe?.shopId}/checkout/success`, search: `orderId=${id}&socioDetail=true` }} className={styles['orders-item-link']}>
    <div className={styles['orders-item']}>
      <Grid container spacing={1}>
        <Grid item xs={media.length > 1 ? 8 : 10}>
          <div className={styles['orders-item-status']}><span className={styles[getStatusPillClass(status)]}>{orderStatusLabels?.order?.[status] || status}</span></div>
          <div className={styles['orders-item-name']}>{user?.firstName} {user?.lastName}</div>
        </Grid>
        <Grid container item xs={media.length > 1 ? 4 : 2} alignItems="center" justifyContent="flex-end">
          <ImageConga congaClass={styles['orders-item-previews']} media={media} />
        </Grid>
        <Grid item xs={12}>
          <div className={styles['orders-item-details']}>{orderId} &nbsp; | &nbsp; {quantity} {t(quantity > 1 ? 'items' : 'item')} &nbsp; | &nbsp; {getPriceString(total)}</div>
        </Grid>
      </Grid>
    </div>
  </Link>;
};

export default withTranslation()(SocioOrderItem);