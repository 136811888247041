/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography, Modal, Button } from '@mui/material';
import { showBrowserWarning } from "../../utils";
import WaoIcon from '../WaoIcon';
import styles from './styles.module.sass';
import TopBar from '../TopBar';

export const ModalComponent = ({ t, hideBrowserWarning, flipClose, closeButton, content: modalContent, button: CustomButton, modal: CustomModal, info: modalInfo = {} }) => {
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const DefaultButton = (props) => (
    <Button onClick={handleOpen} {...props}>Open modal</Button>
  );

  const DefaultModal = () => (    
    <Box className={styles.modal}>
      {!hideBrowserWarning && showBrowserWarning() &&
        <TopBar message={t('RecommendedBrowsers')} type="warning" icon="warning" /> }
      <div className={styles["top-section"]}>
        {!closeButton ? ("") : (          
          <WaoIcon icon="close-" size="16" className={flipClose ? styles["flipped-close"] : styles.close} onClick={() => handleClose()} />
        )}
        <div>{/* Used as a spacer */}</div>
      </div>
      {!modalContent ? (
        <Box className={styles['content-section']}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalInfo?.title ? modalInfo?.title : "Text in a modal"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalInfo?.description ? modalInfo?.description : "Duis mollis, est non commodo luctus, nisi erat porttitor ligula."}
          </Typography>
        </Box>
      ) : (
          <Box className={styles['content-section']}>                        
            <div>
              {modalContent()}
            </div>
          </Box>
      )}
    </Box>
  );
  

  return (
    <div>
      {CustomButton ? (<div onClick={handleOpen}>{typeof CustomButton === 'string' ? CustomButton : <CustomButton />}</div>) : (<DefaultButton />)}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
        {CustomModal ? (<CustomModal info={modalInfo} />) : (<DefaultModal />)}
          {/* {CustomModal ? (CustomModal) : (<DefaultModal />)} */}
        </div>
      </Modal>
    </div>
  );
};

export default withTranslation()(ModalComponent);