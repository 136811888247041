import React, { useMemo } from 'react';
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import Emoji from '../../components/Emoji';
import PriceDiscountFormat from '../../components/PriceDiscountFormat';


const ExgengeSection = (({ t, contentArray, country, currency, pointRate }) => {


  const context = useMemo(() => ({ context: country }), [country]);
  const defaultRedeemSections = [
    {
      id: 1,
      title: t("pages.redeem.sections.1.title"),
      description: t("pages.redeem.sections.1.description"),
      points: 100,
    },
    
  ];
  let redeemSections = defaultRedeemSections;
  if (contentArray) redeemSections = contentArray;

  return <div className={styles.redeemContainer}>
    <section className={styles.redeemSection}>
      <h3>{t("pages.redeem.title", context)}</h3>
      <div className="flex column g24 mt12">
        {redeemSections.map(({ id, title, description, points, linkOpenDrawer }) => (
          <div key={id}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            {points ? <div className="flex nowrap">
              <div className="mr6">{points}</div><Emoji label="star" content="⭐️" />{` = `}
              <div className="ml6"><PriceDiscountFormat suffix value={points * pointRate} currency={currency} parenthesis={false} /></div>
            </div> : ""}
            {linkOpenDrawer ? <div>
              <span>{}</span>
            </div> : ""}
          </div>
        ))}
      </div>
    </section>
  </div>;
  }
);

export default withTranslation()(ExgengeSection);