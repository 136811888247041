import React from 'react';

export function useAddToHomescreenPrompt() {
  const [prompt, setPrompt] = React.useState(null);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  React.useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setPrompt(e);
    };

    if (window.deferredPrompt) {
      setPrompt(window.deferredPrompt);
    } else {
      window.addEventListener('beforeinstallprompt', ready);
    }

    window.addEventListener('appinstalled', () => setPrompt(null));

    return () => {
      window.removeEventListener('beforeinstallprompt', ready);
      window.removeEventListener('appinstalled', ready);
    };
  }, []);

  return [prompt, promptToInstall];
}