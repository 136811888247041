import WorkflowActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {

    case WorkflowActions.validateZipcodeRequest().type:
      return state.merge({
        zipcodeValidLoading: true,
        zipcodeValidError: null,
        zipcodeValidLoaded: false
      });

    case WorkflowActions.validateZipcodeSuccess().type:
      return state.merge({
        zipcodeValidLoading: false,
        zipcodeValidError: null,
        zipcodeValidLoaded: true,
        zipcodeValid: action.payload.data.data,
      });

    default:
      return state;
  }
};