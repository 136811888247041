import Immutable from 'seamless-immutable';

const initialState = Immutable({
  zipcodeValidLoading: false,
  zipcodeValidError: null,
  zipcodeValidLoaded: false,
  zipcodeValid: {},
});


export default initialState;