/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Divider, Grid } from '@mui/material';
import styles from './styles.module.sass';
import PriceDiscountFormat from '../PriceDiscountFormat';
import { useDispatch, useSelector } from 'react-redux';
import WaoIcon from '../WaoIcon';
import { useFocus, usePrevious, useRequest } from '../../utils/hooks';
import { validatePromo } from '../../services/orders';
import ContentLoader from 'react-content-loader';
import { deleteDuplicates, onEnter, toFloat } from '../../utils';
import WaoTooltip from '../WaoTooltip';
import PointsSection from '../PointsSection';
import PaymentActions from '../../redux/payments/actions';
import { toast } from 'react-toastify';
import ConfirmationDialog from '../ConfirmationDialog';

const SummaryRow = ({ rowClass, left, right, removeCode, clearCodeFunc, alternativeClassDescription }) => (
  <Grid className={`${rowClass} ${styles.row} ${left?.description ? (alternativeClassDescription ) : ' '}`} container item spacing={1} xs={12}>
    <Grid className={left.className} item xs>
      <div className={styles.code}>
        <span>{left.content}</span> {removeCode && <WaoIcon size="14" color="#8A898E" icon="close-" onClick={() => { clearCodeFunc(removeCode); }} />}
      </div>
      {left.description && <span className={alternativeClassDescription || styles["sub-description"]}>{left.description}</span>}
    </Grid>
    {right && right.content &&
      <Grid className={`${styles.right} ${right.className}`} item xs="auto">
        {right.content}
      </Grid>
    }
  </Grid>
);

const DealSummary = ({ deal, clearCodeFunc }) => {
  const left = {
    className: styles.description,
    content: <span>{deal.name} <span className={styles.quantity}>(x{deal.currentQuantity})</span></span>
  };
  const right = {
    className: styles?.value,
    content: <><PriceDiscountFormat value={deal.price} currency={deal.currency} /> </>
  };
  return <SummaryRow rowClass={styles["deal-row"]} left={left} right={right} clearCodeFunc={clearCodeFunc} />;
};
  
const DiscountSummary = ({ t, discount, clearCodeFunc, customClass, leftClass, rightClass, semiBold }) => {
  const left = {
    className: `${customClass || ""} ${leftClass || ""} ${discount?.code ? styles["name-code-promo"] : styles.name}`,
    content: <span>{discount.name} {discount.name.indexOf(t('Socio discount')) !== -1 ? <WaoTooltip msg={t('socioDiscountPorcentage')}/> : ""}</span>
  };
  const right = {
    className: `${customClass || ""} ${rightClass || ""} ${discount?.code ? styles["name-code-promo"] : styles?.valueDiscountSummary}`,
    content: <PriceDiscountFormat fixNegative value={discount?.value} currency={discount.currency} semiBold={semiBold} />
  };
  return <SummaryRow rowClass={styles["discount-row"]} left={left} right={right} removeCode={discount.code} clearCodeFunc={clearCodeFunc}/>;
};

const OrderSummary = ({ t, deals, total, updatePromoCode, updatePointsToUse, byCustomer, isOwnStore, country, dataPoints, showCreditNotification, setShowCreditNotification, setTotalOrder, dataFetchPaymentMethod, setExternalMinimumPayment }) => {

  const dispatch = useDispatch();

  const [promoInputEnabled, setPromoInputEnabled] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState('');
  const [cancelCode, setCancelCode] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeArray, setPromoCodeArray] = useState([]);
  const [promoError, setPromoError] = useState('');
  const [refresh, setRefresh] = useState(0);
  const { loading: validatePromoLoading, /* error: validatePromoError, */ run: validatePromoRun } = useRequest(validatePromo);
  const [inputRef, setInputFocus] = useFocus();
  const [areDiscountsMerged, setAreDiscountsMerged] = useState(false);
  const [finalDiscounts, setFinalDiscounts] = useState([]);
  const [promoDiscounts, setPromoDiscounts] = useState([]);
  const [socioDiscount, setSocioDiscount] = useState(null);
  const [creditApplied, setCreditApplied] = useState(0);
  const [pointsApplied, setPointsApplied] = useState(0);
  const prevPointsApplied = usePrevious(pointsApplied);
  const [validatedTotal, setValidatedTotal] = useState(0);
  const [minimumPayment, setMinimumPayment] = useState(0);
  const [costShipping, setCostShipping] = useState(0);
  const [pointsLoseVoucher, setPointsLoseVoucher] = useState(null);
  const [promoCodeConfirmationOpen, setPromoCodeConfirmationOpen] = useState(false);
  const { userMe } = useSelector(state => state.auth);
  const store = useSelector(state => state.stores.store);
  const referrerId = store?.id;
  let blurTimeout;
  // const { width: screenWidth } = useWindowDimensions();
  const currency = deals?.[0]?.currency;
  const newTotalWithSocioDiscount = socioDiscount ? (Number(total) + Number(socioDiscount?.value)) : total;

  const setPromotionCode = useCallback((newPromoCode) => {
    setPromoCode(newPromoCode);
  }, []);

  const setPromotionCodeArray = useCallback((newPromoCodeArray) => {
    setPromoCodeArray(newPromoCodeArray);
    updatePromoCode(newPromoCodeArray);
  }, [updatePromoCode]);

  const calculateNewTotal = useCallback((newTotal) => (
    toFloat(finalDiscounts.reduce((rTotal, discount) => rTotal + Number(discount?.value), Number(newTotal)))
  ), [finalDiscounts]);

  const togglePromoInput = () => {
    setPromoInputEnabled(!promoInputEnabled);
  };

  const clearCurrentCode = useCallback((code) => {
    setPromoError('');
    setTempPromoCode('');
    // setPromoInputEnabled(false);
    setPromotionCode('');
    setPromotionCodeArray([]);
    setPromoCodeArray(promoCodeArray.filter((i) => i !== code));
    setRefresh(refresh + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[promoCodeArray]);

  const clearIcon = <WaoIcon className="input-clear" color="#000" icon="close-" size={11} onClick={() => {
    setInputFocus();
    setPromoError('');
    setTempPromoCode('');
  }}
  />;

  const formatDiscount = useCallback((discount) => {
    let name = discount.name || 'test';
    const value = (discount.discountTotal * -1);
    if (discount.code) name = `${t("Promotional code")}: ${discount.code}`;
    return { name, currency, value, code: discount.code };
  }, [t, currency]);

  const getCurrentPromo = useCallback(() => {
    const result = {
      customerId: userMe?.id,
      deals: deals.map(deal => {
        const newDeal = { id: deal.id, quantity: deal.currentQuantity };
        return newDeal;
      }),
      promotionCodes: [],
      referrerId,
      byCustomer
    };
    if (pointsApplied && pointsApplied !== "0") result.pointsToUse = Number.parseInt(pointsApplied, 10);
    return result;
  }, [deals, referrerId, userMe.id, byCustomer, pointsApplied]);

  const checkPromos = useCallback(async (promoCodeToApply = '') => {
    const promoToUse = { ...getCurrentPromo() };
    const currentPromoCodeArray = [...promoCodeArray]; 

    if (promoCodeToApply || promoCode) currentPromoCodeArray.push(promoCodeToApply || promoCode || null);
    if (promoCodeToApply || promoCode || promoCodeArray?.length) promoToUse.promotionCodes = deleteDuplicates(currentPromoCodeArray);

    const res = await validatePromoRun(promoToUse);
    const errorMessage = res?.data?.message || 'El código promocional que ingresaste no es válido';

    if (res.ok) {
      setMinimumPayment(res.data?.data?.minimumPaymentTotal);
      setCostShipping(res.data?.data?.costShipping);
      setPointsLoseVoucher(res.data?.data?.pointsLoseVoucher);
      setAreDiscountsMerged(true);
      const newDiscounts = res.data?.data?.promotions?.filter(discount => !discount.code)?.map(formatDiscount) || [];
      const newPromoDiscounts = res.data?.data?.promotions?.filter(discount => discount.code)?.map(formatDiscount) || [];
      const {discountCommissionTotal, discountCommissionRateAverage, pointsToCreditTotal, totalOrder} = res.data.data;
      if (isOwnStore && !byCustomer) {
        setSocioDiscount({
          name: `${t('Socio discount')} ${toFloat(discountCommissionRateAverage * 100)}%`,
          value: discountCommissionTotal * -1,
        });    
      } else {
        setSocioDiscount(null);
      };
      if (pointsApplied && pointsToCreditTotal) {
        if (prevPointsApplied !== pointsApplied) {
          setShowCreditNotification(true);
          setTimeout(() => setShowCreditNotification(false), 2000);
        }
        setCreditApplied({
          name: t('Credit applied'),
          value: pointsToCreditTotal * -1,
        });
      } else {
        setCreditApplied(null);
      };
      setFinalDiscounts([...newDiscounts]);
      setPromoDiscounts([...newPromoDiscounts]);
      setPromoError('');
      if (promoCodeToApply) {
        setTempPromoCode('');
      };
      setPromoInputEnabled(false);
      if (promoCodeToApply && newPromoDiscounts?.length > 0) {
        setPromotionCode(promoCodeToApply);
      };
      if (currentPromoCodeArray && newPromoDiscounts?.length > 0) {
        setPromotionCodeArray(deleteDuplicates(currentPromoCodeArray));
      };
      setValidatedTotal(totalOrder);
    } else if (promoCodeToApply) {
      setPromoError(errorMessage);
      setPromoInputEnabled(true);
    } else {
      if (pointsApplied !== prevPointsApplied) setPointsApplied(prevPointsApplied || 0);
      console.log({errorMessage})
      toast.error(t(errorMessage));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentPromo, pointsLoseVoucher, refresh, validatePromoRun, formatDiscount, setPromotionCodeArray, setPromotionCode, pointsApplied, promoCode, byCustomer, isOwnStore, t]);

  const applyCode = useCallback(async (promoCodeToApply) => {
    let result = promoCodeToApply;
    if (!promoCodeToApply) return;
    result = promoCodeToApply.trim();
    setCancelCode(result)
    checkPromos(result);
  }, [checkPromos]);

  const onPromoCodeDialogOk = () => {
    console.log('OK');
  };

  const onPromoCodeDialogCancel = useCallback(() => {
    clearCurrentCode(cancelCode)
  }, [cancelCode, clearCurrentCode]);

  const getPromoSection = useCallback(() => (
    <div>
      {!promoInputEnabled && <div className={styles.codes} >
            <div className={styles.codesAvailable}>
              <div className={styles.codesTitle}>{t('Do you have promo code?')}</div>
            </div>
            <div>
              <button onClick={togglePromoInput} onKeyDown={onEnter(togglePromoInput)} type='button' className={styles.codesButton}>{t('Use code')}</button>
            </div>
          </div>}
      {(promoInputEnabled) && <div className={`${styles["add-promo-input"]} form-control-mat-alt`}>
        <div className={styles["input-row"]}>
          <div>
            <input ref={inputRef} autoFocus type="text" value={tempPromoCode} onChange={
              (e) => {
                if (promoError !== '') setPromoError('');
                const val = e?.target?.value;
                if (val && val !== tempPromoCode) {
                  setTempPromoCode(val.toUpperCase());
                } else {
                  setTempPromoCode('');
                }
              }
            } />
            {tempPromoCode && clearIcon}
          </div>
          <button type="button" className="btn" disabled={validatePromoLoading || tempPromoCode?.length < 1}
            onClick={() => {
              clearTimeout(blurTimeout);
              applyCode(tempPromoCode);
            }}>{t('Apply code') + (validatePromoLoading ? '...' : '')} </button>
        </div>
        {promoError && <div className={styles.error}>
          {promoError}
        </div>
        }
      </div>}
      { promoCodeArray?.length ? <div>
        {promoCodeArray?.map((code, codeIndex)=>(<div key={codeIndex} className={styles["add-promo-text"]}>
        <div>*<span>{code}</span> {t('applied')}</div>
        <button type="button" className="btn btn-outline" onClick={() => {
          clearCurrentCode(code);
          clearTimeout(blurTimeout);
        }}>{t('Remove code')} </button>
      </div>))} 
      </div>: ""}
    </div>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ),[promoCodeArray, tempPromoCode,validatePromoLoading,promoError,promoInputEnabled,inputRef]);

  const pointsAppliedChangeHandler = val => {
    updatePointsToUse(val);
    setPointsApplied(val);
  };

  useEffect(() => {
    if (deals?.length) checkPromos(promoCode);
  }, [deals, byCustomer, promoCode, checkPromos, pointsApplied, refresh]);

  useEffect(() => {
    const totalV = validatedTotal >= 0 ? validatedTotal : calculateNewTotal(newTotalWithSocioDiscount)
    setTotalOrder(totalV);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatedTotal, newTotalWithSocioDiscount]);

  useEffect(() => {
    dispatch(PaymentActions.fetchPaymentMethods(dataFetchPaymentMethod));
  }, [dataFetchPaymentMethod, dispatch, refresh]);

  useEffect(() => {
    if(setExternalMinimumPayment)setExternalMinimumPayment(minimumPayment);
  }, [minimumPayment, setExternalMinimumPayment]);

  useEffect(() => {
    if(pointsLoseVoucher)setPromoCodeConfirmationOpen(true);
  }, [pointsLoseVoucher]);
  

  const showContent = () => {
    if ((!areDiscountsMerged || validatePromoLoading)) return <div style={{ width: "100%" }}>
      <ContentLoader
        viewBox="0 0 320 144"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
      >
        <rect x="4" y="8" rx="0" ry="0" width="120" height="10" />
        <rect x="4" y="40" rx="0" ry="0" width="123" height="10" /> 
        <rect x="254" y="40" rx="0" ry="0" width="54" height="10" />
        <rect x="4" y="40" rx="0" ry="0" width="123" height="10" />
        <rect x="254" y="40" rx="0" ry="0" width="54" height="10" />
        <rect x="4" y="66" rx="0" ry="0" width="123" height="10" />
        <rect x="254" y="66" rx="0" ry="0" width="54" height="10" />
        <rect x="4" y="92" rx="0" ry="0" width="123" height="10" />
        <rect x="254" y="92" rx="0" ry="0" width="54" height="10" />
        <rect x="4" y="118" rx="0" ry="0" width="164" height="10" />
        <rect x="220" y="118" rx="0" ry="0" width="85" height="10" />
      </ContentLoader>
      <Divider style={{ height: "1px", border: "none", margin: "20px 0" }} />
      <ContentLoader
        viewBox="0 0 320 34"
        backgroundColor="#f5f5f5"
        foregroundColor="#dbdbdb"
      >
        <rect x="4" y="18" rx="0" ry="0" width="57" height="10" />
        <rect x="220" y="18" rx="0" ry="0" width="85" height="10" />
      </ContentLoader>
      
    </div>;
    const promoDiscountValue = promoDiscounts?.reduce((pTotal, discount) => pTotal + Number(discount?.value), 0);
    const finalDiscountValue = finalDiscounts?.reduce((pTotal, discount) => pTotal + Number(discount?.value), 0);
    const subTotal = (validatedTotal - (socioDiscount?.value || 0) - (creditApplied?.value || 0) - (promoDiscountValue) - (finalDiscountValue));

    return (
      <Grid container spacing={0}>

        <Grid item xs={12}>
          <span className={styles.title}>{t('Detail of your purchase')}</span>
        </Grid>
        <Grid className={styles["deals-rows"]} container item xs={12}>
            <Grid item xs={12}>
              {deals.map((deal) => <DealSummary key={deal.id} deal={deal} clearCodeFunc={clearCurrentCode} />)}
            </Grid>
          <Grid item xs={12}>
            <SummaryRow
              rowClass={styles['shipping-row']}
              left={{
                content: `${t('Shipping')}: ${!costShipping || costShipping <= 0 ? t('Free') : ''}`
              }}
              right={{ content: <PriceDiscountFormat value={costShipping || 0} currency={deals[0].currency} /> }}
              />
          </Grid>
          <Grid item xs={12}>
            <SummaryRow rowClass={styles['delivery-row']} left={{ description: `(${t('estimatedDeliveryTime', { 'context': country })})` }} />
          </Grid> 
          {
            (isOwnStore && !byCustomer) &&
              <SummaryRow rowClass={styles["subTotal-row"]} left={{
                content: `${t('Subtotal')}:`
              }} right={{
                content: <span><PriceDiscountFormat value={subTotal} currency={deals[0].currency} /></span>
              }}/>
          }
          {
            finalDiscounts?.map((discount) => <DiscountSummary key={discount.name} discount={discount} t={t} clearCodeFunc={clearCurrentCode} customClass={styles["subTotal-row-DiscountSummary"]} />)
          }
          {
            socioDiscount &&
            <DiscountSummary key={socioDiscount.name} discount={socioDiscount} customClass={styles["subTotal-row-DiscountSummary"]} t={t}/>
          }
          {
            creditApplied && <>
              {/* <DiscountSummary customClass={styles['delivery-row']} key={creditApplied.name} discount={creditApplied} rightClass="success-text-color" semiBold t={t} /> */}
              <SummaryRow rowClass={`${styles["shipping-row"]} ${styles["discount-row"]}`}
                left={{ 
                  className: `${styles.name}`,
                  content:` ${creditApplied.name} (${pointsApplied} ${t('point', { count: pointsApplied })})`
                }}
                right={{ className:"success-text-color", content: <PriceDiscountFormat fixNegative value={creditApplied?.value} semiBold /> }}
                removeCode='' clearCodeFunc={() => pointsAppliedChangeHandler(0)} 
              />
              {/* <SummaryRow rowClass={`${styles['delivery-row']} ${styles["discount-row"]}`} alternativeClassDescription={styles["sub-description-two"]} left={{ description: `(${pointsApplied} ${t('point', { count: pointsApplied })})` }}/> */}
            </>
          }
          {
            promoDiscounts && promoDiscounts.length > 0 &&
            promoDiscounts.map((discount) => <DiscountSummary key={discount.name} discount={discount} t={t} clearCodeFunc={clearCurrentCode}/>)
          }
        </Grid>

        {
          !dataPoints ? '' : <Grid className={styles["add-promo-row"]} style={{ borderTop: "1px solid rgb(232, 233, 234)" }} item xs={12} >
            <PointsSection dataFetchPaymentMethod={dataFetchPaymentMethod} data={dataPoints} pointsApplied={pointsApplied} setPointsApplied={pointsAppliedChangeHandler} showCreditNotification={showCreditNotification} setShowCreditNotification={setShowCreditNotification} orderTotal={validatedTotal} />
          </Grid>
        }
        <Grid className={styles["add-promo-row"]} item xs={12} >
          {getPromoSection()}
        </Grid>
          <SummaryRow rowClass={styles["total-row"]} left={{
            content: `${t('Total')}:`
          }} right={{
            content: <span><PriceDiscountFormat value={validatedTotal >= 0 ? validatedTotal : calculateNewTotal(newTotalWithSocioDiscount)} currency={deals[0].currency} /></span>
          }}/>
        {/* } */}
        <ConfirmationDialog title="Atención!" open={promoCodeConfirmationOpen} setOpen={setPromoCodeConfirmationOpen}
          ok={{ label: t("pages.checkout.confirmCouponOk"), action: onPromoCodeDialogOk }}
          cancel={{ label: t("pages.checkout.confirmCouponCancel"), action: onPromoCodeDialogCancel }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div>
              {t("pages.checkout.confirmMessage1")}
              <b>{`${pointsLoseVoucher} Puntos WAO!`}</b>
              {t("pages.checkout.confirmMessage1_contnue")}
            </div>
            {/* <div>
              Agregá un producto a la compra para utilizar el máximo de los Puntos disponibles en tu cupón.
            </div> */}
            <div>
              {t("pages.checkout.confirmMessage2")}
            </div>
          </div>
        </ConfirmationDialog>
      </Grid>
    );
  };

  return (
    <div onBlur={(/* e */) => {
      blurTimeout = setTimeout(() => {
        setPromoInputEnabled(false);
      }, 100);
    }} onFocus={() => {
      clearTimeout(blurTimeout);
    }}>
      <div className={styles.container}>
        {!deals?.length ? (t("No deals selected")) : ''}
        {deals?.length ? showContent() : ''}
      </div>
    </div>
  );
};

export default withTranslation()(OrderSummary);