import PageTrackingActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {

    case PageTrackingActions.createPageTrackingRequest().type:
      return state.merge({
        pageTrackingLoading: true,
        pageTrackingError: null,
        pageTrackingLoaded: false,
      });

    case PageTrackingActions.createPageTrackingSuccess().type:
      return state.merge({
        pageTrackingLoading: false,
        pageTrackingError: null,
        pageTrackingLoaded: true,
        pageTrackingData: action.payload.data || state.pageTrackingData,
      });

    default:
      return state;
  }
};
