import { path } from "ramda";
import { createActions } from "redux-actions";
import log from '../../utils/log';

import {
  getStoreDeals,
  getStoreDeal,
  getStoreDealsByCountry,
  getCategories
} from "../../services/deal";

import { GenericError, DealsError } from "../../utils/errors";

const DealActions = createActions({
  FETCH_DEALS_REQUEST: () => {},
  FETCH_DEALS_SUCCESS: (deals) => ({deals}),
  FETCH_DEALS_FAILED: (error) => ({ error }),
  FETCH_CATEGORIES_REQUEST: () => {},
  FETCH_CATEGORIES_SUCCESS: (categories) => (categories),
  FETCH_CATEGORIES_FAILED: (error) => ({ error }),
  SET_SELECTED_DEAL: (deal) => ({ deal }),
  SET_SEARCH_TERM: (searchTerm) => ({ searchTerm }),
  SET_FILTERS: (filters) => ({ filters }),
  FETCH_DEAL_REQUEST: () => {},
  FETCH_DEAL_SUCCESS: (deal) => ({deal}),
  FETCH_DEAL_FAILED: (error) => ({ error }),
  FETCH_DEALS_BY_COUNTRY_REQUEST: () => {},
  FETCH_DEALS_BY_COUNTRY_SUCCESS: (deals) => ({deals}),
  FETCH_DEALS_BY_COUNTRY_FAILED: (error) => ({ error }),
});

DealActions.fetchDeals = (data) => async (dispatch) => {
    try {
      dispatch(DealActions.fetchDealsRequest());
      const result = await getStoreDeals(data);
      if (!result.ok) {
        const dealsError = new DealsError(data, { status: result?.status });
        throw dealsError;
      }
      dispatch(DealActions.fetchDealsSuccess(result.data.data));
    } catch (err) {
      log.log(err);
      dispatch(DealActions.fetchDealsFailed({ error: err }));
    }
  };

DealActions.fetchDeal = (data) => async (dispatch) => {
    try {
      dispatch(DealActions.fetchDealRequest());
      const result = await getStoreDeal(data);
      dispatch(DealActions.fetchDealSuccess(result.data.data));
    } catch (err) {
      dispatch(DealActions.fetchDealFailed({ error: new GenericError(err, err) }));
    }
  };

DealActions.fetchDealsByCountry = (data) => async (dispatch) => {
    try {
      dispatch(DealActions.fetchDealsByCountryRequest());
      const result = await getStoreDealsByCountry(data);
      dispatch(DealActions.fetchDealsByCountrySuccess(result.data.data));
    } catch (err) {
      dispatch(DealActions.fetchDealsByCountryFailed({ error: new GenericError(err, err) }));
    }
  };

DealActions.fetchCategories = (data) => async (dispatch) => {
    try {
      dispatch(DealActions.fetchCategoriesRequest());
      const result = await getCategories(data);
      dispatch(DealActions.fetchCategoriesSuccess(path(['data', 'data'], result)));
    } catch (err) {
      dispatch(DealActions.fetchCategoriesFailed({ error: new GenericError(err, err) }));
    }
  };

export default DealActions;
