import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import UrlsComponent from '../urls';
import Snakbar from '../components/snakbar';
import { withTranslation } from 'react-i18next';
import ShopingCartContextProvider from '../contexts/shoppingCart/ShopingCartContext';
import CustomCatalogContextProvider from '../contexts/customCatalog/CustomCatalogContext';
import AppContextProvider from '../contexts/AppContext';

const Root = ({ t, showUpdateMessage = false }) => {
  const serviceWorker = useSelector(state => state.serviceWorker.registration);

  if (showUpdateMessage && serviceWorker?.waiting) {
    serviceWorker.waiting.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload(true);
  }
  if (showUpdateMessage && !serviceWorker?.waiting) {
    console.error("A new version is available but the ServiceWorker isn't waiting");
  }

  return (
    <>
      <Snakbar open={showUpdateMessage && serviceWorker?.waiting} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={t("NewVersionMessage")} />
      <AppContextProvider>
        <ShopingCartContextProvider>
          <CustomCatalogContextProvider>
            <UrlsComponent />
          </CustomCatalogContextProvider>
        </ShopingCartContextProvider>
      </AppContextProvider>
    </>
  );
};

export default withTranslation()(Root);
