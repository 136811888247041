import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import WaoIcon from "../WaoIcon";
import { ShopingCartContext } from '../../contexts/shoppingCart/ShopingCartContext';
import styles from './styles.module.sass';
import { usePrevious } from '../../utils/hooks';

const ShoppingButtonCounterCart = ({ history, color = "#000", buttonClass = "header-button" }) => {

  const { storeId } = useParams();
  const { getCart } = useContext(ShopingCartContext);
  const cart = getCart(storeId);
  const { itemCount = 0 } = cart;
  const goTo = () => {
    const path = `/store/${storeId}/my-cart`;
    const newRoute = { pathname: path };
    history.push(newRoute);
  };
  const prevItemCount = usePrevious(itemCount);

  return (
    <button className={styles[buttonClass]} onClick={() => goTo()}>
      <figure className={prevItemCount < itemCount ? styles.ring : null}>
        <WaoIcon color={color} size={24} icon="cart_alt" />
      </figure>
      {!!itemCount && (itemCount > 0) && <b><span>{itemCount}</span></b>}
    </button>
  );
};

export default withTranslation()(ShoppingButtonCounterCart);