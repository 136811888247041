import Immutable from 'seamless-immutable';

const initialState = Immutable({
  helpListLoading: false,
  helpListError: null,
  helpList: null,
  helpListPagination: null
});

export default initialState;
