import Immutable from 'seamless-immutable';

const initialState = Immutable({
  promosLoading: false,
  promosError: null,
  promos: null,

  catalogsListLoading: false,
  catalogsListError: null,
  catalogsList: null,
});

export default initialState;
