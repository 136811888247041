import React, { useCallback, useEffect, useState } from 'react';
import { CancelToken } from 'apisauce';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import HeaderStore from '../../components/HeaderStore';
import styles from './styles.module.sass';
import mixpanel from '../../services/mixpanel';
import { Link } from 'react-router-dom';
import { pageUrls } from '../../utils';
import FooterSocial from '../../components/FooterSocial';
import { useDispatch, useSelector } from 'react-redux';
import TutorialList from './Tutorials/TutorialList';
import HelpActions from '../../redux/help/actions';
import contactWhatsapp from '../../assets/help/whatsapp.png';
import contactEmail from '../../assets/help/email.png';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';
import { defaultCountry, getSocialLinks } from '../../utils/countries';
// import ScreenTitle from '../../transition/ScreenTitle';

const HelpBox = ({ toObject, anchorLink, title, content, linkText }) => {

  const innerContent = <div className={styles.box}>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.content}>
      <div>{content}</div>
    </div>

    <div className={styles.bottom}>

      {(toObject && linkText) &&
        <Link className="no-deco" to={toObject}>
          <div className={styles.link}>{linkText}</div>
        </Link>
      }

      {(anchorLink && linkText) &&
        <a className="no-deco" href={anchorLink} target="_blank" rel="noopener noreferrer">
          <div className={styles.link}>{linkText}</div>
        </a>
      }

    </div>
  </div>;

  return <div className="pointer">{innerContent}</div>;
};

const HelpContainer = ({ history, t }) => {

  const { userMe } = useSelector(state => state.auth);
  const country = userMe?.country?.toLowerCase() || defaultCountry;
  const { helpListLoading, helpList, helpListPagination } = useSelector(state => state.help);
  const [sourceHelp, setSourceHelp] = useState();

  const dispatch = useDispatch();
  const fetchData = useCallback((name, increase) => {
    if (helpListLoading) {
      sourceHelp.cancel();
    }
    const source = CancelToken.source();
    setSourceHelp(source);
    dispatch(HelpActions.fetchHelpList(
      {
        name,
        currentPage: increase ? helpListPagination.page + 1 : 1,
        cancelToken: source.token,
      })
    );
  }, [dispatch,
    helpListPagination,
    helpListLoading,
    sourceHelp
  ]);

  const previewTurorials = helpList?.slice(0,3);
  const contacts = [
    {name: "WhatsApp", link: getSocialLinks(country)?.whatsapp?.url, image:contactWhatsapp},
    {name: "Email", link: getSocialLinks(country)?.email?.url, image:contactEmail}
  ];    

  const tutorialsContent = <div>
    <span>{t("tutorialsDescription")}</span>
    <TutorialList alternalItemClass={styles.alternalItemClass} loading={helpListLoading} fetchData={() => fetchData(null, true)} t={t} data={previewTurorials} pagination={helpListPagination} />
  </div>;

  const contactContent = <div>
    <TutorialList alternalItemClass={styles.alternalItemClass} loading={helpListLoading} fetchData={() => fetchData(null, true)} t={t} data={contacts} pagination={helpListPagination} />
  </div>;



  useEffect(() => {
    fetchData(null, false);
    mixpanel.track_optimized('Help');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - {t('SocioHelp')}</title>
        <link rel="canonical" href="https://app.wao.shop/socio-help" />
      </Helmet>
      <HeaderStore history={history} showLogo icons={{ back: true }} altColor />
      <ScreenContent webMobile>
        <section style={{ padding: "20px", display: "flex", flexDirection: "column", gap: "40px" }}>
          {/* <ScreenTitle title={t('Help center')} ignorePadding /> */}
          <section className={`${styles['tutorial-main']} ${styles.section}`}>
            <h3>{t('We are here to help you')}</h3>
            <span>{t('helpDescription')}</span>
          </section>

          {/* Webinars */}
          <section>
            <HelpBox
              anchorLink={getSocialLinks(country)?.webinars?.url}
              title={t("Our webinars")}
              content={t("webinarsDescription")}
              linkText={t('View all webinars')}
            />
          </section>

          {/* Tutorials */}
          <section>
            <HelpBox
              toObject={pageUrls.tutorials}
              title={t("Our tutorials")}
              content={tutorialsContent}
              linkText={t('View all videos')}
            />
          </section>

          {/* Frequent questions */}
          <section>
            <HelpBox
              anchorLink={getSocialLinks(country)?.frequentQuestions?.url}
              title={t("Frequent questions")}
              content={t("frequentQuestionsDescription")}
              linkText={t('View all frequent questions')}
            />
          </section>

          {/* User manual */}
          <section>
            <HelpBox
              anchorLink={getSocialLinks(country)?.userManual?.url}
              title={t("User manual")}
              content={t("userManualDescription")}
              linkText={t('View user manual')}
            />
          </section>

          {/* Contact */}
          <section>
            <HelpBox
              title={t("Contact us for help")}
              content={contactContent}
            />
          </section>
        </section>
        <FooterSocial dark country={country} />
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(HelpContainer);
