import firebase from 'firebase/app';
import 'firebase/messaging';

export const registerNotifications = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await firebase.messaging().getToken();
      return token;
    }
    return '';
  } catch (e) {
    console.log(e);
    console.log('Unable to get permission to notify.');
    throw e;
  }
};
