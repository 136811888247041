import React from 'react';
import styles from './index.module.sass';

const SubtitleAndLegend = ({ subtitle, legend, style }) =>
  <div className={styles['little-card']}>
    {subtitle && <div style={style} className={styles['little-card-subtitle']}>{subtitle}</div>}
    {legend && <div style={style} className={styles['little-card-legend']}>{legend}</div>}
  </div>;

export default SubtitleAndLegend;
