/* eslint-disable react/no-this-in-sfc */
import { Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeaderStore from '../../components/HeaderStore';
import TextInputLiveFeedback from '../../components/TextInputLiveFeedback';
import AuthActions from '../../redux/auth/actions';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';
import * as Yup from 'yup';
import { t } from 'i18next';
import FormikErrorFocus from 'formik-error-focus';
import { CheckLiveFeedback, SelecInputPhone } from '../registration/utils';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { Button, useTheme } from '@mui/material';
import { pick } from "ramda";
import Progress from '../../components/Progress';
import { countriesZipIsOptionalIn } from '../../utils/constants';
import GoogleAutocompleteService from '../../components/GoogleMaps/GoogleAutocompleteService';
import checkoutStyles from '../checkout/styles.module.sass';
import { getSocialLinks } from '../../utils/countries';

const PersonalInfoSection = ({ formikStep, country, disabled, disabledFields = {} }) => <FormikProvider value={formikStep} enableReinitialize>
  <div className="form-control-mat3">
    <Form style={{ paddingBottom: "0px" }}>
      <TextInputLiveFeedback alt label={t('Id')} id="id" name="id" type="text" disabled noBorder />
      <TextInputLiveFeedback alt label={t('First Name')} id="firstName" name="firstName" type="text" disabled={disabled || disabledFields.firstName} noBorder={disabled} />
      <TextInputLiveFeedback alt label={t('Last Name')} id="lastName" name="lastName" type="text" disabled={disabled || disabledFields.lastName} noBorder={disabled} />
      <div>
        <Field alt label={t('WhatsApp Number')} name='phone' country={country} component={SelecInputPhone} t={t} feedbackClass="control-feedback" disabled={disabled || disabledFields.phone} noBorder={disabled || disabledFields.phone} />
        <Field alt label={t('IsWhatsAppBusiness')} name="businessPhone" type="checkbox" component={CheckLiveFeedback} disabled={disabled || disabledFields.businessPhone} />
      </div>
      <TextInputLiveFeedback alt trim label={t('Email Address')} id="email" name="email" type="text" disabled={disabled || disabledFields.email} noBorder={disabled || disabledFields.email} />
      <TextInputLiveFeedback alt label={t(`documentCheckout`, { context: country })} id="document" name="document" type="text" disabled={disabled || disabledFields.document} noBorder={disabled} />
    </Form>
  </div>
  <FormikErrorFocus offset={0} align="top" duration={1000} />
</FormikProvider>;

const AddressSection = ({ formikStep, country, updateFunction }) => {
  const addressOnChangeHandler = (address1) => {
    const newAddress = { ...formikStep?.values, address1 };
    delete newAddress.formatted_address;
    updateFunction(newAddress);
  };
  const context = { context: country };
  return <FormikProvider value={formikStep} enableReinitialize>
    <div className="form-control-mat3">
      <Form style={{ paddingBottom: "0px" }}>
        <div className="form-control-mat-alt">
          <div className="label">{t('Address')}<span className={checkoutStyles.required}>*</span></div>
          <GoogleAutocompleteService
            outputAddressComponent={updateFunction} onChange={addressOnChangeHandler}
            value={{ ...formikStep.values, formatted_address: formikStep.values.address }}
            countryCode={country} clearable alt hideVerified validateRequired useSimpleValue
          />
        </div>
        <TextInputLiveFeedback alt label={t('form.Suite/Unit', context)} id="address2" name="address2" type="text" />
        <TextInputLiveFeedback alt trim label={t('form.Locality/City', context)} id="city" name="city" type="text" required />
        <TextInputLiveFeedback alt trim label={t('form.Province', context)} id="region" name="region" type="text" required />
        <TextInputLiveFeedback alt trim label={t('form.Postal Code')} id="zipCode" name="zipCode" type="text" required={!countriesZipIsOptionalIn.includes(country)} />
        <TextInputLiveFeedback alt label={t('Indications')} id="description" name="description" rows="3" maxLength="128" type="textarea" />
        <div className={checkoutStyles['hide-inputs']}>
          <input label={t('form.Suite/Unit', context)} id="address" name="address" type="text" />
          <input label={t('form.Locality/City', context)} id="city" name="city" type="text" />
          <input label={t('form.Province', context)} id="region" name="region" type="text" />
          <input label={t('form.Postal Code')} id="zipCode" name="zipCode" type="text" />
          <textarea label={t('Indications')} id="zipCode" name="zipCode" type="text" />
          <input label="Lat" id="lat" name="lat" type="text" />
          <input label="Lng" id="lng" name="lng" type="text" />
        </div>
      </Form>
    </div>
    <FormikErrorFocus offset={0} align="top" duration={1000} />
  </FormikProvider>;
};

const ProfileContainer = ({ history }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { userMe, userMeLoaded, userMeLoading, userMeUpdateLoading } = useSelector(state => state.auth);
  const missingPhone = !userMe?.phone;
  const missingEmail = !userMe?.email;
  const missingDocument = !userMe?.document;
  const missingAValue = missingPhone || missingEmail || missingDocument;
  const [edittingPersonal, setEdittingPersonal] = useState(missingAValue);
  const [edittingAddress, setEdittingAddress] = useState(false);
  const lowerCaseCountry = userMe?.country && userMe?.country?.toLowerCase ? userMe?.country.toLowerCase() : '';
  const countrySocialLinks = getSocialLinks(lowerCaseCountry);
  const firstUserAddress = userMe?.addresses?.[userMe.addresses.length - 1];

  const updateProfileSubmit = useCallback(values => {
    const newData = pick(['firstName', 'lastName', 'phone', 'email', 'document'], values);
    dispatch(AuthActions.updateUserMe({ userId: userMe?.id, ...newData }));
    setEdittingPersonal(false);
  }, [dispatch, userMe?.id]);

  const updateAddressSubmit = useCallback(values => {
    const updateData = {};

    const newAddress = {
      address1: values.address,
      country: userMe.country,
      city: values.city
    };

    if (firstUserAddress || values?.address2) newAddress.address2 = values?.address2 || null;
    if (firstUserAddress || values?.region) newAddress.region = values?.region || null;
    if (firstUserAddress || values?.description) newAddress.description = values?.description || null;
    if (firstUserAddress || values?.zipCode) newAddress.zipCode = values?.zipCode || null;

    updateData.addresses = [newAddress];
    if (firstUserAddress) updateData.addresses[0].id = firstUserAddress?.id;

    dispatch(AuthActions.updateUserMe({ userId: userMe?.id, phone: userMe.phone, ...updateData }));
    setEdittingAddress(false);
  }, [dispatch, firstUserAddress, userMe]);

  Yup.addMethod(Yup.string, 'validateDocumentArgentina', function validateFunc(message) {
    return this.test('document', message, (value) => {
      if (!value) return false;
      const size = value.length;
      if (size === 11) return true;
      if ((size > 9 || size < 7)) {
        return false;
      }
      return true;
    });
  });

  Yup.addMethod(Yup.string, 'validPhoneNumber', function validation(message) {
    return this.test('phone', message, value => {
      if (!value) return false;
      return isValidPhoneNumber(value, lowerCaseCountry);
    });
  });

  const formikStep1 = useFormik({
    initialValues: {
      id: userMe?.id || "",
      firstName: userMe?.firstName || "",
      lastName: userMe?.lastName || "",
      phone: userMe?.phone || "",
      businessPhone: userMe?.businessPhone || "",
      email: userMe?.email || "",
      document: userMe?.document || "",
      country: lowerCaseCountry,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('RequiredField')),
      lastName: Yup.string().required(t('RequiredField')),
      phone: Yup.string().validPhoneNumber(t('EnterValidPhone', { context: lowerCaseCountry })).required(t('RequiredField')),
      businessPhone: Yup.boolean(),
      email: Yup.string().email(t('EnterValidEmail')),
      document: Yup.string()
        .when('country', {
          is: (thisCountry) => thisCountry === 'ec',
          then: Yup.string().min(5, t('must be at least x characters', { field: 'Cédula/NIT/DNI', size: 5 })).max(20, t('must be at most x characters', { field: 'Cédula/NIT/DNI', size: 20 })).required(t('RequiredField'))
        })
        .when('country', {
          is: (thisCountry) => thisCountry === 'co',
          then: Yup.string().min(6, t('must be at least x characters', { field: t('documentCheckout', { context: lowerCaseCountry }), size: 6 })).max(10, t('must be at most x characters', { field: t('documentCheckout', { context: lowerCaseCountry }), size: 10 })).required(t('RequiredField'))
        })
        .when('country', {
          is: (thisCountry) => thisCountry === 'ar',
          then: Yup.string().required(t('RequiredField')).validateDocumentArgentina(t('between x characters', { field: t('documentCheckout', { context: lowerCaseCountry }), min: 7, max: 9, other: 11 }))
        }).required(t('RequiredField'))
    }),
    onSubmit: updateProfileSubmit
  });

  const formikStep2 = useFormik({
    initialValues: {
      address: firstUserAddress?.address1 || "",
      address2: firstUserAddress?.address2 || "",
      city: firstUserAddress?.city || "",
      region: firstUserAddress?.region || "",
      zipCode: firstUserAddress?.zipCode || "",
      description: firstUserAddress?.description || "",
      lat: firstUserAddress?.lat || '',
      lng: firstUserAddress?.lng || '',
    },
    validationSchema: Yup.object({
      address: Yup.string().required(t('RequiredField')),
      address2: Yup.string(),
      region: Yup.string().required(t('RequiredField')),
      description: Yup.string(),
      city: Yup.string().required(t('RequiredField')),
      lat: Yup.string(),
      lng: Yup.string(),
      zipCode: countriesZipIsOptionalIn.includes(lowerCaseCountry) ? Yup.string() : Yup.string().required(t('RequiredField')),
    }),
    onSubmit: updateAddressSubmit
  });

  const updateAddressFields = useCallback((data) => {
    formikStep2.setFieldValue('address', data?.formatted_address || data?.address1 || '', true);
    formikStep2.setFieldValue('address2', data?.address2 || '', true);
    formikStep2.setFieldValue('city', data?.city || '', true);
    formikStep2.setFieldValue('region', data?.region || data?.state || '', true);
    formikStep2.setFieldValue('zipCode', data?.zipCode || '', true);
    formikStep2.setFieldValue('lat', data?.lat || '', true);
    formikStep2.setFieldValue('lng', data?.lng || '', true);
    formikStep2.validateForm();
  }, [formikStep2]);

  const toggleEditPersonal = () => {
    if (edittingPersonal) formikStep1.setValues({
      firstName: userMe?.firstName || "",
      lastName: userMe?.lastName || "",
      phone: userMe?.phone || "",
      businessPhone: userMe?.businessPhone || "",
      email: userMe?.email || "",
      document: userMe?.document || ""
    });
    setEdittingPersonal(!edittingPersonal);
  };

  const toggleEditAddress = () => {
    if (edittingPersonal) formikStep2.setValues({
      address: firstUserAddress?.address || "",
      address2: firstUserAddress?.address2 || "",
      city: firstUserAddress?.city || "",
      region: firstUserAddress?.region || "",
      zipCode: firstUserAddress?.zipCode || "",
      description: firstUserAddress?.description || ""
    });
    setEdittingAddress(!edittingAddress);
  };

  const submitPersonalEdit = () => formikStep1.submitForm();
  const submitAddressEdit = () => formikStep2.submitForm();

  useEffect(() => {
    if (!userMeLoaded && !userMeLoading) dispatch(AuthActions.fetchUserMe());
  }, [dispatch, userMeLoaded, userMeLoading]);

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - {t('Profile')}</title>
        <link rel="canonical" href="https://app.wao.shop/settings" />
      </Helmet>
      <HeaderStore history={history} showLogo icons={{ back: true }} altColor />
      <ScreenContent webMobile>
        <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
          <h2 style={{ fontWeight: "bold", padding: "0px", fontSize: "20px" }}>{t('Personal Information')}</h2>
          <PersonalInfoSection disabledFields={{ phone: !!userMe?.phone, email: !!userMe.email }} disabled={!edittingPersonal || userMeLoading || userMeUpdateLoading} formikStep={formikStep1} previousValues={userMe} country={lowerCaseCountry} theme={theme} />
          <div style={edittingAddress ? { display: "none" } : {}}>
            {
              !userMeUpdateLoading && edittingPersonal ?
                <div style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}>
                  <Button variant="outlined" onClick={toggleEditPersonal} style={{ borderRadius: "8px" }}>
                    <span style={{ color: theme?.palette?.primary.main }}>{t("Cancel")}</span>
                  </Button>
                  <Button variant="contained" onClick={submitPersonalEdit} style={{ borderRadius: "8px" }}>
                    <span style={{ color: theme?.palette?.primary.contrast }}>{t("Save")}</span>
                  </Button>
                </div> : ''
            }
            {
              !userMeUpdateLoading && !edittingPersonal ?
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="text" onClick={toggleEditPersonal} style={{ borderRadius: "8px" }}>
                    <span style={{ textDecoration: "underline", color: theme?.palette?.primary.main }}>{t("Edit")}</span>
                  </Button>
                </div> : ''
            }
            {
              userMeUpdateLoading ?
                <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "16px", gap: "8px", color: theme?.palette?.primary.main, opacity: ".5" }}>
                  <span>{t('Saving')}</span><Progress size={20} />
                </div> : ''
            }
          </div>
        </div>
        <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
          <h2 style={{ fontWeight: "bold", padding: "0px", fontSize: "20px" }}>{t('Address')}</h2>

          <div style={!edittingAddress ? { display: "none" } : {}}>
            <AddressSection formikStep={formikStep2} previousValues={userMe} country={lowerCaseCountry} theme={theme} updateFunction={updateAddressFields} />
          </div>

          {
            !edittingAddress ? <div style={{ padding: "20px 0" }}>{firstUserAddress?.address1}</div> : ''
          }

          <div style={edittingPersonal ? { display: "none" } : {}}>
            {
              !userMeUpdateLoading && edittingAddress ?
                <div style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}>
                  <Button variant="outlined" onClick={toggleEditAddress} style={{ borderRadius: "8px" }}>
                    <span style={{ color: theme?.palette?.primary.main }}>{t("Cancel")}</span>
                  </Button>
                  <Button variant="contained" onClick={submitAddressEdit} style={{ borderRadius: "8px" }}>
                    <span style={{ color: theme?.palette?.primary.contrast }}>{t("Save")}</span>
                  </Button>
                </div> : ''
            }
            {
              !userMeUpdateLoading && !edittingAddress ?
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="text" onClick={toggleEditAddress} style={{ borderRadius: "8px" }}>
                    <span style={{ textDecoration: "underline", color: theme?.palette?.primary.main }}>{t("Edit")}</span>
                  </Button>
                </div> : ''
            }
            {
              userMeUpdateLoading ?
                <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "16px", gap: "8px", color: theme?.palette?.primary.main, opacity: ".5" }}>
                  <span>{t('Saving')}</span><Progress size={20} />
                </div> : ''
            }
          </div>
        </div>
        <div style={{ margin: "20px", background: "rgba(0, 166, 153, 0.1)", borderRadius: "6px", padding: "20px", display: "flex", flexDirection: "column", gap: "24px", textAlign: "center" }}>
          <span>Si necesitas ayuda puedes ponerte en contacto con nuestro equipo</span>
          <a className="no-deco" href={countrySocialLinks?.whatsapp?.url} target="_blank" rel="noopener noreferrer">
            <Button variant="contained" color="paleGreen" style={{ width: "100%", borderRadius: "8px" }}>
              <span style={{ fontWeight: "bold" }}>{t('Contact agent now!')}</span>
            </Button>
          </a>
        </div>
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(ProfileContainer);
