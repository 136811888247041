/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import { startsWith, isEmpty, prop, equals, __, toUpper, last, converge, head, map, curry, compose, toLower, pipe, reduce, sum, split, isNil } from "ramda";
import log from './log';

export const mpLogoURL = "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/images%2Fmp_logo.png?alt=media&token=b40ba619-6cce-4ad5-b495-b6c8915b6a26";
export const viviWaoLogo = "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/images%2Fvivi-wao.png?alt=media&token=6e15fdb1-1380-4494-9c9e-c863fa8ed5c7";

export const colors = {
  primaryTextColor: "#030029",
  primaryBaseColor: "#EBEBEB",
  mainColor: "#FF914D",
  mainLight: "#FFA770",
  mainDark: "#B26535",
  mainLogoColor: "#EE5340",
  secondaryFirstDark: "#FDCB71",
  secondaryFirstMain: "#FDD387",
  secondaryFirstLight: "#FEE9C3",
  secondarySecondDark: "#76698E",
  secondarySecondMain: "#9A8DB9",
  secondarySecondLight: "#BCB3D0",
  secondaryThirdDark: "#7FCDBB",
  secondaryThirdMain: "#B6E2D8",
  secondaryThirdLight: "#D3EEE7",
};

export const avatars = [
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F1.png?alt=media&token=0b96089f-2a67-426a-9caf-ccea1813cf40",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F2.png?alt=media&token=19561696-fb0b-4054-afd2-6f6f8edc0531",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F3.png?alt=media&token=188c52d3-43ad-44bd-9c61-09771510c504",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F4.png?alt=media&token=e03d33e2-7980-44c2-8238-0e7c6d15fcf4",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F5.png?alt=media&token=fe3fcd88-35a7-4077-a931-31916d8526be",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F6.png?alt=media&token=15f7d58f-c915-435e-8c90-99fd534edd69",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F7.png?alt=media&token=99e99633-e8f5-4f00-9f24-91a369e84089",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F8.png?alt=media&token=f9ee37c6-81cc-4c74-98ea-6e6bd481e7de",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F9.png?alt=media&token=8754a28a-3f4f-4569-8820-a3d3233d2730",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/avatars%2F10.png?alt=media&token=754c858c-0ca8-4260-af95-e61a1dc56675",
];

export const backgrounds = [
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F18-ondas.svg?alt=media&token=599d009f-957c-4b56-84de-562de6f7c8e1",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F03-dandelion.svg?alt=media&token=c39ae275-224d-433c-8c6a-c0351af87c74",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F05-flores.svg?alt=media&token=1a81f5f1-e82b-4697-9e2b-56c92f4f1f2b",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F13-cocina.svg?alt=media&token=e4c74f49-2be8-4224-8c81-1c145735cf45",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F14-cocina2.svg?alt=media&token=44329c5d-768e-4f4f-b8c6-2666743d885d",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F15-frutas.svg?alt=media&token=ddae547b-8f1d-4444-bd8c-4607ef771409",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F16-lineas.svg?alt=media&token=c0476f16-3c19-4449-8fb8-bd6029f52bbc",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F19-patitas.svg?alt=media&token=69dcb218-c92d-4c48-9acd-fed86d3e5d6b",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F23-escolar.svg?alt=media&token=fb4a5add-1617-467c-9ce2-e58aac1815e1",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F24-morrales.svg?alt=media&token=cb78feb7-0b83-4edc-b877-d1b81bc67170",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F25-escolar2.svg?alt=media&token=33dd8d7d-d35b-462f-aa83-19ac1308079f",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F37-wifi.svg?alt=media&token=f71dcf9a-673b-463f-a83d-a3017fa3aec0",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F39-widi2.svg?alt=media&token=1491d9bf-aa87-486c-8070-c1dd4a88ad89",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F40-play.svg?alt=media&token=79df6b65-6b63-4cf9-ac8f-191c015a0183",
  "https://firebasestorage.googleapis.com/v0/b/dev-wao-shop.appspot.com/o/backgrounds%2F41-play2.svg?alt=media&token=3e3cd0b3-8e0c-4766-b084-6cb8dfe5051f",
];

const countryAndCodes = {
  ARGENTINA: 'AR',
  COLOMBIA: 'CO'
};

export const countriesZipIsOptionalIn = ["co"];

export const daysToPay = 4;

export const getDefaultRolePageRedirect = ({role, user, manualCondition}) => {

  if (!role) return '/landingPage';
  let result = "/home";
  if (manualCondition || role === 'customer' || user?.metadata?.status === 'lead') result = '/account';
  return result;
};


const paymentMethodsInfoByCountry = (t, country) => {
  const context = { context: country };
  const countryInfo = {
    'ar': [
      {
        label: "Mercado Pago",
        provider: "mercado_pag",
        value: "checkout_pro",
        buttonText: "Continuar y pagar",
        instructions: t('payment.mercadoPagoInstructions', context),
        successInstructions: t("payment.mercadoPagoSuccessInstructions", context),
      },
      {
        label: "Cuotas sin interés*",
        provider: "manual",
        value: "TODO_PAGO",
        buttonText: t("Contact agent", { ...context, name: "Cuotas sin interés*" }),
        instructions: t('payment.eVoucherInstallmentsInstructions', { ...context, name: "Cuotas sin interés*" }),
        instructions_2: "*Las cuotas sin interés se podrán aplicar a productos superiores a los $5.000.",
        successInstructions: t('payment.eVoucherSuccessInstructions', context)
      },
      {
        label: "Rapipago o Pago Fácil",
        provider: "dlocal",
        value: "CASH",
        buttonText: t("Payment ticket"),
        instructions: t("payment.cashInstructions", context),
        successInstructions: t("payment.cashSuccessInstructions", context)

      },
      {
        label: "Tarjeta de Crédito / Débito",
        provider: "dlocal",
        value: "CARD",
      },
      {
        label: "Wibond",
        provider: "wibond",
        value: "wibond+",
      },
      {
        label: "Pago gratis",
        provider: "manual",
        value: "FREE",
      },
      {
        label: "Transferencia Bancaria",
        provider: "manual",
        value: "BANK_TRANSFER",
        buttonText: t("Send proof of payment", context),
        instructions: t("payment.transferInstructions", context),
        successInstructions: t("payment.transferSuccessInstructions", context),
        PayInfo:[
          {key:t("Bank"),value:"Mercado Pago"},
          {key:"CVU",value:"0000003100047236507976"},
          {key:t("Nickname"),value:"wao.shop.mp"},
          {key:"CUIT",value:"30-71720794-3"}
        ]
      }
    ],
    'co': [
      {
        label: "Tarjeta de Crédito / Débito",
        provider: "dlocal",
        value: "CARD",
      },
      {
        label: "Transferencia Bancaria",
        provider: "manual",
        value: "BANK_TRANSFER",
        buttonText: t("Send proof of payment", context),
        instructions: t("payment.transferInstructions", context),
        successInstructions: t("payment.transferSuccessInstructions", context),
        PayInfo:[
          {key:t("Bank"),value:"Bancolombia"},
          {key:t("Account type"),value:"Ahorro"},
          {key:t("Account number"),value:"61248932596"},
          {key:"NIT",value:"900890894"}
        ]
      },
      {
        label: "Pago por SU+ Pay",
        provider: "manual",
        value: "PAGO_POR_SU+",
        instructions: t("payment.PayBySU+Instructions", context),
        successInstructions: " "
      },
      {
        label: "Pago gratis",
        provider: "manual",
        value: "FREE",
      },
      {
        label: "Efecty",
        provider: "dlocal",
        value: "CASH",
        buttonText: t("Payment ticket"),
        instructions: t("payment.cashInstructions", context),
        successInstructions: t("payment.cashSuccessInstructions", context),
      },
      {
        label: "Efectivo contra entrega",
        provider: "manual",
        value: "CASH_ON_DELIVERY",
        instructions: t('payment.eVoucherInstructions', context),
        successInstructions: t('payment.eVoucherSuccessInstructions', context)
      },
      {
        label: "Pago por PSE",
        provider: "dlocal",
        value: "PSE",
        buttonText: "Continuar y pagar",
        instructions: t("payment.pseInstructions", context),
        successInstructions: t("payment.pseSuccessInstructions", context),
      },
      {
        label: "Mercado Pago",
        provider: "mercado_pag",
        value: "checkout_pro",
        buttonText: "Continuar y pagar",
        instructions: t('payment.mercadoPagoInstructions', context),
        successInstructions: t("payment.mercadoPagoSuccessInstructions", context),
      },
      {
        label: "Pago en cuotas con tarjeta de crédito",
        provider: "manual",
        value: "PAGO_POR_CUOTAS",
        buttonText: t("Contact agent", context),
        instructions: t('payment.installments', context),
        successInstructions: t('payment.installmentsSuccessInstructions', context)
      },
    ]
  };
  return countryInfo[country];
};

export const getPaymentMethodsInfoByCountry = (t, country) => paymentMethodsInfoByCountry(t, country);

const mimeTypes = {
  jpeg: 'image',
  jpg: 'image',
  gif: 'image',
  png: 'image',
  tif: 'image',
  tiff: 'image',
  webp: 'image',
  mov: 'video',
  mp4: 'video',
  webm: 'video',
  pdf: 'application'
};

export const enablePhoneDropdownUrls = [
  (x, y) => (isEmpty(y) || isNil(y)),
  (x) => startsWith('/sign-in', prop('pathname', x)) && (equals(prop('search', x), '')),
];

export const enableAutoRegisterUrls = [
  (x) => startsWith('/sign-in', prop('pathname', x)) && !(equals(prop('search', x), ''))
];

export const compareCaseInsensitive = curry((s1, s2) => converge(equals, [head, last])(map(toUpper, [s1, s2])));
export const countryToCode = (countryP) => compose(curry(prop(__, countryAndCodes)), toUpper)(countryP);
export const isVideo = (name) => !name || pipe(split('.'), last, split('?'), head, toLower, prop(__, mimeTypes), equals('video'))(name);
export const isImage = (name) => !name || pipe(split('.'), last, split('?'), head, toLower, prop(__, mimeTypes), equals('image'))(name);
export const isApplication = (name) => !name || pipe(split('.'), last, split('?'), head, toLower, prop(__, mimeTypes), equals('application'))(name);
export const countVideosInMedia = (media) => reduce((acc, val) => sum([isVideo(val), acc]), 0, media);
export const countImagesInMedia = (media) => reduce((acc, val) => sum([isImage(val), acc]), 0, media);
export const countDocInMedia = (media) => reduce((acc, val) => sum([isApplication(val), acc]), 0, media);

export const dbug = (x) => {
  log.log(x);
  return x;
};
