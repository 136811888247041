import { takeNullPairsOut } from '../utils';
import api from './api';

export const getCommissions = ({ currentPage, types = 'order', fromDate, toDate, perPage = 10 }) => api.get(`commissions/mini-store`,
  takeNullPairsOut({
    fromDate,
    toDate,
    currentPage,
    perPage,
    types
  })
);

export const getCommissionTotals = ({fromDate, toDate}) => api.get(`commissions/mini-store/summary`,
  takeNullPairsOut({
    fromDate,
    toDate
  })
);
