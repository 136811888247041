import React, { useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import AccumulatedCommissionList from '../../../components/AccumulatedCommissionList';
import styles from "../../socio/home/styles.module.sass";
import HeaderStore from '../../../components/HeaderStore';
import CommissionActions from '../../../redux/commission/actions';
import { useDispatch, useSelector } from 'react-redux';
import './index.sass';
import { createWeekDateRange } from '../../../utils';
import { map } from 'ramda';
import { formatISO } from 'date-fns';
import mixpanel from '../../../services/mixpanel';
import OrderActions from '../../../redux/orders/actions';

const CommissionsDetail = ({ t, history }) => {
  const { commissions, commissionsPagination, commissionsLoading, commissionsTotal } = useSelector(state => state.commissions);
  const dispatch = useDispatch();
  const dateRange = createWeekDateRange();
  const fetchData = useCallback(() => {
    if (!commissionsLoading) {
      dispatch(CommissionActions.fetchCommissions({ currentPage: commissionsPagination.currentPage + 1 }));
    }
  }, [dispatch, commissionsPagination, commissionsLoading]);
  const { orderStatuses, orderStatusesLoading } = useSelector(state => state.orders);  
  
  useEffect(() => {
    const [fromDate, toDate] = map(formatISO, dateRange);
    dispatch(CommissionActions.fetchCommissions({ currentPage: 1, fromDate, toDate }));
    dispatch(CommissionActions.fetchCommissionTotals({ fromDate, toDate }));
    mixpanel.track_optimized('Commission Details');
    if (!orderStatuses && !orderStatusesLoading) dispatch(OrderActions.fetchOrderStatuses());
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);  

  return (
    <section id="scrollable-section" className="min-h-120 app-wrapper-mobile">
      <section className={styles.header}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>WAO! - {t('Account')}</title>
          <link rel="canonical" href="https://app.wao.shop/commisions/detail" />
        </Helmet>
        <HeaderStore history={history} icons={{ back: true }} altColor />
      </section>
      <div className="m-2">
        <AccumulatedCommissionList fetchData={fetchData} enablePagination data={commissions} pagination={commissionsPagination} totals={commissionsTotal} />
      </div>
    </section>
  );
};

export default withTranslation()(CommissionsDetail);
