import React from 'react';
import { withTranslation } from 'react-i18next';
import SearchBar from '../../components/SearchBar';
import WaoIcon from '../../components/WaoIcon';
import styles from './styles.module.sass';

const SearchBarAndDownload = ({ t, action, clearable, onChange, lookupEvent, value, showButton, text, inputstyle, containerstyle, inputsIconStyle}) => (
    <div className={styles['search-bar-n-download']}>
      <div style={{width: '100%'}}>
        <SearchBar
          clearable={clearable}
          onChange={onChange}
          lookupEvent={lookupEvent}
          value={value}
          placeholder={text || t('Buscar Oferta')}
          inputstyle={inputstyle}
          inputsIconStyle={inputsIconStyle}
          containerstyle={containerstyle}
        />
      </div>
      {showButton &&
        <div>
          <div style={{ position: 'relative' }}>
            <WaoIcon className={styles['download-icon']} size={15} color="#000000" icon="arrow_in_down-" />
            <button onClick={action}>{t('Descarga todo')}</button>
          </div>
        </div>
      }
    </div>
  );

export default withTranslation()(SearchBarAndDownload);
