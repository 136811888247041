import { createActions } from "redux-actions";
import { getCommissions, getCommissionTotals } from "../../services/commissions";
import { GenericError } from "../../utils/errors";

const CommissionActions = createActions({
  FETCH_COMMISSIONS_REQUEST: (data) => (data),
  FETCH_COMMISSIONS_SUCCESS: (commissions) => ({commissions}),
  FETCH_COMMISSIONS_FAILED: (error) => ({ error }),
  FETCH_COMMISSIONS_TOTALS_REQUEST: () => {},
  FETCH_COMMISSIONS_TOTALS_SUCCESS: (commissions) => ({commissions}),
  FETCH_COMMISSIONS_TOTALS_FAILED: (error) => ({ error }),
  FETCH_REWARDS_REQUEST: () => {},
  FETCH_REWARDS_SUCCESS: (commissions) => ({commissions}),
  FETCH_REWARDS_FAILED: (error) => ({ error }),
});

CommissionActions.fetchCommissions = (data) => async (dispatch) => {
  try {
    dispatch(CommissionActions.fetchCommissionsRequest(data));
    const result = await getCommissions(data);
    dispatch(CommissionActions.fetchCommissionsSuccess(result.data.data));
  } catch (err) {
    dispatch(CommissionActions.fetchCommissionsFailed({ error: new GenericError(err, err) }));
  }
};

CommissionActions.fetchRewards = (data) => async (dispatch) => {
  try {
    dispatch(CommissionActions.fetchRewardsRequest());
    const result = await getCommissions({...data, types: 'reward'});
    dispatch(CommissionActions.fetchRewardsSuccess(result.data.data));
  } catch (err) {
    dispatch(CommissionActions.fetchRewardsFailed({ error: new GenericError(err, err) }));
  }
};

CommissionActions.fetchCommissionTotals = (data) => async (dispatch) => {
  try {
    dispatch(CommissionActions.fetchCommissionsTotalsRequest());
    const result = await getCommissionTotals(data);
    dispatch(CommissionActions.fetchCommissionsTotalsSuccess(result.data.data));
  } catch (err) {
    dispatch(CommissionActions.fetchCommissionsTotalsFailed({ error: new GenericError(err, err) }));
  }
};

export default CommissionActions;
