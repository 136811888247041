import React, { useContext, useEffect, useState } from 'react';
import mixpanel from '../../services/mixpanel';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ShopingCartContext } from '../../contexts/shoppingCart/ShopingCartContext';
// TODO Shouldn't this be added to package.json?
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router';
import { goToSuccessFromCheckoutLocal } from '../../services/local';
import { onEnter } from '../../utils';
import ProductVariantsDrawer from '../ProductVariantsDrawer';

const BuyNowButton = (
  {
    t,
    children,
    currentQuantity,
    deal,
    deals,
    storeId,
    buttonClass = 'header-button',
    component: CustomComponent,
    enableDrawer,
    ...props
  },
) => {
  const hasVariants = Boolean(deals?.deals?.length);
  const { clearCartBuyNow, addProductBuyNow } = useContext(ShopingCartContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [checkoutClicked, setCheckoutClicked] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [nextRoute, setNextRoute] = useState(`/sign-in?nextRoute=/store/${storeId}/checkout`);
  const [showVariantsDrawer, setShowVariantsDrawer] = useState(false);
  const goToCheckout = () => {
    mixpanel.track_optimized('Buy now go to Checkout');
    setCheckoutClicked(true);
  };

  useEffect(() => {
    if (user) setNextRoute(`/store/${storeId}/checkout`);
  }, [user, storeId, setNextRoute]);

  useEffect(() => {
    if (checkoutClicked) history.push(nextRoute);
  }, [dispatch, checkoutClicked, nextRoute, history]);

  const clickHandler = async (selectedVariant, variantQuantity) => {
    if (hasVariants && !selectedVariant && deals.deals?.length !== 1 && enableDrawer) {
      setShowVariantsDrawer(true);
      return;
    }

    // can be async if you want to wait for the response of the server or function
    goToSuccessFromCheckoutLocal.remove(); // When a pending redirection from an external payment is there, remove it
    clearCartBuyNow({ storeId });
    addProductBuyNow({
      item: { currentQuantity: variantQuantity || currentQuantity, ...(selectedVariant || deal) },
      storeId,
    });
    goToCheckout();
  };

  return (
    <>
      <div role="button" tabIndex="0" onClick={() => clickHandler(null)} onKeyDown={onEnter(clickHandler)}>
        {CustomComponent ? (
          <CustomComponent {...props} />
        ) : (
          <button type="button" className={buttonClass}>
            {children}
          </button>
        )}
      </div>
      <ProductVariantsDrawer
        deals={deals}
        show={showVariantsDrawer}
        onClose={() => setShowVariantsDrawer(false)}
        cta={clickHandler}
        ctaText={t('Buy now')}
        desiredQuantity={currentQuantity}
      />
    </>
  );
};

export default withTranslation()(BuyNowButton);
