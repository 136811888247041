/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import styles from './styles.module.sass';
import InfiniteScroll from 'react-infinite-scroll-component';
import Progress from '../../components/Progress';

const PointVouchersList = ({ data=[], loading = false, pagination: p, fetchData}) => {

  const [totalItems, setTotalItems] = useState(0);

  const dataLength = totalItems || 0;
  const hasMoreData = p?.totalPages && (p?.page < p?.totalPages);

  useEffect(() => {
   // eslint-disable-next-line no-unsafe-optional-chaining
   if(p?.page) setTotalItems((p?.pages * p?.limit));
  }, [p]);

  return (
    <>
      {(loading && !data) && <Progress /> }
          
      { (data && data?.length > 0) &&
      <InfiniteScroll
          dataLength={dataLength} 
          next={fetchData} 
          scrollThreshold={0.7}
          hasMore={hasMoreData}
          loader={
            <Progress />
          } 
        >
          {data?.map((row, indexRow) => (
            <div
              className={styles['voucher-list-container__row']}
              key={indexRow}
            >
              {row.map((column, indexColumn) => (
                <div key={indexColumn} className={styles['voucher-list-container__row__cell']}>{column?.value}</div>
              ))}
            </div>
          ))}
      </InfiniteScroll>}
    </>
  );
};

export default PointVouchersList;