import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loginLoading: false,
  loginError: null,
  loginCalled: false,

  createAccountLoading: false,
  createAccountError: null,
  createdAccount: false,

  createGuestAccountLoading: false,
  createGuestAccountError: null,
  createdGuestAccount: false,

  updateAccountLoading: false,
  updateAccountError: null,
  updateAccountCalled: false,

  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordCalled: false,

  confirmationResultLoading: false,
  confirmationResultError: null,
  confirmationResultCalled: false,

  emailVerificationLoading: false,
  emailVerificationError: null,
  emailVerificationCalled: false,

  changePasswordLoading: false,
  changePasswordError: null,
  changedPassword: false,

  uploadProfilePictureLoading: false,
  uploadProfilePictureError: null,
  uploadedProfilePicture: false,

  userMeLoading: false,
  userMeError: null,
  userMeLoaded: false,
  userMe: null,

  userMeUpdate: null,
  userMeUpdateError: null,
  userMeUpdateLoading: false,

  questionsLoading: false,
  questionsError: null,
  questionsLoaded: true,
  questions: null,

  loggedIn: false,
  user: null,
  checkingUser: true,
  emailVerified: false,
  confirmationResult: null,
  firebaseAuthId: null
  
});


export default initialState;
