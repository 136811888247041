import React from "react";
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import WaoIcon from "../WaoIcon";

const defaultType = 'warning';
const defaultIcon = 'warning';
const defaultIconColors = {
  default: '#fff',
  warning: '#fff',
  success: '#fff'
};

const TopBar = ({ message, icon = defaultIcon, iconSize=14, type = defaultType }) => (
    <div className={`${styles['top-bar']} ${styles[type]}`}>
      <span>
        {icon && <WaoIcon size={iconSize} color={defaultIconColors[type] || defaultIconColors.default} icon={icon} />}
        {message}
      </span>
    </div>
  );

export default withTranslation()(TopBar);