import { createActions } from "redux-actions";
// import { ToastContainer, ToastMessage } from "react-toastr";

import {
  getHelpList
} from "../../services/help";

import { GenericError } from "../../utils/errors";

const HelpActions = createActions({
  FETCH_HELP_REQUEST: () => {},
  FETCH_HELP_SUCCESS: (helpList) => (helpList),
  FETCH_HELP_FAILED: (error) => ({ error }),
});

HelpActions.fetchHelpList = (data) => async (dispatch) => {
    try {
      dispatch(HelpActions.fetchHelpRequest());
      const result = await getHelpList(data);
      dispatch(HelpActions.fetchHelpSuccess(result.data));
    } catch (err) {
      dispatch(HelpActions.fetchHelpFailed({ error: new GenericError(err, err) }));
    }
  };

export default HelpActions;
