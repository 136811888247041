import Immutable from 'seamless-immutable';

const initialState = Immutable({
  customerLoading: false,
  customerError: null,
  customerLoaded: false,
  customer: null,
  validUserLoading: false,
  validUserError: null,
  validUserLoaded: false,
  validUser: null,
});


export default initialState;
