import { withTheme } from '@mui/styles';
import React from 'react';
import View from './View';

const ScreenTitle = ({ big, theme, title, ignorePadding, style, centered }) => {
  const defaultStyles = {
    display: "flex",
    padding: ignorePadding ? "0px" : "0px 20px 30px"
  };
  const baseStyle = { ...defaultStyles, backgroundColor: theme?.colors?.background, justifyContent: centered ? "center" : "flex-start" };
  return <View style={{ ...baseStyle, ...style }}>
    <h1 style={{ fontWeight: "bold", padding: "0px", fontSize: !big ? "22px" : "26px" }}>{title}</h1>
  </View>;
};

export default withTheme(ScreenTitle);