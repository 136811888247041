import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import HeaderStore from '../../components/HeaderStore';
import styles from '../socio/home/styles.module.sass';
import ownStyles from './index.module.sass';
import mixpanel from '../../services/mixpanel';
import SocioOrderItem from '../../components/SocioOrderItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getOrders } from '../../services/orders';
import { startOfWeek, lastDayOfWeek, format } from 'date-fns';
import OrderLoader from '../../components/OrderLoader/orderLoader';
import OrderActions from '../../redux/orders/actions';
import log from '../../utils/log';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';
import ScreenTitle from '../../transition/ScreenTitle';
import Emoji from '../../components/Emoji';


const getWeekIntervalFromDate = (date) => {
  if (!date) return {};
  const currentDate = new Date(date);

  const startDate = startOfWeek(currentDate);
  const endDate = lastDayOfWeek(currentDate);
  const label = `${format(startDate, 'MMM.d')} - ${format(endDate, 'MMM.d')}`;

  return {
    startDate,
    endDate,
    label,
  };
};

const patchOrdersWithTitles = (orders) => {
  let lastWeekLabel = null;
  const output = [];

  for (const order of orders) {
    const weekInterval = getWeekIntervalFromDate(order.created_at);
    if (lastWeekLabel!== weekInterval.label) {
      output.push({
        type: 'week',
        label: weekInterval.label,
      });
      lastWeekLabel = weekInterval.label;
    }
    output.push({
      type: 'order',
      content: order,
    });

  }

  return output;
};


const OrdersView = ({ t, history }) => {
  const dispatch = useDispatch();

  const [ordersLoading, setOrdersLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rawOrders, setRawOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { orderStatusLabels, orderStatusesLoading } = useSelector(state => state.orders);
  const { userMe } = useSelector(state => state.auth);
  const isReferrer = userMe?.type === 'referrer';
  const pageTitle = isReferrer ? t('My Sales') : t('My Orders');

  const processIncomingData = (data) => {
    const newData = rawOrders.concat(data);
    setRawOrders(newData);
    setOrders(patchOrdersWithTitles(newData));
    setCurrentPage(currentPage + 1);
  };

  const incrementPage = async () => {
    if (!hasMore || ordersLoading) return;
    const { data } = await getOrders({page: currentPage, limit: 20});
    if (data?.data?.length) processIncomingData(data.data);
    else setHasMore(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    setOrdersLoading(true);

    getOrders({page: currentPage, limit: 20})
      .then(res => {
        const data = res.data.data || [];
        processIncomingData(data);
      })
      .catch(err => {
        log.log.error(err);
      })
      .finally(() => {
        setOrdersLoading(false);
      });

    mixpanel.track_optimized('Orders');
    if (!orderStatusLabels && !orderStatusesLoading) dispatch(OrderActions.fetchOrderStatuses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Screen>  
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - {t('Orders')}</title>
        <link rel="canonical" href="https://app.wao.shop/orders" />
      </Helmet>
      <HeaderStore history={history} showLogo={isReferrer} icons={{ back: true }} altColor noShadow />
      <ScreenContent webMobile className={styles.content}>
        <section className={ownStyles['orders-view']}>
          <ScreenTitle title={pageTitle} big ignorePadding />
          <section className={ownStyles['orders-content']}>
            {(!ordersLoading && !orders?.length) ? <div className={ownStyles["no-orders"]}>
              <h3>{t("You don't have sales yet")}</h3>
              <div className="my20 fs80"><Emoji label="shrug" content="🤷‍♀️" /></div>
            </div>
            :""}
            {!orders && (ordersLoading || orderStatusesLoading) ? <OrderLoader amount={3} /> : ""}
            <InfiniteScroll
              dataLength={orders.length}
              next={incrementPage}
              scrollThreshold={0.7}
              hasMore={hasMore}
              loader={ordersLoading ? <OrderLoader amount={1} /> : null}
            >
              {orders.map(order => 
                (order.type === 'week' ? <h4 className={ownStyles['date-header']} key={order.label}>{order.label}</h4> : <SocioOrderItem key={order.content.id} content={order.content} orderStatusLabels={orderStatusLabels} />)
              )}
            </InfiniteScroll>
          </section>
        </section>
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(OrdersView);
