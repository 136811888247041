import _mixpanel from 'mixpanel-browser';
import { mixpanelDebugLocal, mixpanelEnabledLocal } from './local';

let disabled = false;

if (process.env.NODE_ENV) {
  let currentEnv = process.env.NODE_ENV || 'development';
  currentEnv = currentEnv.toLowerCase();
  if (currentEnv.includes('dev') || currentEnv.includes('stg') || currentEnv.includes('stag') || currentEnv.includes('local')) {
    const mixpanelEnabledOverride = !!mixpanelEnabledLocal.get();
    console.log('mixpanelEnabled', mixpanelEnabledOverride)
    if (!mixpanelEnabledOverride) disabled = true;
  }
}

const mixpanelDebugFlag = !!mixpanelDebugLocal.get();
const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
const allowedLocation = "America";
if (!userTz.includes(allowedLocation)) disabled = true;

const mixpanel = _mixpanel;
mixpanel.isDisabled = disabled;

mixpanel.track_optimized = (eventName, properties = {}) => {
  if (!disabled) _mixpanel.track(eventName, properties);
  if (mixpanelDebugFlag) console.log('Mixpanel: ', { eventName, properties })
};

mixpanel.time_event_optimized = (eventName) => {
  if (!disabled) _mixpanel.time_event(eventName);
  if (mixpanelDebugFlag) console.log('Mixpanel: ', { eventName })
};

export default mixpanel;