import { withTheme } from '@mui/styles';
import React from 'react';
import View from './View';

const ScreenContent = ({ theme, style, children, webMobile, ...props }) => {
  const webMobileStyles = {
    maxWidth: "500px",
    width: "100%",
    height: "100%",
    // alignItems: "center",
    font: "Open Sans",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  let defaultStyles = {};
  if (webMobile) defaultStyles = { ...defaultStyles, ...webMobileStyles };
    
  const baseStyle = { ...defaultStyles, backgroundColor: theme?.colors?.background };
  return <View style={{ ...baseStyle, ...style }} {...props}>{children}</View>;
};

export default withTheme(ScreenContent);