import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import AuthActions from "../../redux/auth/actions";
import { restoreCredentials } from "../../services/auth";
import { whatUrlMatch } from "../../utils";
import { usePrevious } from "../../utils/hooks";
import LoadingComponent from "../loading";
import { toast } from 'react-toastify';
// import log from '../../utils/log';

let logoutTimeout;

const WaoSessionManager = ({ urls=[], children } ) => {

  const [restored, setRestored] = useState(null);
  const [authenticated, setAuthenticated] = useState(null);
  const prevAuthenticated = usePrevious(authenticated);
  const dispatch = useDispatch();
  const { userMe, userMeError } = useSelector(state => state.auth);
  const location = useLocation();
  const history = useHistory();
  const match = whatUrlMatch(urls, location);


  useEffect(() => {
    setRestored(restoreCredentials());
  }, []);

  useEffect(() => {
    if (!userMe && userMeError && !logoutTimeout) {
      if (userMeError.error?.status === 404) {
        toast.error('No es posible encontrar este usuario, cerrando sesión...', {autoClose: 5000});
        logoutTimeout = setTimeout(() => {
          dispatch(AuthActions.logout(true));
        }, 5500);
      }
    }
  }, [dispatch, userMe, userMeError]);

  useEffect(() => {
    if (restored && !userMe) dispatch(AuthActions.fetchUserMe());
    if (restored !== null && !restored && !userMe) {
      setAuthenticated(false);
    } else if (restored && userMe) {
      setAuthenticated(true);
    }
  }, [restored, dispatch, userMe]);

  useEffect(() => {
    if (authenticated === false && prevAuthenticated !== false && match?.guard) {
      history.replace(`/sign-in?nextRoute=${location.pathname}${location.search}`);
    }
  }, [authenticated, prevAuthenticated, history, match, location, dispatch]);

  if (authenticated !== null) {
    if (children) {
      return children;
    }

    return null;
  } 

  return <LoadingComponent isLoading />;
};

export default WaoSessionManager;
