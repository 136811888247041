import React, { useContext } from 'react';
import { ShopingCartContext } from '../../contexts/shoppingCart/ShopingCartContext';
import WaoIcon from "../../components/WaoIcon";
import PriceDiscountFormat from '../../components/PriceDiscountFormat';
import styles from './styles.module.sass';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { filterMedia } from '../../utils';

const CartItem = ({ product }) => {
  const newProduct = product;
  const price = newProduct.promotion?.priceWithDiscount || newProduct.price;
  const { increase, decrease, removeProduct } = useContext(ShopingCartContext);
  const { storeId } = useParams();
  const getMedia = (productObj) => {
      const firstPhoto = filterMedia(productObj.media, 'product', 'zoho')?.[0] || "https://dummyimage.com/600x400/f1f1f1/333";
      return firstPhoto;
    };
   if (!Object.prototype.hasOwnProperty.call(newProduct, 'currentQuantity')) newProduct.currentQuantity = 1;

  return (
    <section className={styles['item-added']}>
      <figure className={styles['item-preview']}>
        <img src={getMedia(newProduct)} alt=""/>
      </figure>
      <div className={styles['item-data']}>
        <b className={`${styles['item-name']} word-break`}>{newProduct.name}</b>
        <div className={styles['item-options']}>
          <div className={styles.__price}>
            <span>
              <PriceDiscountFormat suffix value={price} calculateDiscount={false} currency={newProduct.currency} />
            </span>
          </div>
          <div className={styles.__qty}>
            <button onClick={() => decrease({item: newProduct, storeId})} className="btn btn-danger btn-sm mb-1">
              <WaoIcon color="#040415" size={12} icon="arrow_down" />
            </button>
            <span>{newProduct.currentQuantity}</span>
            <button onClick={() => increase({item: newProduct, storeId})} className="btn btn-primary btn-sm mr-2 mb-1">
              <WaoIcon color="#040415" size={10} icon="arrow_up" />
            </button>
          </div>
          <div className={styles.__delete}>
            <button onClick={() => removeProduct({item: newProduct, storeId})}>
              <figure>
                <WaoIcon color="#fff" size={12} icon="delete-" />
              </figure>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CartItem;