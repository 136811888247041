import { Box, Chip, Divider, Grid } from "@mui/material";
import { assoc, dissoc, map, path, prop } from "ramda";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DealActions from "../../redux/deals/actions";
import WaoIcon from "../WaoIcon";
import styles from './styles.module.sass';
import Emoji from '../Emoji';

const FiterByCategoryPanel = (
  {
    t,
    filters,
    setFilters,
    visible = false,
    setVisible,
    categories,

  }
) => {

  const theRef = useRef();
  const dispatch = useDispatch();
  const height = theRef?.current?.clientHeight ? theRef.current.clientHeight : 0;
  const [filtersInternal, setFiltersInternal] = useState(filters);
  const chipStyle = (active) => ({
    height: 24,
    borderRadius: 12,
    border: '1px solid #dcdcdc',
    marginRight: 10,
    marginBottom: 10,
    background: active ? '#030029' : 'transparent',
    color: active ? '#ffffff' : '#555555',
    fontSize: 11,
    letterSpacing: '-0.37px',
    fontFamily: 'Open Sans'
  });
  const onClickCategory = (c) => () => {
    let result;
    if (prop(c, filtersInternal)) result = dissoc(c, filtersInternal);
    else result = assoc(c, c, filtersInternal);
    dispatch(DealActions.setFilters(result));
    setFiltersInternal(result);
  };
  const close = () => setVisible && setVisible(false);
  const applyFilters = () => {
    if (setFilters) setFilters(filtersInternal);
    if (setVisible) setVisible(false);
  };

  useEffect(() => {
    setFiltersInternal(filters || {});
  }, [filters]);

  return (
    <div
      ref={theRef}
      className={styles.filters}
      style={{bottom: visible ? `${height !== 0 ? '-45px': `${height*-1}`}` : `-800px`}}>
      <div className={styles['filters-header']}>
        <Box />
        <div className={styles['title-cnt']}>{t('Filters')}</div>
        <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={close}>
          <div className={styles['close-cnt']}>
            <WaoIcon color="#000000" size={14} icon="close-" />
          </div>
        </div>
      </div>
      <Divider />
      <div className={styles['filters-body']}>
        <h2>
          {t('Filter by category')}
        </h2>
        <Emoji>
        <Grid container>
          {categories && map(x =>
          (
            <Chip
              key={`category${prop('category', x)}`}
              label={`${path(['metadata', 'emoji'], x)} ${prop('category', x)}`}
              variant="outlined"
              style={chipStyle(filtersInternal && filtersInternal[prop('category', x)])}
              onClick={onClickCategory(prop('category', x))}
            />
            ) , categories)}
          </Grid>
        </Emoji>
        {/* <Box height={'1px'} bgcolor={'#979797'} mb='10px' /> */}
        <button className="btn btn-round" style={{marginBottom: '11px'}} onClick={applyFilters}>
          {t('ApplyFilters')}
        </button>
      </div>
    </div>
  );
};

export default FiterByCategoryPanel;
