import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PointsSelectionPanel from './PointsSelectionPanel';
import styles from './styles.module.sass';


const PointsSection = ({ t, data, pointsApplied, setPointsApplied, orderTotal, dataFetchPaymentMethod }) => {
  const { rate, currency } = data;
  const userAvailablePoints = Number.parseInt(data.available, 10);
  const orderDealsPointsLimit = Number.parseInt(data?.pointsLimit, 10);
  const pointsToBuyOrder = Math.floor((orderTotal / rate) + pointsApplied);
  // TODO We want to enable this once the backend allows losing 1 point to pay the full order
  // const pointsToBuyOrder = Math.ceil((orderTotal / rate) + pointsApplied);
  const pointsToUseLimit = Math.min(orderDealsPointsLimit, userAvailablePoints, pointsToBuyOrder);

  const [showPointsPanel, setShowPointsPanel] = useState(false);
  const credit = pointsToUseLimit * rate;

  const onClose = (pointsToApply) => {
    if (typeof pointsToApply !== 'undefined' && pointsToApply === parseInt(pointsToApply, 10)) setPointsApplied(pointsToApply);
  };

  return (
    <>
      <div className={styles.points} >
        <div className={styles.pointsAvailable}>
          <div className={styles.pointsTitle}>{t('Available points')}</div>
          {/* <div className={styles.pointsValue}>{`${pointsToUseLimit} ${realPointsMessage}`}</div> */}
          <div className={styles.pointsValue}>{userAvailablePoints - pointsApplied}</div>
        </div>
        <div>
          <button disabled={!userAvailablePoints || userAvailablePoints < 1} type='button' className={styles.pointsButton} onClick={() => { setShowPointsPanel(true); }}>{t('Use credit')}</button>
        </div>
      </div>
      <PointsSelectionPanel
        redeemPoints={data?.redeemPoints}
        visible={showPointsPanel} setVisible={setShowPointsPanel} points={userAvailablePoints} limit={pointsToUseLimit}
        credit={credit} currency={currency} rate={rate} onClose={onClose} pointsUsed={pointsApplied}
        dataFetchPaymentMethod={dataFetchPaymentMethod}
      />
    </>
  );
};

export default withTranslation()(PointsSection);