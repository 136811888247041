import { withTheme } from '@mui/styles';
import React from 'react';
import View from './View';

const Screen = ({ theme, style, children, className }) => {
  const defaultStyles = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  // if (webMobile) defaultStyles = { ...defaultStyles, ...webMobileStyles };
    
  const baseStyle = { ...defaultStyles, backgroundColor: theme?.colors?.background };
  return <View className={className} style={{ ...baseStyle, ...style }}>{children}</View>;
};

export default withTheme(Screen);