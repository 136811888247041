/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import registerStyles from '../../pages/registration/styles.module.sass';
import pointsStyles from '../../pages/points/styles.module.sass';
import log from '../../utils/log';
import { sentOnboarding } from '../../services/auth';
import Button from '../../transition/Button';
import { useRequest } from '../../utils/hooks';
import Icon from '../Icon';
import mixpanel from '../../services/mixpanel';
import { toast } from 'react-toastify';
import { Step, Stepper, Box, StepLabel } from '@mui/material';
import { styled } from '@mui/styles';
// import Check from '@mui/icons-material/Check';
import ScreenContent from '../../transition/ScreenContent';
import View from '../../transition/View';
import loadingImage from '../../assets/loadingPage/loadingPageImage@3x.png';
import loadingPageBackgroung from '../../assets/loadingPage/loadingPageBackgroung@3x.png';
import onboardingResultImage1 from '../../assets/onboardingResultPage/onboardingResultImage1@3x.png';
import onboardingResultImage1Pinata from '../../assets/onboardingResultPage/onboardingResultImage1Pinata@3x.png';
import onboardingResultImage2 from '../../assets/onboardingResultPage/onboardingResultImage2@3x.png';
import onboardingResultPageBackground from '../../assets/onboardingResultPage/onboardingResultBackground@3x.png';
import IcomoonReact from 'icomoon-react';
import iconSet from "../../assets/fonts/icomoon/selection.json";
import Text from '../../transition/Text';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Emoji from '../Emoji';
import { useDispatch } from 'react-redux';
import AuthActions from '../../redux/auth/actions';
import WaoIcon from '../WaoIcon';

const QuizMaker = ({ questions, history, user, userMe, t, isSubmitting, setIsSubmitting, currentQuestionNumber, setCurrentQuestionNumber, previousChangeQuestionNumber, setPreviousChangeQuestionNumber, selectedAnswers, setSelectedAnswers, activeStep, setActiveStep, completed, setCompleted, isOnboardingResult, setIsOnboardingResult, skipQuestions, setSkipQuestions}) => {

  const [currentQuestionContent, setCurrentQuestionContent] = useState(questions[currentQuestionNumber] || null);
  const [onboardingDataResult, setOnboardingDataResult] = useState(null);

  const dispatch = useDispatch();

  const globalStyles = useGlobalStyles();

  const { /* loading: customerLoading,  data: customer, */ error: onboardingError, run: sentOnboardingRun } = useRequest(sentOnboarding);

  const steps = questions;

  // const totalSteps = () => steps?.length;

  // const isLastStep = () => activeStep === totalSteps() - 1;
  // const isStartStep = () => activeStep === 0;

  const handleComplete = (decision = true) => {
    const newCompleted = completed;
    newCompleted[activeStep] = decision;
    setCompleted(newCompleted);
  };
  
  // const handleNext = useCallback(() => {
  //   const newActiveStep =
  //     isLastStep()
  //       ? // It's the last step, but not all steps have been completed,
  //         // find the first step that has been completed
  //         steps.findIndex((step, i) => !(i in completed))
  //       : (currentQuestionNumber + 1) || (activeStep + 1);
  //   setActiveStep(newActiveStep);
  // },[activeStep, completed, currentQuestionNumber, isLastStep, setActiveStep, steps]);
 
  // const handleBack = useCallback(() => {
  //   setActiveStep((currentQuestionNumber - 1) || ((prevActiveStep) => prevActiveStep - 1));
  // },[currentQuestionNumber, setActiveStep]);
  
  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };

  const stepperStyle = useMemo(()=>({
    '& .MuiStepConnector-line': {
      borderColor: '#E54A38',
      borderTopWidth: '4px'
    }
  }),[])

  const StepIconRoot = styled('div')(({ ownerState }) => ({
    backgroundColor:  '#FFF',
    zIndex: 1,
    color: '#E54A38',
    width: 26,
    height: 26,
    fontSize: 12,
    position: 'absolute',
    left: '25%',
    top: '50%',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
      'linear-gradient(243.31deg, #EE7340 3.25%, #E54A38 91.24%)',
      color: '#FFF',
    }),
    ...(ownerState.completed && {
      backgroundImage:
      'linear-gradient(243.31deg, #EE7340 3.25%, #E54A38 91.24%)',
    }),
  }));

  const StepIcon = useCallback((props) => {
    const { active, completed, className, icon } = props;
  
    return (
      <StepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? (
          <WaoIcon icon="checkmark" color='#FFF' size={16} />
        ) : (
          <div>{icon}</div>
        )}
      </StepIconRoot>
    );
  },[]);

  const HorizontalLabelPositionBelowStepper = useCallback(() => (
      <Box sx={{ width: '100%', marginBottom:4 }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={stepperStyle}>
          {steps.map((label, index) => (
            <Step key={`${label}${index}`} completed={activeStep=== index ? false : completed[index]}>
              <StepLabel StepIconComponent={StepIcon} index={index}/>
            </Step>
          ))}
        </Stepper>
      </Box>
  ), [StepIcon, activeStep, completed, stepperStyle, steps]);

  const nextQuestionNumber = useMemo(() => {
    let result = null;
    const currentQuestionsKeyword = questions[currentQuestionNumber]?.keyword;
    if (currentQuestionNumber >= questions.length - 1) return result;
    if (selectedAnswers && selectedAnswers?.[currentQuestionsKeyword]) {
      // eslint-disable-next-line guard-for-in
      for (const answerId in selectedAnswers[currentQuestionsKeyword]) {
        if (selectedAnswers[currentQuestionsKeyword][answerId]?.value && selectedAnswers[currentQuestionsKeyword][answerId]?.skipTo) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          result = selectedAnswers[currentQuestionsKeyword][answerId]?.skipTo - 1;
          setPreviousChangeQuestionNumber({previousNumber: currentQuestionNumber, skipToquestionNumber: result, value: true})
          setSkipQuestions({currentQuestionNumber, skipToquestionNumber: result})
          break;
        }else if(previousChangeQuestionNumber?.skipToquestionNumber < currentQuestionNumber){
          setPreviousChangeQuestionNumber({...previousChangeQuestionNumber, value: false, positionUp:true})
        }else {
          setPreviousChangeQuestionNumber({...previousChangeQuestionNumber, value: false, positionUP:false})
        }
      }
    }
    result = result || currentQuestionNumber + 1;
    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionNumber, questions, selectedAnswers, setPreviousChangeQuestionNumber]);

  const isCurrentAnswerRequired = useMemo(()=>(!!currentQuestionContent.required),[currentQuestionContent.required]);
  const canGoNext = useMemo(() => {
    if (!isCurrentAnswerRequired) return true;
    let result = false;
    if(selectedAnswers[currentQuestionContent?.keyword]){
      for (const answerId in selectedAnswers[currentQuestionContent?.keyword]) {
        if (selectedAnswers[currentQuestionContent?.keyword][answerId]?.value) {
          result = true;
          break;
        }
      }
    }
    return result;
  }, [currentQuestionContent?.keyword, isCurrentAnswerRequired, selectedAnswers]);
  
  const previousQuestionNumber = useMemo(() => {
    const validateSkip1 = previousChangeQuestionNumber && previousChangeQuestionNumber?.skipToquestionNumber === currentQuestionNumber && previousChangeQuestionNumber?.value;
    const validateSkip2 = previousChangeQuestionNumber && previousChangeQuestionNumber?.skipToquestionNumber === currentQuestionNumber && !previousChangeQuestionNumber?.value && previousChangeQuestionNumber?.positionUp;
    let result = currentQuestionNumber === 0 ? currentQuestionNumber : currentQuestionNumber - 1;
    if(validateSkip1 || validateSkip2){
      result = previousChangeQuestionNumber?.previousNumber;
    }
    return result;
  }, [currentQuestionNumber, previousChangeQuestionNumber]);

  useEffect(() => {
    setCurrentQuestionContent({ index: currentQuestionNumber, ...questions[currentQuestionNumber]});
  }, [currentQuestionNumber, questions]);
  const toggleAnswer = useCallback((answer) => {
    const {questionId, keyword, id } = answer;
    const newAnswers = { ...selectedAnswers };
    const { type: questionType } = questions?.[questionId] || {};

    if (questionType === 'single') {
      newAnswers[keyword] = [{ ...answer, value: (!newAnswers?.[keyword]?.some(a => a?.id === id && a?.value)) } ];
    } else if (questionType === 'multiple') {

      const answerExists = newAnswers[keyword]?.some(a => a?.id === id);
      let setAnswerArray = null;
      let setAnswerMap = null;
      if(newAnswers[keyword]){
        setAnswerArray = [...newAnswers[keyword], { ...answer, value: (!newAnswers?.[keyword]?.some(a => a?.id === id && a?.value)) }];
        setAnswerMap = newAnswers[keyword]?.map(a => {
          if(a?.id === id){ 
              a.value = !a?.value
            }

            return a;
        }).filter(a => a.value);
      }
      const setNewAnswers = answerExists ? setAnswerMap : setAnswerArray;
      newAnswers[keyword] = setNewAnswers || [{ ...answer, value: (!newAnswers?.[keyword]?.some(a => a?.id === id && a?.value)) } ];
    }
    setSelectedAnswers(newAnswers);
  }, [questions, selectedAnswers, setSelectedAnswers]);

  const singleColumnStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%"
  };

  const doubleColumnStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: "16px",
    width: "100%"
  };

  const currentQuestion = questions[currentQuestionNumber];
  const isSinglecolumn = !currentQuestion?.doubleColumn;
  const hasBack = currentQuestionNumber > 0;
  const hasNext = currentQuestionNumber < questions.length - 1;

  const itemStyles = { borderRadius: "8px" };
  if (!isSinglecolumn) itemStyles.width = 'calc(50% - 8px)';

  const renderAnswer = (answer = {}) => {
    if (!answer || answer.id === undefined) return null;
    const { title, id, keyword } = answer;
    const isSelected = selectedAnswers?.[keyword]?.some(a => a?.id === id && a?.value);
    return <Button color={isSelected ? "waoOrange" : "richBlack"} style={itemStyles} key={title + id} mode={isSelected ? "contained" : "outlined"}
      onClick={() => { toggleAnswer({ ...answer }) }}
    >{title}</Button>
  };

  const goToSignIn = useCallback(() => {
    dispatch(AuthActions.fetchUserMe());
    mixpanel.track_optimized('Goto Sign In');
    mixpanel.track_optimized('Onboarding Success');
    history?.replace({
      pathname: '/sign-in',
      search: '?new=1&nextRoute=/home?welcome=1',
    });
    setIsOnboardingResult(false);
  },[dispatch, history, setIsOnboardingResult]);
  
  const removeUserStorage = () => localStorage.removeItem('user');

  const formOnSubmit = useCallback(async (values) => {

    const dataAnswers = values;

    if(skipQuestions){
      for (const answerId in dataAnswers) {
        if(dataAnswers[answerId][0]?.questionId > skipQuestions?.currentQuestionNumber && dataAnswers[answerId][0]?.questionId < skipQuestions?.skipToquestionNumber){
          delete dataAnswers[answerId]
        }
      }
    }

    for (const answer in dataAnswers) {
      if (dataAnswers[answer]) {
        dataAnswers[answer] = dataAnswers[answer]?.map(answer => ({id: answer?.id, title: answer?.title}))
      }
    }

    const payload = {
      userId: `${user?.id || userMe?.id}`,
      ...dataAnswers
    };

    const errorNext = () => {
      history?.replace({
        pathname: '/sign-in',
        search: '?new=1&nextRoute=/home?welcome=1',
      });
    }
    
    setIsSubmitting(true);

    const onboarding = await sentOnboardingRun(payload);
    
    if (!onboardingError && onboarding?.ok) {
      if (onboarding?.data?.data && !onboarding?.data?.error) {
        log.debug('success', onboarding?.data?.data);
        if(onboarding?.data?.data)setOnboardingDataResult(onboarding?.data?.data)
        setTimeout(() => {
          setIsSubmitting(false);
          setIsOnboardingResult(true);
          removeUserStorage();
        }, 3000);
      } else if (onboarding?.data?.error) {
        log.debug('Onboarding.error detected', onboarding?.data?.error);
        toast.dismiss();
        if (onboarding?.data?.message) toast.error(onboarding?.data?.message || t('LoginProcessFailed'));
        setTimeout(() => {
          setIsSubmitting(false);
          errorNext();
          removeUserStorage();
        }, 3000);
      }
    }else if (onboarding?.data?.error || onboarding?.originalError || onboardingError) {
      toast.dismiss();
      log.debug('onboarding error detected', onboarding?.data?.error || onboarding?.originalError?.message || onboardingError);
      const err = onboarding?.data?.message || onboarding?.originalError?.message || onboardingError || t('LoginProcessFailed');
      toast.error(err);
      setTimeout(() => {
        setIsSubmitting(false);
        errorNext();
        removeUserStorage();
      }, 3000);
    }

  },[history, onboardingError, sentOnboardingRun, setIsOnboardingResult, setIsSubmitting, skipQuestions, t, user?.id, userMe?.id]);

  const pagesStyles = useMemo(()=>({
    maxWidth: "500px",
    width: "100%",
    height: "100%",
    // alignItems: "center",
    font: "Open Sans",
    display: "flex",
    flexDirection: "column",
  }),[]);

  const LoadingonboardingPage = useCallback(() => (
    <ScreenContent style={pagesStyles} className={registerStyles.landingPage}>
      <View style={{ backgroundImage: `url('${loadingPageBackgroung}')`, backgroundSize: "cover", backgroundRepeat: "round"}}>
          <View className={registerStyles.logo}>
        <IcomoonReact color="#000" iconSet={iconSet} icon="logo" size={100} />
        </View>
        <View className={registerStyles.loadingImage}>
            <img src={loadingImage}/>
        </View>
      </View>
      <View style={{ padding: "0 30px", zIndex: 100000 }}>
        <Text style={{ marginTop: "34px", fontSize: "24px", fontWwight: "700", color: "#030029", textAlign: "center" }}>{t("We are analizing your answers")}</Text>
        <Text style={{ marginTop: "105px", fontSize: "16px", color: "#030029", textAlign: "center" }}>{t("Don´t close or reload this page")}</Text>
      </View>
    </ScreenContent>
  ),[pagesStyles, t])

  const OnboardingResultPage = useCallback(({points}) => {
    const resulPointsHigh = points >= 70
    const resulPointsMedium = points >= 40 && points <= 69
    const resulPointsLow = points >= 0 && points <= 39


    const resultInfo1 = <>
      <Text style={{ marginTop: "32px", fontSize: "28px", fontWwight: "600", color: "#030029", textAlign: "center" }}>{t("Congratulations")}</Text>
      <Text style={{ marginTop: "32px", fontSize: "18px", fontWwight: "700", color: "#030029", textAlign: "center" }}>{t("resultInfo1-1-1")}<span style={{fontWeight:"700", color:"#EE5340"}}>1%</span>{t("resultInfo1-1-2")}</Text>
      <Text style={{ marginTop: "18px", fontSize: "18px", color: "#030029", textAlign: "center" }}>{t("resultInfo1-2")}</Text>
    </>
    const resultInfo2 = <>
      <Text style={{ marginTop: "32px", fontSize: "28px", fontWwight: "600", color: "#030029", textAlign: "center" }}>{t("Congratulations")}</Text>
      <Text style={{ marginTop: "32px", fontSize: "18px", fontWwight: "700", color: "#030029", textAlign: "center" }}>{t("resultInfo2-1-1")}<span style={{fontWeight:"700", color:"#000"}}>25%</span>{t("resultInfo2-1-2")}</Text>
      <Text style={{ marginTop: "18px", fontSize: "18px", color: "#030029", textAlign: "center" }}>{t("resultInfo2-2")}</Text>
    </>
    const resultInfo3 = <>
      <Text style={{ marginTop: "32px", fontSize: "28px", fontWwight: "600", color: "#030029", textAlign: "center" }}>{t("Congratulations")}</Text>
      <Text style={{ marginTop: "65px", fontSize: "18px", fontWwight: "700", color: "#030029", textAlign: "center" }}>{t("resultInfo3")}</Text>
    </>
    
    return (
    <ScreenContent style={pagesStyles} className={registerStyles.landingPage}>
      <View style={{ backgroundImage: `url('${onboardingResultPageBackground}')`, backgroundSize: "cover", backgroundRepeat: "round"}}>
        {resulPointsHigh && <View className={registerStyles.onboardingResultImage1Pinata}>
            <img src={onboardingResultImage1Pinata}/>
        </View>}
        {(resulPointsMedium || resulPointsLow) && <View className={registerStyles.logo}>
        <IcomoonReact color="#000" iconSet={iconSet} icon="logo" size={100} />
        </View>}
        {resulPointsHigh && <View className={registerStyles.onboardingResultImage}>
            <img src={onboardingResultImage1}/>
        </View>}
        {(resulPointsMedium || resulPointsLow) && <View className={registerStyles.onboardingResultImage2}>
            <img src={onboardingResultImage2}/>
        </View>}
      </View>
      <View style={{ padding: "0 30px", zIndex: 100000 }}>

        {(resulPointsHigh) && resultInfo1}
        {(resulPointsMedium) && resultInfo2}
        {(resulPointsLow) && resultInfo3}

        {!points && points !== 0 ? <div className={pointsStyles["no-points"]}>
            <h3>{t("Error")}</h3>
            <div className="my20 fs80"><Emoji label="shrug" content="🤷‍♀️" /></div>
          </div>
        :"" }

        <View style={{ padding: "36px 0" }}>
            <View style={{ ...globalStyles?.row, ...globalStyles?.center, marginTop: "14px" }}>
            {points && <Button 
                style={{ width: "Hug (119px)", height: "42px", padding: "8px, 20px, 8px, 20px", fontSize: "16px", fontWeight: 600, borderRadius: "12px", color: "#FFF", background: "#030029", boxShadow: "0px 2px 10px rgba(4, 4, 21, 0.5)" }}
                onPress={goToSignIn}
                >
                  <Text>{t('Start')}</Text>
              </Button>}
            </View>
          </View>
      </View>
    </ScreenContent>
  )},[globalStyles?.center, globalStyles?.row, goToSignIn, pagesStyles, t])

  if (!questions.length) return '';
  if (isSubmitting) return <LoadingonboardingPage/>;
  if (isOnboardingResult) return <OnboardingResultPage points={onboardingDataResult?.points}/>;
 
  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", gap: "24px" }}>
      <HorizontalLabelPositionBelowStepper/>
      <div style={{ background: "#FFF", borderRadius: 20, padding: "20px", display: "flex", flexDirection: "column", width: "100%", alignItems: "center", gap: "24px", minHeight: "500px", justifyContent: "space-between" }}>
        <div key={currentQuestionNumber} style={{ display: "flex", flexDirection: "column", gap: currentQuestionContent?.description ? "10px" : "24px", width: "100%" }}>
          <div style={{ fontWeight: 600, fontSize: "18px" }}>
            {currentQuestionContent?.title}
          </div>

          {currentQuestionContent?.description ? <Text style={{ paddingTop: "8px", fontSize: "12px", color: "#979797" }}>{currentQuestionContent?.description}</Text> : ''}

          <div style={isSinglecolumn ? singleColumnStyle : doubleColumnStyle}>
            {currentQuestionContent?.answers?.map((answer) => renderAnswer({ questionId: currentQuestionNumber, keyword: questions[currentQuestionNumber]?.keyword, ...answer }))}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          {hasBack ? <Button style={{ borderRadius: "8px", color: "#030029" }}
            onClick={() => {
              setCurrentQuestionNumber(previousQuestionNumber)
              setActiveStep(previousQuestionNumber)
              // handleBack();
              handleComplete(false)
            }}>
            <Icon icon="arrow_left-7" size="12" /> Atrás
          </Button> : <div />}
          {hasNext ? <Button color="richBlack" style={{ borderRadius: "8px" }} mode="contained"
            disabled={!canGoNext}
            onClick={() => {
              setCurrentQuestionNumber(nextQuestionNumber)
              setActiveStep(nextQuestionNumber)
              // handleNext()
              handleComplete()
            }}>
            Siguiente <Icon icon="arrow_right-6" size="12" color={canGoNext ? "#FFF" : "#00000042"} />
          </Button> : ''}
          {!hasNext? <Button color="waoOrange" style={{ borderRadius: "8px" }} mode="contained"
            disabled={!canGoNext}
            onClick={() => formOnSubmit(selectedAnswers)}>
            Continuar <Icon icon="arrow_right-6" size="12" color={canGoNext ? "#FFF" : "#00000042"} />
          </Button> : ''}
        </div>
      </div>
    </div>
    
  );
};

export default QuizMaker;