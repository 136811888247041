import Immutable from 'seamless-immutable';

const initialState = Immutable({
  commissionsLoading: false,
  commissions: [],
  commissionsLoaded: false,
  commissionsError: null,
  rewardsLoading: false,
  rewards: [],
  rewardsLoaded: false,
  rewardsError: null,
  commissionsTotalLoading: false,
  commissionsTotal: null,
  commissionsTotalLoaded: false,
  commissionsTotalError: null,
  commissionsPagination: null
});


export default initialState;
