import React, { useCallback, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeaderStore from '../../components/HeaderStore';
import AuthActions from '../../redux/auth/actions';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';
import ScreenTitle from '../../transition/ScreenTitle';
import SettingsSections from './SettingsSections';

const SettingsContainer = ({ history, t }) => {

  const dispatch = useDispatch();
  const { userMe, userMeLoaded, userMeLoading, userMeUpdateLoading } = useSelector(state => state.auth);

  useEffect(() => {
    if (!userMeLoaded && !userMeLoading) dispatch(AuthActions.fetchUserMe());
  }, [dispatch, userMeLoaded, userMeLoading]);

  const toggleNotifications = useCallback((value) => {
    dispatch(AuthActions.updateUserMe({userId: userMe?.id, sendNotification: value }));
  }, [dispatch, userMe]);

  const buttons = [
    {
      label: t('pages.settings.notifications.whatsapp.title'),
      description: t('pages.settings.notifications.whatsapp.description'),
      loading: userMeUpdateLoading,
      updateFunction: toggleNotifications,
      value: userMe?.sendNotification,
      type: 'toggle'
    }
  ];

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - {t('Settings')}</title>
        <link rel="canonical" href="https://app.wao.shop/settings" />
      </Helmet>
      <HeaderStore history={history} showLogo icons={{ back: true }} altColor />
      <ScreenContent webMobile>
        <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
          <ScreenTitle title={t('Settings')} ignorePadding />
          <div>
            <div style={{ padding: "0 20px", marginTop: "30px" }}>
              <SettingsSections data={buttons} user={userMe} loading={userMeLoading} />
            </div>
          </div>
        </div>
    </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(SettingsContainer);
