import { getAvailableCountries } from '../utils/countries';
import api from './api';

export const getUserMeta = () => api.get(`/users/public/meta-data`);

const currentCountries = getAvailableCountries();
const countries = currentCountries.map(country => ({ name: country.title, code: country.code }));

export const getCountries = () => new Promise(resolve => {
  resolve({
    "ok": true, "data": {
      "data": countries
    }
  });
});