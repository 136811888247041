import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { browserSupportsSharing, onEnter } from '../../utils';
import { AppContext } from '../../contexts/AppContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import log from '../../utils/log';
import Progress from '../../components/Progress';
import WaoIcon from '../../components/WaoIcon';



const enableFacebookMessenger = false;


const PointVoucherSuccess = (({ t, visible, /* setVisible, */ setVisibleDrawer, info, isLoading = false, onShareClick = () => { }, onShareClose = () => { } }) => {
  
  const usableAppContext = useContext(AppContext);
  const { notify } = usableAppContext;
  const canCopy = true;
  const [canShare,] = useState(browserSupportsSharing());
  const drawerInfo = { ...info };
  // const closeHandler = () => { setVisible(false) };
  const openHandler = useCallback(() => {
    if (!info.url) drawerInfo.url = document.location.href;
    if (!info.title) drawerInfo.title = document.title;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, drawerInfo.url, drawerInfo.title]);
  const nativeShareClickHandler = async () => {
    onShareClick('native', drawerInfo);
    navigator.share(drawerInfo)
      .then(() => {
        log.log('Successfully shared');
        onShareClose('native', drawerInfo);
        // closeHandler();
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          toast.error('No fue posible compartir la información');
          log.error('Something went wrong sharing', error);
          // closeHandler();
        }
      });
  };

  const facebookShareClickHandler = () => { onShareClick('facebook', drawerInfo); };
  const facebookShareCloseHandler = () => { onShareClose('facebook', drawerInfo); };
  const facebooMsnShareClickHandler = () => { onShareClick('facebook-messenger', drawerInfo); };
  const facebooMsnShareCloseHandler = () => { onShareClose('facebook-messenger', drawerInfo); };
  const whatsappShareClickHandler = () => { onShareClick('whatsapp', drawerInfo); };
  const whatsappShareCloseHandler = () => { onShareClose('whatsapp', drawerInfo); };
  const twitterShareClickHandler = () => { onShareClick('twitter', drawerInfo); };
  const twitterShareCloseHandler = () => { onShareClose('twitter', drawerInfo); };
  const telegramShareClickHandler = () => { onShareClick('telegram', drawerInfo); };
  const telegramShareCloseHandler = () => { onShareClose('telegram', drawerInfo); };

  const copyText = `${drawerInfo.copyText} ${drawerInfo.url}`;

  const copySuccessHandler = () => {
    // onShareClick('copy', copyText);
    onShareClose('copy', copyText);
    // closeHandler();
    notify({ message: t('URL copied to clipboard!'), background: '#000', color: '#fff', duration: 1500 });
  };

  useEffect(() => {
    if (visible) openHandler();
  }, [visible, openHandler]);

  return <div className={styles.shareSuccessContainer}>
    <section className={styles.shareSuccessSection}>
      <h3 className="mt12 mb32">{t("pages.succesMessageSharePoints.title")}</h3>
      {/* <div className="fw600">{t("pages.succesMessageSharePoints.description1")}</div> */}
      <div className="mt12 mb32">{t("pages.succesMessageSharePoints.description2")}</div>
      <div className="mt12 mb32">{t("pages.succesMessageSharePoints.description3")}</div>
      <div className="flex column g24 ">
      {isLoading ? <Progress /> : (
          <div className={`${styles['share-buttons']} ${(((canCopy || canShare) && enableFacebookMessenger) ? (styles.flex) : '')} mb32`}>
            <div>
              <FacebookShareButton beforeOnClick={facebookShareClickHandler} onShareWindowClose={facebookShareCloseHandler} url={drawerInfo.url} title={drawerInfo.title}>
                <FacebookIcon size={42} />
              </FacebookShareButton>
            </div>
            {enableFacebookMessenger && (
              <div>
                <FacebookMessengerShareButton beforeOnClick={facebooMsnShareClickHandler} onShareWindowClose={facebooMsnShareCloseHandler} appId="3902581913189085" redirectUri={drawerInfo.url} url={drawerInfo.url} title={drawerInfo.title}>
                  <FacebookMessengerIcon size={42} />
                </FacebookMessengerShareButton>
              </div>
            )}
            <div>
              <WhatsappShareButton beforeOnClick={whatsappShareClickHandler} onShareWindowClose={whatsappShareCloseHandler} url={drawerInfo.url} title={drawerInfo.title}>
                <WhatsappIcon size={42} />
              </WhatsappShareButton>
            </div>
            <div>
              <TwitterShareButton beforeOnClick={twitterShareClickHandler} onShareWindowClose={twitterShareCloseHandler} url={drawerInfo.url} title={drawerInfo.title}>
                <TwitterIcon size={42} />
              </TwitterShareButton>
            </div>
            <div>
              <TelegramShareButton beforeOnClick={telegramShareClickHandler} onShareWindowClose={telegramShareCloseHandler} url={drawerInfo.url} title={drawerInfo.title}>
                <TelegramIcon size={42} />
              </TelegramShareButton>
            </div>
            {
              canCopy && <CopyToClipboard text={copyText} onCopy={copySuccessHandler}>
                <div className={`${styles['social-button']} ${styles.copy}`}>
                  <WaoIcon icon="link" size="34" color="#fff" />
                </div>
              </CopyToClipboard>
            }
            {
              canShare &&
              <div>
                <div role="button" tabIndex="0" className={`${styles['social-button']} ${styles.share}`} onClick={nativeShareClickHandler} onKeyDown={onEnter(nativeShareClickHandler)}>
                  <WaoIcon icon="navigation-more" size="34" color="#fff" />
                </div>
              </div>
            }
          </div>
        )}
        <div className={styles.linkOpenDrawer}>
          <span onClick={setVisibleDrawer}>
              {t("See all my created vouchers")}
          </span>
        </div>
      </div>
    </section>
  </div>;
  }
);

export default withTranslation()(PointVoucherSuccess);