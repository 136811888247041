import React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import WaoIcon from "../WaoIcon";
import ShoppingButtonCounterCart from '../ShoppingCart/ButtonCounterCart';
import ShareButton from '../ShareButton';
import styles from './styles.module.sass';
import { useSelector } from 'react-redux';
import { equals, prop } from 'ramda';
import DealListHeader from './DealListHeader';
import { showBrowserWarning } from "../../utils";
import TopBar from '../TopBar';
import SearchBarAndDownload from '../../pages/active-offers/SearchBarAndDownload';
import ButtonCounterCustomCatalog from '../CustomCatalog/ButtonCounterCustomCatalog';
import { useHistory } from 'react-router-dom';
import ScreenContent from '../../transition/ScreenContent';

const HeaderStore = ({
  t,
  customBack,
  drawerSharePayload,
  SearchBarProps,
  isSearchBar,
  icons = {},
  scroll,
  title,
  showLogo,
  altColor,
  noShadow,
  clickable = true,
  safeBar,
  children,
  lookupEvent,
  onChange,
  searchValue,
  storeTitle,
  headerSlug = '',
  country = 'co',
  v = 0 }) => {
  const history = useHistory();
  const defaultFontColor = "#040415";
  const defaultBgClass = "header-button";
  const altFontColor = "#040415";
  const altBgClass = "header-button-alt";
  const fontColor = !altColor ? defaultFontColor : altFontColor;
  const buttonClass = !altColor ? defaultBgClass : altBgClass;
  const { userMe } = useSelector(state => prop('auth', state));
  // const { store } = useSelector(state => state.stores);

  // const storeFromAnotherCountry = store?.country !== userMe?.country;

  const goBack = () => {
    if (customBack) {
      customBack();
    } else {
      history.goBack(userMe);
    };
  };
  const goToProfile = () => { history.push('/account'); };

  let leftCols = 3;
  let titleCols = 6;
  let rightCols = 3;

  if ((!icons?.share && !icons?.profile) || !icons?.cart) {
    leftCols = 2;
    rightCols = 2;
    titleCols = 8;
  }

  const shareInfo = {
    title: t('shopShareText', { context: country }),
    url: document.location.href,
    copyText: `${t('shopShareText', { context: country })}`,
    ...drawerSharePayload
  };

  const screenContentStyles = {
    maxWidth: "500px",
    width: "100%",
    font: "Open Sans",
    height: "100%",
    flexBasis: "content"
  }

  return (
    <ScreenContent style={screenContentStyles}>
      {
        equals(v, 0) && <header className={`${styles['store-header']} ${(altColor ? styles.alt : '')} ${(noShadow ? styles['no-shadow'] : '')}`}>
          {
            showBrowserWarning() &&
            <TopBar message={t('RecommendedBrowsers')} type="warning" />
          }
          {
            safeBar &&
            <TopBar message={t('SafePurchaseMessage')} type="success" icon="lock" iconSize="16" />
          }
          {/* {
            storeFromAnotherCountry &&
            <TopBar message={t('storeFromAnotherCountry')} type="warning" />
          } */}
          <section className={`${styles['header-inner']} app-wrapper-mobile`}>
            <Grid container spacing={0} className={styles["header-buttons"]} alignItems="center" alignContent="center" justifyContent="center">
              <Grid item xs={leftCols} className={styles["back-button"]}>
                {
                  !icons.back ? '' :
                    <button type="button" className={`${styles[buttonClass]} ${styles['header-button-back']}`} style={{width: "50px"}} onClick={goBack}>
                      <WaoIcon color={fontColor} size={20} icon="arrow_left-7" />
                    </button>
                }
                {
                  !icons.profile ? '' :
                    <button type="button" className={styles[buttonClass]} onClick={goToProfile}>
                      <WaoIcon color={fontColor} size={22} icon="Group-5" />
                    </button>
                }
              </Grid>
              <Grid item xs={titleCols} className={styles["title-section"]}>
                {!title ? '' : <span className={styles['page-title']}>{title}</span>}
                {
                  !showLogo ? '' :
                    ((clickable && <a className={styles.logo} href="/">
                      <figure>
                        <WaoIcon color="#e54a38" size={100} icon="logo" />
                      </figure>
                    </a>) ||
                      (!clickable && <div className={styles.logo}>
                        <figure>
                          <WaoIcon color="#e54a38" size={100} icon="logo" />
                        </figure>
                      </div>
                      ))
                }
                {
                  children
                }
              </Grid>
              <Grid item xs={rightCols} className={styles["other-buttons"]}>
                {
                  !icons?.share ? '' : <ShareButton drawer sourceName={headerSlug} info={shareInfo} buttonClass={styles[buttonClass]} fontColor={fontColor} />
                }
                {
                  icons?.customCatalog && <ButtonCounterCustomCatalog color={fontColor} buttonClass={buttonClass} history={history}/>
                }
                {
                  !icons?.cart ? '' :
                    <div>
                      <ShoppingButtonCounterCart color={fontColor} buttonClass={buttonClass} history={history} />
                    </div>
                }
              </Grid>
            </Grid>
          </section>
            {isSearchBar &&
              <SearchBarAndDownload {...SearchBarProps}/>
            }
          {/* <hr className={styles['divider']} /> */}
        </header>
      }
      {
        equals(v, 1) &&
        <>
          {showBrowserWarning() && <TopBar message={t('RecommendedBrowsers')} type="warning" />}
          {/* {storeFromAnotherCountry && <TopBar message={t('storeFromAnotherCountry')} type="danger" />} */}
          <DealListHeader
            t={t}
            drawerSharePayload={drawerSharePayload}
            history={history}
            icons={icons}
            title={title}
            goBack={customBack || goBack}
            altColor
            country={country}
            onChange={onChange}
            lookupEvent={lookupEvent}
            searchValue={searchValue}
            storeTitle={storeTitle}
            scroll={scroll}

          >
            {children}
          </DealListHeader>
        </>
      }
    </ScreenContent>
  );
};

export default withTranslation()(HeaderStore);