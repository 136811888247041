import StoreActions from "./actions";
import InitialState from ".";
import { clone } from 'ramda';

const Storage = (store) => {
  localStorage.setItem('store', JSON.stringify(store || null));
};

const save = (state) => {
  Storage(state.store);
  return state;
};

export const reducer = (state = InitialState, action) => {

  const copy = clone(state);

  switch (action.type) {
    
    case StoreActions.fetchStoreRequest().type:
      return state.merge({
        storeLoading: true,
        storeError: null,
        storeLoaded: false,
      });
    
    case StoreActions.fetchStoreSuccess().type:
      copy.store = action.payload.store.data || state.store;
      return state.merge({
        ...save(copy),
        storeLoading: false,
        storeError: null,
        storeLoaded: true
      });

    case StoreActions.fetchStoreFailed().type:
      return state.merge({
        storeLoading: false,
        storeError: action.payload.error.error,
        storeLoaded: false
      });

    default:
      return state;
  }
};
