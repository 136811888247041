import React from 'react';
import ContentLoader from 'react-content-loader';
import { generateYoutubeImgUrl } from '../../../utils';
import styles from '../styles.module.sass';

const TutorialItem = ({ data, loading, styleItem, alternalItemClass=""}) => {

  if (loading || !data) {
    return (
      <ContentLoader
        speed={2}
        width="320px"
        height={120}
        viewBox="0 0 320 120"
        backgroundColor="#f2f2f2"
        foregroundColor="#ecebeb"
      >
        <rect x="0px" y="10px" rx="3" ry="3" width="75px" height="100px" />
        <rect x="110px" y="35px" rx="3" ry="3" width="200px" height="20px" />
        <rect x="110px" y="65px" rx="3" ry="3" width="75px" height="15px" />
      </ContentLoader>
    );
  }

  const imgUrl = generateYoutubeImgUrl(data.link || '');

  return (
    <a className={styles['tutorial-link']} href={data.link} target="__blank">
      <div className={`${styles['tutorial-item']} ${alternalItemClass}`} style={{...styleItem}}>
        <div className={styles['img-cnt']}>
          {data?.image ? <img src={data?.image} alt="tutorial-thumbnail" /> : <img src={imgUrl} alt="tutorial-thumbnail" />}
        </div>
        <div className={styles['tutorial-item-content']}>
          <h3 className={styles['tutorial-title']}>{data.name}</h3>
        </div>
      </div>
    </a>
  );
};

export default TutorialItem;
