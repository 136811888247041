/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import styles from './styles.module.sass';
import WaoIcon from '../WaoIcon';

const OrderStatusStepper = ({ steps, currentStepIndex, skipUpcoming }) => {
  
  const [activeStep/* , setActiveStep */] = useState(currentStepIndex);
  const isStepNext = (step) => activeStep + 1 === step;
  const getIcon = (index, { active, completed }) => {
    const filled = completed || active;
    const isNext = isStepNext(index) && !skipUpcoming;
    let iconClass = styles.empty;
    if (completed) iconClass = styles.completed;
    else if (active) iconClass = styles.active;
    else if (isNext) iconClass = styles.upcoming;
    
    return (
      <div className={`${styles.icon} ${iconClass}`}>
        {filled && <WaoIcon icon="checkmark" size="14" color="#fff" />}
        {isNext && <div />}
      </div>
    );
  };

  const getStepClass = (index) => {
    if (index < activeStep) return styles.completed;
    if (index === activeStep) return styles.active;
    if (index === activeStep + 1) return styles.upcoming;
    return styles.empty;
  };


  const CustomConnector = ({ index }) => <div className={`${styles.connector} ${getStepClass(index)} ${skipUpcoming ? styles.skip : ''}`} />;
  
  return (
    <div className={styles["stepper-parent"]}>
      <Stepper activeStep={activeStep} orientation="vertical" connector={<CustomConnector />} className={styles.stepper}>
        {steps.map((step, i) => (
          <Step key={i} className={`${styles[step.class]} ${getStepClass(i)}`}>
            <StepLabel style={{ padding: "1px 0" }} StepIconComponent={getIcon.bind(null, i)}>
              <div className={styles.label}>
                <div className={styles.title}>{step.title}</div>
                <div className={styles.subtitle}>{step.subtitle}</div>
              </div>
            </StepLabel>
            <StepContent className={styles["break-line"]}>
              {step.content}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withTranslation()(OrderStatusStepper);