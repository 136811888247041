import React, { useCallback, useEffect } from 'react';
// import { /* useSelector, */ useDispatch } from 'react-redux';
import { Form, Field, useFormikContext, getIn } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import styles from './styles.module.sass';
// import ShipmentActions from '../../redux/shipment/actions';
import GoogleAutocompleteService from '../../components/GoogleMaps/GoogleAutocompleteService';
import { usePrevious } from '../../utils/hooks';
// import { getWAUrl, onEnter } from '../../utils';
import TextInputLiveFeedback from '../../components/TextInputLiveFeedback';
import WaoIcon from '../../components/WaoIcon';
import { propEq } from 'ramda';
import { useParams } from 'react-router-dom';
import { CheckLiveFeedback, SelecInputPhone, SelectInputLiveFeedback } from '../registration/utils';
import Progress from '../../components/Progress';
import ScreenContent from '../../transition/ScreenContent';
import PriceDiscountFormat from '../../components/PriceDiscountFormat';


const CheckoutSteps = (
  { countriesZipIsOptionalIn,
    steps,
    setIsPSESelected,
    paymentMethodsLoaded,
    paymentMethodsLoading,
    paymentMethodsError,
    paymentMethodOptions,
    cashPaymentMethods = [],
    countryParam,
    lowerCaseCountryParam,
    t,
    userMe,
    updatePages,
    // waText,
    setInnerValues,
    freeMethod,
    isFree,
    // externalMinimumPayment,
    currency,
    totalOrder,
    setPaymentMethodLimit
  }) => {

  const { values, setValues , setFieldValue } = useFormikContext();
  const { storeId } = useParams();
  const isReferrer = propEq('type', 'referrer');
  const isOwnStore = userMe && isReferrer(userMe) && storeId && userMe?.shopId === storeId;
  const prevUpdateUser = usePrevious(values.formikStep1.updateUser);
  // const [showZipCodeWarning, setshowZipCodeWarning] = useState(false);
  // const [isFirstTime, setIsFirstTime] = useState(false);
  // const [currentZipCode, setCurrentZipCode] = useState(0);
  // const previousZipCode = usePrevious(currentZipCode);
  // const dispatch = useDispatch();
  // const { zipcodeValid } = useSelector(state => state.shipments);

  const updateAddressFields = useCallback((data, newState) => {
    const formikStep2 = {
      address: data?.formatted_address || data?.address1 || '',
      address2: data?.address2 || '',
      city: data?.city || '',
      region: data?.state || data?.region || '',
      zipCode: data?.zipCode || '',
      description: data?.description || '',
      lat: data?.lat || '',
      lng: data?.lng || '',
      country: data?.country || ''
    };

    if (!data?.zipCode) formikStep2.zipCode = '';
    // if (currentZipCode !== formikStep2.zipCode) setCurrentZipCode(formikStep2.zipCode);
    
    // Move to checkout
    // if (!formikStep2.address) setValidFormik2(false);

    if (newState) {
      formikStep2.address2 = data.address2 || formikStep2.data || newState?.formikStep2?.address2 || '';
      setValues({ ...newState, formikStep2 }, true);
    } else {
      setValues({ ...values, formikStep2 }, true);
    }
    // if (showZipCodeWarning) setshowZipCodeWarning(false);    
    // if (currentZipCode) dispatch(ShipmentActions.validateZipcode({ zipCode: currentZipCode, country: countryParam }));
  }, [setValues, values]);

  const updateUserInfoToOrder = useCallback((innerUserMe) => {
    const formikStep1 = {
      firstName: innerUserMe.firstName || '',
      lastName: innerUserMe.lastName || '',
      phone: innerUserMe.phone || '',
      email: innerUserMe.email || '',
      document: innerUserMe.document || '',
      country: innerUserMe.country || '',
      updateUser: innerUserMe.updateUser || false,
    };

    const { addresses = [{}] } = innerUserMe || [];
    const mainAddress = addresses[0] || {};
    const formikStep3 = {
      ...values.formikStep3,
      document: innerUserMe.document || '',
      country: innerUserMe.country || '',
    };
    const newState = { ...values, formikStep1, formikStep3 };
    // TODO this was breaking the Address after history changes
    // updateAddressFields(newState?.formikStep2?.zipCode ? {} : mainAddress, newState);
    updateAddressFields(mainAddress, newState);
  }, [updateAddressFields, values]);

  // const whatsAppUrl = getWAUrl(countryParam, waText, 'sales') + encodeURIComponent(`${values.formikStep2.address}, ${(values.formikStep2.address2 || '')}\n\nGracias!`);
  const context = { context: lowerCaseCountryParam || 'co' };
  const freeMethodValidate = paymentMethodOptions?.length === 1 && paymentMethodOptions[0].type === "FREE";

  useEffect(() => {
    const updateValues = async () => {
      setInnerValues(values);
    };
    updateValues();
  }, [values, setInnerValues]);

  useEffect(() => {
    if(freeMethodValidate){
      setFieldValue("formikStep3.paymentMethod",freeMethod);
    }else{
      setFieldValue('formikStep3.paymentMethod',{ value: '', label: "Selecciona método de pago" });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFree]);
    
  useEffect(() => {
    if (values.formikStep1.updateUser !== prevUpdateUser && prevUpdateUser !== undefined) {
      if (!values.formikStep1.updateUser) {
        updateUserInfoToOrder({ ...userMe, updateUser: false });
      } else {
        updateUserInfoToOrder({ ...userMe, firstName: '', lastName: '', phone: '', email: '', updateUser: true, document: '' });
      }
    }

  }, [values.formikStep1.updateUser, updateUserInfoToOrder, userMe, prevUpdateUser]);

  useEffect(() => {
    if(setPaymentMethodLimit) setPaymentMethodLimit(values?.formikStep3?.paymentMethod?.minimumPayment && values?.formikStep3?.paymentMethod?.minimumPayment > 0 && totalOrder < values?.formikStep3?.paymentMethod?.minimumPayment)
  }, [values, totalOrder, setPaymentMethodLimit]);

  // Whenever the zipcode is updated and it has a new value, check if it needs to be validated, otherwise mark as invalid
  //  useEffect(() => {
  //    if (!currentZipCode) {
  //      setshowZipCodeWarning(false)
  //      setFieldValue('formikStep2.zipCodeIsValid', true);
  //    } else if (currentZipCode !== previousZipCode) {
  //      setshowZipCodeWarning(false);
  //      dispatch(ShipmentActions.validateZipcode({ zipCode: currentZipCode, country: countryParam }));
  //    } else {
  //      console.log('No zip code change');
  //    }
  //  }, [dispatch, previousZipCode, currentZipCode, countryParam, setFieldValue, values.formikStep2.address]);

  // Whenever the zipcode valid request has a response, update the form and
  //  useEffect(() => {
  //    setFieldValue('formikStep2.zipCodeIsValid', !!(zipcodeValid?.isValid));
  //    setshowZipCodeWarning(!(zipcodeValid?.isValid));
  //  }, [zipcodeValid, setFieldValue]);

  const paymentCashProviderValue = getIn(values, ['formikStep3', 'paymentCashProvider']);
  const lngValue = null || getIn(values, ['formikStep2', 'lng']);
  const prevLngValue = usePrevious(lngValue);
  const latValue = null || getIn(values, ['formikStep2', 'lat']);
  const prevLatValue = usePrevious(latValue);

  // Keep the pages object updated
  useEffect(() => {
    if (paymentCashProviderValue && paymentCashProviderValue !== '') {
      updatePages({ paymentCashProvider: paymentCashProviderValue });
    }
    if ((lngValue && (prevLngValue !== lngValue)) || (latValue && (prevLatValue !== latValue))) {
      updatePages({ ...values.formikStep2, formatted_address: values.formikStep2.address });
    }
  }, [paymentCashProviderValue, lngValue, latValue, updatePages, prevLatValue, prevLngValue, values]);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateUserInfoToOrder(userMe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPaymentMethodSection = (param) => {
    const paymentMethod = param?.value;
    let contentSection;
    switch (paymentMethod) {
      case 'CASH':
        contentSection = <div className={styles['cash-methods']}>
          <div className={styles['provide-details']}>
            {t("selectOneOfFollowingMethods")}
          </div>
          {cashPaymentMethods?.map((item) => (
              <label htmlFor='formikStep3.paymentCashProvider' key={item?.id} className={`${styles['__label-input']} ${((values.formikStep3.paymentCashProvider === item.method) ? styles['__label-input__selected'] : null)}`}>
                <div className={styles.__card}>
                  <Field id={`paymentCashProvider${item.method}`} name='formikStep3.paymentCashProvider' data={item} value={item.method} type="radio" />
                  <img alt={item.name} src={item.image} />
                  <span>{t(item.name)}</span>
                </div>
              </label>
          ))
          }
        </div>;
        break;
      default:
        contentSection = "";
    }
    return contentSection;
  };

  const getStepTitle = (step, stepsTotal, title, nextTitle) => (
    <div className={styles["title-section"]}>
      <div className={styles["title-circle"]}>
        <span>{step}/{stepsTotal}</span>
      </div>
      <div className={styles["title-text"]}>
        <div className={styles.title}>{title}</div>
        {nextTitle && <div className={styles["next-title"]}>Próximo: {nextTitle}</div>}
      </div>
    </div>
  );

  const step1Title = t('Delivery Information');
  const step2Title = t('Shipping Address');
  const step3Title = t('Payment');
  const step1Class = steps[0].status === 'current' ? (` ${styles.current}`) : '';
  const step2Class = steps[1].status === 'current' ? (` ${styles.current}`) : '';
  const step3Class = steps[2].status === 'current' ? (` ${styles.current}`) : '';

  const renderStep1 = () => (
    <section className={styles.step + step1Class}>
      <div className={styles['content-step']}>
        {getStepTitle(1, 3, step1Title, step2Title)}
        {(isOwnStore) ?
          <div className={`${styles['disclaimer-section']} ${styles['updateUser-container']}`}>
            <Field
              label={t("I am buying on behalf of my client")}
              name="formikStep1.updateUser"
              type="checkbox"
              component={CheckLiveFeedback}
            />
            <div className={styles.tooltip}>
              <WaoIcon color="#090909" size={16} icon="info2" />
              <span className={styles.tooltiptext}>{t('buyByClient')}</span>
            </div>
          </div>
          : null
        }
        <div className={styles['disclaimer-section']}>
          {t("All fields below are required")}.
        </div>
        <div className={`${styles['wrap-form']} form-control-mat3`}>
          <Form>
            <TextInputLiveFeedback alt label={t('First Name')} id="firstName" name="formikStep1.firstName" type="text" />
            <TextInputLiveFeedback alt label={t('Last Name')} id="lastName" name="formikStep1.lastName" type="text" />
            <Field alt label={t('Phone Number')} name='formikStep1.phone' country={countryParam} component={SelecInputPhone} feedbackClass="control-feedback" />
            <TextInputLiveFeedback alt label={t('Email Address')} trim id="email" name="formikStep1.email" type="text" />
            <TextInputLiveFeedback alt label={t(`documentCheckout`, { context: lowerCaseCountryParam })} id="document" name="formikStep1.document" type="text" />
          </Form>
        </div>
      </div>
      <FormikErrorFocus offset={0} align="top" duration={1000} />
    </section>
  );

  const addressOnChangeHandler = (address1) => {
    const newAddress = { ...values.formikStep2, address1 };
    delete newAddress.formatted_address;
    updateAddressFields(newAddress);
    setFieldValue('formikStep3.paymentMethod',{ value: '', label: "Selecciona método de pago" });
  };

  /* const chatActionHandler = () => {
    window.open(whatsAppUrl, '_blank');
  }; */

  const renderStep2 = () => (
    <section className={styles.step + step2Class}>
      <div className={styles['content-step']}>
        {getStepTitle(2, 3, step2Title, step3Title)}
        <div className={styles['disclaimer-section']}>
          {t("All fields below are required")}.
        </div>
        <div className={styles['wrap-form']}>
          <Form>
            <div className="form-control-mat-alt">
              <div className="label">{t('form.Address', context)}</div>
              <GoogleAutocompleteService
                outputAddressComponent={updateAddressFields} onChange={addressOnChangeHandler}
                value={{ ...values.formikStep2, formatted_address: values.formikStep2.address }}
                countryCode={countryParam} clearable alt hideVerified validateRequired useSimpleValue />
            </div>
            <TextInputLiveFeedback alt forceFeedback optional label={t('form.Suite/Unit', context)} id="address2" name="formikStep2.address2" type="text" />
            <TextInputLiveFeedback alt forceFeedback label={t('form.Locality/City', context)} id="city" name="formikStep2.city" type="text" />
            <TextInputLiveFeedback alt forceFeedback label={t('form.Province', context)} id="region" name="formikStep2.region" type="text" />
            <TextInputLiveFeedback alt forceFeedback optional={countriesZipIsOptionalIn.includes(lowerCaseCountryParam)} label={t('form.Postal Code', context)} id="zipCode" name="formikStep2.zipCode" type="text" />
            <TextInputLiveFeedback alt forceFeedback optional label={t('Indications')} name="formikStep2.description" rows="3" maxLength="128" type="textarea" />

            {/* {
              showZipCodeWarning && !!values.formikStep2.lat && !!values.formikStep2.lng &&
              <div className={styles["invalid-address"]}>
                <p>Por el momento no estamos entregando en tu zona.</p>
                <p>Chatea con un agente de compra para ver mas opciones de entrega.</p>
                <div role="button" tabIndex="0" className={`${styles["invalid-button"]} btn btn-round`} onClick={chatActionHandler} onKeyDown={onEnter(chatActionHandler)}>{t("Chat Now", context)}</div>
              </div>
            } */}
            <div className={styles['hide-inputs']}>
              <TextInputLiveFeedback alt label={t('form.Address', context)} id="address" name="formikStep2.address" type="text" />
              <TextInputLiveFeedback alt label={t('form.Locality/City', context)} id="city" name="formikStep2.city" type="text" />
              <TextInputLiveFeedback alt label={t('form.Province', context)} id="region" name="formikStep2.region" type="text" />
              <TextInputLiveFeedback alt label={t('form.Postal Code')} id="zipCode" name="formikStep2.zipCode" type="text" />
              <TextInputLiveFeedback alt label="Lat" id="lat" name="formikStep2.lat" type="text" />
              <TextInputLiveFeedback alt label="Lng" id="lng" name="formikStep2.lng" type="text" />
            </div>
          </Form>
        </div>
      </div>
      <FormikErrorFocus offset={0} align="top" duration={1000} />
    </section>
  );

  const selectChangeHandler = (option) => {
    setIsPSESelected(option?.value === 'PSE');
  };

  const renderStep3 = () => 
     <section className={styles.step + step3Class}>
      <div className={styles['content-step']}>
        {getStepTitle(3, 3, step3Title)}
        <div className={styles['disclaimer-section']}>
          {t("All fields below are required")}.
        </div>
        {!paymentMethodsLoaded || paymentMethodsError || paymentMethodsLoading ? (
          <div className="flex center mb20">
            {paymentMethodsLoading ? <Progress style={{ color: "#ee7d40" }} size={20}/> : ''}
            {paymentMethodsError ? <span className="fs14 italic danger-text-color">{t("paymentMethodsLoadFailed")}</span> : ''}
          </div>
        ) : (
          <div className={styles['__inner-step']}>
            <div className={styles['wrap-form']}>
              <Form>
                <Field onChange={selectChangeHandler} labelClassName={styles["payment-select"]}
                  cb={updatePages} t={t} label="Selecciona método de pago"
                  placeholder='Selecciona método de pago' name='formikStep3.paymentMethod'
                  options={paymentMethodOptions} component={SelectInputLiveFeedback} isDisabled={freeMethodValidate}
                />
                <div className={styles['__payment-inputs']}>
                  <div className={((values.formikStep3.paymentMethod?.value === 'CARD') ? 'hide' : '')}>
                    {getPaymentMethodSection(values.formikStep3?.paymentMethod)}
                  </div>
                  {values?.formikStep3?.paymentMethod?.instructionsWarning &&
                    <p style={{ marginTop: "6px", fontWeight: 'bold', fontSize: '12px' }}>
                      {values?.formikStep3?.paymentMethod?.instructionsWarning}
                    </p>
                  }
                  {values?.formikStep3?.paymentMethod?.instructions && !(values?.formikStep3?.paymentMethod?.minimumPayment && values?.formikStep3?.paymentMethod?.minimumPayment > 0 && totalOrder < values?.formikStep3?.paymentMethod?.minimumPayment) && <div className={styles["description-warning"]}>
                    <div>
                      <WaoIcon color="#ffcc00" size="20" icon="info1" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                      {values?.formikStep3?.paymentMethod?.instructions}
                    </div>
                  </div>}
                  {values?.formikStep3?.paymentMethod?.minimumPayment && values?.formikStep3?.paymentMethod?.minimumPayment > 0 && totalOrder < values?.formikStep3?.paymentMethod?.minimumPayment && <div className={styles["description-warning"]}>
                    <div>
                      <WaoIcon color="#ffcc00" size="20" icon="info1" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                      <span>{t('MethodLimitExplanation')} <PriceDiscountFormat suffix simple value={values?.formikStep3?.paymentMethod?.minimumPayment} currency={currency} /></span>
                    </div>
                  </div>}
                  {values?.formikStep3?.paymentMethod?.instructions_2 && <br />}
                  {values?.formikStep3?.paymentMethod?.instructions_2 && <div className={styles.description}>{values?.formikStep3?.paymentMethod?.instructions_2}</div>}
                </div>
              </Form>
            </div>
          </div>
        )}
      </div>
      <FormikErrorFocus offset={0} align="top" duration={1000} />
    </section>
  ;


  return (
    <ScreenContent webMobile>
      <section className={styles['container-steps']}>
        {renderStep1()}
        {renderStep2()}
        {renderStep3()}
      </section>
    </ScreenContent>
  );
};

export default CheckoutSteps;