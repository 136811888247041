import Immutable from 'seamless-immutable';

const initialState = Immutable({
  dealsLoading: false,
  dealsError: null,
  dealsLoaded: false,
  deals: null,
  dealsPagination: null,

  selected: null,
  searchTerm: '',
  filters: '',

  deal: null,
  dealLoading: false,
  dealError: null,
  dealLoaded: false,

  dealsByCountryLoading: false,
  dealsByCountryError: null,
  dealsByCountryLoaded: false,
  dealsByCountry: null,
  dealsByCountryPagination: null,

  categories: null,
  categoriesLoading: false,
  categoriesError: null,
});

export default initialState;
