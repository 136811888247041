import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';


function ScrollToTop({ history, children }) {
  
  useEffect(() => {
    if (history) {
        const unlisten = history.listen(() => {
          window.scrollTo(0, 0);
        });
      return () => { unlisten(); };
    }
    return () => { };
  });

  return children;
}

export default withRouter(ScrollToTop);