/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import TutorialItem from './TutorialItem';

const TutorialList = ({ data, loading, pagination: p, fetchData, infiniteScroll=false, styleItem, alternalItemClass }) => {
   
  // eslint-disable-next-line no-unsafe-optional-chaining
  const dataLength = p?.pages * p?.limit || 0;
  const hasMoreData = p?.totalPages && (p?.pages < p?.totalPages);

    return (
      <Fragment>
        <>
          {(loading && !data) &&
            <>
              <TutorialItem loading={loading} />
              <TutorialItem loading={loading} />
              <TutorialItem loading={loading} />
            </>
          }
          {(!infiniteScroll && data && data.length > 0) &&
            data?.map((tutorialItem, tutorialIndx) => (
                <TutorialItem styleItem={styleItem} alternalItemClass={alternalItemClass} key={`Tutorial${tutorialIndx}`} data={tutorialItem} />
              ))
          }
        </>
      

        { (infiniteScroll && data && data.length > 0) &&
          <InfiniteScroll
            dataLength={dataLength}
            next={fetchData}
            scrollThreshold={0.7}
            hasMore={hasMoreData}
            loader={
              <>
                <TutorialItem loading={loading} />
                <TutorialItem loading={loading} />
                <TutorialItem loading={loading} />
              </>
            }
            >
            {data?.map((tutorialItem, tutorialIndx) => (
              <TutorialItem styleItem={styleItem} alternalItemClass={alternalItemClass} key={`Tutorial${tutorialIndx}`} data={tutorialItem} />
              ))
            }
          </InfiniteScroll>
        }
      </Fragment>
      );
};

export default TutorialList;
