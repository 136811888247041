import React from 'react';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Emoji from '../../../components/Emoji';
import Progress from '../../../components/Progress';
import styles from '../styles.module.sass';
import {formatDate, pageUrls, showFriendlyDateSameYearCaseShortInverted } from '../../../utils';
import { es } from 'date-fns/locale';


const PointsHistory = ({
  t,
  pointsHistory = [],
  pointsHistoryPagination,
  // pointsHistoryError,
  earnClickHandler,
  fetchData,
  loading,
}) => {

  const pointsHistoryLength = pointsHistoryPagination?.to || 0;  
  const hasMorePointsHistory = pointsHistoryPagination?.lastPage && (pointsHistoryPagination?.currentPage < pointsHistoryPagination?.lastPage);
  
  const iconCase = (caseType) => {
    switch (caseType) {
      case 'earned':
        return <Emoji label="earned star" content="⭐️" />;
      case 'spent':
        return <Emoji label="redeemed forbidden sign" content="⛔️" />;
      case 'accumulated':
        return <Emoji label="accumulated sparks" content="✨" />;
      default:
        return <Emoji label="earned star" content="⭐️" />;
    }
  };  


  
  return (
  <>

  {(!loading && !pointsHistory?.length) ? <div className={styles["no-points"]}>
    <h3>{t("You have not earned any points yet")}</h3>
    <div className="my20 fs80"><Emoji label="shrug" content="🤷‍♀️" /></div>
    
    <div className={styles["no-points__msg__instructions"]}>
      <span>{t("To see how to win go to the section of")} </span>
      <a href={pageUrls.pointsDetails} className="link" onClick={earnClickHandler}>
        {t('How to earn')}
      </a>
    </div>
  </div>
  :""}

  {(loading && !pointsHistory?.length) ? <Progress /> : ''}
  
  {(pointsHistory?.length) ?
    <InfiniteScroll
      next={fetchData}
      hasMore={hasMorePointsHistory}
      dataLength={pointsHistoryLength}
      loader={<Progress />}
    >
      {pointsHistory.map(({ id, history, points, created_at, operationType }, index, array) => {

        const prevIDate = new Date(array[index - 1]?.created_at);
        const date = new Date(created_at);
        
        const renderDayTitle = date.getDay() !== prevIDate.getDay();
        
        return <div key={id} className={`${styles.historySection} ${!renderDayTitle ? styles.border : ''}`}>
          {
            !renderDayTitle ? '' : 
              <div className="highlight-color bold mb8 mt6">
                {`${showFriendlyDateSameYearCaseShortInverted(date, es, null, null, 3)}`}
              </div>
          }
          <div className={styles.historyMsgContent}>
            <div className={styles.historyMsg}>{history}</div>
            <div className={styles.historyPoints}><span>{points}</span>{iconCase(operationType)}</div>
          </div>
          <div className={styles.historyDate}>{formatDate(created_at, '/')}</div>
          <div className={styles.line}/>
        </div>;
      })}
    </InfiniteScroll>
  : ""}
  </>
  );


};

export default withTranslation()(PointsHistory);
