import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import Tabber from '../../components/Tabber';
import RedeemSection from './RedeemSection';
import EarnSection from './EarnSection';
import SharePointsSection from './SharePointsSection';
import Points from '.';
import { getSiteUrl, pageUrls } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import DrawerPanel from '../../components/DrawerPanel';
import AuthActions from '../../redux/auth/actions';
import PointVoucherSuccess from './PointVoucherSuccess';
import PointVouchersSection from './PointVouchersSection';
import { Helmet } from 'react-helmet';
import HeaderStore from '../../components/HeaderStore';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';
import ScreenTitle from '../../transition/ScreenTitle';
import { currencies } from '../../utils/countries';

const pageUrl = `${getSiteUrl()}${pageUrls.pointsDetails}`;

const DetailSection = (({ t, history }) => {

  const dispatch = useDispatch();

  const { userMe, userMeLoaded } = useSelector(state => state.auth);
  const country = userMe?.country?.toLowerCase();

  const [pointsAvailable, setPointsAvailable] = useState();
  const [showSharePanel, setShowSharePanel] = useState(false);
  const [showExgengeConfigPanel, setShowExgengeConfigPanel] = useState(false);
  const [showPointVouchersSuccessPanel, setShowPointVouchersSuccessPanel] = useState(false);
  const [showPointVouchersPanel, setShowPointVouchersPanel] = useState(false);
  const [shareCode, setShareCode] = useState(null);

  const [selectTab, setSelectTab] = useState(0);

  const relativeShopUrl = `/store/${userMe?.shopId}`;

  const pointsRate = useMemo(() =>
    userMe?.ratePointsByCountry?.find(countryRate => countryRate?.country === country?.toUpperCase())?.rate
    , [country, userMe]);

  const shareCodeTitle = `${t('shopShareCodeText', { context: country })}: ${shareCode}`;
  const shareCodeObject = useMemo(() => ({
    title: shareCodeTitle,
    url: document.location.origin + relativeShopUrl,
    copyText: `${shareCodeTitle}`,
  }), [relativeShopUrl, shareCodeTitle]);

  const shareClickHandler = e => {
    e.preventDefault();
    setSelectTab(0);
    setShowSharePanel(true);
  };

  const exgengeConfigClickHandler = useCallback(e => {
    e.preventDefault();
    setShowExgengeConfigPanel(!showExgengeConfigPanel);
  }, [showExgengeConfigPanel]);

  const pointVouchersSuccessClickHandler = e => {
    if (e) e.preventDefault();
    setShowPointVouchersSuccessPanel(!showPointVouchersPanel);
    setShowSharePanel(!showSharePanel);
  };

  const pointVouchersClickHandler = e => {
    e.preventDefault();
    setSelectTab(1);
    setShowPointVouchersSuccessPanel(!showPointVouchersSuccessPanel);
    setShowSharePanel(!showSharePanel);
  };

  const earnClickHandler = () => {
    setSelectTab(0);
  };

  const fetchUser = () => {
    dispatch(AuthActions.fetchUserMe());
  };

  const onClose = () => {
    fetchUser();
  };

  const handlerObject = useMemo(() => ({
    redeem: exgengeConfigClickHandler,
    sharePoints: shareClickHandler
  }), [exgengeConfigClickHandler]);

  const tabs = [
    {
      label: t('Accumulate'),
      content: <EarnSection country={country} pointsAvailable={pointsAvailable} />
    },
    {
      label: t('Benefits'),
      content: <RedeemSection country={country} currency={currencies[country]} pointRate={pointsRate} handlerObject={handlerObject} />
    },
    {
      label: t('My Points'),
      content: <Points earnClickHandler={earnClickHandler} />
    }
  ];

  const stylesOverride = {
    styleCotainerTabs: {
      borderBottom: '1px solid #c8c8c8',
      top: '-1px',
      position: 'sticky',
      zIndex: '50',
    },
    selector: {
      root: {
        borderBottom: 'transparent',
        backgroundColor: '#ffffff',
        top: '-1px',
        position: 'sticky',
        zIndex: '50',
      },
      indicator: {
        height: '4px !important',
        backgroundColor: '#FF6856 !important',
      }
    },
    tab: {
      root: {
        textTransform: 'none',
        fontSize: '18px !important',
        padding: '30px 5px 12px 5px !important',
        '&:nth-child(1)': {
          padding: '30px 5px 12px 0px !important',
          marginLeft: '20px'
        },
        '&:last-child': {
          padding: '30px 0px 12px 5px !important',
          marginRight: '20px'
        }

      },
      selected: {
        fontWeight: '600',
        color: '#FF6856 !important'
      }
    }
  };

  useEffect(() => {
    if (userMeLoaded && userMe) setPointsAvailable(userMe?.points?.pointsAvailable);
  }, [userMeLoaded, userMe, selectTab]);

  return <Screen>
    <Helmet>
      <meta charSet="utf-8" />
      <title>WAO! - {t('waoPointsTitle')}</title>
      <link rel="canonical" href={pageUrl} />
    </Helmet>
    <HeaderStore history={history} showLogo icons={{ back: true }} altColor noShadow />
    <ScreenContent webMobile className={styles.detailSection}>
      <section className={styles.content}>
        <ScreenTitle ignorePadding centered title={t("waoPointsTitle")} />
        <Tabber tabs={tabs} styles={stylesOverride} selectTab={selectTab} />
      </section>
    </ScreenContent>
    <DrawerPanel styleDrawerBody={styles.styleDrawerBody} styleDrawerCloseSection={styles.styleDrawerCloseSection} visible={showSharePanel} setVisible={setShowSharePanel} onClose={onClose} maxHeight="600px">
      <SharePointsSection country={country} currencies={currencies} pointsRate={pointsRate} t={t} pointsAvailable={userMe?.points?.pointsAvailable} selectTab={selectTab} setVisibleDrawer={pointVouchersSuccessClickHandler} setShareCode={setShareCode} />
    </DrawerPanel>
    <DrawerPanel visible={showPointVouchersSuccessPanel} setVisible={setShowPointVouchersSuccessPanel} onClose={onClose} height="600px">
      <PointVoucherSuccess country={country} currency={currencies[country]} pointRate={pointsRate} t={t} setVisibleDrawer={pointVouchersClickHandler} info={shareCodeObject} />
    </DrawerPanel>
    <DrawerPanel styleDrawerBody={styles.styleDrawerBody} styleDrawerCloseSection={styles.styleDrawerCloseSection} visible={showPointVouchersPanel} setVisible={setShowPointVouchersPanel} onClose={onClose} height="600px">
      <PointVouchersSection country={country} currency={currencies[country]} pointRate={pointsRate} t={t} relativeShopUrl={relativeShopUrl} />
    </DrawerPanel>
  </Screen>;
}
);

export default withTranslation()(DetailSection);