/* eslint-disable default-param-last */
import PaymentActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case PaymentActions.fetchPaymentMethodsRequest().type:
      return state.merge({
        paymentMethodsLoading: true,
        paymentMethodsError: null,
        paymentMethodsLoaded: false,
      });
    
    case PaymentActions.fetchPaymentMethodsSuccess().type:
      return state.merge({
        paymentMethodsLoading: false,
        paymentMethodsError: null,
        paymentMethodsLoaded: true,
        paymentMethods: action.payload.paymentMethods.data || state.paymentMethods,
      });

    case PaymentActions.fetchPaymentMethodsFailed().type:
      return state.merge({
        paymentMethodsLoading: false,
        paymentMethodsError: action.payload.error.error,
        paymentMethodsLoaded: true,        
      });

    case PaymentActions.createPaymentTransactionRequest().type:
      return state.merge({
        paymentTransactionLoading: true,
        paymentTransactionError: null,
        paymentTransactionLoaded: false,
      });

    case PaymentActions.createPaymentTransactionSuccess().type:
      return state.merge({
        paymentTransactionLoading: false,
        paymentTransactionError: null,
        paymentTransactionLoaded: true,
        paymentTransaction: action.payload.paymentTransaction.data || state.paymentTransaction,
      });


    default:
      return state;
  }
};
