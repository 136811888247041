import { propEq } from "ramda";


const goToCurrentStore = (history, match) => {
  const { storeId } = match.params;
  history.replace(`/store/${storeId}`);
};

export const goToCart = (history, match, /* user */ ) => {
  const { storeId } = match.params;
  const cartBuyNow = localStorage.getItem('cartsBuyNow') ? JSON.parse(localStorage.getItem('cartsBuyNow')) : {};
  const isCartBuyNow = !!cartBuyNow?.[storeId]?.cartItems?.length > 0;

  if(isCartBuyNow){
    history.replace(`/store/${storeId}`);
  }else{
    history.replace(`/store/${storeId}/my-cart`);
  }
};

export const goToReferrerDashboard = (history, match, user) => {
  if (user && propEq('type', 'referrer', user)) {
    history.push(`/home`);
  } else {
    history.go(-1);
  }
};

export const goToCommissions = (history, match, user) => {
  if (user && propEq('type', 'referrer', user)) {
    history.push(`/commissions`);
  } else {
    history.go(-1);
  }
};

export const goToActiveOffers = (history, match, user) => {
  if (user && propEq('type', 'referrer', user)) {
    history.push(`/active-offers`);
  }
};

export const goToOrders = (history, match, user) => {
  if (user && propEq('type', 'referrer', user)) {
    history.push(`/orders`);
  } else {
    history.go(-1);
  }
};

export const checkoutSuccessExc = goToCurrentStore;
export const myCartExc = goToCurrentStore;
export const customCatalogExc = goToCurrentStore;
export const dealDetailExc = goToCurrentStore;