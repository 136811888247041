import log from 'loglevel';

/*
logLevel supports the following logging functions with their corresponding log levels:

log.trace(msg) // TRACE
log.debug(msg) // DEBUG
log.log(msg) // DEBUG
log.info(msg) // INFO
log.warn(msg) // WARN
log.error(msg) // ERROR / SILENT

*/

export const init = () => {
  if (process.env.REACT_APP_LOG_LEVEL) log.setLevel(process.env.REACT_APP_LOG_LEVEL);
  else log.setLevel(log.getLevel());
  log.log('Log level set to', process.env.REACT_APP_LOG_LEVEL || log.getLevel());
};

export default log;