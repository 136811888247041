/* eslint-disable default-param-last */
import PromotionsActions from "./actions";
import InitialState from ".";

const procedures = {
  [PromotionsActions.fetchPromosRequest().type]: state => (
    state.merge({
      promosLoading: true,
      promosError: null
    })
  ),
  [PromotionsActions.fetchPromosSuccess().type]: (state, payload) => (
    state.merge({
      promosLoading: false,
      promosError: null,
      promos: payload.data
    })
  ),
  [PromotionsActions.fetchPromosFailed().type]: (state, payload) => (
    state.merge({
      promosLoading: false,
      promosError: payload?.error?.error,
    })
  ),
  [PromotionsActions.fetchCatalogsRequest().type]: state => (
    state.merge({
      catalogsListLoading: true,
      catalogssListError: null
    })
  ),
  [PromotionsActions.fetchCatalogsSuccess().type]: (state, payload) => (
    state.merge({
      catalogsListLoading: false,
      catalogsListError: null,
      catalogsList: payload.data
    })
  ),
  [PromotionsActions.fetchCatalogsFailed().type]: state => (
    state.merge({
      catalogsListLoading: false,
      catalogsListError: null
    })
  )
};

export const reducer = (state = InitialState, action) => procedures[action.type] ? procedures[action.type](state, action.payload) : state;
