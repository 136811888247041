import Immutable from 'seamless-immutable';

const initialState = Immutable({
  registerLoading: false,
  registerError: null,
  registerLoaded: false,

  stakeLoading: false,
  stakeError: null,
  stakeLoaded: false,

  unstakeLoading: false,
  unstakeError: null,
  unstakeLoaded: false,
});

export default initialState;
