import api from './api';

export const getCustomCatalogs = () => api.get("/catalogs" );

export const createCustomCatalog = (data) => {
    if (!data) return console.error('Missing data param');
    return api.post("/catalogs", data );
};

export const updateCustomCatalog = (data) => {
    const {id} = data;
    const dataUpdate = data;
    delete dataUpdate.id
    if (!data) return console.error('Missing data param');
    return api.put(`/catalogs/${id}`, dataUpdate );
};

export const deleteCustomCatalog = ({id}) => {
    if (!id) return console.error('Missing id param');
    return api.delete(`/catalogs/${id}` );
};