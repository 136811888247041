/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import 'firebase/auth';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import HeaderStore from '../../../components/HeaderStore';
import AuthActions from '../../../redux/auth/actions';
import phoneIcon from "../../../assets/images/auth/auth-number.png";
import phoneIcon2x from "../../../assets/images/auth/auth-number@2x.png";
import phoneIcon3x from "../../../assets/images/auth/auth-number@3x.png";
import 'react-phone-input-2/lib/style.css';
import styles from './styles.module.sass';
import { usePrevious } from '../../../utils/hooks';
import { useLocation } from 'react-router-dom';
import { TextInputLiveFeedback } from '../../registration/utils';
import { getDefaultRolePageRedirect } from '../../../utils/constants';
import { equals, path } from 'ramda';
import mixpanel from '../../../services/mixpanel';
import log from '../../../utils/log';
import FooterSocial from '../../../components/FooterSocial';
import View from '../../../transition/View';
import { Helmet } from 'react-helmet';
import ScreenContent from '../../../transition/ScreenContent';
import Progress from '../../../components/Progress';
import Text from '../../../transition/Text';
import useGlobalStyles from '../../../hooks/useGlobalStyles';
import Icon from '../../../components/Icon';
import Screen from '../../../transition/Screen';
import useCaptcha from '../../../hooks/useCaptcha';
import { Recaptcha } from '../../../components/Recaptcha.js';
import { emailForSignInLocal } from '../../../services/local';


const EmailCheck = ({ history, t }) => {
  const globalStyles = useGlobalStyles();
  const [storedEmail, /* setPreviousEmail */] = useState(emailForSignInLocal.get() || '');
  const [atStep2, setAtStep2] = useState(false);
  const dispatch = useDispatch();
  const { userMe, loginError, loginLoading } = useSelector(state => state.auth);
  const prevLoginError = usePrevious(loginError);
  const recaptchaContainer = useRef(null);
  const authState = useSelector(state => state.auth);
  const location = useLocation();
  const nextRoute = (new URLSearchParams(location.search)).get('nextRoute');
  const apiKey = (new URLSearchParams(location.search)).get('apiKey');
  const {initRecaptchaVerifier, captchaObject, validateRefreshForRecaptcha, setValidateRefreshForRecaptcha, renderedCaptcha} = useCaptcha({errorMessage:'CAPTCHA error'});
  
  const goToNext = useCallback((userParam) => {
    if (nextRoute) history.replace(nextRoute);
    else history.replace(getDefaultRolePageRedirect({role: userParam?.role || userParam?.type, user:userMe}));
  }, [nextRoute, history, userMe]);

  const formikStep1 = useFormik({
    initialValues: { email: storedEmail || '' },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email(t('EnterValidEmail')).required(t('RequiredField')),
    }),
  });
  
  const { email } = formikStep1.values;

  const cleanup = useCallback(() => {
    const currElement = recaptchaContainer?.current;
    while (currElement?.firstChild) currElement?.removeChild(currElement?.firstChild);
    setAtStep2(false);
    if (!storedEmail) initRecaptchaVerifier();
  }, [storedEmail, initRecaptchaVerifier]);

  const goLoginWithEmail = useCallback(() => {
    const functionMain = async () => {
      mixpanel.track_optimized('Auth confirm email link', {email});
      if (!authState.loginError) {
        console.log('trying to login with', {email})
        dispatch(AuthActions.signInWithEmailLink(window.location.href, email));
        setAtStep2(true);
      }
    };
    functionMain();
  }, [authState.loginError, dispatch, email]);

  const goBackToSignIn = useCallback(() => {
    const signInPath = { pathname: '/sign-in' };
    if (nextRoute) signInPath.search = `?nextRoute=${nextRoute}`;
    history.push(signInPath);
  }, [history, nextRoute]);

  useEffect(() => {
    dispatch(AuthActions.resetLoginState());
  }, [dispatch]);

  useEffect(() => {
    if (userMe?.country) goToNext(userMe);
  }, [userMe, dispatch, goToNext]);

  useEffect(() => {
    if (loginError && (prevLoginError !== loginError)) {
      log.error('Login error', JSON.stringify(loginError));
      // cleanup();
      if (equals("auth/too-many-requests", path(['userMessage', 'code'], loginError))) {
        toast.error(t('AuthTooManyRequests'));
      } else if (equals("SESSION_EXPIRED", path(['error', 'message'], loginError))) {
        toast.error(t('SessionExpired'));
      } else if (equals("TOKEN_EXPIRED", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC3)`);
      } else if (equals("USER_NOT_FOUND", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC4)`);
      } else if (equals("INVALID_GRANT_TYPE", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC5)`);
      } else if (equals("TOO_MANY_ATTEMPTS_TRY_LATER", path(['error', 'message'], loginError))) {
        toast.error(`${t('AuthTooManyRequests')} (sC6)`);
      } else if (equals("USER_DISABLED", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC7)`);
      } else if (equals("MISSING_REFRESH_TOKEN", path(['error', 'message'], loginError))) {
        toast.error(`${t('LoginProcessFailed')} (sC8)`);
      } else if (equals("QUOTA_EXCEEDED", path(['error', 'message'], loginError))) {
        toast.error(`${t('AuthTooManyRequests')} (sC9)`);
      } else if (loginError?.debugMessage?.code === "LOGIN_EMAIL_LINK_FAILED") {
        console.log('Login email failed');
      } else {
        toast.error(`${t('LoginProcessFailed')} (sD)`);
      }
    }
  }, [loginError, prevLoginError, formikStep1, dispatch, cleanup, t, goLoginWithEmail]);

  useEffect(() => {
    if (!atStep2 && !authState.loginError && !authState.loginLoading && !authState.loginCalled) {
      if (!storedEmail) {
        if (!renderedCaptcha) {
          mixpanel.time_event_optimized('Auth Email link check');
          initRecaptchaVerifier();
        }
      } else {
        goLoginWithEmail();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!apiKey && !atStep2) setAtStep2(true);
  }, [apiKey, atStep2]);

  const isContinueButtonDisabled = !!(loginLoading || !captchaObject);
  const showIfStep1 = useMemo(() => ({ display: !atStep2 ? "flex" : "none", flexDirection: "column" }), [atStep2]);
  const showIfStep2 = useMemo(() => ({ display: atStep2 ? "flex" : "none", flexDirection: "column" }), [atStep2]);
  
  const Title = useCallback(() =>
    <View style={{ display: "flex", flexDirection: "column", width: "100%"}}>
      <Text style={globalStyles.sectionTitle}>{t("pages.emailSignIn.innerTitle")}</Text>
      <Divider style={globalStyles.titleDivider} />
    </View>
    , [globalStyles.sectionTitle, globalStyles.titleDivider, t]);

  const ErrorView = useCallback(({ message, style }) => <View style={{ ...showIfStep2, alignItems: "center", ...style }}>
    <Title />
    <Text style={{ paddingTop: "8px" }}>{message}</Text>
    <Icon icon="cancel-circle" size="84" color="#ED703F" style={{ marginTop: "48px", marginBottom: "48px" }} />
    <Text>{t('pages.emailCheck.urlError2')}</Text>
    <button style={{ marginTop: "16px" }} type="button" id="sign-in-button" className={`btn btn-round ${styles.__btn}`}
      onClick={goBackToSignIn}>
      <span>{t('Go back')}</span>
    </button>
  </View>, [goBackToSignIn, showIfStep2, t]);

  const renderError = useCallback(() => {
    if (authState.loginError) return <ErrorView message={t('pages.emailCheck.tokenError')} />;
    if (!apiKey) return <ErrorView message={t('pages.emailCheck.urlError')} />;
    return '';
  }, [apiKey, authState.loginError, t]);
  
  return (  
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("pages.title", { title: t("pages.emailSignIn.title") })}</title>
        <link rel="canonical" href="https://app.wao.shop/sign-in/phone" />
      </Helmet>
      <HeaderStore showLogo altColor icons={{ back: true }} />
      <ScreenContent webMobile>
        <View style={{ padding: "30px" }}>
          <View style={{ ...showIfStep1, alignItems: "center", marginBottom: "-16px" }}>
            <Title />
            <Text style={{ paddingTop: "8px" }}>
              {storedEmail ? t(`Vamos a verificar si este enlace está vinculado a ${storedEmail}...`) : t(`Parece que estas usando un dispositivo diferente, por favor ingresa la dirección de correo a la que solicitaste el enlace.`)}
            </Text>
            <img style={{ marginTop: "24px" }} src={phoneIcon} width="235" height="205" alt="" srcSet={`${phoneIcon} 1x, ${phoneIcon2x} 2x, ${phoneIcon3x} 3x`} />
          </View>
          <View style={{ ...showIfStep1, alignItems: "center", marginBottom: "-16px" }}>
            <div style={{ width: "100%" }}>
              <FormikProvider value={formikStep1} enableReinitialize>
                <Form>
                  <div className={styles['__form-init']}>
                    <div className={`${styles['phone-section']} form-control-mat2`}>
                      <TextInputLiveFeedback label={t('Email Address')} trim id="email" name="email" type="text" value={formikStep1.values.email} />
                    </div>
                  </div>
                </Form>
              </FormikProvider>
              <Recaptcha renderedCaptcha={renderedCaptcha} refRecaptchaContainer={recaptchaContainer} validateRefreshForRecaptcha={validateRefreshForRecaptcha} setValidateRefreshForRecaptcha={setValidateRefreshForRecaptcha} t={t} />
              <button type="button" disabled={isContinueButtonDisabled} id="sign-in-button" className={`btn btn-round ${styles.__btn}`} onClick={goLoginWithEmail}>
                <span>{t('Confirm')}</span>
              </button>
            </div>
          </View>
          {
            authState.loginError || !apiKey ? renderError()
          :
              <View style={{ ...showIfStep2, alignItems: "center" }}>
                <Title />
                <Text style={{ paddingTop: "8px" }}>{t('pages.emailCheck.loading1')}</Text>
                <Progress size={30} style={{ color: "#ED703F", marginTop: "72px", marginBottom: "72px" }} />
                <Text>{t('pages.emailCheck.loading2')}</Text>
            </View>
          }
        </View>
        <FooterSocial />
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(EmailCheck);