import StepperActions from './actions';
import InitialState from ".";

export const reducer = (state = InitialState, action) => {
  switch (action.type) {

    case StepperActions.fetchSteppersRequest().type:
      return state.merge({
        steppersLoading: true,
        steppersError: null,
        steppersLoaded: false,
        steppers: null,
        stepperPagination: null
      });

    case StepperActions.fetchSteppersSuccess().type:
      return state.merge({
        steppersLoading: false,
        steppersError: null,
        steppersLoaded: true,
        steppers: action.payload.data,
        stepperPagination: null
      });

    case StepperActions.fetchSteppersFailed().type:
      return state.merge({
        steppersLoading: false,
        steppersError: action.payload.error,
        steppersLoaded: false,
        steppers: null,
        stepperPagination: null
      });

    default:
      return state;
  }
};
    
