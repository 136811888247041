/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import styles from './styles.module.sass';

import { ShopingCartContext } from '../../contexts/shoppingCart/ShopingCartContext';
import PriceDiscountFormat from '../PriceDiscountFormat';
import { FormikContext } from 'formik';
import { useParams } from 'react-router-dom';


const TotalItemsFooter = ({ t, cta, disabled, titleButton, processing = false, newTotal }) => {
  const { getCart } = useContext(ShopingCartContext);
  const { storeId } = useParams();
  const cart = getCart(storeId);
  const { itemCount = 0, total = 0 } = cart;
  const formikContext = useContext(FormikContext);
  const [localDisabled, setLocalDisabled] = useState(false);    
  const buttonCallback = useCallback(() => {
    if (formikContext) {
      cta(formikContext?.values || {});
    } else {
      cta();
    }
  }, [formikContext, cta]);

  useEffect(() => {
    if (formikContext) {
      setLocalDisabled(!(formikContext.isValid) || processing);
    }
  }, [formikContext, localDisabled, processing]);

  return (
    <section>
      <hr className={styles.divider}/>
      <Grid container spacing={1} className={styles['total-area']}>
        <Grid item xs={7} className={styles['total-data']}>
          <span className={styles.__text}>{t("TotalItems", { count: itemCount })}: <PriceDiscountFormat value={newTotal || total} discount={0} /></span>
        </Grid>
        <Grid item xs={5}>
          <button className='btn btn-round' disabled={disabled || localDisabled} onClick={buttonCallback}>{!processing ? titleButton : t('Processing...')}</button>
        </Grid>
      </Grid>
    </section>
  );
};

export default withTranslation()(TotalItemsFooter);
