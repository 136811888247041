import { createActions } from "redux-actions";
import { getSteppers } from "../../services/stepper";

const StepperActions = createActions({

  FETCH_STEPPERS_REQUEST: () => {},
  FETCH_STEPPERS_SUCCESS: (steppers) => (steppers),
  FETCH_STEPPERS_FAILED: (error) => ({ error }),
});

StepperActions.fetchStepper = (data) => async (dispatch) => {
    try {
      dispatch(StepperActions.fetchSteppersRequest());
      const result = await getSteppers(data);
      dispatch(StepperActions.fetchSteppersSuccess(result.data));
    } catch (err) {
      console.log(err);
      dispatch(StepperActions.fetchSteppersFailed({ error: err }));
    }
  };



export default StepperActions;
