import React from 'react';
import Text from '../../transition/Text';
import { useHistory } from 'react-router-dom';

const TextLink = ({ newTab, children, path, url, style, onPress, ...props }) => {
  const history = useHistory();
  const goToNewTab = () => window.open(url);
  const goToSameTab = () => window.location.assign(url);
  const externalFunction = newTab ? goToNewTab : goToSameTab;
  const redirectFunction = path ? () => history.push(path) : externalFunction;
  return <Text style={{ cursor: "pointer", textDecoration: "underline", ...style }} {...props} onPress={() => {
    if (onPress) onPress();
    redirectFunction();
  } }>{children}</Text>;
};

export default TextLink;