import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.module.sass';

const Progress = ({ size = 40, color = 'primary', ...props }) => (
    <div className={ styles.container_loader}>
      <CircularProgress size={size} color={color} {...props} />
    </div>
  );

export default Progress;
