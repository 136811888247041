import React from 'react';
import { withTranslation } from 'react-i18next';
import CurrencyFormat from 'react-currency-format';
import utils from '../../utils';
import { isEmpty } from 'ramda';
import styles from './styles.module.sass';

const PriceDiscountFormat = ({ style, value: price = 0, discountPrice = '', currency, discount, calculateDiscount = true, suffix: showSuffix = false, align, simple, parenthesis = true, fixNegative, semiBold, showDiscountPrice = false }) => {

  const initialValue = (calculateDiscount ? (discountPrice || utils.priceDiscount(price, discount)) : price);
  const isPositive = (initialValue >= 0 || !fixNegative) ? 1 : -1;
  const addDash = fixNegative && isPositive !== 1 ? "-" : "";
  const value = (isPositive * initialValue);// .toFixed(2)
  const currencyToShow = parenthesis ? `(${currency})` : currency; 
  const suffix = showSuffix ? ` ${currencyToShow}` : '';
  const valueDiscountPrice = (!isEmpty(discountPrice) && discountPrice !== '' ? discountPrice : 0); // .toFixed(2);
  const valueDiscount = !discount ? 0 : (discount * 100).toFixed();
  const hasDiscount = valueDiscount > 0 && discount > 0;
  
  const hasDecimalPrice = value % 1 !== 0;
  const hasDecimalDiscount = valueDiscountPrice % 1 !== 0;
  const hasDecimalPercentage = value % 1 !== 0


  return (
    !simple ?
      <div className={`${styles['main-content']} ${align ? styles[align] : ''} ${'fw600'}`} >
        <div className={`${semiBold ? styles.semi : ''} ${styles['final-price']}`} >
          {addDash}<CurrencyFormat style={style} isNumericString value={value} displayType="text" thousandSeparator decimalSeparator="." prefix="$" suffix={suffix} decimalScale={2} fixedDecimalScale={hasDecimalPrice}/>
        </div>
        {(!hasDiscount || !showDiscountPrice) ? '' :
          <div className={styles['full-price']} >
            <CurrencyFormat isNumericString value={valueDiscountPrice} displayType="text" thousandSeparator decimalSeparator="." prefix="$" suffix={suffix} decimalScale={2} fixedDecimalScale={hasDecimalDiscount}/>
          </div>
        }
      </div>
      :
      <CurrencyFormat isNumericString style={style} value={value} displayType="text" thousandSeparator decimalSeparator="." prefix="$" suffix={suffix} decimalScale={2} fixedDecimalScale={hasDecimalPercentage}/>
  );
};

export default withTranslation()(PriceDiscountFormat);