import React, { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import HeaderStore from '../../components/HeaderStore';
import styles from "../socio/home/styles.module.sass";
import ownStyles from './styles.module.sass';
import ActiveOffersList from './ActiveOffersList';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DealActions from '../../redux/deals/actions';
import PromotionsActions from '../../redux/promotions/actions';
import { CancelToken } from 'apisauce';
import { countryToCode } from '../../utils/constants';
import { paths } from 'ramda';
import mixpanel from '../../services/mixpanel';
import ContentLoader from "react-content-loader";
import SpinnerCircularFixed from "../../components/Spinner/CircularFixed";
import CatalogList from '../../components/CatalogList';
// import { showFriendlyDateSameYearCaseShort } from '../../utils';
// import { es } from 'date-fns/locale';
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const formToLinkFn = obj => ({ pathname: `/catalog`, state: { catalog: obj } });

// const Catalog = ({obj}) => {
//   const image = obj.images.filter(a => a.type === 'marketing')[0]
//   return <Link to={{
//     pathname: `/catalog`,
//     state: { catalog: obj }
//   }}> 
//       <div className={ownStyles['catalog-item']} style={{ 
//       backgroundImage: `url(${image.url})`,
//       backgroundSize: '100% 100%'
//     }} />
//   </Link>
// }

const ActiveOffersContainer = ({ t, history }) => {

  const [searchValue] = useState('');
  const dispatch = useDispatch();
  const [sourceDeals, serSourceDeals] = useState();
  const [shopId, userId, type] = paths([['userMe', 'shopId'], ['userMe', 'id'], ['userMe', 'type']], useSelector(state => state.auth));
  const isReferrer = type === 'referrer';
  const { deals, dealsPagination, dealsLoading } = useSelector(state => state.deals);
  const { dealsByCountry, dealsByCountryPagination, dealsByCountryLoading } = useSelector(state => state.deals);
  const { catalogsList, catalogsListLoading } = useSelector(state => state.promotions);
  const { country } = useParams();
  const countryCode = countryToCode(country || '');
  // const context = { context: countryCode?.toLowerCase() || userCountry?.toLowerCase() };

  const fetchData = useCallback((name, increase) => {
    const source = CancelToken.source();
    serSourceDeals(source);
    if (!countryCode && shopId) {
      dispatch(DealActions.fetchDeals(
        {
          shopId,
          name,
          currentPage: increase && dealsPagination?.currentPage ? Number(dealsPagination?.currentPage) + 1 : 1,
          cancelToken: source.token,
          userId,
          grantPoints: !isReferrer ? true : null
        })
      );
      dispatch(PromotionsActions.fetchCatalogsList(userId));
    } else {
      dispatch(DealActions.fetchDealsByCountry(
        {
          country: countryCode,
          name,
          currentPage: increase && dealsByCountryPagination?.currentPage ? dealsByCountryPagination.currentPage + 1 : 1,
          cancelToken: source.token
        })
      );
    }
  }, [countryCode, shopId, dispatch, dealsPagination, userId, isReferrer, dealsByCountryPagination]);

  useEffect(() =>
    // cancels after the component's life cycle is over
    () => {
      if (sourceDeals) sourceDeals.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);


  useEffect(() => {
    fetchData(null, false);
    mixpanel.track_optimized('Active Offers');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const topCatalogsOfweekFakeData = [
  //   {
  //     id: 1,
  //     name: 'En Tendecia',
  //     createdAt: new Date(),
  //     totalSells: 6,
  //     totalShares: 8
  //   },
  //   {
  //     id: 2,
  //     name: 'Todo en Tecnología',
  //     createdAt: new Date(),
  //     totalSells: 6,
  //     totalShares: 8
  //   },
  //   {
  //     id: 3,
  //     name: 'Celulares',
  //     createdAt: new Date(),
  //     totalSells: 6,
  //     totalShares: 8
  //   },
  //   {
  //     id: 4,
  //     name: 'Ropa',
  //     createdAt: new Date(),
  //     totalSells: 6,
  //     totalShares: 8
  //   },
  //   {
  //     id: 5,
  //     name: 'Video juegos',
  //     createdAt: new Date(),
  //     totalSells: 6,
  //     totalShares: 8
  //   }
  // ]

  // const TopCatalog = useCallback(({obj}) => <div className={ownStyles['top-catalog-item']}>
  //         <div className={ownStyles['top-catalog-item-img']}>
  //           <span>{obj.name}</span>
  //         </div>
  //         <div className={ownStyles['top-catalog-item-text']}>
  //           <span>{t('Created')}: {showFriendlyDateSameYearCaseShort(obj.createdAt, es)}</span>
  //           <span>{t('Shared')}: {obj.totalShares} {t('Times')}</span>
  //           <span>{t('Total sales')}: {obj.totalSells} {t('Sales')}</span>
  //         </div>
  //     </div>, [t])


  // const youCatalogsData = [
  //   {
  //     id: 1,
  //     name: 'Solo Hogar',
  //     totalPurchasesFromCatalog: 3,
  //     totalShares: 10
  //   },
  //   {
  //     id: 2,
  //     name: 'Solo Deportes',
  //     totalPurchasesFromCatalog: 104,
  //     totalShares: 68
  //   },
  //   {
  //     id: 3,
  //     name: 'Solo Para Mujeres',
  //     totalPurchasesFromCatalog: 8,
  //     totalShares: 3
  //   },
  //   {
  //     id: 4,
  //     name: 'Solo Para Hombres',
  //     totalPurchasesFromCatalog: 6,
  //     totalShares: 8
  //   },
  //   {
  //     id: 5,
  //     name: 'Solo Para Niños',
  //     totalPurchasesFromCatalog: 6,
  //     totalShares: 8
  //   },
  //   {
  //     id: 6,
  //     name: 'Solo Para Mascotas',
  //     totalPurchasesFromCatalog: 6,
  //     totalShares: 8
  //   }
  // ]

  // const YouCatalogs = useCallback(({obj}) => <div className={ownStyles['you-catalog-item']}>
  //         <div className={ownStyles['you-catalog-item-img']}>
  //           <span>{obj.name}</span>
  //         </div>
  //         <div className={ownStyles['you-catalog-item-text']}>
  //           <span>{obj.totalShares}</span>
  //           <span>{t('Times shared')}</span>
  //         </div>
  //         <div className={ownStyles['you-catalog-item-text']}>
  //           <span>{obj.totalPurchasesFromCatalog}</span>
  //           <span>{t('Purchases from the catalog')}</span>
  //         </div>
  //     </div>, [t])
      

  return (
    <div>
      <div className={`${styles['page-wrapper']} app-wrapper-mobile`}>
        <section className={styles.content}>
          <section className={styles.header}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>WAO! - {t('Promotional content')}</title>
              <link rel="canonical" href="https://app.wao.shop/active-offers" />
            </Helmet>
            <HeaderStore history={history} clickable={false} showLogo icons={{ back: true }} altColor />
          </section>

          <div className={ownStyles['page-wrapper-active-offers']}>
            <p className={ownStyles['main-title']}>{t('Promotional content')}</p>

            { !countryCode && shopId ?
              <div>
                {catalogsListLoading ?
                  <ContentLoader
                    speed={2}
                    width="100%"
                    height={180}
                    viewBox="0 0 600 180"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="25" y="0" rx="3" ry="3" width="25%" height="25" />
                    <rect x="25" y="35" rx="3" ry="3" width="15%" height="10" />
                    <rect x="25" y="55" rx="3" ry="3" width="50%" height="15" />
                    <rect x="25" y="77" rx="3" ry="3" width="55%" height="15" />
                    <rect x="25" y="99" rx="3" ry="3" width="57%" height="15" />
                    <rect x="25" y="121" rx="3" ry="3" width="40%" height="15" />
                    <rect x="70%" y="45" rx="3" ry="3" width="22%" height="136" />
                    <rect x="70%" y="5" rx="8" ry="8" width="22%" height="27" />
                  </ContentLoader>
                  : <div>
                    <p className={ownStyles['main-subtitle']}>{t('Catalog title')}</p>
                    <p className={ownStyles['main-content']}>{t('catalogDescription')}</p>
                    <p className={ownStyles['main-numberOf']}>{`${catalogsList?.length || 0} ${t('catalogs')}`}</p>
                    <CatalogList catalogs={catalogsList} filter="marketing" formToLinkFn={formToLinkFn} />
                    </div>
                  
                  // : <div>
                  //   <p className={ownStyles['main-subtitle']}>{t('You Catalogs')}</p>
                  //   <p className={ownStyles['main-content']}>{t('YouCatalogsDescription')}</p>
                  //   <p className={ownStyles['main-numberOf']}>{`${youCatalogsData?.length} ${t('catalogs')}`}</p>
                  //   <section className={ownStyles['you-catalogs-section']}>
                  //     <section className={ownStyles['you-catalogs-content']}>
                  //       <div className={ownStyles['vertical-items']}>
                  //         {youCatalogsData?.slice(0,3).map(obj => <YouCatalogs obj={obj} key={obj.id} />)}
                  //       </div>
                  //     </section>
                  //   </section>
                  //   <section className={ownStyles.link}>
                  //   <Link to={`/store/${shopId}/custom-catalog`} className={ownStyles.link}>{t('See all my catalogs')}</Link>
                  //   </section>
                  // </div>
                }
              </div>
              : ''}

          </div>
          </section>
        </div>

        { !countryCode && shopId ?
        <Divider className={ownStyles.divider} style={{height:"8px"}}/>
        : '' }
          {/* <div className={`${styles['page-wrapper'] } app-wrapper-mobile`}>
            <section className={styles.content}>
             <div className={ownStyles['page-wrapper-active-offers']}>


            { !countryCode && shopId ?
              <div>
                { catalogsListLoading ?
                  <ContentLoader
                    speed={2}
                    width="100%"
                    height={180}
                    viewBox="0 0 600 180"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="25" y="0" rx="3" ry="3" width="25%" height="25" />
                    <rect x="25" y="35" rx="3" ry="3" width="15%" height="10" />
                    <rect x="25" y="55" rx="3" ry="3" width="50%" height="15" />
                    <rect x="25" y="77" rx="3" ry="3" width="55%" height="15" />
                    <rect x="25" y="99" rx="3" ry="3" width="57%" height="15" />
                    <rect x="25" y="121" rx="3" ry="3" width="40%" height="15" />
                    <rect x="70%" y="45" rx="3" ry="3" width="22%" height="136" />
                    <rect x="70%" y="5" rx="8" ry="8" width="22%" height="27" />
                  </ContentLoader>
                : <div>
                    <p className={ownStyles['main-subtitle']}>{t('Top catalogs of the week')}</p>
                    <p className={ownStyles['main-content']}>{t('TopCatalogOfTheWeekDescription')}</p>
                    <p className={ownStyles['main-numberOf']}>{`${topCatalogsOfweekFakeData?.length} ${t('catalogs')}`}</p>
                    <section className={ownStyles['top-catalogs-section']}>
                      <section className={ownStyles['top-catalogs-content']}>
                        <div className={ownStyles['vertical-items']}>
                          {topCatalogsOfweekFakeData?.slice(0,3).map(obj => <TopCatalog obj={obj} key={obj.id} />)}
                        </div>
                      </section>
                    </section>
                    <section className={ownStyles.link}>
                    <Link to={`/store/${shopId}/custom-catalog`} >{t('See all catalogs')}</Link>
                    </section>
                  </div>
                }
              </div>
            : ''}

            </div>
            </section>
          </div>

            { !countryCode && shopId ?
            <Divider className={ownStyles.divider} style={{height:"8px"}}/>
            : '' } */}
      <div className={`${styles['page-wrapper'] } app-wrapper-mobile`}>
        <section className={styles.content}>
          <div className={ownStyles['page-wrapper-active-offers']}>
            <p className={ownStyles['main-subtitle']}>{t('Product title')}</p>
            <p className={ownStyles['main-content']}>{t('offerDescription')}</p>
            <p className={ownStyles['main-numberOf']}>
              {dealsLoading ?
                <SpinnerCircularFixed
                  size={13}
                  thickness={180}
                  speed={100}
                  color="85, 85, 85"
                  secondaryColor="85, 85, 85"
                />
                : `${(dealsPagination?.total || dealsByCountryPagination?.total || 0)}`}
              {'  '}
              {`${t('offers')}`}
            </p>
          </div>
          <div className={ownStyles['page-wrapper-active-offers']}>
            <ActiveOffersList
              data={deals || dealsByCountry}
              loading={dealsLoading || dealsByCountryLoading}
              pagination={dealsPagination || dealsByCountryPagination}
              shopId={shopId}
              country={countryCode}
              fetchData={() => fetchData(searchValue, dealsPagination?.lastPage && (dealsPagination?.currentPage < dealsPagination?.lastPage))}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default withTranslation()(ActiveOffersContainer);
