import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PriceDiscountFormat from '../PriceDiscountFormat';
import SubtitleAndLegend from '../SubtitleAndLegend';
import styles from './index.module.sass';

const Rewards = ({ t, totals, data }) => (
    <>
      <div className={styles.header}>
        <div className={styles.subheader}>
          <div className={styles['subheader-title']}>{t('rewards')}:</div>
          <div className={styles['subheader-total']}>
            {totals && <PriceDiscountFormat value={totals.totalByRewards} discount={0} />}
            {!totals && <PriceDiscountFormat value={0} discount={0} />}
          </div>
        </div>
      </div>
      <div className={styles['rewards-header']}>
        <div className={styles['commission-grid']}>
          {data && data.map((x, i) => (
            <Fragment key={`${i}p`}>
              <SubtitleAndLegend subtitle={x.byReward.name} legend={x.byReward.description} />
              <SubtitleAndLegend style={{ textAlign: 'right' }}
               subtitle={<PriceDiscountFormat align="right" value={x.amount} />}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );

export default withTranslation()(Rewards);