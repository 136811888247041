export default {
  typography: {
    button: {
      textTransform: 'none',
      fontFamily: 'Open Sans',
    },
    body2: {
      fontSize: '14px',
    },
    body1: {
      lineHeight: '1.15',
    },
    fontFamily: 'Open Sans',
  },
  palette: {
    primary: { // orange
      main: '#E54A38',
      light: '#EA6E5F',
      dark: '#A03327',
      contrast: '#FFFFFF',
    },
    secondary: { // purple
      main: '#7D37D7',
      light: '#975FDF',
      dark: '#572696',
      contrast: '#FFFFFF',
    },
    customGreen: { // green
      main: '#CDEDEC',
      light: '#E5F6F5',
      dark: '#00A699',
      contrast: '#000000DE',
    },
    customYellow: { // yellow
      main: '#F8ECCC',
      light: '#F9EFD6',
      dark: '#D2C7A9',
      contrast: '#000000DE',
    },
    customPink: { // pink
      main: '#FCDCD6',
      light: '#FCE3DE',
      dark: '#E6BFAD',
      contrast: '#000000DE',
    },
    customDarkBlue: {
      main: '#1B2835',
      light: '#48535D',
      dark: '#121C25',
      contrast: '#FFFFFF'
    },
    customGray: {
      main: '#ECECEC',
      light: '#EFEFEF',
      dark: '#A5A5A5',
      contrast: '#000000DE',
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans !important',
          fontSize: 15,
          lineHeight: 1.27,
          letterSpacing: 0.17,
          color: '#c0c0c0',
        }
      }
    },
    MuiRating: {
      iconEmpty: {
        fill: '#efeff4'
      },
      iconFilled: {
        fill: '#a84600'
      }
    }
  }
};