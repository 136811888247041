import api from './api';

export const createOrder = (data) => api.post("orders/", data);

export const getOrders = ({ orderId, ...params }) => api.get(`orders/${orderId || ''}`, { ...params });

export const createOrderWithPayment = (data) => api.post("orders/socio-mini-store", data);

export const getOrderStatus = () => api.get("orders/statuses");

export const validatePromo = (data) => api.post("orders/validate-promotion-code", data);