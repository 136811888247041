import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingLine = ({
  width,
  height,
  viewBox,
  backgroundColor = "#f3f3f3",
  foregroundColor = "#ecebeb",
  margin
}) => (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      style={{margin}}
    >
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />
    </ContentLoader>
  );

export default LoadingLine;
