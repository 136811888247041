import React, { useCallback, useEffect } from 'react';
import styles from './styles.module.sass';
import IcomoonReact from 'icomoon-react';
import iconSet from "../../assets/fonts/icomoon/selection.json";
import { withTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";
import FooterSocial from '../../components/FooterSocial';
import mixpanel from 'mixpanel-browser';
import View from '../../transition/View';
import ScreenContent from '../../transition/ScreenContent';
import Text from '../../transition/Text';
import useGlobalStyles from '../../hooks/useGlobalStyles';
import Button from '../../transition/Button';
import TextLink from '../../components/atoms/TextLink';
import landingImage from '../../assets/landingPage/landingImage@3x.png';
import landingBackground from '../../assets/landingPage/background@3x.png';
import Screen from '../../transition/Screen';
import { useSelector } from 'react-redux';
import { getDefaultRolePageRedirect } from '../../utils/constants';

const LandingPage = ({ t, history }) => {

  const { userMe } = useSelector(state => state.auth);
  
  const globalStyles = useGlobalStyles();

  const nextPath = useCallback((path) => {
    const newRoute = {
      pathname: path,
    };
    history.push(newRoute);
  }, [history]);

  const GoStartRegister = useCallback(() => () => {
    mixpanel.track_optimized('From landing to Registration');
    nextPath('/registration');
  }, [nextPath]);

  const goToSignIn = () => {
    mixpanel.track_optimized('Goto Sign In');
  };

  mixpanel.track_optimized('Welcome');

  const navigateAfterAuthenticated = useCallback((userParam) => {
      history.push(getDefaultRolePageRedirect({role: userParam?.role || userParam?.type, user:userMe}));
  }, [history, userMe]);

  useEffect(() => {
    if (userMe?.country) navigateAfterAuthenticated(userMe);
  }, [userMe, navigateAfterAuthenticated]);

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("pages.title", { title: t("pages.landingPage.title") })}</title>
        <link rel="canonical" href="https://app.wao.shop/landingPage" />
      </Helmet>
      <ScreenContent webMobile className={styles.landingPage}>
          <View style={{ backgroundImage: `url('${landingBackground}')`, backgroundSize: "cover", backgroundRepeat: "round"}}>
            <View className={styles.logo}>
              <IcomoonReact color="#000" iconSet={iconSet} icon="logo" size={180} />
            </View>
            <View className={styles.landingImage}>
              <img src={landingImage} />
            </View>
          </View>
        <View style={{ padding: "0 30px", zIndex: 100000 }}>
          <Text style={{ paddingTop: "8px", fontSize: "26px", fontWeight: "600", color: "#030029" }}>{t("Get ready to be a WAO partner!")}</Text>
          <Text style={{ paddingTop: "8px", fontSize: "16px", color: "#030029" }}>{t("Landing description")}</Text>

          <View style={{ padding: "36px 0" }}>
            <View style={{ ...globalStyles.row, ...globalStyles.center, marginTop: "14px" }}>
              <Button 
                style={{ width: "320px", height: "42px", fontSize: "16px", fontWeight: 600, borderRadius: "12px", color: "#FFF", background: "#030029", boxShadow: "0px 2px 10px rgba(4, 4, 21, 0.5)" }}
                onPress={GoStartRegister()}
                >
                  <Text>{t('Start here')}</Text>
              </Button>
            </View>
          </View>
          <View style={{ ...globalStyles.row, ...globalStyles.center, margin: "14px 0 30px 0" }}>
            <Text>{t("Already have an account")}</Text>
            <TextLink onPress={goToSignIn} style={{ ...globalStyles.textButton, marginLeft: "10px", color: "#000" }} path="/sign-in"><Text>{t("Sign In")}</Text></TextLink>
          </View>
        </View>
        <FooterSocial />
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(LandingPage);
