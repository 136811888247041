import { createActions } from 'redux-actions';

import {
  registerNotifications,
} from '../../services/notifications';

import {
  GenericError,
} from '../../utils/errors';


import {
  putMe,
} from '../../services/auth';

import AuthActions from '../auth/actions';

const NotificationActions = createActions({
  REGISTER_PUSH_NOTIFICATION_REQUEST: () => { },
  REGISTER_PUSH_NOTIFICATION_SUCCESS: token => ({ token }),
  REGISTER_PUSH_NOTIFICATION_FAILED: error => ({ error }),

  CLEAR: () => { },
});

NotificationActions.registerNotifications = () => async (dispatch, getState) => {
    dispatch( NotificationActions.registerPushNotificationRequest() );
    try {
      const token = await registerNotifications();

      if (token === undefined) {
        return dispatch( NotificationActions.registerPushNotificationFailed( new GenericError('Push notification token denied') ) );
      }

      const currentUser = getState().auth.user;

      if (token === currentUser.fcm_token) {
        return dispatch( NotificationActions.registerPushNotificationSuccess(token) );
      }

      const response = await putMe({ fcm_token: token });
      const newUser = currentUser.merge({ fcm_token: response?.data.data.fcm_token });
      dispatch(AuthActions.updateUser(newUser));

      dispatch( NotificationActions.registerPushNotificationSuccess(token) );
    } catch (e) {
      dispatch( NotificationActions.registerPushNotificationFailed( new GenericError(e) ) );
    }
    return undefined;
  };

export default NotificationActions;
