/* eslint-disable default-param-last */
import HelpActions from "./actions";
import InitialState from ".";

const procedures = {
  [HelpActions.fetchHelpRequest().type]: (state) => state.merge({
      helpListLoading: true,
      helpListError: null
    }),
  [HelpActions.fetchHelpSuccess().type]: (state, payload) => {
    let comm;
    if (payload.paging.page === 1) {
      comm = payload.data;
    } else if(payload.data && payload.data.length){
      comm = state.helpList.concat(payload.data);
    } else {
      comm = payload.data;
    }
    return state.merge({
      helpListLoading: false,
      helpListError: null,
      helpList: comm,
      helpListPagination: payload.paging
    });
  },
  [HelpActions.fetchHelpFailed().type]: (state) => state.merge({
      helpListLoading: false,
      helpListError: null
    })
};

export const reducer = (state = InitialState, action) => procedures[action.type] ? procedures[action.type](state, action.payload) : state;
