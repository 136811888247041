import api from "./api";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import { takeNullPairsOut } from "../utils";
import { getUserToken as fbGetUserToken, signOut } from "./firebase";
import { emailForSignInLocal } from "./local";

export const login = async (email, password) => (
  new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(result => { resolve(result.user); })
        .catch(error => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  })
);

export const getUserToken = () => fbGetUserToken;

export const createAccount = async (params) => (
  new Promise((resolve, reject) => {
    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(params.email, params.password)
        .then(result => { resolve(result.user); })
        .catch(error => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  })
);

export const updateProfile = async (params) => (
  new Promise((resolve, reject) => {
    try {
      const user = firebase.auth().currentUser;
      user
        .updateProfile({
          displayName: `${params.firstName}  ${params.lastName}`,
        })
        .then(() => { resolve(user); })
        .catch(error => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  })
);

export const getUserFromFirebase = () => {
  const firebaseUser = firebase.auth().currentUser;
  const user = { 'email': firebaseUser.email, 'firstName': '', 'lastName': '' };
  if (firebaseUser.displayName) {
    const [firstName, lastName] = firebaseUser.displayName.split("  ");
    if (firstName) user.firstName = firstName;
    if (lastName) user.lastName = lastName;
  }
  return user;
};

export const sendEmailVerification = async () => (
  new Promise((resolve, reject) => {
    try {
      const user = firebase.auth().currentUser;
      user.sendEmailVerification()
        .then(() => { resolve(); })
        .catch(error => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  })
);

export const verifyEmail = async (actionCode) => (
  new Promise((resolve, reject) => {
    try {
      firebase.auth().applyActionCode(actionCode)
        .then(() => { resolve(); })
        .catch(error => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  })
);

export const forgotPassword = (email) => (
  new Promise((resolve, reject) => {
    try {
      firebase.auth().sendPasswordResetEmail(email)
        .then(() => { resolve(); })
        .catch(error => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  })
);

export const changePassword = (password) => (
  new Promise((resolve, reject) => {
    try {
      if (firebase.auth().currentUser) {
        firebase.auth().currentUser.updatePassword(password)
          .then(() => { resolve(); })
          .catch(error => { reject(error.message); });
      }
    } catch (err) {
      reject(err);
    }
  })
);

export const updateUserMe = ({ userId, cancelToken, ...data }) => (
  api.put(`/users/${userId}`, data, takeNullPairsOut({ cancelToken }))
);

export const storeCredentials = (credentials) => api.storeToken(credentials);

export const removeCredentials = async () => {
  api.removeToken();
  await signOut();
};

export const restoreCredentials = api.restoreToken;


export const signInWithPhoneNumber = async (phoneNumber, appVerifier) => {
  if (!phoneNumber) return;
  const phone = phoneNumber[0] === '+' ? phoneNumber : `+${phoneNumber}`;
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().signInWithPhoneNumber(phone, appVerifier)
        .then((confirmationResult) => {
          resolve(confirmationResult)
        })
        .catch(error => {
          reject(error)
        });
    } catch (err) {
      reject(err);
    }
  });
};


export const registerUser = (data) => api.post("/users/register", data);
export const getUserMe = () => api.get("users/me");
export const putMe = (data) => api.put("users/me", data);

export const sentOnboarding = (data) => api.post("users/onboarding", data);
export const getResultOnboarding = ({ userId, ...params }) => api.get(`users/onboarding/${userId || ''}`, { ...params });
export const getOnboardingQuestions = () => api.get("users/onboardingQuestions");


export const checkFirebaseUserSession = () => (
  new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) resolve(user);
      else reject(new Error("User is signed out."));
    });
  })
);

export const setAnalyticsUserId = (userId) => {
  firebase.analytics().setUserId(userId);
};

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account" });
  return new Promise((resolve, /* reject */) => {
    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        resolve(result?.user);
      })
      .catch((error) => {
        console.log("Error = ", error);
        // reject(error.message);
      });
  });
};

export const sendLinkToEmail = (url, email) => {
  const actionCodeSettings = {
    // URL must be in the authorized domains list in the Firebase Console.
    url,
    handleCodeInApp: true,
    // dynamicLinkDomain:
  };
  firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      // Link sent, email saved in case they open the link on the same device.
      emailForSignInLocal.set(email);
    })
    .catch((error) => {
      console.log({error});
    });
};

export const signInWithEmailLink = async (url, email = '') => new Promise((resolve, reject) => {
  const isSignInURL = firebase.auth().isSignInWithEmailLink(url);
  if (isSignInURL) { 
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before sign-in
    if (!email) {
      reject(new Error("Missing email"));
    } else {
      // The client SDK parses code
      firebase.auth().signInWithEmailLink(email, url)
        .then((result) => {
          // Clear email from storage.
          if (result.user) {
            emailForSignInLocal.remove();
            resolve(result);
          }
          // You can access the new user via result.user
        })
        .catch((error) => {
          reject(error);
        });
    }
  }
});

