import React from 'react';

import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';


import TermsAndConditions from './index';

import styles from './styles.module.sass';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '80%',
    maxHeight: '80%',
    overflow: 'scroll',
    borderRadius: '5px',
    backgroundColor: theme?.palette?.background?.paper,
    // border: '2px solid #000',
    boxShadow: theme?.shadows[5],
    padding: theme?.spacing(2, 4, 3),
  },
}));

export default function TermsAndConditionsAsModal(props) {
    
  const { label } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span onClick={handleOpen} className={styles.__link}>{label}</span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TermsAndConditions />
          </div>
        </Fade>
      </Modal>
    </>
    
  );
}
