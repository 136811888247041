import React, { useEffect, useMemo, useState } from 'react';
import ProductVariantSelector from '../ProductVariantSelector';
import WaoIcon from '../WaoIcon';
import { Grid, Drawer } from '@mui/material';
import Select from 'react-select';

import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';

const ProductVariantsDrawer = ({ ctaText, deals, show, onClose, cta, desiredQuantity, t }) => {
  const [selectedVariant, setSelectedVariant] = useState();
  const [quantity, setCurrentQuantity] = useState(desiredQuantity);

  useEffect(() => {
    setCurrentQuantity(selectedVariant && desiredQuantity > selectedVariant.quantity ? 1 : desiredQuantity);
  }, [desiredQuantity, selectedVariant]);

  const options = useMemo(
    () =>
      [...Array(selectedVariant?.quantity === null ? 10 : selectedVariant?.quantity || 0).keys()].map(i => ({
        value: i + 1,
        label: i + 1,
      })),
    [selectedVariant]
  );

  return (
    <Drawer
      anchor="bottom"
      open={show}
      onClose={onClose}
      transitionDuration={500}
      PaperProps={{ style: { borderRadius: '20px 20px 0 0', boxShadow: '0px 0px 12px 0px rgba(3, 0, 41, 0.4)' } }}
    >
      <div className={styles.variantsDrawerBody}>
        <Grid container className={styles.closeSection}>
          <Grid item xs>
            <WaoIcon icon="close-" size="16" color="#030029" onClick={onClose} />
          </Grid>
        </Grid>
        <ProductVariantSelector
          isDrawerLayout
          productGroup={deals.deals}
          title={deals.name}
          onProductDecision={setSelectedVariant}
        />
        <div className={styles.buyArea}>
          <div className={styles.quantityRow}>
            <span>{t('Quantity')}:</span>
            <Select
              isSearchable={false}
              menuPlacement="top"
              className={styles.variantsSelectInput}
              placeholder={quantity}
              options={options}
              onChange={row => setCurrentQuantity(row.value)}
              isOptionSelected={row => row.value === quantity}
              value={quantity}
              styles={{
                control: stl => ({
                  ...stl,
                  borderRadius: '8px',
                  borderColor: 'rgb(208, 208, 208)',
                }),
                placeholder: stl => ({
                  ...stl,
                  fontSize: '14px',
                }),
                dropdownIndicator: stl => ({
                  ...stl,
                  color: '#040415',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                option: (stl, { isSelected }) => ({
                  ...stl,
                  ...(isSelected ? { backgroundColor: '#ee7d40' } : { backgroundColor: undefined }),
                }),
                menuList: stl => ({
                  ...stl,
                  maxHeight: '250px',
                }),
              }}
            />
          </div>
          <button
            disabled={!selectedVariant || quantity === 0}
            className={styles.buyNowButton}
            onClick={() => {
              onClose();
              cta(selectedVariant, quantity);
            }}
          >
            {ctaText}
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default withTranslation()(ProductVariantsDrawer);
