import React, { useCallback, useEffect, useMemo, useState } from 'react';
import pointsStyles from '../points/styles.module.sass';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeaderStore from '../../components/HeaderStore';
import Screen from '../../transition/Screen';
import ScreenContent from '../../transition/ScreenContent';
import QuizMaker from '../../components/QuizMaker';
import AuthActions from '../../redux/auth/actions';
import Emoji from '../../components/Emoji';
import Progress from '../../components/Progress';

// const question1 = {
//   id: 0,
//   title: "¿Cómo conociste a WAO!?",
//   type: "single",
//   required: true,
//   answers: [
//     { id: 0, title: "Facebook WAO!" },
//     { id: 1, title: "Instagram WAO" },
//     { id: 2, title: "Página web WAO" },
//     { id: 3, title: "YouTube WAO" },
//     { id: 4, title: "Me contó un amigo/ pariente" },
//     { id: 5, title: "Reclutador por redes sociales" },
//     { id: 6, title: "Otro" }
//   ]
// };

// const question2 = {
//   id: 1,
//   title: "¡Un gusto! Nos encantaría saber algo más sobre vos… ¿Hace cuánto tiempo que trabajas en ventas?",
//   type: "single",
//   required: true,
//   answers: [
//     { id: 0, title: "Nunca trabajé en ventas", skipTo: 6 },
//     { id: 1, title: "Menos de 1 año" },
//     { id: 2, title: "1 a 3 años" },
//     { id: 3, title: "3 a 5 años" },
//     { id: 4, title: "Más de 5 años" },
//   ]
// }

// const question3 = {
//   id: 2,
//   title: "Elegí los lugares donde vendés (Elige tantas opciones como desees)",
//   type: "multiple",
//   required: true,
//   answers: [
//     { id: 0, title: "Tengo tienda física o depósito" },
//     { id: 1, title: "Vendo en una página web, mercadolibre, etc." },
//     { id: 2, title: "Vendo en mis redes sociales (Facebook, Instagram, FB Marketplace)" },
//     { id: 3, title: "Vendo por WhatsApp, Telegram u otros mensajeros" },
//   ]
// }

// const question4 = {
//   id: 3,
//   title: "¿Cuánto tiempo dedicás a tus ventas?",
//   type: "single",
//   required: true,
//   answers: [
//     { id: 0, title: "Algunas horas (1 - 2hrs al día)" },
//     { id: 1, title: "Medio tiempo (3 - 4hrs al día)" },
//     { id: 2, title: "Tiempo completo (+5hrs al día)" }
//   ]
// }

// const question5 = {
//   id: 4,
//   title: "¿Qué categorías de productos vendes? (Elige tantas opciones desees)",
//   type: "multiple",
//   required: true,
//   answers: [
//     { id: 0, title: "Tecnología" },
//     { id: 1, title: "Hogar" },
//     { id: 2, title: "Belleza y salud" },
//     { id: 3, title: "Moda" },
//     { id: 4, title: "Otros" },
//   ]
// }

// const question6 = {
//   id: 5,
//   title: "¿Alguna vez vendiste con alguna de estas compañías?  (Elige tantas opciones desees)",
//   type: "multiple",
//   doubleColumn: true,
//   required: false,
//   answers: [
//     { id: 0, title: "Avon / Natura" },
//     { id: 1, title: "Mary Kay" },
//     { id: 2, title: "NuSkin" },
//     { id: 3, title: "Amway" },
//     { id: 4, title: "Tupperware" },
//     { id: 5, title: "ESSEN" },
//     { id: 6, title: "Just" },
//     { id: 7, title: "Otros", open: true }
//   ]
// }

// const question7 = {
//   id: 6,
//   title: "¿Cuántos seguidores tienes en tu red social preferida (Facebook, Instagram, TikTok, YouTube)?",
//   type: "multiple",
//   required: true,
//   answers: [
//     { id: 0, title: "No tengo redes sociales" },
//     { id: 1, title: "1 - 500" },
//     { id: 2, title: "501 - 1,500" },
//     { id: 3, title: "1,501 - 3,000" },
//     { id: 4, title: "3,001 - 5,000" },
//     { id: 5, title: "5,001 + " },
//   ]
// }
// const questions = [question1, question2, question3, question4, question5, question6, question7];

const QuizContainer = ({ history, location, t }) => {
  useSelector(state => state.stepperInfo);
  const {questions, questionsLoading , questionsError, userMe} = useSelector(state => state.auth);

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [previousChangeQuestionNumber, setPreviousChangeQuestionNumber] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [skipQuestions, setSkipQuestions] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOnboardingResult, setIsOnboardingResult] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const getUserStorage = useMemo(()=> (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null),[]);
  const user = useMemo(()=> (location?.user || getUserStorage),[location?.user, getUserStorage]);

  const dispatch = useDispatch();

  const navigateAfterAuthenticated = useCallback(() => {
    if(userMe?.metadata?.status === 'onboarding') history.push('/home');
  }, [history, userMe]);

  const quiestionData = useMemo(() => {
    let newQuestions = questions;
    if(questions){
     newQuestions = questions?.map(question => {
      let questionObject = question;
        if(question?.type === 'multiple'){
          questionObject = {...question, description: t('Select all that apply')}
        }
        return questionObject;
      })

    }
    return newQuestions;

  },[questions, t]);

  useEffect(() => {
    if (userMe?.country) navigateAfterAuthenticated(userMe);
  }, [userMe, navigateAfterAuthenticated]);

  useEffect(() => {
    dispatch(AuthActions.fetchOnboardingQuestions());
  }, [dispatch])  

  return (
    <Screen>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - {t('Onboarding')}</title>
        <link rel="canonical" href="https://app.wao.shop/quiz" />
      </Helmet>
      {isSubmitting || isOnboardingResult ? "" : <HeaderStore history={history} showLogo icons={{ back: true }} altColor />}
      <ScreenContent webMobile style={{ background: "#ECECEC", padding: isSubmitting || isOnboardingResult ? "0" : "20px" }}>

        {questionsError && !quiestionData?.length && !questionsLoading ? <div className={pointsStyles["no-points"]}>
            <h3>{t("Error")}</h3>
            <div className="my20 fs80"><Emoji label="shrug" content="🤷‍♀️" /></div>
          </div>
        :"" }

        {(questionsLoading && !quiestionData?.length) ? <Progress /> : ''}


        { quiestionData?.length && !questionsLoading && <QuizMaker 
          questions={quiestionData} 
          questionsLoading={questionsLoading} 
          questionsError={questionsError} 
          user={user} 
          userMe={userMe} 
          history={history}
          t={t} 
          isSubmitting={isSubmitting} 
          setIsSubmitting={setIsSubmitting}
          skipQuestions={skipQuestions} 
          setSkipQuestions={setSkipQuestions} 
          isOnboardingResult={isOnboardingResult}
          setIsOnboardingResult={setIsOnboardingResult}
          currentQuestionNumber={currentQuestionNumber}
          setCurrentQuestionNumber={setCurrentQuestionNumber}
          previousChangeQuestionNumber={previousChangeQuestionNumber}
          setPreviousChangeQuestionNumber={setPreviousChangeQuestionNumber}
          selectedAnswers={selectedAnswers}
          setSelectedAnswers={setSelectedAnswers}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          completed={completed}
          setCompleted={setCompleted}
          />}
      </ScreenContent>
    </Screen>
  );
};

export default withTranslation()(QuizContainer);
