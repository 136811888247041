import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PagesActions from "../../redux/pages/actions";
import { usePrevious } from "../../utils/hooks";

const KeepScroll = ({ view, scroll = false, delay }) => {

  const pages = useSelector(state => state.pages);
  const prevScroll = usePrevious(scroll);
  const dispatch = useDispatch();
  const pageConfig = pages[view];
  const updateScrollPosition = useCallback(() => {
    const scrollVal = document?.body?.scrollTop || document?.documentElement?.scrollTop || 0;
    dispatch(PagesActions.updateScrollRequest({ scroll: scrollVal, view }));
  }, [dispatch, view]);

  useEffect(() => {
    if (scroll !== prevScroll && scroll) {
      window.scrollTo(0, pageConfig.scroll);
      if (delay) {
        const timeoutId = setTimeout(() => {
        }, delay);
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
    return () => { };
  }, [scroll, prevScroll, pageConfig, delay]);

  useEffect(() => {
    window.addEventListener('scroll', updateScrollPosition, true);
    return () => {
      window.removeEventListener('scroll', updateScrollPosition, true);
    };
  }, [updateScrollPosition]);

  return null;
};

export default KeepScroll;
