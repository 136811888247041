import React, {Suspense, lazy } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import history from './history';
import ScrollToTop from './components/ScrollToTop';

import SignIn from "./pages/auth/signIn";
import landingPage from "./pages/registration/landingPage";
import TermsAndConditions from "./pages/terms-and-conditions";
import StoreDealsListing from "./pages/deals/listing";
import StoreDealsDetail from "./pages/deals/detail";
import ShoppingCart from "./pages/shopping-cart";
import Home from "./pages/socio/home";
import Account from "./pages/user/account";
import Checkout from "./pages/checkout";
import CheckoutSuccess from "./pages/checkout/success";
import PhoneSignIn from "./pages/auth/phoneSignIn";
import EmailSignIn from "./pages/auth/emailSignIn";
import EmailCheck from "./pages/auth/emailCheck";

import AuthGuard from "./components/AuthGuard";
import SocioGuard from "./components/SocioGuard";
import WaoSessionManager from "./components/SessionManager/WaoSessionManager";
import { whatUrlMatch } from "./utils";
import { checkoutSuccessExc, dealDetailExc, goToReferrerDashboard, goToCommissions, myCartExc, customCatalogExc, goToCart, goToActiveOffers } from "./urlsExceptionsFunctions";
import WaoOverlay from "./components/WaoOverlay";
import CommissionsView from "./pages/commissions";
import CommissionsDetail from "./pages/commissions/details";
import OrdersView from './pages/orders';
import ActiveOffersContainer from "./pages/active-offers";
import HelpContainer from "./pages/help";
import TutorialsContainer from "./pages/tutorials";
import SettingsContainer from "./pages/settings";
import ProfileContainer from "./pages/profile";
import { getDefaultRolePageRedirect } from "./utils/constants";
import CatalogItemContainer from "./pages/active-offers/CatalogItem";
import DetailSection from "./pages/points/DetailSection";
import LoadingComponent from "./components/loading";
import PanelManager from "./components/PanelManager";
import Tutorials from "./pages/help/Tutorials";
import Quiz from "./pages/quiz";

// import KitchenSink from "./pages/kitchen-sink";
// import Registration from "./pages/registration";
// import RegistrationSteps from "./pages/registration/steps";


const kitchenSink = lazy(() => import("./pages/kitchen-sink"));
const registration = lazy(() => import("./pages/registration"));
const registrationSteps = lazy(() => import("./pages/registration/steps"));
const customCatalog = lazy(() => import ("./pages/custom-catalog"));


const urls = [
  { path: '/sign-in', exact: true, component: SignIn, guard: null, key: 'signIn' },
  { path: '/sign-in/phone', exact: true, component: PhoneSignIn, guard: null, key: 'phoneSignIn' },
  { path: '/sign-in/email', exact: true, component: EmailSignIn, guard: null, key: 'emailSignIn' },
  { path: '/sign-in/email/check', exact: true, component: EmailCheck, guard: null, key: 'emailCheck' },
  { path: '/registration', exact: true, component: registration, guard: null, key:'registration' },
  { path: '/registration/steps', exact: true, component: registrationSteps, guard: null, key:'registrationSteps' },
  { path: '/landingPage', exact: true, component: landingPage, guard: null, key:'landingPage' },
  { path: '/terms-and-conditions', exact: true, component: TermsAndConditions, guard: false, key: 'termsAndConditions' },
  { path: '/home', exact: true, component: Home, guard: SocioGuard, key: 'home' },
  { path: '/account', exact: true, component: Account, guard: AuthGuard, key: 'account' },
  { path: '/store/:storeId', exact: true, component: StoreDealsListing, guard: false, key:'storeList', backException: goToReferrerDashboard },
  { path: '/store/:storeId/s/:shareId', exact: true, component: StoreDealsListing, guard: false, key:'storeList', backException: goToReferrerDashboard },
  { path: '/store/:storeId/deals/:dealId', exact: true, component: StoreDealsDetail, guard: false, key: 'storeDealId', backException: dealDetailExc },
  { path: '/store/:storeId/my-cart', exact: true, component: ShoppingCart, guard: false, key: 'storeMyCart', backException: myCartExc },
  { path: '/store/:storeId/custom-catalog', exact: true, component: customCatalog, guard: false, key: 'customCatalog', backException: customCatalogExc },
  { path: '/store/:storeId/checkout', exact: true, component: Checkout, guard: AuthGuard, key:'storeCheckout', backException: goToCart },
  { path: '/store/:storeId/checkout/success', exact: true, component: CheckoutSuccess, guard: AuthGuard, key:'storeCheckoutSuccess', backException: checkoutSuccessExc},
  { path: '/commissions/detail', exact: true, component: CommissionsDetail, guard: SocioGuard, key: 'commissionView', backException: goToCommissions},
  { path: '/commissions', exact: true, component: CommissionsView, guard: SocioGuard, key: 'commissionView', backException: goToReferrerDashboard},
  { path: '/orders', exact: true, component: OrdersView, guard: AuthGuard, key: 'ordersView'},
  { path: '/active-offers/:country', exact: true, component: ActiveOffersContainer, guard: false, key:'activeOffersView'},
  { path: '/active-offers', exact: true, component: ActiveOffersContainer, guard: AuthGuard, key:'activeOffersView', backException: goToReferrerDashboard},
  { path: '/socio-help', exact: true, component: HelpContainer, guard: SocioGuard, key: 'help'},
  { path: '/socio-help/tutorials', exact: true, component: Tutorials, guard: SocioGuard, key: 'help'},
  { path: '/socio-tutorials', exact: true, component: TutorialsContainer, guard: SocioGuard, key: 'tutorials'},
  { path: '/catalog', exact: true, component: CatalogItemContainer, guard: AuthGuard, key: 'catalog', backException: goToActiveOffers },
  { path: '/points/details', exact: true, component: DetailSection, guard: SocioGuard, key: 'DetailSection', backException: goToReferrerDashboard },
  { path: '/settings', exact: true, component: SettingsContainer, guard: AuthGuard, key: 'settings' },
  { path: '/profile', exact: true, component: ProfileContainer, guard: AuthGuard, key: 'profile' },
  { path: '/kitchen-sink', exact: true, component: kitchenSink, guard: AuthGuard, key: 'kitchenSink' },
  { path: '/quiz', exact: true, component: Quiz, guard: null, key: 'quiz' },
];

function historyEnhancer(originalHistory, routes) {
  return {
    ...originalHistory,
    goBack: (user) => {
      const url = whatUrlMatch(routes, originalHistory.location);
      if (url.backException) {
        return url.backException(originalHistory, url.match, user);
      }
      return originalHistory.goBack();
    }
  };
}

const historyEnhanced = historyEnhancer(history, urls);

// import Loading from "./pages/loading";
// Create router
const AppRouter = () => {
  const { userMe } = useSelector(state => state.auth);
  
  return (
    <Router history={historyEnhanced}>
      <Suspense fallback={<LoadingComponent isLoading />}>
        <WaoSessionManager urls={urls}>
          <WaoOverlay />
          <PanelManager />
          <ScrollToTop>
            <Switch>
              {urls.map(route => {
                if (route.guard) return (route.guard({ ...route }));
                return <Route {...route}/>;
              })}
              <Route exact path="/">
                <Redirect to={getDefaultRolePageRedirect({role: userMe?.role || userMe?.type, user:userMe})} />
              </Route>
              <Redirect to={getDefaultRolePageRedirect({role: userMe?.role || userMe?.type, user:userMe})} />
            </Switch>
          </ScrollToTop>
        </WaoSessionManager>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
