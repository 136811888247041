import React, { useEffect } from 'react';
import styles from './styles.module.sass';
import { withTranslation } from 'react-i18next';
import Emoji from '../../components/Emoji';
import { useDispatch, useSelector } from 'react-redux';
import PointsActions from '../../redux/points/actions';
import Progress from '../../components/Progress';

const EarnSection = (({ t, pointsAvailable }) => {
  
  const dispatch = useDispatch();
  const { userMe } = useSelector(state => state.auth);
  const country = userMe?.country?.toLowerCase();
  const { challenges, challengesLoading /* , challengesLoaded */ } = useSelector(state => state.points);
  
  useEffect(() => {
    if (dispatch && country) dispatch(PointsActions.fetchChallenges(country.toUpperCase()));
  }, [country, dispatch]);

  return <div className={`${styles.earnContainer} ${styles['points-section']} px20`}>
    <section className={styles.earnSection}>
      <div className={`${styles["your-available-points"]} ${styles.top}`}>
        <div className={styles["your-available-points__title"]}>
          <span>{t("Your available points")}: </span>
        </div>
        <div className={styles.total}>
          <span>{pointsAvailable}</span>
          <div><Emoji label="star" content="⭐️" /></div>
        </div>
      </div>
      <h3>{t("pages.earn.title")}</h3>
      <div className={styles.description}>{t("pages.earn.description")}</div>
      <div className="flex column g24 ">
        {challengesLoading ? <Progress />
          :
          ""}
        {!challengesLoading && challenges?.map(({ id, metadata: { challenge }, pointBase, bonus }) => (
            <div key={id} className="flex flex-sb box-shadow-item minH-L space-between">
              <div>
                <div className={styles.descriptionChallenges}>
                  {bonus ? <span className="fw600">BONUS: </span> : ''}
                  {challenge}</div>
              </div>
              <div className="flex pl56">
                <div className="fw600 mr6">{pointBase}</div>
                <div><Emoji label="star" content="⭐️" /></div>
              </div>
            </div>
          ))}
      </div>
    </section>
  </div>;
  }
);

export default withTranslation()(EarnSection);