import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useCallback } from 'react';

const buttonsStyle = { borderRadius: "20px" };
const cancelButtonStyle = { ...buttonsStyle };
const okButtonStyle = { ...buttonsStyle, color: "#000", borderColor: "#E0E0E0", padding: "4px 20px" };


const ConfirmationDialog = ({ open, setOpen, setValue, children, onClose, maxWidth = "xs", title, ok: { label: okLabel, action: okAction } = {}, cancel: { label: cancelLabel, action: cancelAction } = {} }) => {
  const missingActionButton = !okLabel && !cancelLabel;
  const missingSetOpen = !setOpen;

  if (missingActionButton) throw new Error("ConfirmationDialog is missing the action buttons paramters");
  if (missingSetOpen) throw new Error("ConfirmationDialog is missing the set open parameter");

  const handleClose = useCallback((newValue) => {
    setOpen(false);
    if (onClose) onClose();
    if (setValue) setValue(newValue);
  }, [onClose, setOpen, setValue]);

  const handleCancel = useCallback((val = false) => {
    handleClose(val);
    if (cancelAction) cancelAction();
  },[cancelAction, handleClose]);

  const handleOk = useCallback((val = true) => {
    handleClose(val);
    if (okAction) okAction();
  }, [handleClose, okAction]);

  return <Dialog maxWidth={maxWidth} /* TransitionProps={{ onEntering: handleEntering }} */ open={open} style={{ borderRadius: "20px" }}>
    {title ? <DialogTitle style={{ textAlign: "center" }}><b>{title}</b></DialogTitle> : ""}
    <DialogContent>
      {children}
    </DialogContent>
    <DialogActions style={{ paddingBottom: "16px" }}>
      {cancelLabel ? <Button style={cancelButtonStyle} onClick={handleCancel}>{cancelLabel}</Button> : ''}
      {okLabel ? <Button variant="outlined" style={okButtonStyle} onClick={handleOk}>{okLabel}</Button> : ''}
    </DialogActions>
  </Dialog>
};

export default ConfirmationDialog;
