import React from 'react';
import { getWAUrl } from '.';
import ar from '../countries/ar.json';
import co from '../countries/co.json';
import mx from '../countries/mx.json';
import Twemoji from 'react-twemoji';

const countries = { ar, co, mx };

export const availableCountries = ['ar', 'co', 'mx'];
export const getAvailableCountries = () => availableCountries.map(country => countries[country]);

export const enabledCountries = [/* 'ar', 'co', */ 'mx'];
export const getEnabledCountries = () => enabledCountries.map(country => countries[country]);

export const defaultCountry = 'ar';
export const currencies = availableCountries.reduce((result, value) => {
  result[value] = countries[value].currency;
  return result;
}, {});

export const getSocialLinks = (country, text, urlType) => {
  if (!country || !countries[country]) return {};
  return {
    ...countries[country].social,
    name: countries[country].title,
    flag: <Twemoji noWrapper options={{ className: 'twemoji' }}><span role="img" aria-label={`${countries[country].title} flag`}>{countries[country].flag}</span></Twemoji>,
    whatsapp: {
      title: `wao.shop.${countries[country].title.toLowerCase()}`,
      url: getWAUrl(countries[country].code, text, urlType),
    },
    email: {
      title: `info.${countries[country].title.toLowerCase()}`,
      url: `mailto:${countries[country].email}`,
    }
  }
};

export { ar, co, mx };