import { clone } from 'ramda';

export const AppReducer = (state, action) => {
  
  const copy = clone(state);
  const updatedState = copy || {
    notification: {
      status: false,
      message: '',
      // background: 'red',
      // color: 'blue',
      duration: 1500
    }
  };

  switch (action.type) {
    case "NOTIFY":
      updatedState.notification.status = true;      
      if (action.payload.message) updatedState.notification.message = action.payload.message;
      if (action.payload.background) updatedState.notification.background = action.payload.background;
      if (action.payload.color) updatedState.notification.color = action.payload.color;
      return updatedState;
    
    case "NOTIFICATION_HIDE":
      updatedState.notification.status = false;
      return updatedState;
    
    default:
      return state;
  }
};