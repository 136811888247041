import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';
import styles from './index.module.sass';
import TopImage from '../../../assets/images/saving-program/top.png';
import BenefitsImage from '../../../assets/images/saving-program/benefits.png';
import CryptoActions from "../../../redux/crypto/actions";
import { CircularProgress } from "@mui/material";


const SavingProgramModal = ({ t, data }) => {
    const dispatch = useDispatch();

    const { registerLoading } = useSelector(state => state.crypto);
    const { isCryptoRegistered } = data;

    const onSubscribe = () => {
        dispatch(CryptoActions.register());
    };

    return (<div className={styles.savingsContainer}>
        <div className={styles.savingsSection}>
            <h3>{t('savingsProgram.savingProgramWao')}</h3>
            <div className={styles.centerContainer}>
                <img src={TopImage} alt="topImage" />
            </div>
            <div>
                <h5> {t('savingsProgram.whatIs')}</h5>
                <p>Fermentum iaculis eu non diam phasellus vestibulum lorem sed risus. Id semper risus in hendrerit gravida rutrum quisque. Facilisi nullam vehicula ipsum a arcu cursus.</p>

                {!isCryptoRegistered && (
                    <button type="button" className="btn btn-round" onClick={onSubscribe} disabled={registerLoading}>
                        {registerLoading ? (<CircularProgress />) : t('savingsProgram.subscribe')}
                    </button>
                )}

            </div>

            <div className={styles.section}>
                <h5>
                    {t('savingsProgram.howWorks')}
                </h5>
                <div className={styles.howWorksSection}>
                    <h3 style={{ display: 'inline' }}>1 </h3>
                    <p style={{ display: 'inline' }}><b>Non consectetur</b> a erat nam at lectus urna duis. Sociis natoque penatibus et magnis dis parturient</p>
                </div>
                <div className={styles.howWorksSection}>
                    <h3 style={{ display: 'inline' }}>2 </h3>
                    <p style={{ display: 'inline' }}><b>Gravida</b> neque convallis a cras semper auctor</p>
                </div>
                <div className={styles.howWorksSection}>
                    <h3 style={{ display: 'inline' }}>3 </h3>
                    <p style={{ display: 'inline' }}><b>Egestas</b> fringilla phasellus faucibus scelerisque eleifend.</p>
                </div>
            </div>
            <div className={styles.section}>
                {!isCryptoRegistered && (
                    <button type="button" className="btn btn-round" onClick={onSubscribe} disabled={registerLoading}>
                        {registerLoading ? (<CircularProgress />) : t('savingsProgram.subscribe')}
                    </button>
                )}
            </div>
            <div className={styles.section}>
                <h5>
                    {t('savingsProgram.whatAreBenefits')}
                </h5>
                <div className={styles.benefitsSection}>
                    <img src={BenefitsImage} alt="benefitsImage" />
                    <p>
                        Erat nam at lectus urna duis. Sociis natoque penatibus et magnis dis parturient
                    </p>
                    <img src={BenefitsImage} alt="benefitsImage" />
                    <p>
                        Erat nam at lectus urna duis. Sociis natoque penatibus et magnis dis parturient
                    </p>
                </div>
            </div>
        </div>

    </div >);
};

export default withTranslation()(SavingProgramModal);