import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './styles.module.sass';

const OrderLineLoader = () => (
  <ContentLoader
    speed={2}
    className={styles['content-loader']}
  >
    <rect x="0px" y="5" rx="3" ry="3" width="90" height="15" /> 
    <rect x="0px" y="30" rx="3" ry="3" width="140" height="30" /> 
    <rect x="0px" y="70" rx="3" ry="3" width="190" height="5" /> 
    <circle cx="90%" cy="30" r="20" />
  </ContentLoader>
);

export default OrderLineLoader;