import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

const AuthGuard = (props) => {
  const { userMe } = useSelector(state => state.auth);
  if (userMe) return <Route {...props} />;
  return null;
};

export default AuthGuard;