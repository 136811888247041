import { join, length, map } from 'ramda';
import { takeNullPairsOut } from '../utils';
import api from './api';

export const getStoreDeals = ({ shopId, currentPage = 1, perPage = 10, name, cancelToken, sort, category, promotions, shareId, userId, grantPoints, searchPromosObject }) => {
  let objectRequest = {
    currentPage,
    perPage,
    name, 
    sort,
    promotions,
    shareId,
    searchPromosObject
  };
  if (userId) objectRequest = { ...objectRequest, userId };
  if (grantPoints) objectRequest = { ...objectRequest, grantPoints };
  return api.get(
    `deals/user/${shopId}${(category && length(category) > 0) ? `?category=${join('&category=', map(encodeURIComponent, category))}` : ''}`,
    takeNullPairsOut(objectRequest), { cancelToken }
  );
};

export const getStoreDeal = ({ dealId, userId, searchPromosObject, shopId }) => {
  let objectRequest = { shopId, searchPromosObject };
  if (userId) objectRequest = { ...objectRequest, userId };

  return api.get(`deals/mini-store/${dealId}`, takeNullPairsOut(objectRequest));
};

export const getStoreDealsByCountry = ({ country, cancelToken, currentPage, name }) => api.get(
  `/deals/country/${country}?currentPage=${currentPage}&perPage=10${name ? `&name=${name}` : ''}`, {}, { cancelToken }
);

export const getCategories = ({ cancelToken }) => api.get(`/deals/categories/`, {}, { cancelToken });
