import AuthActions from './actions';
import InitialState from ".";
import { omit } from 'ramda';
import mixpanel from '../../services/mixpanel';

export const reducer = (state = InitialState, action) => {

  const { firstName, lastName, ...userMe } = action?.payload?.user?.data || {};

  switch (action.type) {
    case AuthActions.loginRequest().type:
      return state.merge({
        loginLoading: true,
        loginError: null,
        loginCalled: false,
      });

    case AuthActions.loginSuccess().type:
      return state.merge({
        loginLoading: false,
        loginError: null,
        loginCalled: true,
        user: action.payload.user || state.user,
        emailVerified: action.payload.emailVerified,
      });

    case AuthActions.loginFailed().type:
      return state.merge({
        loginLoading: false,
        loginError: action.payload.error.error,
        loginCalled: true,
      });

    case AuthActions.loginPhoneRequest().type:
      return state.merge({
        loginLoading: true,
        loginError: null,
        loginCalled: false,
      });

    case AuthActions.loginPhoneSuccess().type:
      return state.merge({
        loginLoading: false,
        loginError: null,
        loginCalled: true,
        user: action.payload.user || state.user,
        userMe: action.payload.user || state.user,
        confirmationResult: action.payload.confirmationResult,
      });

    case AuthActions.loginPhoneFailed().type:
      return state.merge({
        loginLoading: false,
        loginError: action.payload.error.error,
        loginCalled: true,
      });
    
    case AuthActions.sendLoginEmailRequest().type:
      return state.merge({
        loginEmailSendLoading: true,
        loginEmailSendError: null,
        loginEmailSendCalled: false,
      });

    case AuthActions.sendLoginEmailSuccess().type:
      return state.merge({
        loginEmailSendLoading: false,
        loginEmailSendError: null,
        loginEmailSendCalled: true,
      });

    case AuthActions.sendLoginEmailFailed().type:
      return state.merge({
        loginEmailSendLoading: false,
        loginEmailSendError: action.payload.error.error,
        loginEmailSendCalled: true,
      });
    
    case AuthActions.loginEmailLinkRequest().type:
      return state.merge({
        loginLoading: true,
        loginError: null,
        loginCalled: false,
      });

    case AuthActions.loginEmailLinkSuccess().type:
      return state.merge({
        loginLoading: false,
        loginError: null,
        loginCalled: true,
        user: action.payload.user || state.user,
        userMe: action.payload.user || state.user,
        confirmationResult: null,
      });

    case AuthActions.loginEmailLinkFailed().type:
      return state.merge({
        loginLoading: false,
        loginError: action.payload.error.error,
        loginCalled: true,
      });

    case AuthActions.resetLoginState().type:
      return state.merge({
        loginError: null,
        loginLoading: false,
        loginCalled: false,
      });

    case AuthActions.createAccountRequest().type:
      return state.merge({
        createAccountLoading: true,
        createAccountError: null,
        createdAccount: false,
      });

    case AuthActions.createAccountSuccess().type:
      return state.merge({
        createAccountLoading: false,
        createAccountError: null,
        createdAccount: true,

        user: action.payload.user,
        emailVerified: action.payload.user.emailVerified,
      });

    case AuthActions.createAccountFailed().type:
      return state.merge({
        createAccountLoading: false,
        createAccountError: action.payload.error.error,
        createdAccount: false,
      });

    case AuthActions.clearCreateAccount().type:
      return state.merge({
        createAccountLoading: false,
        createAccountError: null,
        createdAccount: false,
      });

    case AuthActions.updateAccountRequest().type:
      return state.merge({
        updateAccountLoading: true,
        updateAccountError: null,
        updateAccountCalled: false,
      });

    case AuthActions.updateAccountSuccess().type:
      return state.merge({
        updateAccountLoading: false,
        updateAccountError: null,
        updateAccountCalled: true,

        user: action.payload.user,
        emailVerified: action.payload.emailVerified,
      });

    case AuthActions.updateAccountFailed().type:
      return state.merge({
        updateAccountLoading: false,
        updateAccountError: action.payload.error,
        updateAccountCalled: true,
      });

    case AuthActions.clearUpdateAccount().type:
      return state.merge({
        updateAccountLoading: false,
        updateAccountError: null,
        updateAccountCalled: false,
      });

    case AuthActions.forgotPasswordRequest().type:
      return state.merge({
        forgotPasswordLoading: true,
        forgotPasswordError: null,
        forgotPasswordCalled: false,
      });
    case AuthActions.forgotPasswordSuccess().type:
      return state.merge({
        forgotPasswordLoading: false,
        forgotPasswordError: null,
        forgotPasswordCalled: true,
      });
    case AuthActions.forgotPasswordFailed().type:
      return state.merge({
        forgotPasswordLoading: false,
        forgotPasswordError: action.payload.error,
        forgotPasswordCalled: false,
      });
    case AuthActions.resetForgotPassword().type:
      return state.merge({
        forgotPasswordLoading: false,
        forgotPasswordError: null,
        forgotPasswordCalled: false,
      });
    case AuthActions.emailVerificationRequest().type:
      return state.merge({
        emailVerificationLoading: true,
        emailVerificationError: null,
        emailVerificationCalled: false,
      });
    case AuthActions.emailVerificationSuccess().type:
      return state.merge({
        emailVerificationLoading: false,
        emailVerificationError: null,
        emailVerificationCalled: true,
      });
    case AuthActions.emailVerificationFailed().type:
      return state.merge({
        emailVerificationLoading: false,
        emailVerificationError: action.payload.error,
        emailVerificationCalled: false,
      });

    case AuthActions.resetEmailVerification().type:
      return state.merge({
        emailVerificationLoading: false,
        emailVerificationError: null,
        emailVerificationCalled: false,
      });

    case AuthActions.changePasswordRequest().type:
      return state.merge({
        changePasswordLoading: true,
        changePasswordError: null,
        changedPassword: false,
      });

    case AuthActions.changePasswordSuccess().type:
      return state.merge({
        changePasswordLoading: false,
        changePasswordError: null,
        changedPassword: true,
        user: { ...state.user, withTemporaryPassword: false },
      });

    case AuthActions.changePasswordFailed().type:
      return state.merge({
        changePasswordLoading: false,
        changePasswordError: action.payload.error.error,
        changedPassword: false,
      });
    case AuthActions.clearChangePassword().type:
      return state.merge({
        changePasswordLoading: false,
        changePasswordError: null,
        changedPassword: false,
      });

    case AuthActions.uploadProfilePictureRequest().type:
      return state.merge({
        uploadProfilePictureLoading: true,
        uploadProfilePictureError: null,
        uploadedProfilePicture: false,
      });

    case AuthActions.uploadProfilePictureSuccess().type:
      return state.merge({
        uploadProfilePictureLoading: false,
        uploadProfilePictureError: null,
        uploadedProfilePicture: true,
      });

    case AuthActions.uploadProfilePictureFailed().type:
      return state.merge({
        uploadProfilePictureLoading: false,
        uploadProfilePictureError: action.payload.error,
        uploadedProfilePicture: false,
      });

    case AuthActions.storePersonalInformation().type:
      return state.merge({
        user: { ...action.payload },
      });

    case AuthActions.fetchUserMeRequest().type:
      return state.merge({
        userMeLoading: true,
        userMeError: null,
        userMeLoaded: false,
      });

    case AuthActions.fetchUserMeSuccess().type:
      if (firstName.toUpperCase() === 'GUEST') {
        userMe.firstName = '';
      } else {
        userMe.firstName = firstName;
      } if (lastName.toUpperCase() === 'GUEST') {
        userMe.lastName = '';
      } else {
        userMe.lastName = lastName;
      }

      if (!mixpanel.isDisabled) {
        mixpanel.identify(userMe.phone);
        mixpanel.people.set({ 'phone': userMe.phone, 'email': userMe.email, 'name': userMe.fullName, 'country': userMe.country, id: userMe.id, type: userMe.type });
      }

      return state.merge({
        userMeLoading: false,
        userMeError: null,
        userMeLoaded: true,
        userMe,
      });

    case AuthActions.fetchUserMeFailed().type:
      return state.merge({
        userMeLoading: false,
        userMeError: action.payload.error,
        userMeLoaded: false,
      });

    case AuthActions.loggedIn().type:
      return state.merge({
        loggedIn: true,
        checkingUser: false,
      });
    case AuthActions.loggedOut().type:
      if (!mixpanel.isDisabled) mixpanel.reset();
      return state.merge({
        loggedIn: false,
        checkingUser: false,
        user: null,
      });
    case AuthActions.updateUser().type:
      return state.merge({
        user: action.payload.user,
        emailVerified: action.payload.emailVerified,
      });
    case AuthActions.updateUserMeRequest().type:
      return state.merge({
        userMeUpdate: omit(['cancelToken'], action.payload),
        userMeUpdateLoading: true,
        userMeUpdateError: null
      });
    case AuthActions.updateUserMeFailed().type:
      return state.merge({
        userMeUpdateLoading: false,
        userMeUpdateError: action.payload.error
      });
    case AuthActions.updateUserMeSuccess().type:
      return state.merge({
        userMeUpdate: null,
        userMeUpdateLoading: false,
        userMeUpdateError: null,
        userMe: action.payload.data
      });

      case AuthActions.fetchOnboardingQuestionsRequest().type:
        return state.merge({
          questionsLoading: true,
          questionsError: null,
          questionsLoaded: false,
        });
  
      case AuthActions.fetchOnboardingQuestionsSuccess().type:
  
        return state.merge({
          questionsLoading: false,
          questionsError: null,
          questionsLoaded: true,
          questions: action.payload.questions.data,
        });
  
      case AuthActions.fetchOnboardingQuestionsFailed().type:
        return state.merge({
          questionsLoading: false,
          questionsError: action.payload.error,
          questionsLoaded: false,
        });

    default:
      return state;
  }
};
