/* eslint-disable default-param-last */
import WorkflowActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {


  switch (action.type) {
    
    case WorkflowActions.fetchCountriesRequest().type:
      return state.merge({
        countriesLoading: true,
        countriesError: null,
        countriesLoaded: false,
      });

    case WorkflowActions.fetchCountriesSuccess().type:
      return state.merge({
        countriesLoading: false,
        countriesError: null,
        countriesLoaded: true,
        countries: action.payload.countries.data || state.countries,
      });

    case WorkflowActions.fetchMetadataRequest().type:
      return state.merge({
        countriesLoading: true,
        countriesError: null,
        countriesLoaded: false,
      });
    
    case WorkflowActions.fetchMetadataSuccess().type:
      return state.merge({
        metadataLoading: false,
        metadataError: null,
        metadataLoaded: true,
        metadata: action.payload.metadata.data || state.metadata,
      });

    default:
      return state;
  }
};
