import { makeStyles } from '@mui/styles';
import React from 'react';
import Progress from './Progress';
import Icon from './Icon';

const useStyles = makeStyles({
  contLoader: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: '-40px',
    left: '0px',
    zIndex: '100',
    backgroundColor: 'rgba(255, 255, 255, 1)'
  }
});


const LoadingComponent = ({ isLoading, error }) => {
  const styles = useStyles();
  // Handle the loading state
  if (isLoading) {
    return <div className={styles.contLoader}>
      <Icon icon="logo" color="#e54a38" size={100} />
      <Progress style={{ color: "#e54a38", marginTop: "-20px", marginRight: "15px" }} />
    </div>;
  }
  // Handle the error state
  if (error) {
    return <div>Sorry, there was a problem loading the page.</div>;
  }

  return null;
};

export default LoadingComponent;
