import Immutable from 'seamless-immutable';

const store = localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')) : null;

const initialState = Immutable({
  storeLoading: false,
  storeError: null,
  storeLoaded: false,
  store,
});


export default initialState;
