import i18n from '../i18n';

export const GenericError = function GenericError(debugMessage, userMessage = i18n.t('errors.SorryProblem'), errorData) {
  this.debugMessage = debugMessage;
  this.userMessage = userMessage;
  if (errorData?.status) this.status = errorData.status;
};

// export default withTranslation()(BlastCreate);
export const DealsError = function DealsError(data, errorData) {
  let errMsg = i18n.t('errors.UnableStoreDeals');
  if (data.promotions) errMsg = i18n.t('errors.UnablePromotion');
  this.debugMessage = errMsg;
  this.userMessage = errMsg;
  this.type = 'DealsError';
  if (errorData.status) this.status = errorData.status;
};
