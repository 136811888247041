import { createActions } from "redux-actions";

import { GenericError } from '../../utils/errors';

import {
    getCountries,
    getUserMeta
} from '../../services/workflow';

const WorkflowActions = createActions({
    FETCH_COUNTRIES_REQUEST: () => {},
    FETCH_COUNTRIES_SUCCESS: (countries) => ({countries}),
    FETCH_COUNTRIES_FAILED: (error) => ({error}),

    FETCH_METADATA_REQUEST: () => {},
    FETCH_METADATA_SUCCESS: (metadata) => ({metadata}),
    FETCH_METADATA_FAILED: (error) => ({error}),
});


WorkflowActions.fetchCountries = (query) => async (dispatch) => {
  try {
    dispatch(WorkflowActions.fetchCountriesRequest());
    const result = await getCountries(query);
    dispatch(WorkflowActions.fetchCountriesSuccess(result));
  } catch (err) {
    dispatch(WorkflowActions.fetchCountriesFailed({ error: new GenericError(err, err) }));
  }
};

WorkflowActions.fetchMetadata = (query) => async (dispatch) => {
  try {
    dispatch(WorkflowActions.fetchMetadataRequest());
    const result = await getUserMeta(query);
    dispatch(WorkflowActions.fetchMetadataSuccess(result));
  } catch (err) {
    dispatch(WorkflowActions.fetchMetadataFailed({ error: new GenericError(err, err) }));
  }
};

export default WorkflowActions;