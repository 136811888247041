/* eslint-disable default-param-last */
import WorkflowActions from "./actions";
import InitialState from ".";

const procedures = {
  [WorkflowActions.fetchCategoriesRequest().type]: (state) => state.merge({
    categoriesLoading: true,
    categoriesError: null,
    categories: null,
  }),
  [WorkflowActions.fetchCategoriesSuccess().type]: (state, payload) => state.merge({
    categoriesLoading: false,
    categoriesError: null,
    categories: payload,
  }),
  [WorkflowActions.fetchCategoriesFailed().type]: (state, payload) => state.merge({
    categoriesLoading: false,
    categoriesError: payload.error,
    categories: null,
  }),
  [WorkflowActions.fetchDealsRequest().type]: (state) => state.merge({
    dealsLoading: true,
    dealsError: null,
    dealsLoaded: false,
  }),
  [WorkflowActions.fetchDealsSuccess().type]: (state, payload) => {
    let comm;
    if (payload.deals.pagination.currentPage === 1) {
      comm = payload.deals.deals;
    } else if(payload.deals.deals && payload.deals.deals.length) {
      comm = state.deals.concat(payload.deals.deals);
    }else{
      comm = payload.deals.deals;
    }
    return state.merge({
      dealsLoading: false,
      dealsError: null,
      dealsLoaded: true,
      deals: comm,
      dealsPagination: payload.deals.pagination
    });
  },
  [WorkflowActions.fetchDealsFailed().type]: (state, payload) => state.merge({
    dealsLoading: false,
    dealsError: payload?.error?.error,
    dealsLoaded: true,
  }),
  [WorkflowActions.setSelectedDeal().type]: (state, payload) => state.merge({
    selected: payload.deal,
  }),
  [WorkflowActions.setSearchTerm().type]: (state, payload) => state.merge({
    searchTerm: payload.searchTerm,
  }),
  [WorkflowActions.setFilters().type]: (state, payload) => state.merge({
    filters: payload.filters,
  }),
  [WorkflowActions.fetchDealRequest().type]: (state) => state.merge({
    dealLoading: true,
    dealError: null,
    dealLoaded: false,
  }),
  [WorkflowActions.fetchDealSuccess().type]: (state, payload) => state.merge({
    dealLoading: false,
    dealError: null,
    dealLoaded: true,
    deal: payload.deal,
    selected: payload.deal
  }),
  [WorkflowActions.fetchDealFailed().type]: (state, payload) => state.merge({
    dealLoading: false,
    dealError: payload,
    dealLoaded: false,
  }),
  [WorkflowActions.fetchDealsByCountryRequest().type]: (state) => state.merge({
    dealsByCountryLoading: true,
    dealsByCountryError: null,
    dealsByCountryLoaded: false,
  }),
  [WorkflowActions.fetchDealsByCountrySuccess().type]: (state, payload) => {
    let comm;
    if (payload.deals.pagination.currentPage === 1) {
      comm = payload.deals.deals;
    } else {
      comm = state.dealsByCountry.concat(payload.deals.deals);
    }
    return state.merge({
      dealsByCountryLoading: false,
      dealsByCountryError: null,
      dealsByCountryLoaded: true,
      dealsByCountry: comm,
      dealsByCountryPagination: payload.deals.pagination
    });
  },
  [WorkflowActions.fetchDealsByCountryFailed().type]: (state, payload) => state.merge({
    dealsByCountryLoading: false,
    dealsByCountryError: payload,
    dealsByCountryLoaded: false,
  }),
};

export const reducer = (state = InitialState, action) => procedures[action.type] ? procedures[action.type](state, action.payload) : state;
