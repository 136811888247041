import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from 'react-step-progress-bar';
import WaoIcon from '../WaoIcon';
import { useWindowDimensions } from '../../utils/hooks';

const StepProgressBar = ({ data: { available: originalAvailable }, steps, stepRangeValue, minPoints, maxPoints }) => {

  const available = originalAvailable < minPoints ? minPoints : originalAvailable;
  const [progressIndicatorstate, setProgressIndicatorstate] = React.useState(0);
  const { width: screenWidth } = useWindowDimensions();
  const barRef = useRef();

  useEffect(() => {
    setProgressIndicatorstate(barRef.current?.offsetWidth);
  }, [screenWidth]);

  const widthbar = progressIndicatorstate;
  const rangePorcent = (stepRangeValue * 100)/(maxPoints - minPoints);

  // const currentDataTotal = ((maxPoints - available) * (pointsRanageRoundDown + 1)) / stepRangeValue ;
  const percentProgressBar = ((available - minPoints) * rangePorcent) / stepRangeValue;
  const progressIndicator = (percentProgressBar * widthbar) / 100;
  const rangeHiddenStep = stepRangeValue * 0.50;

  return (
  <div className={styles['progress-bar']}>
        <div className={styles['progress-indicator']} style={{left:`${progressIndicator + 3}px`}}>
          <WaoIcon color="#7d37d7" size={16} icon="triangle" />
        </div> 
        <div className={styles['progress-bar-inner']} ref={barRef}>
        <ProgressBar
          height={6}
          percent={percentProgressBar}
          filledBackground="rgb(255, 104, 86)"
        >      
          {steps?.map((step, index, arr) => (
              <Step
                key={index}
                position={100 * (index / arr.length)}
                transition="scale"
              >
              {({ accomplished }) => (
                <>
                  <div
                    style={{
                      display: step?.status === 1 ? "none" : "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid rgb(224, 224, 224)",
                      borderRadius: "50%",
                      width: 12,
                      height: 12,
                      color: "white",
                      backgroundColor: accomplished ? "rgb(255, 104, 86)" : "rgb(255, 255, 255)"
                    }}
                  />
                  <div className={styles['label-step']} style={{ marginLeft: step?.status === 1 ? "25px" : " ", color: accomplished ? "rgb(255, 104, 86)" : " " }}>
                    {step.status === 1 || step.value - available <= rangeHiddenStep ? " " : step?.value}
                  </div>
                </>
              )}
              </Step>
            ))}
        </ProgressBar>
        </div>
        <div 
          className={styles['point-indicator']} 
          style={{
            marginLeft: percentProgressBar >= 2 ? "-20px" : " ",
            left: `${progressIndicator >= widthbar ? widthbar : progressIndicator}px`, color: "rgb(255, 104, 86)" 
          }}>
            { available } 
        </div>
  </div>  
  );
};


export default withTranslation()(StepProgressBar);
