import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AccumulatedCommissionList from '../../components/AccumulatedCommissionList';
import HeaderStore from '../../components/HeaderStore';
import CommissionHeader from './header';
import Rewards from '../../components/Rewards';
import styles from '../socio/home/styles.module.sass';
import ownStyles from './index.module.sass';
import CommissionActions from '../../redux/commission/actions';
import { createWeekDateRange } from '../../utils';
import { map } from 'ramda';
import { formatISO } from 'date-fns';
import mixpanel from '../../services/mixpanel';
import OrderActions from '../../redux/orders/actions';
import SavingProgram from '../../components/SavingProgram';
import { defaultCountry, getSocialLinks } from '../../utils/countries';

const CommissionsView = ({ t, history, location }) => {

  const phonesByCountry = {
    ar: { formatted: "5491173629387" },
    co: { formatted: "573053746344" },
    mx: { formatted: "5491173629387" }
  };

  const qParams = new URLSearchParams(location.search);
  const testCountry = useState(qParams.get('country'))?.[0];
  const { userMe } = useSelector(state => state.auth);
  const country = testCountry?.toLowerCase() || userMe?.country?.toLowerCase() || defaultCountry;
  const context = { context: country };
  const dispatch = useDispatch();
  const dateRange = createWeekDateRange();
  const socialLinks = getSocialLinks(country);
  const { orderStatuses, orderStatusesLoading } = useSelector(state => state.orders);
  // const flags = useFlags();
  const flags = {};

  for (const countryCode in phonesByCountry) {
    if (countryCode) phonesByCountry[countryCode].clean = phonesByCountry[countryCode].formatted.replace(/\s/g, '');
  }

  const { commissions, commissionsTotal, rewards } = useSelector(state => state.commissions);

  const [fromDate, toDate] = map(formatISO, dateRange);
  useEffect(() => {
    dispatch(CommissionActions.fetchCommissions(
      { currentPage: 1, fromDate, toDate, perPage: 5 }));
    dispatch(CommissionActions.fetchRewards(
      { currentPage: 1, fromDate, toDate, perPage: 5 }));
    mixpanel.track_optimized('Commissions');
    if (!orderStatuses && !orderStatusesLoading) dispatch(OrderActions.fetchOrderStatuses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(CommissionActions.fetchCommissionTotals({ fromDate, toDate }));
  }, [dispatch, fromDate, toDate, userMe]);

  const waClickHandler = () => {
    window.open(socialLinks.whatsapp.url);
  };

  return (
    <div className={`${styles['page-wrapper']} app-wrapper-mobile`}>
      <section className={styles.content}>
        <section className={styles.header}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>WAO! - {t('Account')}</title>
            <link rel="canonical" href="https://app.wao.shop/commissions" />
          </Helmet>
          <HeaderStore showLogo history={history} icons={{ back: true }} altColor />
        </section>
        <div>
          {flags?.cryptoYield && (<SavingProgram data={userMe} commission={commissionsTotal?.total} />)}
          <CommissionHeader dateRange={dateRange} data={commissionsTotal} />
          <AccumulatedCommissionList showLink showHeader data={commissions} totals={commissionsTotal} enablePagination={false} infoIcon />
          <Rewards data={rewards} totals={commissionsTotal} />
        </div>
        <hr className="divider" />
        <section className={ownStyles['contact-section']}>
          <h3 className={ownStyles['section-title']}>{t("QuestionOrInquiry", context)}</h3>
          <section className={ownStyles['contact-content']}>
            <button type="button" className="btn btn-round" onClick={waClickHandler}>{t("Chat Now", context)}</button>
          </section>
        </section>
        <hr />
      </section>
    </div>
  );
};

export default withTranslation()(CommissionsView);
