import React, { createContext, useCallback, useMemo, useReducer } from 'react';
import { CartReducer, sumItems, sumItemsBuyNow } from './ShopingCartReducer';

export const ShopingCartContext = createContext();

const carts = localStorage.getItem('carts') ? JSON.parse(localStorage.getItem('carts')) : {};
const cartsBuyNow = localStorage.getItem('cartsBuyNow') ? JSON.parse(localStorage.getItem('cartsBuyNow')) : {};

const initialState = {
  carts,
  cartsBuyNow
};

// create state function cart buy now
// if cartBuyNow is available use it


const ShopingCartContextProvider = ({ children }) => {

  const [state, dispatch] = useReducer(CartReducer, initialState);
  const increase = payload => {
    dispatch({ type: 'INCREASE', payload });
  };
  const decrease = payload => {
    dispatch({ type: 'DECREASE', payload });
  };
  const addProduct = payload => {
    dispatch({ type: 'ADD_ITEM', payload });
    /* setTimeout(function () {
      dispatch({ type: 'CLEAR_ACTIONS', payload });
    }, 1000); */
  };
  const removeProduct = payload => {
    dispatch({ type: 'REMOVE_ITEM', payload });
  };
  const clearCart = (payload) => {
    dispatch({ type: 'CLEAR', payload });
  };
  const totalItems = useCallback((payload) => sumItems(state.carts[payload.storeId]), [state.carts]);
  const getCart = useCallback((storeId) => (state.carts[storeId] || {}), [state.carts]);
  const handleCheckout = (payload) => {
    dispatch({ type: 'CHECKOUT', payload });
  }; 

  // Cart BuyNow

  const increaseBuyNow = payload => {
    dispatch({ type: 'INCREASE_BUYNOW', payload });
  };
  const decreaseBuyNow = payload => {
    dispatch({ type: 'DECREASE_BUYNOW', payload });
  };
  const addProductBuyNow = payload => {
    dispatch({ type: 'ADD_ITEM_BUYNOW', payload });
    /* setTimeout(function () {
      dispatch({ type: 'CLEAR_ACTIONS_BUYNOW', payload });
    }, 1000); */
  };
  const removeProductBuyNow = payload => {
    dispatch({ type: 'REMOVE_ITEM_BUYNOW', payload });
  };
  const clearCartBuyNow = (payload) => {
    dispatch({ type: 'CLEAR_BUYNOW', payload });
  };
  const totalItemsBuyNow = useCallback((payload) => sumItemsBuyNow(state.cartsBuyNow[payload.storeId]), [state.cartsBuyNow]);
  const getCartBuyNow = useCallback((storeId) => (state.cartsBuyNow[storeId] || {}), [state.cartsBuyNow]);
  const handleCheckoutBuyNow = (payload) => {
    dispatch({ type: 'CHECKOUT_BUYNOW', payload });
  };
    const contextValues = useMemo(() => ({
      removeProduct,
      addProduct,
      increase,
      decrease,
      clearCart,
      handleCheckout,
      totalItems,
      getCart,

      // Cart BuyNow
      increaseBuyNow,
      decreaseBuyNow,
      addProductBuyNow,
      removeProductBuyNow,
      clearCartBuyNow,
      handleCheckoutBuyNow,
      totalItemsBuyNow,
      getCartBuyNow,
      ...state
    }), [getCart, getCartBuyNow, state, totalItems, totalItemsBuyNow]);

  return (
    <ShopingCartContext.Provider value={contextValues}>
      {children}
    </ShopingCartContext.Provider>
  );
};

export default ShopingCartContextProvider;