import { createActions } from "redux-actions";

import {
  register,
  stake,
  unstake,
} from "../../services/crypto";

import { GenericError } from "../../utils/errors";
import AuthActions from "../auth/actions";

const CryptoActions = createActions({
  REGISTER_REQUEST: () => { },
  REGISTER_SUCCESS: data => ({ data }),
  REGISTER_FAILED: error => ({ error }),

  STAKE_REQUEST: () => { },
  STAKE_SUCCESS: data => ({ data }),
  STAKE_FAILED: error => ({ error }),

  UNSTAKE_REQUEST: () => { },
  UNSTAKE_SUCCESS: data => ({ data }),
  UNSTAKE_FAILED: error => ({ error }),
});

CryptoActions.register = () => async (dispatch) => {
  try {
    dispatch(CryptoActions.registerRequest());
    const result = await register();
    if (result.ok) {
      dispatch(AuthActions.updateUserMeSuccess(result.data));
    } else if (!result.ok) {
      const errMsg = result.data?.message || result.originalError?.message;
      const err = new GenericError("", errMsg, { status: result?.status });
      throw (err);
    }
    dispatch(CryptoActions.registerSuccess(result.data));
  } catch (err) {
    dispatch(CryptoActions.registerFailed({ error: new GenericError(err, err) }));
  }
};

CryptoActions.stake = () => async (dispatch) => {
  try {
    dispatch(CryptoActions.stakeRequest());
    const result = await stake();
    if (result.ok) {
      dispatch(AuthActions.updateUserMeSuccess(result.data));
    } else if (!result.ok) {
      const errMsg = result.data?.message || result.originalError?.message;
      const err = new GenericError("", errMsg, { status: result?.status });
      throw (err);
    }
    dispatch(CryptoActions.stakeSuccess(result.data));
  } catch (err) {
    dispatch(CryptoActions.stakeFailed({ error: new GenericError(err, err) }));
  }
};

CryptoActions.unstake = () => async (dispatch) => {
  try {
    dispatch(CryptoActions.unstakeRequest());
    const result = await unstake();
    if (result.ok) {
      dispatch(AuthActions.updateUserMeSuccess(result.data));
    } else if (!result.ok) {
      const errMsg = result.data?.message || result.originalError?.message;
      const err = new GenericError("", errMsg, { status: result?.status });
      throw (err);
    }
    dispatch(CryptoActions.unstakeSuccess(result.data));
  } catch (err) {
    dispatch(CryptoActions.unstakeFailed({ error: new GenericError(err, err) }));
  }
};

export default CryptoActions;
