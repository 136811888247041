import mixpanel from "../../services/mixpanel";
import { createActions } from "redux-actions";
import log from "../../utils/log";

import {
  forgotPassword as forgotPasswordService,
  changePassword as changePasswordService,
  createAccount as createAccountService,
  updateProfile,
  sendEmailVerification,
  verifyEmail as verifyEmailService,
  storeCredentials,
  removeCredentials,
  restoreCredentials,
  checkFirebaseUserSession,
  login as loginService,
  getUserToken,
  setAnalyticsUserId,
  getUserFromFirebase,
  signInWithPhoneNumber,
  signInWithEmailLink,
  sendLinkToEmail,
  /* registerUser, */
  getUserMe,
  registerUser,
  updateUserMe,
  signInWithGoogle as signInWithGoogleService,
  getOnboardingQuestions,
} from "../../services/auth";

import { updateFirebaseData } from "../../services/customer";

import { uploadFile } from "../../services/files";
import { enableAutoRegisterUrls } from "../../utils/constants";

import { GenericError } from "../../utils/errors";
import { firebaseUserLocal } from "../../services/local";

const AuthActions = createActions({
  LOGIN_REQUEST: () => { },
  LOGIN_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  LOGIN_FAILED: (error) => ({ error }),
  RESET_LOGIN_STATE: () => { },

  LOGIN_PHONE_REQUEST: () => { },
  LOGIN_PHONE_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  LOGIN_PHONE_FAILED: (error) => ({ error }),

  SEND_LOGIN_EMAIL_REQUEST: () => { },
  SEND_LOGIN_EMAIL_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  SEND_LOGIN_EMAIL_FAILED: (error) => ({ error }),

  LOGIN_EMAIL_LINK_REQUEST: () => { },
  LOGIN_EMAIL_LINK_SUCCESS: (user) => ({ user }),
  LOGIN_EMAIL_LINK_FAILED: (error) => ({ error }),

  FORGOT_PASSWORD_REQUEST: () => { },
  FORGOT_PASSWORD_SUCCESS: () => { },
  FORGOT_PASSWORD_FAILED: (error) => ({ error }),
  RESET_FORGOT_PASSWORD: () => { },

  EMAIL_VERIFICATION_REQUEST: () => { },
  EMAIL_VERIFICATION_SUCCESS: () => { },
  EMAIL_VERIFICATION_FAILED: (error) => ({ error }),
  RESET_EMAIL_VERIFICATION: () => { },

  CHANGE_PASSWORD_REQUEST: () => { },
  CHANGE_PASSWORD_SUCCESS: () => { },
  CHANGE_PASSWORD_FAILED: (error) => ({ error }),
  CLEAR_CHANGE_PASSWORD: () => { },

  CREATE_ACCOUNT_REQUEST: () => { },
  CREATE_ACCOUNT_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  CREATE_ACCOUNT_FAILED: (error) => ({ error }),
  CLEAR_CREATE_ACCOUNT: () => { },


  UPDATE_ACCOUNT_REQUEST: () => { },
  UPDATE_ACCOUNT_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  UPDATE_ACCOUNT_FAILED: (error) => ({ error }),
  CLEAR_UPDATE_ACCOUNT: () => { },

  UPLOAD_PROFILE_PICTURE_REQUEST: () => { },
  UPLOAD_PROFILE_PICTURE_SUCCESS: () => { },
  UPLOAD_PROFILE_PICTURE_FAILED: (error) => ({ error }),

  STORE_PERSONAL_INFORMATION: (firstName, lastName, email) => ({
    firstName,
    lastName,
    email,
  }),

  LOGGED_IN: () => { },
  LOGGED_OUT: () => { },

  UPDATE_USER: (user, emailVerified) => ({ user, emailVerified }),
  UPDATE_USER_ME_REQUEST: (params) => (params),
  UPDATE_USER_ME_FAILED: (error) => ({ error }),
  UPDATE_USER_ME_SUCCESS: (params) => (params),

  FETCH_USER_ME_REQUEST: () => ({}),
  FETCH_USER_ME_SUCCESS: (user) => ({ user }),
  FETCH_USER_ME_FAILED: (error) => ({ error }),

  FETCH_ONBOARDING_QUESTIONS_REQUEST: () => ({}),
  FETCH_ONBOARDING_QUESTIONS_SUCCESS: (questions) => ({questions}),
  FETCH_ONBOARDING_QUESTIONS_FAILED: (error) => ({error}),
});

AuthActions.loginWithEmailAndPassword = (email, password) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.loginPhoneRequest());
      const firebaseUser = await loginService(email, password);
      const userToken = await getUserToken();
      setAnalyticsUserId(firebaseUser.uid);
      await storeCredentials(userToken);
      const user = getUserFromFirebase();
      dispatch(AuthActions.loginSuccess(user, firebaseUser.emailVerified));
      dispatch(AuthActions.loggedIn());
    } catch (err) {
      dispatch(
        AuthActions.loginFailed({
          error: new GenericError(err, err)
        })
      );
    }
  }
);

const getDefaultUser = ({ country, phone, email }) => ({ firstName: "Guest", lastName: "Guest", country, type: "customer", phone, email });

const loginConfirmation = async (dispatch, firebaseUser) => {
  firebaseUserLocal.set(firebaseUser);
  const userToken = JSON.parse(JSON.stringify(firebaseUser)).stsTokenManager.accessToken;
  setAnalyticsUserId(firebaseUser.uid);
  await storeCredentials(userToken);
  const user = getUserFromFirebase();
  dispatch(AuthActions.loginSuccess(user, firebaseUser.emailVerified));
  dispatch(AuthActions.fetchUserMe());
  dispatch(AuthActions.loggedIn());
};

AuthActions.sendLinkToEmail = (url, email, country, location) => async (dispatch) => {
  try {
    dispatch(AuthActions.sendLoginEmailRequest());
    let urlMatches;
    if (location) urlMatches = enableAutoRegisterUrls.reduce((prev, curr) => prev || curr(location), false);
    log.debug('location', location);
    if (urlMatches) await registerUser(getDefaultUser({ country, email }));
    log.debug('urlMatches', urlMatches);
    const confirmationResult = await sendLinkToEmail(url, email);
    log.debug('confirmationResult', confirmationResult);
    window.confirmationResult = confirmationResult;
    log.debug('Confirmation assigned to window', window?.confirmationResult);
    
    if (confirmationResult.error) throw Error(confirmationResult.error);
  } catch (err) {
    dispatch(AuthActions.sendLoginEmailFailed({ error: new GenericError(err, err) }));
  }
};

AuthActions.signInWithEmailLink = (url, email) => async (dispatch) => {
  if (!mixpanel.isDisabled) mixpanel.alias(email);
  try {
    dispatch(AuthActions.loginEmailLinkRequest());
    const result = await signInWithEmailLink(url, email);
    if (result.user) {
      await loginConfirmation(dispatch, result.user);
      mixpanel.track_optimized('Auth Email link confirmed');
    } else {
      throw Error("No user found after email sign in");
    }
  } catch (err) {
    dispatch(AuthActions.loginEmailLinkFailed({ error: new GenericError(err, err) }));
  }
};

AuthActions.signInWithPhoneNumber = (phoneNumber, country, appVerifier, location) => {
  console.log('signInWithPhoneNumber')
  if (!phoneNumber) return null;
  const phone = phoneNumber[0] === '+' ? phoneNumber : `+${phoneNumber}`;
  
  if (!mixpanel.isDisabled) mixpanel.alias(phone);
  
  return async (dispatch) => {
    try {
      dispatch(AuthActions.loginPhoneRequest());
      let urlMatches;
      if (location) urlMatches = enableAutoRegisterUrls.reduce((prev, curr) => prev || curr(location), false);
      log.debug('location', location);
      if (urlMatches) await registerUser(getDefaultUser({ country, phone }));
      log.debug('urlMatches', urlMatches);
      const confirmationResult = await signInWithPhoneNumber(`${phoneNumber}`, appVerifier);
      log.debug('confirmationResult', confirmationResult);
      window.confirmationResult = confirmationResult;
      log.debug('Confirmation assigned to window', window?.confirmationResult);
      
      if (confirmationResult.error) throw Error(confirmationResult.error);
    } catch (err) {
      dispatch(AuthActions.loginFailed({error: new GenericError(err, err)}));
    }
  };
};

AuthActions.otcConfirmed = ({ user: firebaseUser }) => (
  async (dispatch) => {
    try {
      firebaseUserLocal.set(firebaseUser);
      const userToken = await firebaseUser.getIdToken(true);
      if (userToken === null) throw Error('No token');
      await storeCredentials(userToken);
      setAnalyticsUserId(firebaseUser.uid);
      const userF = getUserFromFirebase();
      dispatch(AuthActions.loginPhoneSuccess(userF, window.confirmationResult));
      dispatch(AuthActions.fetchUserMe());
      dispatch(AuthActions.loggedIn());
      mixpanel.track_optimized('Auth Phone OTC confirmed');
    } catch (err) {
      dispatch(
        AuthActions.loginFailed({
          error: new GenericError(err, err)
        })
      );
    }
  }
);

AuthActions.signInWithGoogle = () => async (dispatch) => {
    try {
      dispatch(AuthActions.loginRequest());
      const firebaseUser = await signInWithGoogleService();
      await loginConfirmation(dispatch, firebaseUser);
      mixpanel.track_optimized('Auth Google confirmed');
    } catch (err) {
      dispatch(AuthActions.loginFailed({ error: new GenericError(err, err) }));
    }
  };

AuthActions.createAccount = (params) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.createAccountRequest());
      const firebaseUser = await createAccountService({ ...params });
      const userToken = await getUserToken();
      await updateProfile(params);
      await sendEmailVerification();
      const user = getUserFromFirebase();
      setAnalyticsUserId(firebaseUser.uid);
      storeCredentials(userToken);
      // Removed so it doesn't cause a dependency cycle
      // dispatch(NotificationActions.registerNotifications());
      dispatch(AuthActions.createAccountSuccess(user, firebaseUser.emailVerified));
    } catch (err) {
      dispatch(
        AuthActions.createAccountFailed({ error: new GenericError(err, err) })
      );
    }
  }
);

AuthActions.sendEmailVerification = () => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.emailVerificationRequest());
      await sendEmailVerification();
      dispatch(AuthActions.emailVerificationSuccess());
    } catch (err) {
      dispatch(
        AuthActions.emailVerificationFailed({ error: new GenericError(err, err) })
      );
    }
  }
);

AuthActions.verifyEmail = (code) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.emailVerificationRequest());
      await verifyEmailService(code);
      dispatch(AuthActions.emailVerificationSuccess());
    } catch (err) {
      dispatch(
        AuthActions.emailVerificationFailed({ error: new GenericError(err, err) })
      );
    }
  }
);

AuthActions.forgotPassword = (email) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.forgotPasswordRequest());
      await forgotPasswordService(email);
      dispatch(AuthActions.forgotPasswordSuccess());
    } catch (err) {
      dispatch(
        AuthActions.forgotPasswordFailed({ error: new GenericError(err, err) })
      );
    }
  }
);

AuthActions.changePassword = (password) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.changePasswordRequest());
      await changePasswordService(password);
      dispatch(AuthActions.changePasswordSuccess());
    } catch (err) {
      dispatch(AuthActions.changePasswordFailed({ error: new GenericError(err, err) }));
    }
  }
);

AuthActions.updateUserMe = (params) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.updateUserMeRequest(params));
      const result = await updateUserMe(params);
      dispatch(AuthActions.updateUserMeSuccess(result.data));
    } catch (err) {
      dispatch(AuthActions.updateUserMeFailed({ error: new GenericError(err, err) }));
    }
  }
);

AuthActions.restoreUserAccess = () => (
  async (dispatch) => {
    let loggedIn = false;
    try {
      const firebaseUser = await checkFirebaseUserSession();
      if (firebaseUser) {
        await restoreCredentials();
        const user = getUserFromFirebase();
        if (user) {
          dispatch(AuthActions.updateUser(user, firebaseUser.emailVerified));
          setAnalyticsUserId(firebaseUser.uid);
          loggedIn = true;
        }
      } else {
        log.debug('Removing credentials');
        dispatch({ type: "RESET_STORE" });
      }
    } catch (err) {
      log.error('Failed to restoreUserAccess', err);
    }
    if (loggedIn) return dispatch(AuthActions.loggedIn());
    return dispatch(AuthActions.loggedOut());
  }
);

AuthActions.updateAccount = (params) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.updateAccountRequest());
      const firebaseUser = await updateProfile(params);
      const user = getUserFromFirebase();
      await dispatch(
        AuthActions.updateAccountSuccess(user, firebaseUser.emailVerified)
      );
    } catch (err) {
      dispatch(AuthActions.updateAccountFailed({ error: new GenericError(err, err) }));
      throw err;
    }
  }
);

AuthActions.uploadProfilePicture = (file) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.uploadProfilePictureRequest());
      const url = await uploadFile(file);
      await dispatch(AuthActions.updateAccount({ profile_url: url }));
      dispatch(AuthActions.uploadProfilePictureSuccess());
    } catch (err) {
      dispatch(AuthActions.uploadProfilePictureFailed(err));
      throw err;
    }
  }
);

AuthActions.fetchUserMe = (data) => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.fetchUserMeRequest());
      const result = await getUserMe(data);
      if (!result.ok) {
        const errMsg = result.data?.message || result.originalError?.message;
        const err = new GenericError("", errMsg, { status: result?.status });
        throw (err);
      }
      const userMeUser = result?.data?.data;
      const localFirebaseUser = firebaseUserLocal.get();
      firebaseUserLocal.remove();
      // Check if user doesn't have a phone number in Firebase but it exists in the DB
      if (localFirebaseUser && (!localFirebaseUser?.phoneNumber && userMeUser?.phone)) {
        // Ask backend to update firebase user with user data
        await updateFirebaseData();
      }
      dispatch(AuthActions.fetchUserMeSuccess(result.data));
      // dispatch(AuthActions.loginSuccess(result.data.data, true)); // TODO Might need this
    } catch (err) {
      dispatch(AuthActions.fetchUserMeFailed({ error: err }));
    }
  }
);

AuthActions.fetchOnboardingQuestions = () => (
  async (dispatch) => {
    try {
      dispatch(AuthActions.fetchOnboardingQuestionsRequest());
      const result = await getOnboardingQuestions();
      if (!result.ok) {
        const errMsg = result.data?.message || result.originalError?.message;
        const err = new GenericError("", errMsg, { status: result?.status });
        throw (err);
      }
      dispatch(AuthActions.fetchOnboardingQuestionsSuccess(result.data));
    } catch (err) {
      dispatch(AuthActions.fetchOnboardingQuestionsFailed({ error: err }));
    }
  }
);

AuthActions.logout = (redirection) => (
  async (dispatch) => {
    await removeCredentials();
    await dispatch({ type: "RESET_STORE" });
    if (redirection) (window.location.href = '/sign-in');
    dispatch(AuthActions.loggedOut());
  }
);

export default AuthActions;
