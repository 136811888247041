import { createActions } from "redux-actions";
// import { ToastContainer, ToastMessage } from "react-toastr";

import {
    getStore,
} from "../../services/store";


import { GenericError } from "../../utils/errors";


const StoreActions = createActions({
  FETCH_STORE_REQUEST: () => {},
  FETCH_STORE_SUCCESS: (store) => ({store}),
  FETCH_STORE_FAILED: (error) => ({ error }),
});

StoreActions.fetchStore = (data) => async (dispatch) => {
    try {
      dispatch(StoreActions.fetchStoreRequest());
      const result = await getStore(data);
      if (!result.ok) {
        if (result?.data?.message) throw (result.data.message);
        else throw new Error('Lo sentimos, por favor intenta de nuevo');
      }
      dispatch(StoreActions.fetchStoreSuccess(result.data));
    } catch (err) {
      dispatch(StoreActions.fetchStoreFailed({ error: new GenericError(err, err) }));
    }
  };



export default StoreActions;
