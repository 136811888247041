import React, { useEffect, useState } from 'react';
import { Grid, Drawer } from '@mui/material';
import { withTranslation } from 'react-i18next';

import styles from './styles.module.sass';
import { usePrevious } from '../../utils/hooks';

const PWAInstallerModal = (props) => {
  const {
    t,
    promptToInstall,
    modals, 
    userType,
    onClick
  } = props;

  const pwaInstaller = modals?.pwaInstaller ;
  const prevpwaInstaller = usePrevious(pwaInstaller);
  const [visible, setVisible] = useState(false);
  
  const close = () => {
    onClick("pwaInstaller");
    setVisible(false);
  };

  useEffect(() => {
    if (!pwaInstaller && !pwaInstaller !== prevpwaInstaller) {
      if (userType === 'referrer') {
        setVisible(true);
      }
    }
  }, [pwaInstaller, prevpwaInstaller, userType]);

  return (
    <Drawer
      anchor='bottom'
      open={visible}
      onClose={close}
      transitionDuration={500}
      PaperProps={{ style: { borderRadius: '50px 50px 0 0', boxShadow: '0px 0px 12px 0px rgb(0 0 0 / 44%)' } }}
    >
      <div className={styles['drawer-body']}>
        <Grid container justifyContent="center" style={{ margin: '20px 0' }}>
          <img src="/icons/icon-96x96.png" alt="Wao Shop Logo" />
        </Grid>

        <h4 className={styles.title}>{t('PWAInstaller.headline')}</h4>
        <p className={styles.content}>{t('PWAInstaller.copyBody')}</p>
        <button className="btn btn-outline-secondary" style={{ marginBottom: '22px' }} onClick={() => { close(); promptToInstall(); }}>
          {t('PWAInstaller.installButton')}
        </button>

        <button className="btn btn-info" style={{ marginBottom: '11px' }} onClick={close}>
          {t('PWAInstaller.dismissButton')}
        </button>
      </div>
    </Drawer>
  );
};

export default withTranslation()(PWAInstallerModal);