/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useContext } from 'react';
import ContentLoader from "react-content-loader";
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ShareButton from '../../components/ShareButton';
import WaoIcon from '../../components/WaoIcon';
import styles from './styles.module.sass';
import { getAssetType, getUrlExtension, filterMedia, mimeTypes, buildProductURL, getMinMax, fileDownload, getDealPrices } from '../../utils';
import imagePlaceholder from '../../assets/images/splash/img-placeholder.png';
import Modal from '../../components/Modal';
import mixpanel from '../../services/mixpanel';
import PriceDiscountFormat from '../../components/PriceDiscountFormat';
import { AppContext } from '../../contexts/AppContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import log from '../../utils/log';

const ActiveOfferItem = ({ t, shopId, data, loading }) => {

  const usableAppContext = useContext(AppContext);
  const { notify } = usableAppContext;
  const parsedMedia = filterMedia(data?.media, 'marketing');
  const firstImage = parsedMedia.find(media => mimeTypes[getUrlExtension(media)] === 'image') || imagePlaceholder;
  const copyUrl = buildProductURL(shopId, data?.dealId || data?.deals[0]?.dealId);
  const textToCopy = `${t('activeOffers.copyTextOffer')} ${copyUrl}`;

  const copyIcon = <WaoIcon size={16} color="#000000" icon="content_copy" />;

  const [minPrice, maxPrice] = getMinMax(data?.deals?.map(deal => getDealPrices(deal).price));
  const [minCommission, maxCommission] = getMinMax(data?.deals?.map(deal => getDealPrices(deal).commission));
  const [minDiscountPrice, maxDiscountPrice] = getMinMax(data?.deals?.map(deal => getDealPrices(deal).discountPrice));

  const samePrice = minPrice === maxPrice;
  const sameDiscountPrice = minDiscountPrice === maxDiscountPrice;
  // const sameCommission = minCommission === maxCommission;

  const ModalButton2 = useCallback(() => (
    <div>
      <img src={firstImage} alt='placeholder' />
    </div>
  ), [firstImage]);

  const ShareHtml = useCallback(() => (
    <button type="button" className="btn btn-round">
      <WaoIcon size={20} color="#fff" icon="share" /> {t('Share')}
    </button>
  ), [t]);

  if (loading) {
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height={180}
        viewBox="0 0 600 180"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="25" y="0" rx="3" ry="3" width="25%" height="25" />
        <rect x="25" y="35" rx="3" ry="3" width="15%" height="10" />
        <rect x="25" y="55" rx="3" ry="3" width="50%" height="15" />
        <rect x="25" y="77" rx="3" ry="3" width="55%" height="15" />
        <rect x="25" y="99" rx="3" ry="3" width="57%" height="15" />
        <rect x="25" y="121" rx="3" ry="3" width="40%" height="15" />
        <rect x="70%" y="45" rx="3" ry="3" width="22%" height="136" />
        <rect x="70%" y="5" rx="8" ry="8" width="22%" height="27" />
      </ContentLoader>
    );
  }

  // shareProductTitle = `WAO! - ${data.name}`,
  const fileDownloadHandler = async (assets, index) => {
    log.log('Downbloading file', assets, index);
    mixpanel.track_optimized('DownLoad ', { file: data.name });
    assets.forEach((asset, i) => {
      try {
        fetch(asset)
          .then(res => res.blob()).then(blob => {
            const ext = getUrlExtension(blob.type);
            fileDownload(blob, `${data.name}_${((index || i) + 1)}.${ext}`);
          }).catch(err => {
            if (err.name === 'TypeError' && err.message === 'cancelled') return;
            toast.error(`No fue posible procesar las imágenes para descargar${err} . ${err.message} . ${err.name}`);
            log.log(err);
          });
      } catch (error) {
        toast.error(`No fue posible acceder a las imágenes para descargar ${error}`);
      }
    });
  };

  const renderAsset = (url, index) => {
    let result = "";
    let fileType = getUrlExtension(url);
    if (mimeTypes[fileType]) fileType = mimeTypes[fileType];
    if (getAssetType(fileType) === 'application') {
      result = <iframe title={index} src={url} />;
    }
    else if (getAssetType(fileType) === 'video') {
      result = <video src={url} controls>Your browser does not support the video tag.</video>;
    }
    else if (getAssetType(fileType) === 'image') {
      result = <img src={url} alt='offer-asset' />;
    }
    if (result) result =
      <div className={styles["asset-row"]} key={index}>
        {result}
      </div>;
    return result;
  };

  const onCopyHandler = () => {
    notify({ message: t('message.textCopiedToClipboard'), background: '#000', color: '#fff', duration: 1500 });
  };

  const offerContent = () => (
    <div className={styles['offer-content']}>
      <div className="app-wrapper-mobile">
        <div>
          <p className={styles['main-title']}>{data.name}</p>
          {/* <div className={styles['grid-legend']}>{getAssetCount()}</div> */}
        </div>
        <div className={styles.prices}>
          <span className={styles.you}>
            <span>{`${t('You earn')}: `}</span>
            {samePrice && sameDiscountPrice ?
              <PriceDiscountFormat value={minCommission} simple />
              :
              (<span><PriceDiscountFormat value={minCommission} simple /> - <PriceDiscountFormat value={maxCommission} simple /></span>)
            }
          </span>
          <span className={styles.them}>
            <span>{`${t('Your customer pays')}: `}</span>
            {samePrice ?
              <PriceDiscountFormat value={minDiscountPrice} simple />
              :
              (<span><PriceDiscountFormat value={minDiscountPrice} simple /> - <PriceDiscountFormat value={maxDiscountPrice} simple /></span>)
            }
          </span>
        </div>
        <hr />
        <div className={styles['copy-instructions']}>
          {t('activeOffers.instruction')}
        </div>
        <div className={styles['number-instruction']}>
          {t('activeOffers.number1')}
        </div>
        <div className={styles['copy-instructions']}>
          {t('activeOffers.clickTheIcon')} {copyIcon} {t('activeOffers.copyInstructions')}
        </div>
        <div className={styles['copy-section']}>
          <CopyToClipboard text={textToCopy} onCopy={onCopyHandler}>
            <div className={styles.icon}>
              {copyIcon}
            </div>
          </CopyToClipboard>
          <div className={styles.text}>
            <div>
              <span id='copy-url'>
                <span>{t('activeOffers.copyTextOffer')}</span><br /><br /><span className={styles['copy-link']}>{copyUrl}</span>
              </span>
            </div>
          </div>
        </div>
        <div className={styles['number-instruction']}>
          {t('activeOffers.number2')} {t('product').toLowerCase()}
        </div>
        <div className={styles['copy-instructions']}>
          {t('activeOffers.instruction2-1')} <br /><br /> {t('activeOffers.instruction2-2')}
        </div>
        <div className={styles['material-text']}>
          {t('activeOffers.material')}
        </div>
        <div className={styles.assets}>
          {parsedMedia.map((media, mediaIndx) => {
            let result = '';
            if (mediaIndx < parsedMedia.length - 1) result =
              <div key={media}>
                {renderAsset(media, mediaIndx)}
                <hr />
              </div>;
            else
              result = renderAsset(media, mediaIndx);
            return result;
          })}
        </div>
        <div className={styles['buttons-section']}>
          <div className={styles.share}>
            <ShareButton sourceSlug="active offers" component={ShareHtml} info={{ url: firstImage, copyText: '' }} drawer />
          </div>
          <div className={styles.download}>
            <button type="button" className="btn btn-outline" onClick={async () => { fileDownloadHandler(parsedMedia); }}>
              <WaoIcon className={styles['base-header-button']} size={20} color="#000000" icon="download" />{t('Download')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );




  return (
    <div className={styles['offer-item-grid']}>
      <div>
        <div className={styles['grid-images']}>
          <Modal button={ModalButton2} flipClose closeButton topBar="" content={offerContent} />
        </div>
      </div>
      <div>
        <div>
          <h6 className={styles['grid-title']}>
            <Modal button={data?.name} flipClose closeButton topBar="" content={offerContent} />
          </h6>
          {/* <span className={styles['grid-legend']}>{getAssetCount()}</span> */}
        </div>
        <div className={styles['grid-actions']}>
          {/* <WaoIcon className={styles['base-header-button']} size={20} color={'#000000'} icon={'download'} onClick={async () => { fileDownloadHandler([parsedMedia[0]]) }} />
          <ShareButton buttonClass={styles['base-header-button']} size={20} info={shareObject} /> */}
        </div>
        <div className={styles['grid-summary-container']}>
          <span className={`${styles['grid-summary-1']} ${styles['grid-summary']}`}>
            <span>{`${t('You earn')}: `}</span>
            {samePrice && sameDiscountPrice ?
              <PriceDiscountFormat value={minCommission} simple />
              :
              (<span><PriceDiscountFormat value={minCommission} simple /> - <PriceDiscountFormat value={maxCommission} simple /></span>)
            }
          </span>
          <span className={`${styles['grid-summary-2']} ${styles['grid-summary']}`}>
            <span>{`${t('Your customer pays')}: `}</span>
            {samePrice ?
              <PriceDiscountFormat value={minDiscountPrice} simple />
              :
              (<span><PriceDiscountFormat value={minDiscountPrice} simple /> - <PriceDiscountFormat value={maxDiscountPrice} simple /></span>)
            }
          </span>
          {/* <span className={`${styles['grid-summary-3']} ${styles['grid-summary']}`}>
            <Modal button={ModalButtonViewDetails} flipClose closeButton topBar={''} content={offerContent} />
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ActiveOfferItem);
