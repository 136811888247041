/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Geocode from "react-geocode";
import { withTranslation } from 'react-i18next';
import { GoogleMap/* , useJsApiLoader */ } from '@react-google-maps/api';
import { cleanString, getLocationFromPlace } from './utils';
import WaoIcon from "../WaoIcon";
import styles from './styles.module.sass';

let pendingZoomUpdate = false;
let zoomUpdateTimeout;
let googleCreds;

const AddressStates = React.memo(({ findOnMap, t, showVerified, showRequired }) => (
  <div className={styles.address_states}>
    <div className={styles.address_states__inner}>
      <div className={`${styles.__verified} ${showVerified ? styles.__active : null}`} >
        <figure>
          <WaoIcon color="#068f20" size={14} icon="done_mini-" />
        </figure>
        <span>{t("Address verified")}</span>
      </div>
      <div className={`${styles.__unverified} ${showRequired ? styles.__active : null}`}>
        <span>{t("Please input a valid address")}</span>
        <hr className={styles.divider} />
        <div className={styles.__unverified_cta} onClick={findOnMap}>
          <figure>
            <WaoIcon color="#e54a38" size={14} icon="bookmark-2" />
          </figure>
          <span>{t("Select Address on map")}</span>
        </div>
      </div>
    </div>
  </div>
));

export const addressIsEqual = (oldAddress, newAddress) => {
  let equal = true;
  const oldKeys = Object.keys(oldAddress);
  const newKeys = Object.keys(newAddress);
  if (oldKeys.length !== newKeys.length) return false;
  oldKeys.every(key => {
    if (!Object.prototype.hasOwnProperty.call(newAddress, key) || oldAddress[key] !== newAddress[key]) {
      equal = false;
      return false;
    }
    return true;
  });
  return equal;
};

const GoogleAutocompleteService = React.memo(({ t, useSimpleValue, validateRequired, disabled, hideVerified, clearable, outputAddressComponent, countryCode = '', onChange = () => { }, value, alt }) => {
  const containerStyle = { height: '300px' };
  const defaultLocationData = { lat: 0, lng: 0, city: '', state: '', zipCode: '', neighborhood: '' };
  const placesOptions = useMemo(() => ({ componentRestrictions: { country: countryCode }, types: ["address"] }), [countryCode]);
  const [selectedValue, setSelectedValue] = useState(value?.address);
  const [showMap, setShowMap] = useState(false);
  const [tempLocation, setTempLocation] = useState(defaultLocationData);
  const [showListing, setShowListing] = useState(false);
  const [addressState, setAddressState] = useState('init');
  const [valueInitialized, setValueInitialized] = useState(false);
  const [map, setMap] = useState(null);
  const [lastAddressSent, setLastAddressSent] = useState({});

  const updateOutside = useCallback(data => {
    if (addressIsEqual(data, lastAddressSent)) return;
    setLastAddressSent(data);
    outputAddressComponent(data);
  }, [lastAddressSent, outputAddressComponent]);

  const clearInput = useCallback(() => {
    setSelectedValue('');
    setAddressState('unverified');
    onChange('');
    // updateOutside({ address2: tempLocation.address2 });
  }, [onChange/* , updateOutside, tempLocation */]);

  const clearIcon = <WaoIcon className="input-clear" color="#999" icon="close-" size={18} onClick={clearInput}/>;
  

  if (!googleCreds) googleCreds = { apiKey: process.env.REACT_APP_GOOGLE_APIKEY };
  Geocode.setApiKey(googleCreds?.apiKey);
  Geocode.setRegion(countryCode);
  Geocode.enableDebug();

  // const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: googleCreds.apiKey });
  const onUnmount = useCallback((/* map */) => { setMap(null); }, []);

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: googleCreds.apiKey,
    debounce: 500
  });

  /**
    Sets verification, address input value and hides map and saves address data
  */
  const setGeoLocation = useCallback((data) => {
    setAddressState('verified');
    const fullAddress = data.formatted_address || data.fullAddress;
    const simplerAddress = fullAddress?.split(',')?.[0] || '';
    setSelectedValue(useSimpleValue ? simplerAddress : fullAddress);
    setShowMap(false);
    if (data.lat && typeof data.lat === 'function') updateOutside({ ...data, lat: data.lat(), lng: data.lng() });
    else updateOutside(data);
  }, [updateOutside, useSimpleValue]);

  const setAddressFromPlace = useCallback((place) => {
    setShowListing(false);
    const locationData = getLocationFromPlace(place, countryCode);
    setTempLocation(locationData);
    setGeoLocation({ ...locationData });
  }, [countryCode, setGeoLocation]);

  // When address suggestion is clicked, geocode and set as selected address   
  const selectItem = useCallback(({ description, place_id }) => {
    placesService.getDetails({ placeId: place_id }, (placeDetails, status) => {
      if (status === 'OK') {
        setAddressFromPlace({ ...placeDetails, formatted_address: description });
      } else {
        console.error('Error: ', 'Something went wrong getting the place details', placeDetails, status);
      }
    });
  }, [placesService, setAddressFromPlace]);

  // Address prediction element
  const renderItem = useCallback((data, key) => (<li onClick={() => { selectItem(data); }} key={key} data={data}>{data.description}</li>), [selectItem]);

  const getPlaceFromAddress = (addressString) => Geocode.fromAddress(addressString).then((response) => new Promise(resolve => { resolve(response.results[0]); }), (error) => {
    console.error('error:', error);
    return new Promise((resolve, reject) => { reject(error); });
  });


  // Try to find current address on map
  const findOnMap = useCallback(() => {
    setShowListing(false);
    getPlaceFromAddress(cleanString(selectedValue || placePredictions?.[0]?.description || countryCode)).then((mapPlace) => {
      const mapLocation = getLocationFromPlace(mapPlace, countryCode);
      setTempLocation(mapLocation);
      if (!showMap) setShowMap(true);
    }).catch((error) => {
      console.error('Error finding place on map:', error);
    });
  }, [countryCode, placePredictions, selectedValue, showMap]);

  const updateTempLocationFromCoordinates = (newLat, newLng) => {
    if (newLat !== tempLocation.lat && newLng !== tempLocation.lng) {
      // Get Place from coordinates
      Geocode.fromLatLng(newLat, newLng).then(response => {
        const locationData = getLocationFromPlace(response.results[0], countryCode);
        setTempLocation(locationData);
      },
        error => { console.error('Error:', error); }
      );
    }
  };

  const onMapDragEnd = () => {
    const { lat, lng } = map?.getCenter()?.toJSON() || {};
    updateTempLocationFromCoordinates(lat, lng);
  };

  const clearPendingZoomUpdate = () => {
    if (zoomUpdateTimeout) clearTimeout(zoomUpdateTimeout);
    zoomUpdateTimeout = undefined;
    pendingZoomUpdate = false;
  };

  const onZoomChanged = () => {
    clearPendingZoomUpdate();
    pendingZoomUpdate = true;
  };

  const onIdle = () => {
    if (pendingZoomUpdate) {
      clearPendingZoomUpdate();
      zoomUpdateTimeout = setTimeout(() => {
        const { lat, lng } = map?.getCenter()?.toJSON() || {};
        updateTempLocationFromCoordinates(lat, lng);
      }, 500);
    }
  };

  useEffect(() => {
    if (value?.address) setSelectedValue(value?.address);
  }, [value]);

  /**
   * If the address already had a value set, mark the address as verified once if it meets the requirements
   */
  useEffect(() => {
    if (!valueInitialized) {
      if (value?.address && value?.city && value?.region && value?.lat && value?.lng) setAddressState('verified');
      setValueInitialized(true);
    }
  }, [addressState, value, valueInitialized]);

  const addressInputChangeHandler = useCallback(evt => {
    const { value: inputValue } = evt.target;
    setSelectedValue(inputValue);
    if (addressState === 'verified') setAddressState('unverified');
    onChange(inputValue);
    // updateOutside({ address2: tempLocation.address2 });
    getPlacePredictions({ input: `${inputValue}`, ...placesOptions });
    setShowListing(true);
  }, [addressState, onChange, /* tempLocation, updateOutside, */ getPlacePredictions, placesOptions]);

  const addressInputBlurHandler = useCallback(() => {
    setTimeout(() => { setShowListing(false); }, 200);
  }, []);

  const addressInputFocusHandler = useCallback(() => {
    if (placePredictions && placePredictions.length > 0) setShowListing(true);
  }, [placePredictions]);


  const isVerified = addressState === 'verified';

  return (
    <>
      <div className={`${styles['google-search-wrapper']} ${(clearable ? styles.clearable : "")} ${(alt ? styles.alt : "")}`}>
        <input
          placeholder="Ingresa una dirección" autoComplete="off-false-no" type="text"
          onChange={addressInputChangeHandler} value={selectedValue}
          onFocus={addressInputFocusHandler} onBlur={addressInputBlurHandler}
          disabled={disabled}
        />
        {(clearable && selectedValue) ? clearIcon : ''}
      </div>

      {/* Address Suggestions */}
      <div className={styles['google-listing-wrapper']}>
        <ul className={showListing ? styles.__show : '__hide'}>
          <li className={styles['select-address-label']} onClick={(e) => { e.preventDefault();}}>
            {hideVerified ? <span>{t("googleAddress.useManualAddress")}</span> : <span>{t("googleAddress.selectVerifiedAddress")}</span>}
          </li>
          {isPlacePredictionsLoading ? (<p className={`${showListing ? (styles.__show) : ('__hide') } ${ styles.loading}`}> {`${t('Loading results') }...`}</p>) : placePredictions.map((item, key) => renderItem(item, key))}
          <li className={styles['find-on-map']} onClick={findOnMap}>
            <figure>
              <WaoIcon color="#e54a38" size={14} icon="bookmark-2" />
            </figure>
            <span>{t("Locate on map")}</span>
          </li>
        </ul>
      </div>
      <div className={styles['google-map-wrapper']}>
        {
          (!showMap /* || !isLoaded */) ? null :
            <div>
              <div className={styles["relative-container"]}>
                <GoogleMap
                  mapContainerStyle={containerStyle} clickableIcons={false} center={{ lat: tempLocation.lat, lng: tempLocation.lng }} zoom={16}
                  options={{ fullscreenControl: false, streetViewControl: false, scaleControl: false, rotateControl: false, mapTypeControl: false }}
                  onLoad={setMap} onUnmount={onUnmount} onIdle={onIdle}
                  onDragEnd={onMapDragEnd} onZoomChanged={onZoomChanged}
                />
                <WaoIcon color="#ee7340" size={35} icon="location" />
              </div>
              <div className={styles.__data}>
                <span>{t("Map address")}:</span>
                <p>{tempLocation?.formatted_address}</p>
                <br />
                <p>
                  <span>{t("City")}:</span> {tempLocation?.city} <span>{t("Region")}:</span> {tempLocation?.state}
                </p>
                <p>
                  <span>{t("Postal Code")}:</span> {tempLocation?.zipCode || '--'} <span>{t("Neighborhood")}:</span> {tempLocation?.neighborhood || '--'}
                </p>
                <p>
                  <span>Lat:</span> {tempLocation?.lat} <span>Lng:</span> {tempLocation?.lng}
                </p>
                <div className={styles.__actions}>
                  <button type="button" className="btn btn-round" onClick={setGeoLocation.bind(null, tempLocation)}>
                    {t("Use Address")}
                  </button>
                </div>
              </div>
            </div>
        }
      </div>
      <AddressStates
          showVerified={!hideVerified && isVerified}
          showRequired={(!hideVerified && !isVerified) || (validateRequired && !selectedValue)}
          addressState={addressState} showMap={showMap} findOnMap={findOnMap} t={t}
        />
    </>
  );
});

export default withTranslation()(GoogleAutocompleteService);