import React, { useCallback, useEffect, useRef, useState } from "react";
import WaoIcon from "../../WaoIcon";
import styles from './styles.module.sass';
import "react-step-progress-bar/styles.css";
import PriceDiscountFormat from '../../PriceDiscountFormat';
import { withTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import { styled } from "@mui/styles";
import { useDispatch } from "react-redux";
import PaymentActions from "../../../redux/payments/actions";

const PointsSlider = styled(Slider)({
  color: '#ff6856',
  height: 8,
  '& .MuiSlider-rail': {
    height: 10,
    borderRadius: 4,
    backgroundColor: '#cbcbcb'
  },
  '& .MuiSlider-track': {
    // border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#ff6856',
    border: '2px solid #ffca56',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const PointsSelectionPanel = ({ t, visible = false, setVisible, points, limit, currency, rate, onClose, pointsUsed = 0, dataFetchPaymentMethod, redeemPoints }) => {

  const dispatch = useDispatch();
  const [inputPoints, setInputPoints] = useState(pointsUsed);
  const barRef = useRef();
  const inputRef = useRef();
  const theRef = useRef();
  const height = theRef?.current?.clientHeight ? theRef.current.clientHeight : 0;
  const remainingAvailablePoints = points - inputPoints;

  const close = useCallback((closePoints) => {
    if (setVisible) setVisible(false);
    if (onClose) onClose(closePoints);
  }, [onClose, setVisible]);

  const applyPoints = useCallback(() => {
    setInputPoints(inputPoints);
    dispatch(PaymentActions.fetchPaymentMethods(dataFetchPaymentMethod));
    close(inputPoints);
  }, [close, dataFetchPaymentMethod, dispatch, inputPoints]);


  const handleFocus = useCallback(e => e.target.select(), []);
  const sliderOnChange = useCallback((e, value) => setInputPoints(value || 0), [setInputPoints]);
  const handleChange = useCallback(e => {
    const { value } = e.target;
    const numValue = Number.parseInt(value, 10) || 0;

    if (numValue > limit) setInputPoints(limit);
    else setInputPoints(numValue);
  }, [limit]);

  useEffect(() => {
    if (visible) inputRef?.current?.focus();
  }, [visible]);

  return (
    <div className={`${styles['panel-container']}${visible ? '' : 'close-panel'}`}>
      <div
        ref={theRef}
        className={styles.points}
        style={{ bottom: visible ? `${height !== 0 ? '-45px' : `${height * -1}`}` : `-800px` }}>
        <div className={styles['points-header']}>
          <div onClick={close}>
            <div className={styles['close-cnt']}>
              <WaoIcon color="#030029" size={16} icon="close-" />
            </div>
          </div>
        </div>

        <div className={styles['points-body']}>
          <div className={styles.pointsContent}>
            <div className={styles.pointsTitle}>{t('HowManyPoints')}</div>
            <div className={styles.pointsDescription}>{`${t('PointsLimitExplanation')} `}</div>
            <div className={styles.pointsAvailable}>
              <div>{t('Available points')}: {remainingAvailablePoints}</div>
              <div>{t('Points limit')}: {limit === 0 && redeemPoints === true ? t('No limit') : limit}</div>
              <div>{t('Available credit')}: <PriceDiscountFormat suffix align="left" value={(points - inputPoints) * (rate?.toFixed(2) || 0)} currency={currency} parenthesis={false} /></div>
            </div>

            {
              !redeemPoints ? <p style={{ fontSize: "16px", textAlign: "center", padding: "20px 0 0", fontWeight: "600" }}>{t('NoUsedPoints')}</p> :
                <>
                  <div className={styles.pointsValue}>
                    <input ref={inputRef} type="number" onFocus={handleFocus} onChange={handleChange} value={Number.parseInt(inputPoints, 10).toString()} />
                    <div className={styles.blank}/>
                    <PriceDiscountFormat suffix align="center" value={((inputPoints || 0) * (rate || 0)).toFixed(2)} currency={currency} parenthesis={false} />
                  </div>

                  <div className={styles.pointsProgressBar} ref={barRef}>
                    <PointsSlider
                      aria-label="points slider" defaultValue={0} value={inputPoints} min={0}
                      max={limit} onChange={sliderOnChange}
                    />
                    <div className={styles.remainingPoints}>
                      <span>{t('Remaining max points')} {(limit + pointsUsed) - inputPoints}</span>
                    </div>
                  </div>
                </>
            }

          </div>
          <button type="button" className="btn btn-round" style={{ marginBottom: '11px', marginTop: '32px' }} onClick={!redeemPoints ? close : applyPoints}>
            {!redeemPoints ? t('Accept') : t('Apply')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PointsSelectionPanel);
