import React, { useContext, useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styles from './styles.module.sass';
import { AppContext } from '../../contexts/AppContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const secondsToStartSlider = 1;

const SliderPromos = ({ t, id, promos, copy, imagesSlider, clickFunction }) => {
  const [play, setPlay] = React.useState(false);

  useEffect(() => {
    // Starts the slider after 1 second has passed
    const playTimeout = setTimeout(() => { setPlay(true); }, secondsToStartSlider * 1000);
    return () => { if (playTimeout) clearTimeout(playTimeout); };
  }, []);
 
  const usableAppContext = useContext(AppContext);
  const { notify } = usableAppContext;

  const itemClicked = (elementId, index, item) => {
    if (clickFunction && Object.prototype.hasOwnProperty.call(item, 'key')) {
      const promo = promos.find(p => item.key === `${p.id}`);
      if (promo) clickFunction(promo);
    }
  };

  const textToCopy = document.querySelector(`li.slide.selected div.${styles.code} span`)?.textContent;

  const onCopyHandler = useCallback(() => {
    notify({ message: t('Code copied to clipboard!'), background: '#000', color: '#fff', duration: 1500 });
  }, [notify, t]);

  let handleItemClick;
  if (!copy) handleItemClick = ((index, item) => { itemClicked(id, index, item); });

  const SurroundingComponent = useCallback((props) => {
    let result = <div {...props} />;
    if (copy) result = <CopyToClipboard {...props} />;
    return result;
  }, [copy]);

  return (
    <SurroundingComponent text={textToCopy} onCopy={onCopyHandler}>
      <div>
        <Carousel onClickItem={handleItemClick}
          className={`promos-slider ${styles.slider} ${(copy ? styles.copy : '')}`}
          showThumbs={false} infiniteLoop emulateTouch interval={5000}
          showArrows={false} showStatus={false} showIndicators={!!imagesSlider} autoPlay={play} >
          {
            (promos) && promos?.map(({ id: promoId, label, code, images }, key) => {
              
              if (imagesSlider && (!images || !images.length || images.length < 1)) return null;
              
              return (<div className={`${styles.item}`} key={promoId}>
                  {
                    !imagesSlider ?
                    <div className={styles.promo}>
                      <div className={styles.title}>{label}</div>
                      <div className={styles.code} id={`${id}-${key}`}>{t('Code')}: <span>{code}</span></div>
                      <div className={styles.legal}>{t("ApplyCodeToCart")}</div>
                    </div>
                      :
                      <img src={images[0]} alt="" />
                  }
                </div>
              );})
          }
        </Carousel>
      </div>
    </SurroundingComponent>
  );
};

export default withTranslation()(SliderPromos);
