import React from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HeaderStore from '../../../components/HeaderStore';
import styles from './styles.module.sass';
import { Helmet } from 'react-helmet';
import { filterMedia } from '../../../utils';
import IconRow from '../../../components/IconRow';
import DealCard from '../DealItemVariants';
import { useSelector } from 'react-redux';
import { propEq } from 'ramda';

const DealDetail = ({ t, deals, history, isOwnStore, mainDealId }) => {
  const mainDeal = deals.deals.find(deal => deal.dealId === mainDealId) || deals.deals[0];
  const { storeId } = useParams();
  const { userMe } = useSelector(state => state.auth);
  const relativeDealUrl = `/store/${storeId}/deals/${mainDeal.dealId}`;
  const parsedMedia = filterMedia(mainDeal.media || [], 'product', 'zoho');
  const url = document.location.origin + relativeDealUrl;
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
  const isReferrer = propEq('type', 'referrer');

  const productStructuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: deals.name,
    image: parsedMedia,
    description: mainDeal.details,
    sku: mainDeal.sku,
    mpn: mainDeal.dealId,
    offers: {
      '@type': 'Offer',
      url,
      priceValidUntil: aYearFromNow.toJSON().slice(0, 10),
      priceCurrency: mainDeal.currency,
      price: mainDeal.price,
      itemCondition: 'https://schema.org/NewCondition',
      availability: mainDeal.quantity > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
    },
  };

  const infoIcons = [
    { icon: 'badge', size: '32', color: '#1b2835', description: t('dealPage.icons.guarantee') },
    { icon: 'badge-check', size: '30', color: '#1b2835', description: t('dealPage.icons.safe') },
    { icon: 'returns', size: '36', color: '#1b2835', description: t('dealPage.icons.returns') },
  ];

  return (
    <div className={styles['deal-content']}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WAO! - Detalles de Producto - {mainDeal.name}</title>
        <meta name="twitter:card" content="summary" />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={mainDeal.name} />
        <meta property="og:description" content={mainDeal.details} />
        <meta property="og:image" content={parsedMedia.length > 0 ? parsedMedia[0] : ''} />
        <meta property="og:product:price:amount" content={mainDeal.price} />
        <meta property="og:product:price:currency" content={mainDeal.currency} />
        <script type="application/ld+json">{JSON.stringify(productStructuredData)}</script>
      </Helmet>
      <section className={styles['col-top']}>
        <HeaderStore history={history} showLogo={ userMe && isReferrer(userMe)} icons={{ back: true, cart: true, customCatalog: userMe && isReferrer(userMe), }} altColor />
      </section>

      <section className={styles['col-scroll']}>
        <section className={styles['deal-main-info']}>
          <DealCard
            sourceSlug="deal detail"
            deals={deals}
            share
            isOwnStore={isOwnStore}
            enableSelector
            mainDeal={mainDeal}
            showPoints={userMe && isReferrer(userMe)}
          />
        </section>
        <IconRow icons={infoIcons} className={styles.icons} />
        <section className={styles['deal-additional-info']}>
          <h3>{t('Description')}</h3>
          <pre>{mainDeal.details}</pre>
        </section>
      </section>
    </div>
  );
};

export default withTranslation()(DealDetail);
