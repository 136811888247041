import React, { Fragment } from 'react';
import ActiveOfferItem from './ActiveOfferItem';
import { Box, Divider } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

const ActiveOfferList = ({ data=[], loading = false, pagination: p, fetchData, shopId, country, error }) => {

  const dataLength = p?.to || 0;
  const hasMoreData = p?.lastPage && (p?.currentPage < p?.lastPage);

  if (error) return (<div style={{ textAlign: "center", fontStyle: "italic", color: "#de0e0e", paddingBottom: "40px" }}>
    {error?.userMessage}
  </div>);

  return (
    <InfiniteScroll 
          dataLength={dataLength} 
          next={fetchData} 
          scrollThreshold={0.7}
          hasMore={hasMoreData}
          loader={
            <>
              <ActiveOfferItem loading={loading} />
              <ActiveOfferItem loading={loading} />
              <ActiveOfferItem loading={loading} />
            </>
          } 
        >
          {data?.map((offer, offerIndx) => (
            <Fragment key={`AO${offerIndx}`}>
              <ActiveOfferItem data={offer} shopId={shopId} country={country} />
              <Box mb={1}><Divider /></Box>
            </Fragment>
          ))}
        </InfiniteScroll>
  );
};

export default ActiveOfferList;