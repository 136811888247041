import React from 'react';
import { withTranslation } from 'react-i18next';
import CurrencyFormat from 'react-currency-format';

const Price = ({ style = {}, value, currency, suffix, parenthesis = true, crossed }) => {
  const priceStyles = { fontWeight: "600" };
  if (crossed) {
    priceStyles.textDecoration = "line-through";
    priceStyles.fontWeight = "400";
    priceStyles.color = "#979797";
  }

  const currencyToShow = parenthesis ? `(${currency})` : currency;
  const suffixContent = suffix && currency ? ` ${currencyToShow}` : '';
  const hasDecimalPrice = value % 1 !== 0;

  return (
    <CurrencyFormat isNumericString style={{ ...priceStyles, ...style }} value={value} displayType="text" thousandSeparator decimalSeparator="." prefix="$" suffix={suffixContent} decimalScale={2} fixedDecimalScale={hasDecimalPrice} />
  );
};

export default withTranslation()(Price);