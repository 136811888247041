import api from './api';

// const completeValidation = data => ({
//   country: '',
//   firstName: '',
//   lastName: '',
//   phone: '',
//   type: '',
//   ...data
// });

export const createCustomer = data => api.post("users/register", data);
export const validateUser = data => api.post("users/validate", data);
export const updateUser = (id, data) => api.put(`/users/${id}`, data);
export const updateFirebaseData = () => api.get('/users/updateFirebaseData');
