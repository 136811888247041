/* eslint-disable no-unused-expressions */
import { useEffect, useRef, useState } from 'react';

// Hook to check if the user is near the screen
export default function useHiddenScreen({
  externalRef,
  once = true
}) {

  // Create a ref to store the element
  const [isHiddenScreen, setShow] = useState(false);
  const fromRef = useRef();
  const element = externalRef ? externalRef.current : fromRef.current;

  useEffect(() => {
    let observer;


    // If the element is not defined, return early from the function and don't run the observer and set the state to false
    const onChange = (entries) => {
        if (entries[0].intersectionRatio === 0 ) {
            setShow(true);
          once && observer.disconnect();
        }else if (entries[0].intersectionRatio === 1) {
            !once && setShow(false);
        }

      } ;


    // Create the observer
    Promise.resolve(
      typeof IntersectionObserver !== 'undefined' ?
      IntersectionObserver :
      import('intersection-observer')
    ).then(() => {

      const options = {
        threshold: [0,1]
      };

      const observerAction = new IntersectionObserver(onChange, options);

      if (element) observerAction.observe(element);
    });

    return () => {
      observer && observer.disconnect();
    };

  }, [element, once]);

  return {
    isHiddenScreen,
    fromRef
  };
}