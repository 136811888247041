import OrderActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {

    case OrderActions.createOrderRequest().type:
      return state.merge({
        orderLoading: true,
        orderError: null,
        orderLoaded: false,
      });

    case OrderActions.createOrderSuccess().type:
      return state.merge({
        orderLoading: false,
        orderError: null,
        orderLoaded: true,
        order: action.payload.order.data || state.order,
      });

    case OrderActions.createOrderWithPaymentRequest().type:
      return state.merge({
        orderPaymentLoading: true,
        orderPaymentError: null,
        orderPaymentLoaded: false,
      });

    case OrderActions.updateOrderPaymentStatus().type:
      return state.merge({
        orderPaymentLoading: action.payload.loading
      });

    case OrderActions.createOrderWithPaymentSuccess().type:
      return state.merge({
        orderPaymentLoading: false,
        orderPaymentError: null,
        orderPaymentLoaded: true,
        orderPayment: action.payload.orderPayment.data || state.orderPayment,
      });

    case OrderActions.createOrderWithPaymentFailed().type:
      return state.merge({
        orderPaymentLoading: false,
        orderPaymentError: action.payload.error || state.error,
        orderPaymentLoaded: true,
        orderPayment: null
      });

    case OrderActions.fetchOrdersRequest().type:
      return state.merge({
        ordersLoading: true,
        orderError: null,
        orderLoaded: false,
      });

    case OrderActions.fetchOrdersSuccess().type:
      return state.merge({
        ordersLoading: false,
        orderError: null,
        orderLoaded: true,
        orders: action.payload.orders.data || state.orders,
      });
    
    case OrderActions.fetchOrderStatusesRequest().type:
      return state.merge({
        orderLoading: true,
        orderError: null,
        orderLoaded: false,
      });

    case OrderActions.fetchOrderStatusesSuccess().type:
      return state.merge({
        orderStatusesLoading: false,
        orderStatusesError: null,
        orderStatusesLoaded: true,
        orderStatuses: action.payload.statuses || state.orderStatuses,
        orderStatusLabels: action.payload.labels || state.orderStatusLabels,
      });

    default:
      return state;
  }
};
