/* eslint-disable default-param-last */
import WorkflowActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  let comm;

  switch (action.type) {

    case WorkflowActions.fetchCommissionsRequest().type:
      return state.merge({
        commissionsLoading: true,
        commissionsError: null,
        commissionsLoaded: false,
        commissionsPagination: null,
        commissions: action.payload.currentPage === 1 ? null: state.commissions
      });

    case WorkflowActions.fetchCommissionsSuccess().type:
      if (action.payload.commissions.pagination.currentPage === 1) {
        comm = action.payload.commissions.commissions;
      } else if(action.payload.commissions.commissions && action.payload.commissions.commissions.length) {
        comm = state.commissions.concat(action.payload.commissions.commissions);
      } else {
        comm = action.payload.commissions.commissions;
      }
      return state.merge({
        commissionsLoading: false,
        commissionsError: null,
        commissionsLoaded: true,
        commissions: comm,
        commissionsPagination: action.payload.commissions.pagination
      });

    case WorkflowActions.fetchRewardsRequest().type:
      return state.merge({
        rewardsLoading: true,
        rewardsError: null,
        rewardsLoaded: false,
        rewardsPagination: null
      });

    case WorkflowActions.fetchRewardsSuccess().type:
      return state.merge({
        rewardsLoading: false,
        rewardsError: null,
        rewardsLoaded: true,
        rewards: action.payload.commissions.commissions,
        rewardsPagination: action.payload.commissions.pagination
      });

    case WorkflowActions.fetchCommissionsTotalsRequest().type:
      return state.merge({
        commissionsTotalLoading: true,
        commissionsTotalError: null,
        commissionsTotalLoaded: false
      });

    case WorkflowActions.fetchCommissionsTotalsSuccess().type:
      return state.merge({
        commissionsTotalLoading: false,
        commissionsTotalError: null,
        commissionsTotalLoaded: true,
        commissionsTotal: action.payload.commissions
      });

    default:
      return state;
  }
};
