import { createActions } from "redux-actions";
// import { ToastContainer, ToastMessage } from "react-toastr";

import {
  getCatalogsByReferrer, getPromosByReferrer
} from "../../services/promotions";

import { GenericError } from "../../utils/errors";

const PromotionsActions = createActions({
  FETCH_PROMOS_REQUEST: () => {},
  FETCH_PROMOS_SUCCESS: (promos) => (promos),
  FETCH_PROMOS_FAILED: (error) => ({ error }),

  FETCH_CATALOGS_REQUEST: () => {},
  FETCH_CATALOGS_SUCCESS: (catalogsList) => (catalogsList),
  FETCH_CATALOGS_FAILED: (error) => ({ error }),

});

PromotionsActions.fetchPromos = (data) => (
  async (dispatch) => {
    try {
      dispatch(PromotionsActions.fetchPromosRequest());
      const result = await getPromosByReferrer(data);
      dispatch(PromotionsActions.fetchPromosSuccess(result.data));
    } catch (err) {
      dispatch(PromotionsActions.fetchPromosFailed({ error: new GenericError(err, err) }));
    }
  }
);

PromotionsActions.fetchCatalogsList = (data) => (
  async (dispatch) => {
    try {
      dispatch(PromotionsActions.fetchCatalogsRequest());
      const result = await getCatalogsByReferrer(data);
      dispatch(PromotionsActions.fetchCatalogsSuccess(result.data));
    } catch (err) {
      dispatch(PromotionsActions.fetchCatalogsFailed({ error: new GenericError(err, err) }));
    }
  }
);


export default PromotionsActions;
