import { create } from 'apisauce';
import { credentialsLocal } from './local';
import { getCurrentUser, getUserToken, getIdTokenRefreshed } from './firebase';

// define the api
const api = create({ baseURL: process.env.REACT_APP_API_BASE_URL });

api.getToken = () => credentialsLocal.get();

api.storeToken = (credentials) => {
  credentialsLocal.set(credentials);
  api.setHeader("Authorization", `Bearer ${credentials}`);
};

api.removeToken = () => {
  credentialsLocal.remove();
  delete api.headers.Authorization;
};

api.restoreToken = () => {
  const credentials = credentialsLocal.get();
  if (credentials) {
    api.setHeader("Authorization", `Bearer ${credentials}`);
    return true;
  }

  return false;
};

export const axios = api.axiosInstance;

const sleep = async (ms) => new Promise(resolve => { setTimeout(resolve, ms); });
let refreshingToken = false;
// TODO Instead of a timeout we could probably listen for an event to retry pending requests
const refreshSleep = process.env.REACT_APP_TOKEN_REFRESH_SLEEP_SECS || 5;

const refreshToken = async (originalRequest) => {
  // Refresh Token
  const newRequest = originalRequest;
  let success = false;
  try {
    let newToken;
    const currentUser = await getCurrentUser();
    if (!currentUser) newToken = await getIdTokenRefreshed();
    // else newToken = await currentUser.getIdToken(true);
    else newToken = await getUserToken();
    api.storeToken(newToken);
    if (originalRequest) {
      newRequest.retry = true;
      newRequest.headers.Authorization = `Bearer ${newToken}`;
      success = true;
    } else {
      console.log('No original request to repeat');
    }
  } catch (error) {
    console.error('Unable to refresh token, retry manually');
    // return store.dispatch(AuthActions.logout());
  }
  refreshingToken = false;

  if (success) return axios(newRequest);
  return Promise.reject(new Error('IgnoreResponse'));
};

const refreshCheck = async (originalRequest) => {
  // Refresh Token
  const newRequest = originalRequest;
  if (refreshingToken) {
    await sleep(refreshSleep * 1000);
    newRequest.retry = true;
    console.log('Using stored token');
    newRequest.headers.Authorization = `Bearer ${credentialsLocal.get()}`;
    return axios(newRequest);
  }

  if (!originalRequest.retry) {
    newRequest.retry = true;
    refreshingToken = true;
    return refreshToken(newRequest);
  } 
  
  return Promise.reject(new Error('IgnoreResponse'));
};

axios.interceptors.response.use(
  response => response,
  async (error) => {
    const errorStatus = error?.response?.status;
    const originalRequest = error?.config;
    if (errorStatus === 401 && originalRequest && !originalRequest.retry && error?.response?.data?.message === 'auth/id-token-expired') {
      console.log('Attempting intentional token refresh');
      return refreshCheck(originalRequest);
    }
    
    if (errorStatus === 402 || errorStatus === 403) return Promise.reject(new Error('IgnoreResponse'));    
    if (errorStatus === 401 || errorStatus === 404) console.log('401/404 no token expired error');
    // return store.dispatch(AuthActions.logout());

    if (!error.response) {
      // Attempting to catch CORS error to refresh token, could just be an actual Network Error
      console.log('Attempting token refresh');
      return refreshCheck(originalRequest);
    }

    return Promise.reject(error);
  }
);
export default api;