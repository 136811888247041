import React from 'react';
import SettingsToggle from './SettingsToggle';

const SettingsSections = ({ data, loading }) => {
  const createSettingsItem = (item) =>
    <SettingsToggle key={item.label} label={item.label} description={item.description} value={item.value} updateFunction={item.updateFunction} dummy={loading} loading={item.loading} />;
  
  return (
    <>
      {data?.length && data.map(item => createSettingsItem(item))}
      {loading &&
        <>
          <SettingsToggle dummy={loading} />
          <SettingsToggle dummy={loading} />
          <SettingsToggle dummy={loading} />
        </>
      }
    </>
  );
};

export default SettingsSections;
