import Immutable from 'seamless-immutable';

const initialState = Immutable({
  filtersInit: true,
  countriesLoading: false,
  countriesError: null,
  countriesLoaded: false,
  countries: null,

  metadataLoading: false,
  metadataError: null,
  metadataLoaded: false,
  metadata: null,
});


export default initialState;
