import React from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '../Icon';

const IconRow = ({ icons = [], className }) => <section className={className} style={{
    margin: "34px 20px",
    justifyContent: "space-between",
    display: "flex"
  }}>
    {icons.map((iconObject, index) => {
      const { icon, size="20", color="#000", description } = iconObject;
      return <Icon key={index} icon={icon} size={size} color={color} description={description} />;
    })}
  </section>;

export default withTranslation()(IconRow);