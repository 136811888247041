import { createActions } from "redux-actions";

import {
  sendPageTracking
} from "../../services/rewards";

import { GenericError } from "../../utils/errors";

const PageTrackingActions = createActions({
  CREATE_PAGE_TRACKING_REQUEST: () => {},
  CREATE_PAGE_TRACKING_SUCCESS: (data) => ({data}),
  CREATE_PAGE_TRACKING_FAILED: (error) => ({ error }),
});

PageTrackingActions.createPageTracking = (data) => async (dispatch) => {
    try {
      dispatch(PageTrackingActions.createPageTrackingRequest());
      const result = await sendPageTracking(data);
      dispatch(PageTrackingActions.createPageTrackingSuccess(result));
    } catch (err) {
      dispatch(PageTrackingActions.createPageTrackingrFailed({ error: new GenericError(err, err) }));
    }
  };

export default PageTrackingActions;
