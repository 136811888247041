import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BenefitsImage from '../../assets/images/saving-program/benefits.png';
import DrawerPanel from '../DrawerPanel';
import SavingProgramModal from './Modal';
import WaoIcon from '../WaoIcon';
import DialogScreenHCentered from '../DialogScreenHCentered';
import { onEnter } from '../../utils';
import styles from './index.module.sass';
import { CircularProgress, IconButton, Menu, MenuItem } from '@mui/material';
import CryptoActions from '../../redux/crypto/actions';
import PriceDiscountFormat from '../PriceDiscountFormat';
import Icon from '../Icon';

const InfoIcon = ({ t, index, children }) => {
    const infoIconRef = useRef();
    const [viDialog, setViDialog] = useState(null);

    const leftVar = infoIconRef?.current?.getBoundingClientRect ? infoIconRef.current.getBoundingClientRect().left : 0;

    const clickHandler = (currentIndex) => viDialog === null ? setViDialog(currentIndex) : null;

    return (
        <div role="button" tabIndex="0" ref={infoIconRef} className={styles['waoicon-cnt']} onClick={() => { clickHandler(index); }} onKeyDown={() => { onEnter(clickHandler(index)); }}>
            <WaoIcon color="#090909" size={16} icon="info2" />
            {viDialog === index &&
                <DialogScreenHCentered leftAdj={-leftVar}>
                    {children}
                    <button type="button" onClick={() => setViDialog(null)} className={styles['dialog-btn']}>{t('Accept')}</button>
                </DialogScreenHCentered>
            }
        </div>
    );
};

const SavingProgram = ({ t, data, commission }) => {
    const dispatch = useDispatch();
    const { isCryptoRegistered, cryptoBalance } = data;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showSavingProgram, setShowSavingProgram] = useState(false);

    const { registerLoaded, unstakeLoading, stakeLoading } = useSelector(state => state.crypto);

    useEffect(() => {
        if (registerLoaded) {
            setShowSavingProgram(false);
        }
    }, [registerLoaded]);


    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleStake = () => {
        handleClose();
        dispatch(CryptoActions.stake());
    };

    const handleShowSavingProgram = () => {
        handleClose();
        setShowSavingProgram(true);
    };

    const handleUnstake = () => {
        handleClose();
        dispatch(CryptoActions.unstake());
    };

    useEffect(() => {
        if (isCryptoRegistered) {
            setShowSavingProgram(false);
        }
    }, [isCryptoRegistered]);

    return (<>
        <DrawerPanel visible={showSavingProgram} setVisible={setShowSavingProgram}>
            <SavingProgramModal data={data} />
        </DrawerPanel>
        {isCryptoRegistered ? (<div className={styles['savings-details']}>

            <div className={styles['main-box']}>
                <IconButton className={styles.menu} onClick={handleClick} disabled={unstakeLoading}>
                    {unstakeLoading || stakeLoading ? (
                        <CircularProgress />
                    ) : (<Icon icon="navigation-more" />)}

                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleUnstake}>{t('savingsProgram.requestPayment')}</MenuItem>
                    <MenuItem onClick={handleStake}>{t('savingsProgram.depositCommissions')}</MenuItem>
                    <MenuItem onClick={handleShowSavingProgram}>{t('savingsProgram.learnMore')}</MenuItem>
                </Menu>



                <h5>{t('savingsProgram.savingsWao')}</h5>
                <div className={styles?.value}>
                    <h4><PriceDiscountFormat value={cryptoBalance?.staked || 0} discount={0} /></h4>
                    <InfoIcon t={t} index={0}>{t('savingsProgram.savingsWao')}</InfoIcon>
                </div>
            </div>
            <div className={styles['other-box']}>
                <h5>{t('savingsProgram.interests')}</h5>
                <div className={styles?.value}>
                    <h4><PriceDiscountFormat value={cryptoBalance?.yield || 0} discount={0} /></h4>
                    <InfoIcon t={t} index={1}>{t('savingsProgram.interests')}</InfoIcon>
                </div>
            </div>

            <div className={styles['other-box']}>
                <h5>{t('savingsProgram.commisionPending')}</h5>
                <div className={styles?.value}>
                    <h4><PriceDiscountFormat value={commission || 0} discount={0} /></h4>
                    <InfoIcon index={2}>{t('savingsProgram.commisionPending')}</InfoIcon>
                </div>
            </div>
        </div>) : (
            <div className={styles['save-program-container']}>
                <img style={{
                    float: 'right',
                    margin: -17
                }} src={BenefitsImage} alt="savingProgram" />
                {t("savingsProgram.firstLabel")}
                <div className={styles['see-more']}>
                    <Link to="/commissions" onClick={handleShowSavingProgram}>
                        {t('savingsProgram.learnMore')} {" >"}
                    </Link>
                </div>
            </div>

        )}
    </>);
};

export default withTranslation()(SavingProgram);