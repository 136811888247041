import React, { useCallback, useState } from 'react';
import Store, { persistor } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Root from './pages/Root';
import 'react-toastify/dist/ReactToastify.css';
import 'toastr/build/toastr.min.css';
import CacheBuster from './CacheBuster';
import themeObject from './components/theme';
import { createTheme, CssBaseline, ThemeProvider/* , useMediaQuery */ } from '@mui/material';
import { ToastContainer } from 'react-toastify';


const Container = () => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = useCallback((mainColor) => augmentColor({ color: { main: mainColor } }), [augmentColor]);

  const theme = React.useMemo(
    () =>
      createTheme({
        ...themeObject,
        palette: {
          ...themeObject.palette,
          white: createColor('#FFFFFF'),
          paleGreen: createColor('#00A699'),
          waoYellow: createColor('#fdcb70'),
          waoOrange: createColor('#E54A38'),
          richBlack: createColor('#030029'),
          waoGrey: createColor('#646464'),
          // TODO Enable to autodetect dark mode
          // mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [createColor, /* prefersDarMode */],
  );

  return (
    <CacheBuster>
      {({ loading, isLatestVersion /* , refreshCacheAndReload */ }) => {
        // if (loading) return null;
        setShowUpdateMessage(!loading && !isLatestVersion);
        return (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={Store}>
            <ToastContainer 
              position="top-center"
              theme="colored"
              pauseOnHover
              hideProgressBar
              closeButton={false}
            />
              <PersistGate loading={null} persistor={persistor}>
                <Root showUpdateMessage={showUpdateMessage} />
              </PersistGate>
            </Provider>
          </ThemeProvider>
        );
      }}
    </CacheBuster>
  );
};

export default Container;