import React from 'react';
// import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';
// import { defaultCountry, onEnter } from '../../utils';
import StepProgressBar from '../../components/StepProgressBar';
import WaoTooltip from '../../components/WaoTooltip';
import { BigNumberRounDown } from '../../utils';
import Emoji from '../../components/Emoji';

// eslint-disable-next-line arrow-body-style
const PointsWidget = ({ t, data, showAvailiblePoints = false }) => {
  if (!data) return '';

  const { pending = 0, available = 0, step } = data;

  // calculate range
  const defaultPointsRanage = 6000;
  const pointsRanage = available/defaultPointsRanage;
  const pointsRanageRoundDown = BigNumberRounDown(pointsRanage);

  // get current step
  const minPoints = pointsRanageRoundDown * defaultPointsRanage;
  const maxPoints = minPoints + defaultPointsRanage;
  const stepRangeValue = step || 1000;

  const getCurrentStep = (minPointsparam, maxPointsparam,stepRangValueParam) => {
    const steps = [];
    let status = 0;
    for (let i = minPointsparam; i <= maxPointsparam; i += stepRangValueParam) {
      steps.push({
        status: status += 1,
        value: i 
      });
    }
    return steps;
  };


  return (
    <div className={styles['points-widget']}>
      <div className={styles.top}>
        <div className={styles.total}>
          <span>{available}</span>
          <div><Emoji label="star" content="⭐️" /></div>
        </div>
        {
          showAvailiblePoints && <div className={styles.available}>
            <span>
              {`${t('Pending', { count: 100 })}: ${pending}`}
            </span>
            <div>
              <WaoTooltip msg={t("availablePointsExplanation")} />
            </div>
        </div>
        }
      </div>
      <div className={styles.bottom}>
        <StepProgressBar data={data} steps={getCurrentStep(minPoints, maxPoints, stepRangeValue)} stepRangeValue={stepRangeValue} maxPoints={maxPoints} minPoints={minPoints} pointsRanageRoundDown={pointsRanageRoundDown}/>
      </div>
    </div>
  );
};

export default withTranslation()(PointsWidget);