import { createActions } from "redux-actions";
import { getPoints, getPointsHistory, getChallengesByCountry, getVouchers, createVoucher, getExchangePoints } from "../../services/points";
import { GenericError } from "../../utils/errors";

const PointsActions = createActions({
  CREATE_VOUCHER_REQUEST: () => {},
  CREATE_VOUCHER_SUCCESS: voucher => ({ voucher }),
  CREATE_VOUCHER_FAILED: error => ({ error }),
  FETCH_POINTS_REQUEST: data => data,
  FETCH_POINTS_SUCCESS: points => ({ points }),
  FETCH_POINTS_FAILED: error => ({ error }),
  FETCH_POINTS_HISTORY_REQUEST: data => data,
  FETCH_POINTS_HISTORY_SUCCESS: points => ({ points }),
  FETCH_POINTS_HISTORY_FAILED: error => ({ error }),
  FETCH_CHALLENGES_REQUEST: data => data,
  FETCH_CHALLENGES_SUCCESS: challenges => ({ challenges }),
  FETCH_CHALLENGES_FAILED: error => ({ error }),
  FETCH_VOUCHERS_REQUEST: data => data,
  FETCH_VOUCHERS_SUCCESS: vouchers => (vouchers),
  FETCH_VOUCHERS_FAILED: error => ({ error }),
  FETCH_EXCHANGE_POINTS_REQUEST: data => data,
  FETCH_EXCHANGE_POINTS_SUCCESS: exchangePoints => (exchangePoints),
  FETCH_EXCHANGE_POINTS_FAILED: error => ({ error }),
  RESET_EXCHANGE_POINTS_REQUEST: () => {},
  RESET_EXCHANGE_POINTS_SUCCESS: () => (null),
  RESET_EXCHANGE_POINTS_FAILED: error => ({ error }),

});

PointsActions.fetchPoints = data => async dispatch => {
  try {
    dispatch(PointsActions.fetchPointsRequest());
    const result = await getPoints({ ...data });
    dispatch(PointsActions.fetchPointsSuccess(result.data.data));
  } catch (err) {
    dispatch(PointsActions.fetchPointsFailed({ error: new GenericError(err, err) }));
  }
};
  
PointsActions.fetchPointsHistory = data => async dispatch => {
  try {
    dispatch(PointsActions.fetchPointsHistoryRequest(data));
    const result = await getPointsHistory(data);
    dispatch(PointsActions.fetchPointsHistorySuccess(result.data.data));
  } catch (err) {
    dispatch(PointsActions.fetchPointsHistoryFailed({ error: new GenericError(err, err) }));
  }
};

PointsActions.fetchChallenges = country => async dispatch => {
  try {
    dispatch(PointsActions.fetchChallengesRequest());
    const result = await getChallengesByCountry(country);
    dispatch(PointsActions.fetchChallengesSuccess(result.data.data));
  } catch (err) {
    dispatch(PointsActions.fetchChallengesFailed({ error: new GenericError(err, err) }));
  }
};

PointsActions.fetchVouchers = data => async dispatch => {
  try {
    dispatch(PointsActions.fetchVouchersRequest());
    const result = await getVouchers(data);
    dispatch(PointsActions.fetchVouchersSuccess(result.data));
  } catch (err) {
    dispatch(PointsActions.fetchVouchersFailed({ error: new GenericError(err, err) }));
  }
};

PointsActions.createVoucher = data => async dispatch => {
  try {
    dispatch(PointsActions.createVoucherRequest());
    const result = await createVoucher(data);
    dispatch(PointsActions.createVoucherSuccess(result.data));
  } catch (err) {
    dispatch(PointsActions.createVoucherFailed({ error: new GenericError(err, err) }));
  }
};

PointsActions.fetchExchangePoints = data => async dispatch => {
  try {
    dispatch(PointsActions.fetchExchangePointsRequest());
    const result = await getExchangePoints(data);
    dispatch(PointsActions.fetchExchangePointsSuccess(result.data.data));
  } catch (err) {
    dispatch(PointsActions.fetchExchangePointsFailed({ error: new GenericError(err, err) }));
  }
};

PointsActions.resetExchangePoints = () => async dispatch => {
  try {
    dispatch(PointsActions.resetExchangePointsRequest());
    dispatch(PointsActions.resetExchangePointsSuccess(null));
  } catch (err) {
    dispatch(PointsActions.resetExchangePointsFailed({ error: new GenericError(err, err) }));
  }
};



export default PointsActions;
