/* eslint-disable default-param-last */
import WorkflowActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case WorkflowActions.createCustomerRequest().type:
      return state.merge({
        customerLoading: true,
        customerError: null,
        customerLoaded: false,
      });

    case WorkflowActions.createCustomerSuccess().type:
      return state.merge({
        customerLoading: false,
        customerError: null,
        customerLoaded: true,
        customer: action.payload.customer.data || state.customer,
      });
          
    case WorkflowActions.createCustomerFailed().type:
      return state.merge({
        customerLoading: false,
        customerError: action.payload.error,
        customerLoaded: false,
      });

    case WorkflowActions.validateUserRequest().type:
      return state.merge({
        validUserLoading: true,
        validUserError: null,
        validUserLoaded: false,
      });

    case WorkflowActions.validateUserSuccess().type:
      return state.merge({
        validUserLoading: false,
        validUserError: null,
        validUserLoaded: true,
        validUser: action.payload.customer.data || state.customer,
      });
          
    case WorkflowActions.validateUserFailed().type:
      return state.merge({
        validUserLoading: false,
        validUserError: action.payload.error,
        validUserLoaded: false,
      });

    default:
      return state;
  }
};
