import CryptoActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action = '') => {

  switch (action.type) {

    /// REGISTER
    case CryptoActions.registerRequest().type:
      return state.merge({
        registerLoading: true,
        registerError: null,
        registerLoaded: false,
      });

    case CryptoActions.registerSuccess().type:
      return state.merge({
        registerLoading: false,
        registerError: null,
        registerLoaded: true,

        cryptoUser: action.payload.data || state.cryptoUser,
      });

    case CryptoActions.registerFailed().type:
      return state.merge({
        registerLoading: false,
        registerError: action.payload.error,
        registerLoaded: false,
      });

    /// STAKE
    case CryptoActions.stakeRequest().type:
      return state.merge({
        stakeLoading: true,
        stakeError: null,
        stakeLoaded: false,
      });

    case CryptoActions.stakeSuccess().type:
      return state.merge({
        stakeLoading: false,
        stakeError: null,
        stakeLoaded: true,

        cryptoUser: action.payload.data || state.cryptoUser,
      });

    case CryptoActions.stakeFailed().type:
      return state.merge({
        stakeLoading: false,
        stakeError: action.payload.error,
        stakeLoaded: false,
      });

    /// UNSTAKE
    case CryptoActions.unstakeRequest().type:
      return state.merge({
        unstakeLoading: true,
        unstakeError: null,
        unstakeLoaded: false,
      });

    case CryptoActions.unstakeSuccess().type:
      return state.merge({
        unstakeLoading: false,
        unstakeError: null,
        unstakeLoaded: true,

        cryptoUser: action.payload.data || state.cryptoUser,
      });

    case CryptoActions.unstakeFailed().type:
      return state.merge({
        unstakeLoading: false,
        unstakeError: action.payload.error,
        unstakeLoaded: false,
      });

    default:
      return state;
  }
};
