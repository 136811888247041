import InitialState from ".";
import NotificationActions from './actions';

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case NotificationActions.registerPushNotificationSuccess().type:
      return state.merge({
        token: action.payload.token
      });
    default: return state;
  }
};
