import Immutable from 'seamless-immutable';

const initialState = Immutable({
  paymentMethodsLoading: false,
  paymentMethodsError: null,
  paymentMethodsLoaded: false,
  paymentMethods: null,

  paymentTransactionLoading: false,
  paymentTransactionError: null,
  paymentTransactionLoaded: false,
  paymentTransaction: null,
});


export default initialState;
