const tokenName = 'access_token';
const goToSuccessFromCheckoutName = 'goToSuccessFromCheckout';
const homePointsNotificationName = 'homePointsNotification';
const firebaseUserName = 'waoFireU';
const emailForSignInName = 'emailForSignIn';
const mixpanelEnabledName = 'mixpanelEnabled';
const mixpanelDebugName = 'mixpanelDebug';
const userSettingsName = 'userSettings';

const defaultGet = (name, json = false) => () => {
  let result = localStorage.getItem(name);
  try {
    if (json) result = JSON.parse(result) || {};
  } catch (error) {
    console.error(`Error parsing get for ${name}`, error);
  }
  return result;
};

const defaultSet = (name, json = false) => object => {
  let objectToUse = object;
  try {
    if (json) objectToUse = JSON.stringify(object); 
  } catch (error) {
    console.error(`Error parsing set for ${name}`, error);
  }
  localStorage.setItem(name, objectToUse);
};

const defaultRemove = name => () => localStorage.removeItem(name);

/**
 * createStore
 * @param {string} name local storage key
 * @param {boolean} [json=false] json wether the content stored should be treated as a json object or not
 * @returns Functions to manage the content for the defined key. get, set, remove are returned for all values while update is only returned for json keys
 */
const createStore = (name, json) => {
  const result = {
    get: defaultGet(name, json),
    set: defaultSet(name, json),
    remove: defaultRemove(name),
  };

  if (json) result.update = (key, value) => {
    const currentData = result.get();
    currentData[key] = value;
    result.set(currentData);
  };

  return result;
};

export const credentialsLocal = createStore(tokenName);
export const goToSuccessFromCheckoutLocal = createStore(goToSuccessFromCheckoutName);
export const homePointsNotificationLocal = createStore(homePointsNotificationName);
export const emailForSignInLocal = createStore(emailForSignInName);
export const firebaseUserLocal = createStore(firebaseUserName, true);
export const mixpanelEnabledLocal = createStore(mixpanelEnabledName);
export const mixpanelDebugLocal = createStore(mixpanelDebugName);
export const userSettingsLocal = createStore(userSettingsName, true);