import React from 'react';
import Progress from '../Progress';
import styles from './styles.module.sass';

export const Recaptcha = ({ loader, refRecaptchaContainer, t, validateRefreshForRecaptcha, setValidateRefreshForRecaptcha, renderedCaptcha, ...props }) => {

  const refreshPageRecaptcha = () => {
    window.location.reload();
    setValidateRefreshForRecaptcha(false);
  };

  return (
    <div>
      {validateRefreshForRecaptcha ? <div className={styles['recaptcha-button-refresh']}>
        <span>{t('recaptchaMessagRefresh')}</span>
        <button className='btn btn-round' style={{ background: '#ee7d40' }} onClick={refreshPageRecaptcha}>
          {t('Refresh')}
        </button>
      </div>
        : ''
      }
      {
        !loader || renderedCaptcha || validateRefreshForRecaptcha ? '' : <div>
          <Progress size={20} color="waoOrange" />
        </div>
      }
      {refRecaptchaContainer ? <div ref={refRecaptchaContainer} id="recaptcha-container" className={`${styles['recaptcha-container-wrapper']} ${validateRefreshForRecaptcha ? 'hidden' : ''}`} {...props} /> : "" }
    </div>
  );
};
