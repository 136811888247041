/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import HeaderStore from '../../components/HeaderStore';
import styles from '../socio/home/styles.module.sass';
import ownStyles from './styles.module.sass';
import ActiveOffersList from './ActiveOffersList';
import WaoIcon from '../../components/WaoIcon';
import ShareButton from '../../components/ShareButton';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DealActions from '../../redux/deals/actions';
import { CancelToken } from 'apisauce';
import { useParams } from 'react-router-dom';
import { countryToCode } from '../../utils/constants';
import { paths } from 'ramda';
import mixpanel from '../../services/mixpanel';
import imagePlaceholder from '../../assets/images/splash/img-placeholder.png';
import { getAssetType, getUrlExtension, filterMedia, mimeTypes, buildPromoURL } from '../../utils';
import { AppContext } from '../../contexts/AppContext';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import log from '../../utils/log';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CatalogItemContainer = ({ t, history, location }) => {
  const usableAppContext = useContext(AppContext);
  const { notify } = usableAppContext;
  const [searchValue] = useState('');
  const [catalog] = paths([['state', 'catalog']], location);
  const dispatch = useDispatch();
  const [sourceDeals, serSourceDeals] = useState();
  const [shopId] = paths([['userMe', 'shopId']], useSelector(state => state.auth));
  const { deals, dealsPagination, dealsLoading, dealsError } = useSelector(state => state.deals);
  const { dealsByCountry, dealsByCountryPagination, dealsByCountryLoading } = useSelector(state => state.deals);
  const { country } = useParams();
  const countryCode = countryToCode(country || '');
  // const context = { context: countryCode?.toLowerCase() || userCountry?.toLowerCase() };
  const fetchData = useCallback((name, increase) => {
    // TODO The dispatch should probably be on a setState callback to prevent the sourceDeals from failing, need to review
    if (dealsLoading && sourceDeals) sourceDeals.cancel();
    const source = CancelToken.source();
    serSourceDeals(source);

    dispatch(DealActions.fetchDeals(
      {
        promotions: catalog?.id,
        shopId,
        name,
        currentPage: increase ? (dealsPagination?.currentPage || 1) + 1 : 1,
        cancelToken: source.token,
      })
    );

  }, [dealsLoading, dispatch, catalog, shopId, dealsPagination, sourceDeals]);

  useEffect(() => {
    if (!catalog) {
      history.push("/active-offers");
    } else {
      mixpanel.track_optimized('Catalog Details', { id: catalog?.id, name: catalog?.name });
      fetchData(null, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyIcon = <WaoIcon size={16} color="#000000" icon="content_copy" />;
  const copyUrl = buildPromoURL(shopId, catalog?.id);
  const textToCopy = `${t('activeOffers.copyTextCatalog')} ${copyUrl}`;

  const onCopyHandler = () => {
    notify({ message: t('message.textCopiedToClipboard'), background: '#000', color: '#fff', duration: 1500 });
  };

  const parsedMedia = filterMedia(catalog?.images, 'product', 'zoho');

  const renderAsset = (url, index) => {
    let result = "";
    let fileType = getUrlExtension(url);
    if (mimeTypes[fileType]) fileType = mimeTypes[fileType];
    if (getAssetType(fileType) === 'application') {
      result = <iframe title={index} src={url} />;
    }
    else if (getAssetType(fileType) === 'video') {
      result = <video src={url} controls>Your browser does not support the video tag.</video>;
    }
    else if (getAssetType(fileType) === 'image') {
      result = <img src={url} alt='offer-asset' />;
    }
    if (result) result =
      <div className={styles["asset-row"]} key={index}>
        {result}
      </div>;
    return result;
  };

  const firstImage = parsedMedia.find(media => mimeTypes[getUrlExtension(media)] === 'image') || imagePlaceholder;

  const ShareHtml = useCallback(() => (
    <button type="button" className="btn btn-round"><WaoIcon size={20} color="#fff" icon="share" /> {t('Share')}</button>
  ), [t]);

  const fileDownloadHandler = async (assets, index) => {
    log.log('Downbloading file', assets, index);
    mixpanel.track_optimized('DownLoad ', { file: catalog?.name });
    assets.forEach((asset, i) => {
      try {
        fetch(asset)
          .then(res => res.blob()).then(blob => {
            const ext = getUrlExtension();
            saveAs(blob, `${catalog?.name}_${((index || i) + 1)}.${ext}`);
          }).catch(err => {
            if (err.name === 'TypeError' && err.message === 'cancelled') return;
            toast.error(`No fue posible procesar las imágenes para descargar${err} . ${err.message} . ${err.name}`);
            log.log(err);
          });
      } catch (error) {
        toast.error(`No fue posible acceder a las imágenes para descargar ${error}`);
      }
    });
  };

  return (
    <div>
      <div className={`${styles['page-wrapper']} app-wrapper-mobile`}>
        <section className={styles.content}>
          <section className={styles.header}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>WAO! - {t('Promotional content')}</title>
              <link rel="canonical" href="https://app.wao.shop/active-offers" />
            </Helmet>
            <HeaderStore history={history} clickable={false} showLogo icons={{ back: true }} altColor />
          </section>
          <div className={ownStyles['offer-content']}>
            <div className="app-wrapper-mobile">
              <div>
                <p className={ownStyles['main-title']}>{catalog?.name}</p>
                {/* <div className={styles['grid-legend']}>{getAssetCount()}</div> */}
              </div>
              <div className={ownStyles['copy-instructions']}>
                {t('activeOffers.instruction')}
              </div>
              <div className={ownStyles['number-instruction']}>
                {t('activeOffers.number1')}
              </div>
              <div className={ownStyles['copy-instructions']}>
                {t('activeOffers.clickTheIcon')} {copyIcon} {t('activeOffers.copyInstructions')}
              </div>
              <div className={ownStyles['copy-section']}>
                <CopyToClipboard text={textToCopy} onCopy={onCopyHandler}>
                  <div className={ownStyles.icon}>
                    {copyIcon}
                  </div>
                </CopyToClipboard>
                <div className={ownStyles.text}>
                  <div>
                    <span id='copy-url'>
                      <span>{t('activeOffers.copyTextCatalog')}</span><br /><br /><span className={styles['copy-link']}>{copyUrl}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className={ownStyles['number-instruction']}>
                {t('activeOffers.number2')} {t('catalog').toLowerCase()}
              </div>
              <div className={ownStyles['copy-instructions']}>
                {t('activeOffers.instruction2-1')} <br /><br /> {t('activeOffers.instruction2-2')}
              </div>
              <div className={ownStyles['material-text']}>
                {t('activeOffers.material')}
              </div>
              <div className={ownStyles.assets} >
                {parsedMedia.map((media, mediaIndx) => {
                  let result = '';
                  if (mediaIndx < parsedMedia.length - 1) result =
                    <div key={media}>
                      {renderAsset(media, mediaIndx)}
                      <hr />
                    </div>;
                  else
                    result = renderAsset(media, mediaIndx);
                  return result;
                })}
              </div>
              <div className={ownStyles['buttons-section']}>
                <div className={ownStyles.share}>
                  <ShareButton sourceSlug="active offers" component={ShareHtml} info={{ url: firstImage, copyText: '' }} drawer />
                </div>
                <div className={ownStyles.download}>
                  <button type="button" className="btn btn-outline" onClick={async () => { fileDownloadHandler([firstImage], 0); }}>
                    <WaoIcon className={ownStyles['base-header-button']} size={20} color="#000000" icon="download" />{t('Download')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Divider className={ownStyles.divider} />
      <div className={`${styles['page-wrapper']} app-wrapper-mobile`}>
        <section className={styles.content}>
          <div className={ownStyles['page-wrapper-active-offers']}>
            <p className={ownStyles['main-subtitle']}>{t('offers-on-catalog')}</p>
            <p className={ownStyles['main-content']}>{t('Catalog content')}</p>
            {!dealsError ? <p className={ownStyles['main-numberOf']}>{`${deals?.length || dealsByCountry?.length} ${t('offers')}`}</p> : ''}
          </div>
          <div className={ownStyles['page-wrapper-active-offers']}>
            <ActiveOffersList
              data={deals || dealsByCountry}
              loading={dealsLoading || dealsByCountryLoading}
              error={dealsError}
              pagination={dealsPagination || dealsByCountryPagination}
              shopId={shopId}
              country={countryCode}
              fetchData={() => fetchData(searchValue, true)}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default withTranslation()(CatalogItemContainer);
