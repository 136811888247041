import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useField } from 'formik';
import '../../theme/theme.sass';
import { useDebounce, usePrevious, useRequest } from '../../utils/hooks';
import Progress from '../Progress';
import Icon from '../Icon';

const debounceTime = 500;

const TextInputLiveFeedback = React.memo(({ t, noBorder, tReady, label, helpText, trim, required, optional, forceFeedback, alt, validateFunction, validateWait, formValidationParams, disableWhileValidating, validationCustomError, setValidationError, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [/* didFocus */, setDidFocus] = React.useState(false);
  const handleFocus = () => setDidFocus(true);
  const { loading: validationLoading, error: validationError, data: validationResult, run: runValidation } = useRequest(validateFunction);
  const showFeedback = (/* (!!didFocus && field.value.trim().length > 2) && */ meta.error) || (meta.touched && meta.error) || (forceFeedback && meta.error) || validationError;
  const isTextArea = props.type === 'textarea';
  const [count, setCount] = useState(0);
  const [inputChanged, setInputChanged] = useState(false);
  const maxCharacters = props.maxLength;
  const previousValue = usePrevious(field?.value);
  const debouncedSearchValue = useDebounce(field?.value, validateWait || debounceTime);
  const previouseDebouncedValue = usePrevious(debouncedSearchValue);

  const shouldValidate = validateFunction && !meta?.error;
  const showValidationLoading = shouldValidate && (inputChanged || validationLoading);
  const showValidationCheck = shouldValidate && !validationLoading && !inputChanged && !validationError && validationResult;
  const showValidationFailed = shouldValidate && !validationLoading && !inputChanged && validationError;

  const onChangeHandler = (e) => {
    setInputChanged(true);
    if (setValidationError) setValidationError(true)
    let val = e?.target?.value;
    if (trim) val = val.replace(/\s/g, '');
    helpers.setValue(val);
    if (props.onChange) props.onChange(e);
  };

  const handleChangeTxtArea = (e) => {
    setCount(e?.target?.value?.length);
    onChangeHandler(e);
  };

  const handleBlur = e => {
    if (props.onBlur) props.onBlur(e);
  };

  useEffect(() => {
    const validateParamAsync = async () => {
      if (validateFunction && !meta.error) {
        if (debouncedSearchValue && debouncedSearchValue !== previouseDebouncedValue) {
          if (setValidationError) setValidationError(null);
          const params = formValidationParams ? formValidationParams(debouncedSearchValue) : debouncedSearchValue;
          setInputChanged(false);
          const response = await runValidation(params);
          if (setValidationError && response && !response.ok && response?.data?.message) setValidationError(validationCustomError || response?.data?.message);
        }
      }
    };
    validateParamAsync();
  }, [debouncedSearchValue, formValidationParams, helpers, meta.error, previouseDebouncedValue, runValidation, setValidationError, validateFunction, validationCustomError]);

  useEffect(() => {
    if (field?.value && previousValue !== field?.value) helpers.setValue(field?.value);
  }, [field?.value, helpers, previousValue]);

  return (
    <div className={`${alt ? 'form-control-mat-alt' : 'form-control-mat'} ${showFeedback ? 'invalid' : ''}`}>
      <div className="flex items-center space-between">
        <label htmlFor={props.id}>{label}{required ? <span className="required">*</span> : ''}{optional ? ` (${t("optional")})` : ''}</label>
      </div>
      {isTextArea ?
        (<>
          <textarea
            {...props} {...field}
            aria-describedby={`${props.id}-feedback ${props.id}-help`}
            onFocus={handleFocus} onChange={handleChangeTxtArea}
          />
          {maxCharacters ? <div type="text/html" style={{ color: "gray", float: "right", fontSize: "11px" }} >{`${count}/${maxCharacters}`}</div> : ''}
        </>) : ''
      }
      {!isTextArea ?
        <div>
          <div style={{ position: "absolute", right: "0px" }}>
            {showValidationLoading ? <Progress size={14} color="waoOrange" /> : ''}
            {showValidationCheck ? <Icon icon="check_circle_outline" size={16} color="#0aae8c" /> : ''}
            {showValidationFailed ? <Icon icon="cancel" size={16} color="#de0e0e" /> : ''}
          </div>
          <input
            {...props} {...field}
            className={`${noBorder ? 'no-border' : ''}`}
            aria-describedby={`${props.id}-feedback ${props.id}-help`}
            onFocus={handleFocus} onChange={onChangeHandler} onBlur={handleBlur}
            disabled={disableWhileValidating && validationLoading}
          /></div> : ''
      }
      {showFeedback ? (
        <div id={`${props.id}-feedback`} aria-live="polite" className="control-feedback">
          {meta.error || validationCustomError || validationError?.message}
        </div>
      ) : null}
    </div>
  );
});

export default withTranslation()(TextInputLiveFeedback);