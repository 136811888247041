/* eslint-disable react/no-danger */
import React from "react";
import styles from './styles.module.sass';
import { withTranslation } from "react-i18next";
import SpinnerCircularFixed from "../Spinner/CircularFixed";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const StepperInfo = ({steps, loading, error}) => (
  <div className={styles['step-body']}>
    {
      (!steps || loading) &&
      <div className={styles['step-spinner']}>
        <SpinnerCircularFixed size={64} thickness={180} speed={100} color="85, 85, 85" secondaryColor="85, 85, 85" />
      </div>
    }
    { error && <div className={styles['step-spinner']}> <p>{error}</p> </div> }

    {
      // TODO use sanitize-html instead of dangerouslySetInnerHTML
      !steps?.length ? '' :
      <Slider className={`step-slider ${styles['step-slider']}`} {...settings} >
        {
          steps?.map(({label, media, description}, index) => (
            <div className={styles['step-content']} key={label || media || description || index}>
              <div className={styles['step-title']} dangerouslySetInnerHTML={{__html:`<h3>${label}</h3>`}}/>
              <div className={styles['step-image']} key={label}>
                {media ? <img src={media} alt={label} /> : ''}
              </div>
              <div className={styles['step-description']} dangerouslySetInnerHTML={{__html:`<p>${description}</p>`}}/>
              {/* <div className={styles['step-buttons']}></div> */}
            </div>
          ))
        }   
      </Slider>
    }
  </div>
);

export default withTranslation()(StepperInfo);

