import React from "react";
import ContentLoader from "react-content-loader";

const CatalogsLoader = ({
  catalogs = 2,
  width,
  height,
  viewBox,
  backgroundColor = "#f3f3f3",
  foregroundColor = "#ecebeb",
  margin
}) => {
  const rows = Math.ceil(catalogs / 2);
  const cols = catalogs > 1 ? 2 : catalogs;
  const rectHeight = 186;
  const rectWidth = 234;
  const padding = 8;
  const col2X = rectWidth + (padding * 3);
  const rowFixed = padding;
  const rowIncrement = rectHeight + (padding * 2);
  const viewBoxHeight = (rows * rectHeight) + (rows * padding * 2);
  const viewBoxWidth = (cols * rectWidth) + (cols * padding * 2);
  const autoViewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
  return <ContentLoader
    width={width}
    height={height}
    speed={2}
    viewBox={viewBox || autoViewBox}
    backgroundColor={backgroundColor}
    foregroundColor={foregroundColor}
    style={{ margin }}
  >
    <rect x={padding} y={padding} rx="8" ry="8" width="234" height="186" />
    {catalogs > 1 ? <rect x={col2X} y={padding} rx="8" ry="8" width="234" height="186" /> : ''}
    {catalogs > 2 ? <rect x={padding} y={rowFixed + (rowIncrement * 1)} rx="8" ry="8" width="234" height="186" /> : ''}
    {catalogs > 3 ? <rect x={col2X} y={rowFixed + (rowIncrement * 1)} rx="8" ry="8" width="234" height="186" /> : ''}
    {catalogs > 4 ? <rect x={padding} y={rowFixed + (rowIncrement * 2)} rx="8" ry="8" width="234" height="186" /> : ''}
    {catalogs > 5 ? <rect x={col2X} y={rowFixed + (rowIncrement * 2)} rx="8" ry="8" width="234" height="186" /> : ''}
  </ContentLoader>;
};

export default CatalogsLoader;