import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styles from './index.module.sass';
import WaoIcon from '../WaoIcon';
import { length } from 'ramda';
import { useDispatch } from 'react-redux';
import DealActions from '../../redux/deals/actions';
import { useDebounce, usePrevious } from '../../utils/hooks';

const SearchBar = ({
  value = '',
  placeholder,
  lookupEvent,
  onChange,
  waitTime = 500,
  clearable,
  inputstyle={},
  containerstyle={},
  inputsIconStyle={},
  letterLength=0
}) => {

  const dispatch = useDispatch();
  const [hasMounted, setHasMounted] = useState(false);
  const debouncedSearchValue = useDebounce(value, waitTime);
  const previouseDebouncedValue = usePrevious(debouncedSearchValue);

  const onChangeCallback = useCallback(({ target: { value: eventValue } }) => {
    // TODO Shouldn't this be moved to whatever calls the onChange for the search bar?
    dispatch(DealActions.setSearchTerm(eventValue));
    if (onChange) onChange(eventValue);
  }, [dispatch, onChange]);

  const clearText = useCallback(() => {
    // TODO Shouldn't this be moved to whatever calls the onChange for the search bar?
    dispatch(DealActions.setSearchTerm(''));
    if (onChange) onChange('');
  }, [dispatch, onChange]);

  useEffect(() => {
    if (!hasMounted) setHasMounted(true);
    else if (lookupEvent && previouseDebouncedValue !== debouncedSearchValue && (length(debouncedSearchValue) === 0 || length(debouncedSearchValue) > letterLength)) lookupEvent(debouncedSearchValue);
  }, [debouncedSearchValue, hasMounted, letterLength, lookupEvent, previouseDebouncedValue]);

  const searchIcon = useMemo(() => ((clearable && !value) || !clearable) && (
      <WaoIcon 
        style={inputsIconStyle} 
        className={styles['search-bar-icon']} 
        color="#555555" 
        size={20} 
        icon="search_left-" 
      />
    ), [clearable, inputsIconStyle, value]);

  const clearIcon = useMemo(() => clearable && value && (
      <WaoIcon 
        style={inputsIconStyle} 
        className={styles['search-bar-clear-icon']} 
        color="#555555" 
        size={12} 
        icon="close-" 
        onClick={clearText} 
      />
    ), [clearable, inputsIconStyle, value, clearText]);


  return (
    <div style={containerstyle} className={styles['search-bar-container']}>
      <input style={inputstyle} value={value} className={styles['search-bar-input']} onChange={onChangeCallback} placeholder={placeholder} />
      {searchIcon}
      {clearIcon}
    </div>
  );
};

export default SearchBar;
