import { createActions } from "redux-actions";
import mixpanel from 'mixpanel-browser';
// import { toast } from 'react-toastify';

import { createCustomer, validateUser } from "../../services/customer";
import { GenericError } from "../../utils/errors";


const CustomerActions = createActions({
  CREATE_CUSTOMER_REQUEST: () => {},
  CREATE_CUSTOMER_SUCCESS: (customer) => ({customer}),
  CREATE_CUSTOMER_FAILED: (error) => ({ error }),
  VALIDATE_USER_REQUEST: () => {},
  VALIDATE_USER_SUCCESS: user => ({ user }),
  VALIDATE_USER_FAILED: (error) => ({ error }),
});

CustomerActions.createCustomer = (data) => (
   async (dispatch) => {
    try {
      dispatch(CustomerActions.createCustomerRequest());
      if (!mixpanel.isDisabled) mixpanel.alias(data.phone);
      const result = await createCustomer(data);
      if (!result.ok) {
        const errMsg = result.data?.message || result.originalError?.message;
        const customerError = new GenericError("", errMsg, { status: result?.status });
        // toast.error(errMsg);
        throw customerError;
      }
      dispatch(CustomerActions.createCustomerSuccess(result));
    } catch (err) {
      dispatch(CustomerActions.createCustomerFailed({ error: err }));
    }
  }
);

CustomerActions.validateUser = (data) => (
   async (dispatch) => {
    try {
      dispatch(CustomerActions.validateUserRequest());
      if (!mixpanel.isDisabled) mixpanel.alias(data.phone);
      const result = await validateUser(data);
      if (!result.ok) {
        const errMsg = result.data?.message || result.originalError?.message;
        const customerError = new GenericError("", errMsg, { status: result?.status });
        // toast.error(errMsg);
        throw customerError;
      }
      dispatch(CustomerActions.validateUserSuccess(result));
    } catch (err) {
      dispatch(CustomerActions.validateUserFailed({ error: err }));
    }
  }
);

export default CustomerActions;
